import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/Table';
import Modal from '../../components/Modal';
import Button from '@mui/material/Button';
import { FiDownload } from 'react-icons/fi'; // Replacing GetAppIcon with FiDownload
import { CSVLink } from 'react-csv';
import TextField from '@mui/material/TextField';

const TableHeaders = [
    { id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
    { id: 'timestamp', name: 'Recent Timestamp' },
    { id: 'plate-number', name: 'Plate #', sortable: true, numeric: false },
    { id: 'reason', name: 'Reason', sortable: true, numeric: false },
    { id: 'status', name: 'Status', sortable: true, numeric: false },
];

const SearchOptions = [
    { id: 'kiosk', name: 'Kiosk', pidx: 0 },
    { id: 'plate-number', name: 'Plate #', pidx: 2 },
];

const TableTabs = [
    { id: 'last 28 days', filter: 28, name: 'Last 28 days' },
    { id: 'last 60 days', filter: 60, name: 'Last 60 days' },
    { id: 'last 90 days', filter: 90, name: 'Last 90 days' },
    { id: 'last 180 days', filter: 180, name: 'Last 180 days' },
];

const TableHeaders2 = [
    { id: 'kiosk', name: 'Kiosk' },
    { id: 'dispatcher', name: 'Dispatcher', size: 'small-header' },
    { id: 'timestamp', name: 'Timestamp', size: 'small-header' },
    { id: 'plate', name: 'Plate #', size: 'small-header' },
    { id: 'resolution', name: 'Resolution' },
    { id: 'notes', name: 'Notes', size: 'xlarge-header' },
];

const CallLogTable = (props) => {
    const [callLogs, setCallLogs] = useState([]);
    const [kioskSelected, setKioskSelected] = useState({ id: null, kiosk: null });
    const [searchVal, setSearchVal] = useState('');
    const [searchParam, setSearchParam] = useState(0);
    const [filter, setFilter] = useState(TableTabs[0].filter);
    const [Loading, setLoading] = useState(true);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            RefreshTableData();
            setLoading(false);
        }

        return () => {
            mounted = false;
        };
    }, [filter]);

    /**
     * Refreshes the table data by fetching call logs from the server.
     */
    const RefreshTableData = () => {
        setLoading(true);
        socket.emit('fetchCallLogs', 'customer-log', filter, (result) => {
            setCallLogs(result[0]);
            setLoading(false);
        });
    };

    const handleModal = (e) => {
        const id = e.target.id;
        const log = callLogs.find((log) => log.CallLogID == id);
        setSelected(log);
        setKioskSelected((prevState) => ({
            ...prevState,
            id: id,
            kiosk: callLogs.find((kiosk) => kiosk.CallLogID == id).KioskID,
        }));
        $('#log-modal').toggleClass('flex-box');
    };

    const search = (param, searchVal) => {
        setSearchVal(searchVal);
        setSearchParam(param);
    };

    const clearSearch = () => {
        setSearchVal('');
    };

    // figure out why this and modal renders slowly
    const filterTable = (days) => {
        setFilter(TableTabs.filter((e) => e.filter === +days)[0].filter);
        setLoading(true);
        setCallLogs([]);
    };

    const getTableData = () => {
        return callLogs
            .map((kiosk) => (
            {
                key: kiosk.id,
                cells: [
                    { data: kiosk.kiosk_location },
                    { data: kiosk.date_of_call.split('T')[0] },
                    { data: kiosk.plate_number.toUpperCase() },
                    { data: kiosk.reason_for_call },
                    { data: kiosk.sticker_resolution },
                ],
            }))
            .filter((row) =>
                searchVal && searchParam
                    ? row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
                    : row
            );
    };

    const downloadData = () => {
        return callLogs.map((kiosk) => ({
            ID: kiosk.id,
            Kiosk: kiosk.kiosk_location,
            Timestamp: kiosk.date_of_call.split('T')[0],
            'Plate #': kiosk.plate_number.toUpperCase(),
            Reason: kiosk.reason_for_call,
            Status: kiosk.sticker_resolution,
        }));
    };

    const renderTableButtons = () => {
        return (
            <div style={{ display: 'flex' }}>
                <CSVLink
                    style={{ background: 'none', border: 'none' }}
                    target="_blank"
                    className="hidden"
                    id="table-download"
                    data={downloadData()}
                    filename={'call-logs.csv'}
                ></CSVLink>
                <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<FiDownload />} // Replacing GetAppIcon with FiDownload
                    onClick={() => document.getElementById('table-download').click()}
                >
                    Call Logs
                </Button>
            </div>
        );
    };

    return (
        <div className="ViewedContentContainer" id="OpenContainer">
            <div style={{ height: '100%' }}>
                <div className="TitleBarContainer">
                    <h1 title="All kiosk alerts">Customer Call Logs</h1>
                </div>
                <CustomTable
                    search_options={SearchOptions}
                    paginate
                    searchable
                    loading_data={Loading}
                    search={search}
                    clear_search={clearSearch}
                    headers={TableHeaders}
                    rows={getTableData()}
                    tabs={TableTabs}
                    tab_style="select"
                    active_tab={filter}
                    filter_data={filterTable}
                    onClick={handleModal}
                    onRefresh={RefreshTableData}
                    table_buttons={renderTableButtons()}
                />

                <Modal modal_id="log-modal" dimension={{ width: '1090' }} modal_header={kioskSelected.kiosk}>
                    <div style={{ textAlign: 'center' }}>
                        <div>
                            <TextField id="customer" type="text" defaultValue={selected.CustomerName || ''}></TextField>
                            <TextField id="phone" type="tel" defaultValue={selected.CustomerPhone || ''}></TextField>
                        </div>
                        <div>
                            <TextField id="call-date" type="datetime-local" defaultValue={selected.DateOfCall || ''}></TextField>
                            <TextField id="issue-date" type="datetime-local" defaultValue={selected.DateOfIssue || ''}></TextField>
                        </div>
                        <div>
                            <TextField id="dispatcher" type="text" defaultValue={selected.Dispatcher || ''}></TextField>
                            <TextField id="service" type="text" defaultValue={selected.IsServiceNeeded || ''}></TextField>
                        </div>
                        <div>
                            <TextField id="kiosk" type="text" defaultValue={selected.KioskID || ''}></TextField>
                            <TextField id="issue-date" type="text" defaultValue={selected.LastVin || ''}></TextField>
                        </div>
                        <div>
                            <TextField id="call-date" type="datetime-local" defaultValue={selected.DateOfCall || ''}></TextField>
                            <TextField id="issue-date" type="datetime-local" defaultValue={selected.DateOfIssue || ''}></TextField>
                        </div>
                        <div>
                            <TextField id="call-date" type="datetime-local" defaultValue={selected.DateOfCall || ''}></TextField>
                            <TextField id="issue-date" type="datetime-local" defaultValue={selected.DateOfIssue || ''}></TextField>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default CallLogTable;
