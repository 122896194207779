/**
 * Initial state for the user reducer.
 * @type {Object}
 */
const initialState = {
    user: null,
    // ...other initial state properties
};

/**
 * User reducer function to handle user-related actions.
 * @param {Object} state - The current state.
 * @param {Object} action - The action to be handled.
 * @param {string} action.type - The type of the action.
 * @param {Object} [action.user] - The user object.
 * @param {Array} [action.user_logs] - The user logs.
 * @returns {Object} The new state.
 */
export const userReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }
        case 'UPDATE_USER_LOGS':
            return {
                ...state,
                user: {
                    ...state.user,
                    user_logs: action.user_logs
                }
            }
        default:
            return state
    }
}