/**
 * LAST_UPDATED: 07/31/2024
 * Formats the AlertTable Data for each row
 */

import React, { useState, useEffect } from 'react';
import { FormatDateTime } from '../../imports/API/TextFormatingFunctions.js';
import { useCustomContext } from '../../hoc/Context.js';
import CustomTable from '../../components/Table.js';
import Button from '@mui/material/Button';
import { MdAdd } from 'react-icons/md';
import { MdGetApp } from 'react-icons/md';
import { CSVLink } from 'react-csv';
import Modal from '../../components/Modal';
import Divider from '@mui/material/Divider';
import AlertBar from '../../components/AlertBar.js';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Spinner from '../../components/Spinner.js';

const TableHeaders = [
  { id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
  { id: 'timestamp', name: 'Timestamp' },
  { id: 'status', name: 'Status', sortable: true, numeric: false },
  { id: 'issue', name: 'Issue', sortable: true, numeric: false },
];

const SearchOptions = [{ id: 'kiosk', name: 'Kiosk', pidx: 0 }];

const AlertTable = (props) => {
  const [searchVal, setSearchVal] = useState('');
  const [searchParam, setSearchParam] = useState(0);
  const [rowClicked, setRowClicked] = useState('');
  const [AlertsDB, setAlertsDB] = useState([]);
  const [IssuesDB, setIssuesDB] = useState([]);
  const [Kiosks, setKiosks] = useState([]);
  const [KioskServerID, setKioskServerID] = useState('');
  const [Loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [addAlert, setAddAlert] = useState(false);
  const [selected, setSelected] = useState({});
  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: '',
    severity: 'success',
  });
  const { userState, userDispatch } = useCustomContext();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      socket.emit('selectFromKiosksIssuesForTM', (issues) => {
        socket.emit('selectFromKioskAlert', (alerts) => {
          socket.emit('selectFromKiosksForCreateJob', (result) => {
            let kiosks = result.map((obj) => {
              let rObj = obj.KioskID + ' ' + obj.ServerID;
              return rObj;
            });

            let KioskServerID = result.reduce((newArray, currentArray) => {
              newArray[currentArray.KioskID] = currentArray.ServerID;
              return newArray;
            }, {});

            setIssuesDB(issues);
            setAlertsDB([alerts]);
            setKiosks(kiosks);
            setKioskServerID(KioskServerID);
            setLoading(false);
          });
        });
      });
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    $('#alert-modal').toggleClass('flex-box');
  }, [open]);

  useEffect(() => {
    $('#add-alert').toggleClass('flex-box');
    RefreshTableData();
  }, [addAlert, open]);

  const RefreshTableData = () => {
    setLoading(true);
    socket.emit('selectFromKioskAlert', (result) => {
      setAlertsDB([result]);
      setLoading(false);
    });
  };

  const AddNewItemBtn = () => {
    setAddAlert(true);
  };

  const RowClick = (e) => {
    const id = e.target.id;
    const alert = AlertsDB[0].find((el) => el.AlertID == id);
    if (alert) {
      setSelected(alert);
      setOpen(true);
    }
  };

  function RenderTableBody() {
    const renderData = Object.values(AlertsDB);

    return renderData[0]
      .map((alerts) => {
        return {
          key: alerts.AlertID,
          cells: [
            { data: alerts.KioskID },
            { data: FormatDateTime(alerts.OccuranceDateTimeStamp) },
            { data: alerts.KioskStatus },
            { data: alerts.Description },
          ],
        };
      })
      .filter((row) =>
        searchVal && searchParam
          ? row.cells[SearchOptions[searchParam - 1].pidx].data.toLowerCase().includes(searchVal.toLowerCase())
          : row
      );
  }

  const downloadData = () => {
    const downloadData = Object.values(AlertsDB);

    return downloadData[0].map((alert) => ({
      AlertID: alert.AlertID,
      Kiosk: alert.KioskID,
      Timestamp: alert.OccuranceDateTimeStamp.split('T')[0],
      Status: alert.KioskStatus,
      Description: alert.description,
    }));
  };

  const Search = (param, searchVal) => {
    setSearchVal(searchVal);
    setSearchParam(param);
  };

  const ClearSearch = () => {
    setSearchVal('');
  };

  function RenderTableButtons() {
    return (
      <div style={{ display: 'flex' }}>
        {userState.user.role ? (
          <Button className="rti-blue-round" variant="contained" startIcon={<MdAdd />} onClick={AddNewItemBtn}>
            Alert
          </Button>
        ) : undefined}
        <CSVLink
          style={{ background: 'none', border: 'none' }}
          target="_blank"
          className="hidden"
          id="table-download"
          data={downloadData()}
          filename={'alerts.csv'}
        ></CSVLink>
        <Button
          className="rti-blue-round"
          variant="contained"
          startIcon={<MdGetApp />}
          onClick={() => document.getElementById('table-download').click()}
        >
          Alerts
        </Button>
      </div>
    );
  }

  const handleModalClose = () => {
    setOpen(false);
    setSelected({});
  };

  const handleAddModalClose = () => {
    setAddAlert(false);
  };

  const handleRemoveAlert = () => {
    const confirm = prompt('Remove this alert?\n\nTo confirm removal, type REMOVE in the text input field.');
    if (confirm) {
      if (confirm.toLowerCase() === 'remove') {
        socket.emit('deleteAlertForTM', selected.AlertID, (boolean) => {
          if (boolean) {
            RefreshTableData();
            setOpen(false);
            axios.post('/api/refreshAlert');
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: 'Success! Alert has been removed.',
              severity: 'success',
            }));
          } else {
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: 'ERROR: We encountered an issue when trying to remove this alert.',
              severity: 'error',
            }));
          }
        });
      }
    }
  };

  const handleCloseSnack = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const handleAddAlert = () => {
    const kiosk = document.getElementById('kiosk').value;
    const issue = document.getElementById('issue').value;

    const obj = {
      kiosk: kiosk,
      status: issue,
      message: 'Sent Manually Through Dispatch Console.',
      origin: 'console',
    };

    axios.post('/api/alerts', obj);
    RefreshTableData();
    setAddAlert(false);
    setSnackbar((prevState) => ({
      ...prevState,
      visible: true,
      message: 'Success! Alert has been added.',
      severity: 'success',
    }));
  };

  return (
    <div className="ViewedContentContainer" id="OpenContainer">
      <div style={{ height: '100%' }}>
        {open ? (
          <Modal
            modal_id="alert-modal"
            dimension={{ width: 500 }}
            onClose={handleModalClose}
            modal_header={`${selected.KioskID} - ${selected.Description}`}
          >
            <div style={{ textAlign: 'center' }}>
              <h2>Status: {selected.KioskStatus}</h2>
              <h2>Occurred: {FormatDateTime(selected.OccuranceDateTimeStamp)}</h2>
              {userState.user.role ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Divider orientation="vertical" flexItem />
                  <Button className="secondary" onClick={handleRemoveAlert}>
                    Remove
                  </Button>
                </div>
              ) : null}
            </div>
          </Modal>
        ) : null}
        {addAlert && userState.user.role ? (
          <Modal modal_id="add-alert" dimension={{ width: 500 }} modal_header="Add Alert" onClose={handleAddModalClose}>
            <div>
              <div style={{ display: 'flex', margin: 32, justifyContent: 'space-between' }}>
                <div className="input-block">
                  <label className="input-label">Kiosk</label>
                  <div className="search-by">
                    <input
                      style={{ margin: 0, width: 200 }}
                      id="kiosk"
                      list="kiosk-list"
                      name="kiosk-list-dropdown"
                      placeholder="Search kiosk..."
                    ></input>
                    <datalist id="kiosk-list">
                      {Kiosks.map((kiosk) => (
                        <option key={kiosk} value={kiosk}>
                          {kiosk}
                        </option>
                      ))}
                    </datalist>
                  </div>
                </div>
                <div className="input-block">
                  <label className="input-label">Issue</label>
                  <div className="search-by">
                    <input
                      style={{ margin: 0, width: 200 }}
                      id="issue"
                      list="issue-list"
                      name="issue-list-dropdown"
                      placeholder="Search issue..."
                    ></input>
                    <datalist id="issue-list">
                      {IssuesDB.filter((el) => el.ErrorCode).map((issue) => (
                        <option key={issue.ErrorCode} value={issue.ErrorCode}>
                          {issue.Description}
                        </option>
                      ))}
                    </datalist>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Divider orientation="vertical" flexItem />
                <Button className="primary" onClick={handleAddAlert}>
                  Add
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
        <div className="TitleBarContainer">
          <h1>Alerts</h1>
        </div>
        <AlertBar
          visible={snackbar.visible}
          onClose={handleCloseSnack}
          message={snackbar.message}
          severity={snackbar.severity}
        />
        <div>
          {Loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <CustomTable
              searchable
              paginate
              loading_data={Loading}
              search={Search}
              clear_search={ClearSearch}
              headers={TableHeaders}
              search_options={SearchOptions}
              rows={RenderTableBody()}
              onClick={RowClick}
              table_buttons={RenderTableButtons()}
              onRefresh={RefreshTableData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertTable;
