/**
 * PrintBarcode Component
 * 
 * This component is responsible for generating and printing barcodes. It uses the bwip-js library to create barcode canvases.
 * 
 * @component
 * 
 * @example
 * return (
 *   <PrintBarcode />
 * )
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @typedef {Object} BarcodeOptions
 * @property {string} bcid - The barcode type.
 * @property {string} text - The text to encode in the barcode.
 * @property {number} scale - The scale of the barcode.
 * @property {number} height - The height of the barcode.
 * @property {number} width - The width of the barcode.
 * @property {boolean} includetext - Whether to include text below the barcode.
 * @property {string} textxalign - The alignment of the text.
 * @property {string} alttext - Alternative text for the barcode.
 * @property {number} textsize - The size of the text.
 * @property {number} textyoffset - The y-offset of the text.
 * @property {string} textfont - The font of the text.
 * 
 * @typedef {Object} SelectedBarcode
 * @property {string|null} prefx - The prefix of the barcode.
 * @property {number} amnt - The amount of barcodes.
 * @property {number} startingIdx - The starting index for the barcode.
 * 
 * @typedef {Object} UserState
 * @property {Object} user - The user object.
 * @property {string} user.email - The email of the user.
 * 
 * @typedef {Object} CodeListItem
 * @property {string} id - The ID of the code list item.
 * @property {string} item - The item name.
 * @property {string} code - The code of the item.
 * @property {number} label_count - The label count of the item.
 * 
 * @typedef {Object} SocketResponse
 * @property {Array<CodeListItem>} res - The response from the socket.
 * 
 * @typedef {Object} Barcode
 * @property {string} bcid - The barcode type.
 * @property {string} text - The text to encode in the barcode.
 * @property {number} scale - The scale of the barcode.
 * @property {number} height - The height of the barcode.
 * @property {number} width - The width of the barcode.
 * @property {boolean} includetext - Whether to include text below the barcode.
 * @property {string} textxalign - The alignment of the text.
 * @property {string} alttext - Alternative text for the barcode.
 * @property {number} amount - The amount of barcodes.
 * @property {number} textsize - The size of the text.
 * @property {number} textyoffset - The y-offset of the text.
 * @property {string} textfont - The font of the text.
 * 
 * @function retrieveData
 * @description Retrieves the barcode data from the server.
 * 
 * @function onRegenCode
 * @description Regenerates the barcode preview.
 * 
 * @function getBarcodeOpts
 * @description Gets the options for the barcode.
 * @returns {BarcodeOptions} The barcode options.
 * 
 * @function toggleMultiple
 * @description Toggles the multiple barcode generation mode.
 * 
 * @function onReset
 * @description Resets the barcode preview.
 * 
 * @function handleCodeGenerator
 * @description Handles the generation of paper core barcodes.
 * 
 * @function onAddBarcode
 * @description Adds a new barcode to the list.
 * 
 * @function resetVals
 * @description Resets the input values.
 * 
 * @function onPrint
 * @description Sends the generated barcodes to the server for printing.
 */
// /**
//  * TODO: Create a way for the inventoryV2 table and the Paper Inventory table to be merged, allowing for easier creation of items and barcodes
//  * The Barcodes list is generated from the Paper Inventory Table right now.
//  */

import React, {useState,useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import bwipjs from 'bwip-js';
import { useCustomContext } from '../../hoc/Context.js';
import { FaSyncAlt } from 'react-icons/fa';

const default_opts = {
    bcid: 'code128',
    text: 'ABCD1',
    scale: 1,
    height: 8,
    width: 5,
    includetext: true,
    textxalign: 'center',
    alttext: 'ABCD1',
    textsize: 9,
    textyoffset: 1
}

const PADDING = 6;

const PrintBarcode = () => {
    const [loading,setLoading] = useState(true);
    const [barcodes,setBarcodes] = useState([]);
    const [multiple,setMultiple] = useState(true);
    const [codeList,setCodeList] = useState([]);
    const [retrieved,setRetrieved] = useState(false);
    const [selected,setSelected] = useState({
        prefx: null,
        amnt: 0,
        startingIdx: 0
    });
    const {userState,userDispatch} = useCustomContext();

    useEffect(() => {
        let mounted = true;

        if(mounted){
            onReset();
            if(!retrieved){
                retrieveData();
            }
        }

        return () => {
            mounted = false;
        }
    },[barcodes.length]);

    const retrieveData = () => {
        socket.emit('getLabelCounts',(res) => {
            if(!res){
                console.error(`PrintBarcode.getLabelCounts: There was an issue calling this method`)
            }else{
                setCodeList(res);
                setRetrieved(true);
            }
        });
    }

    const onRegenCode = () => {
        const _opts = getBarcodeOpts();
        bwipjs.toCanvas('barcode-prev',_opts);
    }

    const getBarcodeOpts = () => {
        const _label = document.getElementById('label') ? document.getElementById('label').value : null;
        const _total = document.getElementById('num-codes') ? document.getElementById('num-codes').value : null
        const _barcode = document.getElementById('barcode') ? document.getElementById('barcode').value : null;
        const _prefix = codeList.find(el => el.code.toUpperCase() == _barcode.toUpperCase());
        const _startingIdx = parseInt(_prefix.label_count) + 1;
        const _data = multiple ? `${_prefix.code}${_startingIdx.toString().padStart(PADDING,'0')}` : `${_prefix.code}`;

        return {
            bcid: 'code128',
            text: _data,
            scale: 1,
            height: 8,
            width: 5,
            includetext: true,
            textxalign: 'center',
            alttext: _label ? `${_label}` : `${_data}`,
            amount: _total,
            textsize: 10,
            textyoffset: 2,
            textfont: 'Helvetica'
        }
    }

    const toggleMultiple = () => {
        setMultiple(prevState => !prevState);
    }

    const onReset = () => {
        if(multiple || !barcodes.length){
            bwipjs.toCanvas('barcode-prev',default_opts);
        }else{
            barcodes.forEach(code => {
                bwipjs.toCanvas(`${code.alttext}`,code);
            })
        }
    }

    const handleCodeGenerator = () => {
			socket.emit('getLastPCId', (res) => {
				if (!res) {
					console.error(`PrintBarcode.getLastPCId: There was an issue calling this method`);
				} else {
					const data = [];
					let row = [];

					let starting_idx = parseInt(res[0].id);
					const num_codes = document.getElementById('num-codes').value;
					const total_codes = parseInt(num_codes);

					for (let i = 0; i < total_codes; i++) {
						if (i !== 0) {
							if (i % 9 == 0) {
								// push row twice to keep same qr codes lined up
								data.push(row);
								data.push(row);
								row = [];
							}
						}

						row.push(i + starting_idx + 1);
					}

					if (row.length) {
						if (row.length < 9) {
							for (let i = row.length; i < 9; i++) {
								row.push(null);
							}
						}

						data.push(row);
						data.push(row);
					}

					// Remove the duplicates from the data array for the database.
					// This will allow the stored procedure to insert multiple id's into the database and not just a singular id.
					let unique_codes = [...new Set(data.flat(1))];

                    // Stringify each int in the unique codes array
                    let stringArray = unique_codes.map(String);
                    let jsonString = JSON.stringify(stringArray);

					$.post('/api/generate-qrcodes', { codes: data, codes_for_insert: jsonString ,to: userState.user.email }, (res) => {
						if (res) {
						}
					});
				}
			});
		};

    const onAddBarcode = () => {
        const _label = document.getElementById('label') ? document.getElementById('label').value : null;
        const _barcode = document.getElementById('barcode') ? document.getElementById('barcode').value : null;
        const _numCodes = document.getElementById('num-codes') ? document.getElementById('num-codes').value : null;
        const _prefix = codeList.find(el => el.item.toUpperCase() == _barcode.toUpperCase());

        if(_prefix){
            const _startingIdx = parseInt(_prefix.label_count) + 1;

            if(_numCodes){
                const all_codes = [];

                for(let i = _startingIdx; i < parseInt(_numCodes) + _startingIdx; i++){
                    all_codes.push({
                        bcid: 'code128',
                        text: `${_prefix.code}${`${i}`.padStart(PADDING,'0')}`,
                        scale: 1,
                        height: 8,
                        width: 5,
                        includetext: true,
                        textxalign: 'center',
                        alttext: _label ? `${_label}` : `${`${_prefix.code}${`${i}`.padStart(PADDING,'0')}`}`,
                        amount: _numCodes,
                        textsize: 10,
                        textyoffset: 2,
                        textfont: 'Helvetica'
                    })
                }

                setBarcodes(all_codes);
                setSelected({
                    prefx: _prefix.code,
                    startingIdx: parseInt(_numCodes) + _startingIdx
                })
                !multiple ? toggleMultiple() : null;
                resetVals();
            }else{
                const _opts = getBarcodeOpts();
                setBarcodes(prevState => ([...prevState,_opts]));
                resetVals();
            }
        }else{
            alert(`Please select a barcode to create.`);
        }
    }

    const resetVals = () => {
        document.getElementById('prefix') ? document.getElementById('prefix').value = "" : null;
        document.getElementById('label') ? document.getElementById('label').value = "" : null;
        document.getElementById('num-codes') ? document.getElementById('num-codes').value = 1 : null

    }

    const onPrint = () => {
        $.post('/api/send_generated_codes',{codes: barcodes, update: selected, email: userState.user.email},(res) => {
        })
    }

    return (
        <div className='ViewedContentContainer' id='OpenContainer' >
            <div style={{height: '100%'}}>
                <div className='TitleBarContainer'>
                    <h1 >Barcode Generator</h1>
                </div>
                <div style={{width: 400, margin: '60px 0px'}}>
                    {multiple ?
                        <div>
                            <div className='flex-just-between flex-wrap'>
                                <div className="input-block table-date-input space-below">
                                    <label style={{fontSize: 11}} className="input-label">Barcode to Generate</label>
                                    <div className="search-by">
                                        <input style={{ margin: 0, width: 136 }} id="barcode" list="barcode-list" name="barcode-list-dropdown" placeholder="Search Items..."></input>
                                        <datalist id="barcode-list">
                                            {codeList.map(item => (
                                                <option key={item.id} value={item.item}>{item.item}</option>
                                            ))}
                                        </datalist>
                                    </div>
                                </div>
                                <TextField id="num-codes" className="table-date-input space-below" label='Total Barcodes' defaultValue={1} type="number" />
                            </div>
                        </div>
                     :  <div>
                            <div className='flex-just-between flex-wrap'>
                                <TextField InputLabelProps={{ shrink: true }} id="prefix" className="table-date-input space-below" label='Barcode Data' placeholder='ABCD1234' type="text" />
                                <TextField id="num-codes" className="table-date-input space-below" label='Total Barcodes' defaultValue={1} type="number" />
                            </div>
                        </div>}
                    <div className='flex-just-between flex-wrap'>
                        <TextField InputLabelProps={{shrink: true}} id="label" className="table-date-input space-below" label='Label Text' placeholder='Barcode Description' type="text" />
                        <FormControlLabel
                            label='Sequential Barcode'
                            control={
                                <Checkbox id="single-check" color='primary' checked={multiple ? true : false} onChange={toggleMultiple} />
                            }
                        />
                    </div>
                </div>
                <div id="preview" className='flex-just-even flex-align-center vert-margin-med flex-column'>
                    <h4>Preview</h4>
                    <canvas id="barcode-prev"></canvas>
                    <IconButton onClick={onRegenCode} size="large">
                        <FaSyncAlt />
                    </IconButton>
                </div>
                <div className='flex-just-even'>
                    <Button variant="contained" color="secondary" onClick={onReset}>Cancel</Button>
                    <Button className='rti-blue-round' variant="contained" onClick={onPrint}>Print</Button>
                    <Button className='rti-blue-round' variant="contained" onClick={onAddBarcode}>Add</Button>
                    <Button className='rti-blue-round' variant="contained" onClick={handleCodeGenerator}>Paper Cores</Button>
                </div>
                {!multiple ? <div style={{width: 430}} className='vert-margin-med flex-wrap'>
                    {
                        barcodes.map((code,idx) => (
                           <canvas style={{margin: '0px 20px'}} key={idx} id={`${code.alttext}`}></canvas>
                        ))
                    }
                </div> : null}
            </div>
        </div>
    );
}

export default PrintBarcode;
