import React from 'react';
import PropTypes from 'prop-types';
// const { HeatmapLayer } = require('@react-google-maps/api');
import { HeatmapLayer } from '@react-google-maps/api';

const gradient = [
	'rgba(0, 255, 255, 0)',
	'rgba(0, 255, 255, 1)',
	'rgba(0, 191, 255, 1)',
	'rgba(0, 127, 255, 1)',
	'rgba(0, 63, 255, 1)',
	'rgba(0, 0, 255, 1)',
	'rgba(0, 0, 223, 1)',
	'rgba(0, 0, 191, 1)',
	'rgba(0, 0, 159, 1)',
	'rgba(0, 0, 127, 1)',
	'rgba(63, 0, 91, 1)',
	'rgba(127, 0, 63, 1)',
	'rgba(191, 0, 31, 1)',
	'rgba(255, 0, 0, 1)',
];

const Heatmap = ({ data, visible }) => {
	const mapData = data.map((location) => ({
		location: new window.google.maps.LatLng(parseFloat(location.Latitude), parseFloat(location.Longitude)),
		weight: location.times_serviced * 1000,
	}));

	return visible ? <HeatmapLayer data={mapData} options={{ gradient: gradient }} /> : null;
};

Heatmap.propTypes = {
	data: PropTypes.array.isRequired,
	visible: PropTypes.bool.isRequired,
};

function compareProps(prevProps, nextProps) {
	if (prevProps.visible === nextProps.visible && prevProps.data.length === nextProps.data.length) {
		return true;
	}
	return false;
}

export default React.memo(Heatmap, compareProps);
