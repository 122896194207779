import React, {useEffect} from 'react';
import { AiOutlineSearch } from 'react-icons/ai';  // Importing from react-icons
import '../StyleSheets/SearchInput.css';
import PropTypes from 'prop-types';

const SearchInput = (props) => {
    useEffect(() => {
        if(props.autoSearch){
            window.addEventListener('keypress',handleKeyPress);

            return () => {
                window.removeEventListener('keypress',handleKeyPress);
            }
        }        
    },[])

    const handleKeyPress = (e) => {
        if(e.keyCode === 13 && props.autoSearch){
            const input = document.getElementById(`${props.id}`).value;
            props.onSearch(input);
        }
    }

    return(
        <div className="search-container">
            {props.icon ? <AiOutlineSearch color="disabled" /> : null}  {/* Using the alternative icon */}
            <input list={props.listName} name={`${props.name}`} id={`${props.id}`} type="text" placeholder={props.placeholder ? props.placeholder : 'Search...'}></input>
            <datalist id={props.listName}>{props.list}</datalist>
        </div>
    )
}

SearchInput.propTypes = {
  autoSearch: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};

export default SearchInput;
