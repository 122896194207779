/**
 * KioskAnalytics Component
 * 
 * This component is responsible for rendering the Kiosk Analytics UI, including tables, modals, and various interactive elements.
 * It fetches and displays data related to kiosk issues, events, and reports.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered KioskAnalytics component.
 * @module KioskAnalytics
 * @category User Interface
 */
import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/Table';
import Modal from '../../components/Modal';
import {
	MdError,
	MdCheckCircle,
	MdCompareArrows,
	MdHelp,
	MdPhoneInTalk,
	MdNewReleases,
	MdLocalPhone,
	MdFavorite,
	MdMessage,
	MdGetApp,
} from 'react-icons/md'; // Imported from react-icons
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircleProgress from '@mui/material/CircularProgress';
import AlertBar from '../../components/AlertBar';
import { DateToLocale, DateToMDY, FormatDateToMDY, FormatPhoneNumber } from '../API/TextFormatingFunctions';
import * as XLSX from 'xlsx';

const TableHeaders = [
	{ id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
	{ id: 'total', name: 'Total Issues', sortable: true, numeric: true },
	{ id: 'last-issue', name: 'Last Issue', sortable: true, numeric: false },
	{ id: 'dm1', name: 'Sticker 1', sortable: true, numeric: true },
	{ id: 'dm2', name: 'Sticker 2', sortable: true, numeric: true },
	{ id: 'star', name: 'Registration Printer', sortable: true, numeric: true },
	{ id: 'internet', name: 'Internet', sortable: true, numeric: true },
	{ id: 'alerts', name: 'Alerts', sortable: true, numeric: true },
	{ id: 'times serviced', name: 'Times Serviced', sortable: true, numeric: true },
	{ id: 'last serviced', name: 'Last Serviced', sortable: true, numeric: false },
];

const TableHeaders2 = [
	{ id: 'status', name: '', size: 'xsmall-header' },
	{ id: 'time', name: 'Time' },
	{ id: 'event', name: 'Event', size: 'small-header', sortable: true, numeric: false },
	{ id: 'issue', name: 'Issue', sortable: true, numeric: false },
	{ id: 'job', name: 'Job #', sortable: true, numeric: false },
	{ id: 'plate#', name: 'Plate #', sortable: true, numeric: false },
	{ id: 'user', name: 'User', size: 'small-header', sortable: true, numeric: false },
	{ id: 'notes', name: 'Notes', size: 'xlarge-header' },
];

const TableHeaders3 = [
	{ id: 'time', name: 'Time of Call' },
	{ id: 'user', name: 'User' },
	{ id: 'issue', name: 'Issue' },
	{ id: 'date', name: 'Date', size: 'small-header' },
	{ id: 'vin', name: 'VIN #', size: 'small-header' },
	{ id: 'name', name: 'Name' },
	{ id: 'phone', name: 'Phone #', size: 'small-header' },
	{ id: 'service', name: 'Service' },
	{ id: 'resolution', name: 'Resolution' },
	{ id: 'year', name: 'Sticker Year', size: 'small-header' },
	{ id: 'notes', name: 'Notes', size: 'xlarge-header' },
];

const TableHeaders4 = [
	{ id: 'time', name: 'Service Date' },
	{ id: 'tech', name: 'Technician' },
	{ id: 'issue', name: 'Issue' },
	{ id: 'dt', name: 'Drive Time', size: 'small-header' },
	{ id: 'work', name: 'Work Time', size: 'small-header' },
	{ id: 'notes', name: 'Notes', size: 'xlarge-header' },
];

const SearchOptions = [{ id: 'kiosk', name: 'Kiosk', pidx: 0 }];

const SearchOptions2 = [
	{ id: 'event', name: 'Event', pidx: 2 },
	{ id: 'issue', name: 'Issue', pidx: 3 },
	{ id: 'job', name: 'Job #', pidx: 4 },
	{ id: 'plate', name: 'Plate #', pidx: 5 },
];

const TableTabs = [
	{ id: 7, name: 'Last 7 days', filter: 7 },
	{ id: 28, name: 'Last 28 days', filter: 28 },
	{ id: 60, name: 'Last 60 days', filter: 60 },
	{ id: 90, name: 'Last 90 days', filter: 90 },
	{ id: 180, name: 'Last 180 days', filter: 180 },
];

const ModalTabs = [
	{ id: 7, name: 'Last 7 days', filter: 7 },
	{ id: 28, name: 'Last 28 days', filter: 28 },
	{ id: 60, name: 'Last 60 days', filter: 60 },
	{ id: 90, name: 'Last 90 days', filter: 90 },
	{ id: 180, name: 'Last 180 days', filter: 180 },
];

const KioskAnalytics = () => {
	const [loading, setLoading] = useState(true);
	const [subLoad, setSubLoad] = useState(false);
	const [reportLoading, setReportLoading] = useState(false);
	const [reportData, setReportData] = useState([]);
	const [storePhone, setStorePhone] = useState({
		phone: null,
		display: null,
	});
	const [phoneOther, setPhoneOther] = useState({
		phone: null,
		display: null,
	});
	const [activeTab, setActiveTab] = useState(TableTabs[1].filter);
	const [activeModalTab, setActiveModalTab] = useState(ModalTabs[1].filter);
	const [selected, setSelected] = useState([]);
	const [callLog, setCallLog] = useState([]);
	const [job, setJob] = useState([]);
	const [searchModal, setSearchModal] = useState({
		value: null,
		param: null,
	});
	const [searchTable, setSearchTable] = useState({
		value: null,
		param: null,
	});
	const [data, setData] = useState([]);
	const [eventModal, setEventModal] = useState(false);
	const [callModal, setCallModal] = useState(false);
	const [jobModal, setJobModal] = useState(false);
	const [selectedKioskID, setSelectedKioskID] = useState('');
	const [selectedServerID, setSelectedServerID] = useState('');
	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setLoading(true);
			socket.emit('kioskTableAnalytics', activeTab, (analytics) => {
				if (!analytics) {
					console.error(`KioskAnalytics.kioskTableAnalytics: There was an issue calling this method`);
					setLoading(false);
				} else {
					setData(analytics.filter((kiosk) => kiosk.kiosk));
					setLoading(false);
				}
			});
		}

		return () => {
			mounted = false;
		};
	}, [activeTab]);

	useEffect(() => {
		$('#event-modal').toggleClass('flex-box');
	}, [eventModal]);

	useEffect(() => {
		$('#call-details').toggleClass('flex-box');
	}, [callModal]);

	useEffect(() => {
		$('#job-details').toggleClass('flex-box');
	}, [jobModal]);

	/**
	 * Closes the snack bar.
	 * @memberof module:KioskAnalytics
	 */
	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	/**
	 * Filters the table based on the given filter.
	 *
	 * @param {string} filter - The filter to apply to the table.
	 * @returns {void}
	 * @memberof module:KioskAnalytics
	 */
	const filterTable = (filter) => {
		setActiveTab(filter);
		setActiveModalTab(filter);
	};

	/**
	 * Retrieves details for a specific kiosk ID.
	 * @param {string} kiosk - The ID of the kiosk.
	 * @param {object} filter - The filter object for retrieving specific details.
	 * @returns - {Promise<object>} - A promise that resolves to an object containing the details of the kiosk.
	 * @throws {Error} - If there is an issue calling the method or if the kiosk is not found.
	 * @method getDetailsByKioskID
	 */
	const getDetailsByKioskID = (kiosk, filter) => {
		return new Promise((resolve, reject) => {
			if (kiosk) {
				socket.emit('getKioskEventCountsByID', kiosk, filter, (res) => {
					if (!res) {
						console.error(
							`KioskAnalytics.getKioskEventCountsByID: There was an issue calling this method - res is false`
						);
						setLoading(false);
						reject(
							new Error('KioskAnalytics.getDetailsByKioskID: There was an issue calling this method - res is false')
						);
					} else {
						resolve({
							total_downtime: res[1][0].total_downtime,
							dm1_issue_count: res[0][0].dm1,
							dm2_issue_count: res[0][0].dm2,
							cc_reader_issue_count: res[0][0].cc_reader,
							incomplete_count: res[0][0].incomplete,
							internet_issue_count: res[0][0].internet,
							star_issue_count: res[0][0].star,
							total_issues: res[0][0].total_issues,
							usb_missing_issue_count: res[0][0].usb_missing,
							last_incomplete_date: res[0][0].last_incomplete_date,
						});
					}
				});
			} else {
				reject(
					new Error('KioskAnalytics.getDetailsByKioskID: There was an issue calling this method - Kiosk Not Found')
				);
			}
		});
	};

	/**
	 * Handles events for a kiosk.
	 *
	 * @param {string} kiosk - The kiosk identifier.
	 * @param {string} server - The server identifier.
	 * @param {string} filter - The event filter.
	 * @param {boolean} [isModal=false] - Indicates if the events are displayed in a modal.
	 * @returns - {Promise<void>} - A promise that resolves when the events are handled.
	 * @method handleEvents
	 */
	const handleEvents = async (kiosk, server, filter, isModal = false) => {
		setSubLoad(true);

		if (isModal) {
			setEventModal(true);
		}
		setActiveModalTab(filter);
		if (kiosk) {
			socket.emit('getKioskEvents', kiosk, filter, async ([res, phone]) => {
				if (!res) {
					console.error(`KioskAnalytics.getKioskEvents: There was an issue calling this method`);
				} else {
					try {
						const found_kiosk_in_table = [...data].reverse().find((o) => o.kiosk.includes(kiosk));
						const selectedKioskAnalyticsStats = await getDetailsByKioskID(kiosk, filter);
						const results = res
							.map((event) => {
								const timestamp =
									event.type === 'Service Report' || event.type === 'Tech Support'
										? event.timestamp
										: `${event.timestamp} GMT-0000`;
								return {
									kiosk: kiosk,
									server: server,
									issue: event.issue,
									plate: event.plate,
									job: event.job_id,
									phone: event.phone,
									timestamp: new Date(timestamp).getTime(),
									display_date: timestamp,
									type: event.type,
									user: event.user,
									notes: event.notes,
									last_serviced: DateToMDY(new Date(event.last_serviced)),
									dm1_issue_count: selectedKioskAnalyticsStats.dm1_issue_count
										? selectedKioskAnalyticsStats.dm1_issue_count
										: 0,
									dm2_issue_count: selectedKioskAnalyticsStats.dm2_issue_count
										? selectedKioskAnalyticsStats.dm2_issue_count
										: 0,
									internet_issue_count: selectedKioskAnalyticsStats.internet_issue_count
										? selectedKioskAnalyticsStats.internet_issue_count
										: 0,
									usb_missing_issue_count: selectedKioskAnalyticsStats.usb_missing_issue_count
										? selectedKioskAnalyticsStats.usb_missing_issue_count
										: 0,
									star_issue_count: selectedKioskAnalyticsStats.star_issue_count
										? selectedKioskAnalyticsStats.star_issue_count
										: 0,
									cc_reader_issue_count: selectedKioskAnalyticsStats.cc_reader_issue_count
										? selectedKioskAnalyticsStats.cc_reader_issue_count
										: 0,
									most_recent_downtime: found_kiosk_in_table.most_recent_downtime,
									most_recent_incomplete: selectedKioskAnalyticsStats.last_incomplete_date
										? DateToMDY(new Date(selectedKioskAnalyticsStats.last_incomplete_date))
										: '',
									total_downtime: selectedKioskAnalyticsStats.total_downtime
										? selectedKioskAnalyticsStats.total_downtime
										: '',
									total_issues: selectedKioskAnalyticsStats.total_issues
										? selectedKioskAnalyticsStats.total_issues
										: 0,
									times_serviced: found_kiosk_in_table.times_serviced,
									last_issue: found_kiosk_in_table.last_issue,
									incomplete_count: selectedKioskAnalyticsStats.incomplete_count
										? selectedKioskAnalyticsStats.incomplete_count
										: 0,
								};
							})
							.sort((a, b) => b.timestamp - a.timestamp);
						setStorePhone({ phone: phone[0].StorePhone, display: phone[0].StoreName });
						setSelected(results);
						setSubLoad(false);
					} catch (error) {
						console.error(`ERROR: ${error} - ${new Date()}`);
						setSubLoad(false);
					}
				}
			});
		} else {
			setSnackbar((prevState) => ({
				...prevState,
				visible: true,
				message: `ERROR: No Events found for this time frame.  Please select a different time frame and try again.`,
				severity: 'error',
			}));
		}
	};

        /**
         * Filters the modal based on the provided filter.
         * If there are selected items, it retrieves the kiosk and server from the first selected item
         * and handles events with these parameters and the filter.
         * If no items are selected, it handles the modal without parameters.
         *
         * @param {Object} filter - The filter criteria to apply.
         * @method filterModal
         */
		const filterModal = (filter) => {
			if (selected.length) {
				const kiosk = selected[0].kiosk;
				const server = selected[0].server;
				handleEvents(kiosk, server, filter);
			} else {
				handleModal();
			}
		};

        /**
         * 
         * Handles the modal event.
         * 
         * @param {Event} event - The event object.
         * @returns - {void}
         * @method handleModal
         */
		const handleModal = (event) => {
			if (event) {
				const id = event.target.id;
				const [kiosk, server] = id.split(' - ');
				setSelectedKioskID(kiosk);
				setSelectedServerID(server);
				handleEvents(kiosk, server, activeTab, true);
			} else {
				handleEvents(selectedKioskID, selectedServerID, activeTab, true);
			}
		};

    /**
     * Performs a search operation.
     *
     * @param {string} param - The parameter to search for.
     * @param {any} value - The value to search for.
     * @returns - {void}
     * @method search
     */
    const search = (param, value) => {
        setSearchTable({param,value})
    }


    /**
     * Clears the search parameters by setting them to null.
     *
     * This function resets the search table parameters to their default state,
     * effectively clearing any active search filters.
     * @method clearSearch
     */
    const clearSearch = () => {
        setSearchTable({param: null, value: null})
    }

    /**
     * Retrieves table data for Kiosk Analytics.
     * @returns - {Array} An array of objects representing the table data.
     * @method getTableData
     */
    const getTableData = () => {
        return data.map(issue => ({
            key: issue.kiosk,
            cells: [
                {data: issue.kiosk},
                {data: issue.total_issues},
                {data: issue.last_issue},
                {data: issue.dm1},
                {data: issue.dm2},
                {data: issue.star},
                {data: issue.internet},
                {data: issue.total_issues},
                {data: issue.times_serviced ? issue.times_serviced : 0},
                {data: DateToMDY(new Date(issue.last_serviced))},
            ]
        }))
        .filter(row => row.cells[1].data || row.cells[7].data)
        .filter(row => searchTable.value && searchTable.param ? row.cells[SearchOptions[searchTable.param - 1].pidx].data.toUpperCase().includes(searchTable.value.toUpperCase()) : row);
    }

    /**
     * Handles the customer call event.
     * 
     * @param {Event} event - The event object.
     * @returns - {void}
     * @method handleCustomerCall
     * 
     */
    const handleCustomerCall = (event) => {
        const plate = event.target.id;

		socket.emit('getCallLogByPlateNumber', plate, (res) => {
			if (!res) {
				console.error(`KioskAnalytics.getCallLogByPlateNumber: There was an issue calling this method`);
			} else {
				// do next
				const sorted = res.sort((a, b) => new Date(b.DateOfCall).getTime() - new Date(a.DateOfCall).getTime());
				const phone_found = sorted.find((log) => !log.CustomerPhone.includes('xxx'));
				setCallLog(sorted);
				setPhoneOther({
					phone: phone_found ? phone_found.CustomerPhone : '',
					display: phone_found ? phone_found.CustomerName : '',
				});
				setCallModal(true);
			}
		});
	};

	/**
	 * Handles the click event for a job.
	 *
	 * @param {Event} event - The click event.
	 * @returns - {void}
	 * @method handleJobClick
     * 
	 */
	const handleJobClick = (event) => {
		const job_id = event.target.id;
		socket.emit('selectJobByID', job_id, (res) => {
			if (!res) {
				console.error(`KioskAnalytics.selectJobByID: There was an issue calling this method`);
			} else {
				const sorted = res.sort((a, b) => new Date(b.ServiceDate).getTime() - new Date(a.ServiceDate).getTime());
				setJob(sorted);
				setPhoneOther({ phone: sorted[0].MobileNumber, display: sorted[0].ServiceTech });
				setJobModal(true);
			}
		});
	};


    /**
     * Generates modal data for the selected events.
     *
     * This function processes the `selected` array and maps each event to a structured
     * object containing key-value pairs for rendering in a modal. Each event is represented
     * by a unique key and an array of cell data, which includes various event properties
     * and conditional rendering of icons and links based on the event type and other attributes.
     * @method getModalData
     * @returns {Array<Object>} An array of objects representing the modal data for each event.
     * Each object contains:
     * - `key` {string}: A unique identifier for the event, combining the event's timestamp and index.
     * - `cells` {Array<Object>}: An array of cell data objects, each containing:
     *   - `data` {JSX.Element|string}: The content to be displayed in the cell, which may include
     *     conditional icons, formatted dates, event details, and clickable links.
     */
    const getModalData = () => {
        return selected.map((event,index) => ({
            key: `${event.timestamp}-${index}`,
            cells: [
                {data: event.notes === 'WI Kiosk problem reported' ? <MdNewReleases style={{color: 'red'}}/>
                : event.type === 'Alert' ? <MdError style={{color: 'darkorange'}}/>
                : event.type === 'Service Report' ? <MdCheckCircle style={{color: 'forestgreen'}} />
                : event.type === 'Dispatched' ? <MdCompareArrows />
                : event.type === 'Tech Support' ? <MdHelp style={{color: 'midnightblue'}} />
                : event.type.includes('Call') ? <MdPhoneInTalk style={{color: 'darkgray'}} /> : null},
                {data: DateToLocale(new Date(event.display_date))},
                {data: event.type},
                {data: event.issue},
                {data: event.job ? <a style={{textDecoration: 'underline', color: 'royalblue'}} id={event.job.toUpperCase()} href="#" onClick={handleJobClick}>{event.job.toUpperCase()}</a> : "-"},
                {data: event.plate ? <a style={{textDecoration: 'underline', color: 'royalblue'}} id={event.plate.toUpperCase()} href="#" onClick={handleCustomerCall}>{event.plate.toUpperCase()}</a> : "-"},
                {data: event.user ? event.user : '-'},
                {data: event.notes ? event.notes : '-'}
            ]
        })).filter(row => searchModal.value && searchModal.param ? row.cells[SearchOptions2[searchModal.param - 1].pidx].data.toUpperCase().includes(searchModal.value.toUpperCase()) : row);
    }


    /**
     * Retrieves call data and formats it for table display.
     *
     * @returns {Array<Object>} An array of objects representing call data.
     * Each object contains a key and an array of cells with formatted data.
     *
     * @property {string} key - The unique identifier for the call log.
     * @property {Array<Object>} cells - An array of cell objects containing formatted data.
     * @property {string} cells[].data - The formatted data for each cell.
     * @method getCallData
     */
    const getCallData = () => {
        return callLog.map(log => ({
            key: log.CallLogID,
            cells: [
                {data: DateToLocale(new Date(log.DateOfCall))},
                {data: log.Dispatcher},
                {data: log.ReasonForCalling},
                {data: FormatDateToMDY(log.DateOfIssue.split('T')[0])},
                {data: log.LastVin},
                {data: log.CustomerName},
                {data: log.CustomerPhone},
                {data: log.IsServiceNeeded},
                {data: log.StickerResolution},
                {data: log.StickerYear},
                {data: log.Notes},
            ]
        }))
    }

    /**
     * Retrieves job data and formats it into an array of objects.
     * @returns - {Array} An array of objects representing job data.
     * 
     */
    const getJobData = () => {
        return job.map((log,index) => ({
            key: `${log.JobID}-${index}`,
            cells: [
                {data: FormatDateToMDY(log.ServiceDate.split('T')[0])},
                {data: log.ServiceTech},
                {data: log.DriveTime.split('(')[0]},
                {data: log.WorkTime},
                {data: log.KioskIssue},
                {data: log.Notes}
            ]
        }))
    }

    /**
     * Handles the closing of a modal.
     * 
     * @param {string} modal - The name of the modal to be closed.
     * @returns - {void}
     * 
     */
    const handleModalClose = (modal) => {
        switch(modal){
            case 'event-modal': setActiveModalTab(activeTab);setEventModal(false);
                break;
            case 'job-details': setJobModal(false);
                break;
            case 'call-details': setCallModal(false);
                break;
        }

    }

    /**
     * Sets the search modal with the provided parameter and value.
     * 
     * @param {string} param - The parameter to set in the search modal.
     * @param {any} value - The value to set in the search modal.
     * @returns - {void}
     * 
     */
    const searchEvents = (param, value) => {
        setSearchModal({param, value})
    }

    /**
     * Clears the search events by resetting the search modal parameters.
     * 
     */
    const clearSearchEvents = () => {
        setSearchModal({param: null, value: null})
    }

    /**
     * Handles the phone call event.
     * 
     */
    const handlePhoneCall = () => {
        document.getElementById('phone').click();
    }

	const handleMessage = () => {
		const chatModal = document.getElementById('chat-modal');
		if (chatModal.style.display === 'none' || chatModal.style.display === '') {
			chatModal.style.display = 'block';
		} else {
			chatModal.style.display = 'none';
		}
	};

	/**
	 * Handles the click event for the heartbeat button.
	 * 
	 */
	const handleHeartbeatClick = () => {
		document.getElementById('link-heartbeat').click();
	};

    // New function to fetch data
/**
 * Fetches the report data from the server.
 * @returns - {Promise<any>} A promise that resolves with the report data.
 * @throws {Error} If there was an issue calling the method.
 * 
 */
const fetchReportData = () => {
	return new Promise((resolve, reject) => {
		socket.emit('kioskToWatchReport', (res) => {
            if (!res) {
                console.error(`KioskAnalytics.kioskToWatchReport: There was an issue calling this method`);
				reject(new Error('There was an issue calling this method'));
			} else {
				resolve(res);
			}
		});
	});
};

	/**
	 * Downloads a report and updates the report data state.
	 * @async
	 * @function onReportDownload
	 * @returns {Promise<void>}
	 * @throws {Error} If there is an issue calling the method.
	 * @memberof module:KioskAnalytics
	 */
	const onReportDownload = async () => {
		setReportData([]);
		setReportLoading(true);
		try {
			const res = await fetchReportData();
			setReportLoading(false);
			const { data, alerts, dt } = res;
			const results = data.map((kiosk) => {
				const kiosk_dt = dt.find((dt) => dt.kiosk_id === kiosk.KioskID.substring(0, 6));
				const total_alerts = alerts.length
					? alerts.find((alert) => {
							return alert.KioskID === kiosk.KioskID.substring(0, 6);
					  })
					: '';

				return {
					Kiosk: kiosk.FullID,
					'Call In Last 7 Days': kiosk.CallLast7 ? 'YES' : '',
					'Tech Visit In Last 7 Days': kiosk.VisitLast7 ? 'YES' : '',
					'Called After Last Technical Visit': kiosk.CallsAfter ? 'YES' : '',
					Incompletes: kiosk.Incompletes,
					'Last Incomplete': kiosk.LastIncomplete,
					'Customer Calls': kiosk.TotalCalls,
					'Last Call Date': kiosk.LastCall,
					'Last Customer Call': kiosk.LastCustomerCall,
					'Reason For Call': kiosk.ReasonForCall,
					'Store Calls': kiosk.TotalStoreCalls,
					'Last Store Call': kiosk.LastStoreCall,
					'Calls After Last Technical Visit': kiosk.CallsAfter,
					'Last Paper Change Visit': kiosk.LastPaperVisit,
					'Last Technical Visit': kiosk.LastVisit,
					'Last Visited By': kiosk.VisitedBy,
					'Reason For Visit': kiosk.ReasonForVisit,
					'Printer Type': kiosk.Printer,
					'Paper Change Visits': kiosk.PaperVisits,
					'Technical Visits (Non Paper Change)': kiosk.TechsSent - kiosk.PaperVisits,
					'Total Visits': kiosk.TechsSent,
					'Total Alerts': total_alerts ? total_alerts.total_alerts : '',
					'Total Downtime': kiosk_dt ? kiosk_dt.timediff.replace(/-/g, '') : '',
					'Store Time Cost': `$${kiosk.ServiceCost}`,
					'Total Transactions': kiosk.Transactions,
					'Total Score (Incompletes + Customer Calls + Store Calls + Total Visits)': kiosk.OverallScore,
					'Score % (Total Score / Total Transactions)': `${kiosk.TotalScore}%`,
				};
			});

			setReportData(results);
			exportToCSV(results);
		} catch (error) {
			setReportLoading(false);
			console.error(`KioskAnalytics.kioskToWatchReportDownload: There was an issue calling this method`);
			setReportData([]);
		}
	};

	const exportToCSV = (data) => {
		// Create a new workbook
		const wb = XLSX.utils.book_new();

		// Check if data is not empty
		if (data.length > 0) {
			// Generate headers from the keys of the first object
			const headers = Object.keys(data[0]);

			// Format the data with headers included
			const dataWithHeaders = [headers, ...data.map((row) => headers.map((header) => row[header]))];

			// Convert the formatted data to a worksheet
			const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);

			// Append the worksheet to the workbook
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		}

		// Write the workbook to a file
		XLSX.writeFile(wb, 'kiosks-to-watch.xlsx');
	};

	//

	/**
	 * Renders the table buttons for KioskAnalytics.
	 * @returns {JSX.Element} The rendered table buttons.
	 * @memberof module:KioskAnalytics
	 * Commented this out on 2024-10-18, on click this button is causing the kiosk to watch report email to send every minute.
	 * I have tried to fix the issue but am unable to call the getSRForKioskReport() method directly from the server. - Jackson
	 * TODO: Fix the issue with the kiosk to watch report email sending every minute.
	 */
	const renderTableButtons = () => {
		return (
			<div>
				{/* <Button
					variant='contained'
					disabled={reportLoading ? true : false}
					startIcon={<MdGetApp />}
					onClick={onReportDownload}
				>
					{reportLoading ? <CircleProgress style={{ width: 24, height: 24, margin: '0px 40px' }} /> : 'Kiosks to Watch'}
				</Button> */}
			</div>
		);
	};

	/**
	 * Renders event buttons based on the given type.
	 *
	 * @param {string} type - The type of event buttons to render.
	 * @returns {JSX.Element} - The rendered event buttons.
	 * @memberof module:KioskAnalytics
	 */
	const renderEventButtons = (type) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<a id='phone' href={`tel:${type === 'store' ? storePhone : phoneOther}`}></a>
				<IconButton style={{ margin: '0px 10px' }} onClick={handlePhoneCall} size='large'>
					<MdLocalPhone />
				</IconButton>
				<label>
					{`${FormatPhoneNumber(type === 'store' ? storePhone.phone : phoneOther.phone)}
                    ${
											type === 'store' && storePhone.display
												? storePhone.display.replace(/_/g, "'")
												: phoneOther.display
												? phoneOther.display.replace(/_/g, "'")
												: ''
										}`}
				</label>
				{type === 'tech' ? (
					<div style={{ margin: '0px 20px' }}>
						<IconButton onClick={handleMessage} size='large'>
							<MdMessage />
						</IconButton>
						<label>Message</label>
					</div>
				) : null}
				{type === 'store' ? (
					<div style={{ marginLeft: 24 }}>
						<IconButton onClick={handleHeartbeatClick} size='large'>
							<MdFavorite style={{ color: 'crimson' }} />
						</IconButton>
						<label>Heartbeat</label>
						<a
							id='link-heartbeat'
							hidden
							href={`https://wi.registration-technology.com:8183/report/${selected.length ? selected[0].kiosk : null}`}
							target='_blank'
						></a>
					</div>
				) : null}
			</div>
		);
	};

	return (
		<div className='ViewedContentContainer' id='OpenContainer'>
			<AlertBar
				visible={snackbar.visible}
				onClose={handleCloseSnack}
				message={snackbar.message}
				severity={snackbar.severity}
			/>

			<div style={{ height: '100%' }}>
				<div className='TitleBarContainer'>
					<h1 title='Kiosk analytics'>Kiosk Analytics</h1>
				</div>
				<CustomTable
					searchable
					paginate
					loading_data={loading}
					tabs={TableTabs}
					tab_style='select'
					active_tab={activeTab}
					filter_data={filterTable}
					headers={TableHeaders}
					rows={getTableData()}
					search={search}
					clear_search={clearSearch}
					search_options={SearchOptions}
					table_buttons={renderTableButtons()}
					onClick={handleModal}
				/>
				{eventModal ? (
					<Modal
						modal_id='event-modal'
						dimension={{ width: '1440' }}
						onClose={() => handleModalClose('event-modal')}
						modal_header={selected.length ? `Event Log - ${selected[0].kiosk} ${selected[0].server}` : ''}
						modal_sub_header={
							selected.length
								? `Total Time Down: ${selected[0].total_downtime} |
            Most Recent Time Down: ${selected[0].most_recent_downtime} |
            Last Serviced: ${selected[0].last_serviced} |
            Last Issue: ${selected[0].last_issue} |
            Last Incomplete: ${selected[0].most_recent_incomplete} |
            Incompletes: ${selected[0].incomplete_count} |
            Internet: ${selected[0].internet_issue_count} |
            Sticker Printer 1: ${selected[0].dm1_issue_count} |
            Sticker Printer 2: ${selected[0].dm2_issue_count} |
            Registration Printer: ${selected[0].star_issue_count} |
            USB Missing: ${selected[0].usb_missing_issue_count} |
            CC Reader: ${selected[0].cc_reader_issue_count}`
								: ''
						}
					>
						<div style={{ textAlign: 'center', height: '80%' }}>
							<CustomTable
								searchable
								tabs={ModalTabs}
								tab_style='select'
								active_tab={activeModalTab}
								filter_data={filterModal}
								headers={TableHeaders2}
								loading_data={subLoad}
								rows={getModalData()}
								search={searchEvents}
								clear_search={clearSearchEvents}
								search_options={SearchOptions2}
								paginate
								table_buttons={renderEventButtons('store')}
								maxSize='medium'
							/>
							<div style={{ display: 'flex', margin: 16 }}>
								<div style={{ display: 'flex', margin: '0px 6px' }}>
									<MdNewReleases style={{ color: 'red' }} /> <label style={{ margin: '0px 4px' }}>Critical issue</label>
								</div>
								<div style={{ display: 'flex', margin: '0px 6px' }}>
									<MdError style={{ color: 'darkorange' }} /> <label style={{ margin: '0px 4px' }}>Alert</label>
								</div>
								<div style={{ display: 'flex', margin: '0px 6px' }}>
									<MdCheckCircle style={{ color: 'forestgreen' }} />{' '}
									<label style={{ margin: '0px 4px' }}>Serviced</label>
								</div>
								<div style={{ display: 'flex', margin: '0px 6px' }}>
									<MdHelp style={{ color: 'midnightblue' }} /> <label style={{ margin: '0px 4px' }}>Tech support</label>
								</div>
								<div style={{ display: 'flex', margin: '0px 6px' }}>
									<MdPhoneInTalk style={{ color: 'darkgray' }} />{' '}
									<label style={{ margin: '0px 4px' }}>Customer/store call</label>
								</div>
								<div style={{ display: 'flex', margin: '0px 6px' }}>
									<MdCompareArrows /> <label style={{ margin: '0px 4px' }}>Dispatched</label>
								</div>
							</div>
						</div>
					</Modal>
				) : null}

				{callModal ? (
					<Modal
						modal_id='call-details'
						dimension={{ width: '1440', height: '620' }}
						onClose={() => handleModalClose('call-details')}
						modal_header={callLog.length ? `Call Log - #${callLog[0].PlateNumber}` : ''}
					>
						<div style={{ textAlign: 'center', height: '80%' }}>
							<CustomTable
								headers={TableHeaders3}
								rows={getCallData()}
								paginate
								table_buttons={renderEventButtons('customer')}
								maxSize='smll'
							/>
						</div>
					</Modal>
				) : null}

				{jobModal ? (
					<Modal
						modal_id='job-details'
						dimension={{ width: '1080', height: '400' }}
						onClose={() => handleModalClose('job-details')}
						modal_header={job.length ? `Service Report - #${job[0].JobID}` : ''}
					>
						<div style={{ textAlign: 'center', height: '80%' }}>
							<CustomTable
								headers={TableHeaders4}
								rows={getJobData()}
								paginate
								table_buttons={renderEventButtons('tech')}
								maxSize='medium'
							/>
						</div>
					</Modal>
				) : null}
			</div>
		</div>
	);
};

export default KioskAnalytics;
