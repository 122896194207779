import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AlertBar from "./AlertBar";
import { useCustomContext } from "../hoc/Context";
import PropTypes from 'prop-types';

const PASS_LENGTH = [13, 22];

const PasswordReset = ({ type }) => {
  const [errors, setErrors] = useState({
    username: false,
    newPass: false,
    confirmPass: false,
    verification: false,
  });
  const [pass, setPass] = useState("");
  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: "",
    severity: "success",
  });
  const [creds, setCreds] = useState({
    rid: null,
    uid: null,
    expired: false,
  });
  const { userState } =
    type && type != "forgot"
      ? useCustomContext()
      : { userState: null };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const rid = new URLSearchParams(window.location.search).get("rid");
      const uid = new URLSearchParams(window.location.search).get("uid");
      const utype = new URLSearchParams(window.location.search).get("type"); // can be 'tech' or 'user'
      const expiration = new URLSearchParams(window.location.search).get("exp");
      const reset_state = new URLSearchParams(window.location.search).get(
        "reset_state"
      ); // if reset state == 1, user is resetting pass otherwise registering account


      const timestamp = new Date().getTime();

      setCreds({
        rid,
        uid,
        utype,
        reset_state,
        expired: timestamp > parseInt(expiration) ? true : false,
      });
    }

    return () => {
      mounted = false;
    };
  }, []);

  const handleCloseSnack = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const validate = () => {
    const username = document.getElementById("pr-username").value;
    const newPass = document.getElementById("pr-new-pass").value;
    const confirmPass = document.getElementById("pr-confirm").value;
    const vrcode = document.getElementById("vrcode")
      ? document.getElementById("vrcode").value
      : null;
    const regex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{13,22}$/;

    if (!username) {
      setErrors((prevState) => ({
        ...prevState,
        username: true,
        newPass: false,
        confirmPass: false,
        verification: false,
      }));
      return { valid: false, msg: "WARNING: Username cannot be empty" };
    } else if (!newPass) {
      // can't be empty
      setErrors((prevState) => ({
        ...prevState,
        newPass: true,
        username: false,
        confirmPass: false,
        verification: false,
      }));
      return { valid: false, msg: "WARNING: Password cannot be empty" };
    } else if (!newPass.match(regex)) {
      // must match standard password format
      setErrors((prevState) => ({
        ...prevState,
        newPass: true,
        username: false,
        confirmPass: false,
        verification: false,
      }));
      return {
        valid: false,
        msg: `WARNING: Password must be at least ${PASS_LENGTH[0]} - ${PASS_LENGTH[1]} characters long and include the following: One uppercase and lowercase letter, one number, and one special character.`,
      };
    } else if (confirmPass != newPass) {
      // passwords don't match
      setErrors((prevState) => ({
        ...prevState,
        confirmPass: true,
        username: false,
        newPass: false,
        verification: false,
      }));
      return {
        valid: false,
        msg: "WARNING: Passwords do not match. Please try again.",
      };
    } else if (creds.rid) {
      if (creds.rid != vrcode) {
        setErrors((prevState) => ({
          ...prevState,
          confirmPass: false,
          username: false,
          newPass: false,
          verification: true,
        }));
        return {
          valid: false,
          msg: "WARNING: Verification code does not match our data.",
        };
      } else if (!vrcode) {
        setErrors((prevState) => ({
          ...prevState,
          confirmPass: false,
          username: false,
          newPass: false,
          verification: true,
        }));
        return {
          valid: false,
          msg: "WARNING: Verification code cannot be empty.",
        };
      }
    }

    return { valid: true };
  };

  const handlePasswordReset = () => {
    const valid = validate();
    const username = document.getElementById("pr-username").value;
    const password = document.getElementById("pr-new-pass").value;

    if (valid.valid) {

      if (!creds.rid && userState.user.username == username) {
        socket.emit("logUserAction", userState.user.uid, "PASSWORD_RESET", `User ${userState.user.username} reset their password`);
        socket.emit(
          "updateUserPassword",
          password,
          userState.user.uid,
          (response) => {
            if (!response) {
              setSnackbar({
                visible: true,
                message:
                  "SERVER ERROR: There was an issue resetting this users password.",
                severity: "error",
              });
            } else {
              setSnackbar({
                visible: true,
                message:
                  "Success! Password reset successful. You will now be redirected to the login page.",
                severity: "success",
              });

              setTimeout(() => {
                localStorage.removeItem("local-dispatch-console-user");
                localStorage.removeItem("user");
                localStorage.removeItem("FullName");
                window.location.replace("https://console.ez-tab.net/login");
              }, 2000);
            }
          }
        );
      } else if (creds.rid) {
        if (creds.utype == "tech") {
          // ('inside updating tech pass')
          socket.emit("updateTechPassword", password, creds.uid, (response) => {
            socket.emit('logUserAction', creds.uid, 'PASSWORD_RESET', `Tech ${creds.uid} reset their password`);
            if (!response) {
              setSnackbar({
                visible: true,
                message:
                  "SERVER ERROR: There was an issue resetting this users password.",
                severity: "error",
              });
            } else {
              setSnackbar({
                visible: true,
                message:
                  "Success! Password reset successful. You will now be redirected to the login page.",
                severity: "success",
              });

              setTimeout(() => {
                window.location.replace(
                  "http://localhost:3000/tech-portal/login"
                );
              }, 2000);
            }
          });
        } else {
          socket.emit("updateUserPassword", password, creds.uid, (response) => {
            socket.emit('logUserAction', creds.uid, 'PASSWORD_RESET', `User ${creds.uid} reset their password`);
            if (!response) {
              setSnackbar({
                visible: true,
                message:
                  "SERVER ERROR: There was an issue resetting this users password.",
                severity: "error",
              });
            } else {
              setSnackbar({
                visible: true,
                message:
                  "Success! Password reset successful. You will now be redirected to the login page.",
                severity: "success",
              });

              setTimeout(() => {
                localStorage.removeItem("local-dispatch-console-user");
                localStorage.removeItem("user");
                localStorage.removeItem("FullName");
                window.location.replace("https://console.ez-tab.net/login");
              }, 2000);
            }
          });
        }
      } else {
        setSnackbar({
          visible: true,
          message:
            "WARNING: Make sure the username that you have entered is correct and try again.",
          severity: "warning",
        });
        setErrors((prevState) => ({
          ...prevState,
          username: true,
          newPass: false,
          confirmPass: false,
          verification: false,
        }));
      }
    } else {
      setSnackbar({
        visible: true,
        message: valid.msg,
        severity: "warning",
      });
    }
  };

  const handlePass = (_event) => {
    setPass(_event.target.value);
  };

  return (
    <div
      style={{ height: "100vh" }}
      className="login-wrapper flex-align-center flex-just-center"
    >
      <div
        className="login-form-container"
        style={{ width: 400, display: "flex", flexDirection: "column" }}
      >
        <div className="login-form-label" style={{ textAlign: "center" }}>
          <h1>Reset Password</h1>
        </div>
        <div className="form-divider"></div>
        {!creds.expired ? (
          <div className="login-form-main-body">
            <div className="inner-form-body">
              <form
                autoComplete="off"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <div style={{ width: "60%" }}>
                  <TextField
                    style={{ width: "100%" }}
                    variant="standard"
                    error={errors.username}
                    helperText={errors.username ? "Error" : null}
                    label={
                      creds.utype && creds.utype == "tech"
                        ? "Tech ID"
                        : "Username"
                    }
                    type="text"
                    id="pr-username"
                  />
                </div>
                <div style={{ width: "60%" }}>
                  <TextField
                    style={{ width: "100%" }}
                    variant="standard"
                    error={errors.verification}
                    helperText={errors.verification ? "Error" : null}
                    label="Verification #"
                    type="text"
                    id="vrcode"
                  />
                </div>
                <div style={{ width: "60%" }}>
                  <TextField
                    style={{ width: "100%" }}
                    variant="standard"
                    error={errors.newPass}
                    helperText={errors.newPass ? "Error" : null}
                    label="New Password"
                    type="password"
                    id="pr-new-pass"
                    value={pass}
                    onChange={handlePass}
                  />
                  {pass && !errors.newPass ? (
                    <div>
                      <label style={{ fontSize: 10, color: "darkgray" }}>
                        Character count: {pass.length}
                      </label>
                    </div>
                  ) : null}
                </div>
                <div style={{ width: "60%" }}>
                  <TextField
                    style={{ width: "100%" }}
                    variant="standard"
                    error={errors.confirmPass}
                    helperText={errors.confirmPass ? "Error" : null}
                    label="Confirm Password"
                    type="password"
                    id="pr-confirm"
                  />
                </div>

                <div className="login-button" style={{ margin: 10 }}>
                  <Button
                    className="rti-blue-round"
                    variant="contained"
                    onClick={handlePasswordReset}
                  >
                    Reset
                  </Button>
                </div>
                <p style={{ color: "gray", textAlign: "center" }}>
                  Keep your password safe, and never give it out to anybody. We
                  will never ask for your password.
                </p>
              </form>
            </div>
            <div
              style={{ padding: 26 }}
              className="flex-column flex-align-center"
            >
              <p>Password must include the following:</p>
              <ul style={{ padding: 0, listStyleType: "disc" }}>
                <li>{`Password must be at least ${PASS_LENGTH[0]} - ${PASS_LENGTH[1]} characters long`}</li>
                <li>One uppercase and lowercase letter</li>
                <li>One special character ie. (^%#.)</li>
                <li>One number ie. (0-9)</li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="login-form-main-body">
            <p style={{ textAlign: "center" }}>
              Oops! Looks like this link is expired.
            </p>
          </div>
        )}
        <AlertBar
          visible={snackbar.visible}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={handleCloseSnack}
        />
      </div>
    </div>
  );
};

PasswordReset.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PasswordReset;
