import React from 'react';
import Modal from '../../../components/Modal';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

export const RemovePaperModal = (props) => {
    const { togglePrompt, uploading, onRemoveStickers } = props;
	return (
		<div>
			<Modal modal_id='job-prompt' dimension={{ width: 450 }} onClose={togglePrompt}>
				<div className='flex-align-center flex-column'>
					<p>Are you sure you want to remove the tracking for these stickers?</p>
					{!uploading ? (
						<div className='flex-just-even full-width vert-margin-med'>
							<Button variant='contained' color='secondary' onClick={togglePrompt}>
								No
							</Button>
							<Button className='rti-blue-round' variant='contained' onClick={onRemoveStickers}>
								Yes
							</Button>
						</div>
					) : (
						<div className='full-width'>
							<label>Removing...</label>
							<LinearProgress />
						</div>
					)}
				</div>
			</Modal>
		</div>
	);
};
