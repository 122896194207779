import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { MdCropFree as ScanIcon } from 'react-icons/md'; // Updated import
import Modal from '../../components/Modal';
import AlertBar from '../../components/AlertBar';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const ScanItem = () => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [newItemOptions, setNewItemOptions] = useState([]);
    const [selectedNewItem, setSelectedNewItem] = useState({});
    const [snackbar, setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success',
    });
    const CORE_PREFIX = 'PC';

    useEffect(() => {
        $('#serial-lookup').toggleClass('flex-box');
    }, [modalOpen]);

    useEffect(() => {
        findNextRID();
    }, []);

    const onBulkRequest = () => {
        navigate('/inventory/bulk-item', { state: { inv_item: { bulk: true, barcode: false } } });
    };

    const findNextRID = () => {
        socket.emit('FindNextInvBarcode', (res) => {
            if (!res) {
                setSnackbar((prevState) => ({
                    ...prevState,
                    visible: true,
                    message: 'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
                    severity: 'error',
                }));
                console.error(`ScanItem.FindNextInvBarcode: There was an issue calling this method`);
            } else {
                setNewItemOptions(res[0]);
            }
        });
    };

    const onScan = (data) => {
        const upperData = data.toUpperCase();

        $.post(`/api/scan-item`, { data: upperData }, (path) => {
            if (!path.error) {
                if (!path.data) {
                    navigate(path.path);
                } else {
                    navigate(path.path, { state: path.data });
                }
            } else {
                setSnackbar((prevState) => ({
                    ...prevState,
                    visible: true,
                    message: 'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
                    severity: 'error',
                }));
            }
        });
    };


	/**
	 * Handles the event when a serial is looked up.
	 * @param {Event} event - The event object.
	 */
	const onSerialLookUp = (event) => {
		if (event.keyCode === 13) {
			const serial = event.target.value.toUpperCase();
			socket.emit('getItemBySerial', serial, (res) => {
				if (!res) {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message:
						'SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.',
						severity: 'error',
					}));
					console.error(`Inventory.getItemBySerial: There was an issue calling this method`);
				} else {
					if (res.length) {
						if (serial.toUpperCase().substring(0, 2) === CORE_PREFIX) {
							navigate(`/inventory/paper/${res[0].barcode}`, { state: { inv_item: res[0] } });
						} else {
							navigate(`/inventory/receive/${res[0].barcode}`, { state: { inv_item: res[0] } });
						}
					} else {
						onScan(serial);
					}
				}
			});
		}
	};

	/**
	 * Toggles the modal open state.
	 */
	const onToggleModal = () => {
		setModalOpen((prevState) => !prevState);
	};


    const handleNewItemSelection = (val) => {
        const found_item = newItemOptions.find((el) => el.item_type == val);
        setSelectedNewItem(found_item);
    };

    const handleNewItemSubmit = () => {
        const value = selectedNewItem.generated_barcode;
        onScan(value);
    };

    const handleCloseSnack = () => {
        setSnackbar((prevState) => ({
            ...prevState,
            visible: false,
        }));
    };

    return (
        <div className="ViewedContentContainer" id="OpenContainer">
            <div className="innerContainer-pcore">
                <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 274 }}>
                    Scan Item Barcode
                </h1>
                <FormControl variant="standard">
                    <Input
                        style={{ width: 400, fontSize: 16 }}
                        id="scan-barcode"
                        autoFocus
                        placeholder="Scan RID, Serial # or SIM #..."
                        startAdornment={
                            <InputAdornment position="start">
                                <ScanIcon />
                            </InputAdornment>
                        }
                        onKeyDown={onSerialLookUp}
                    />
                </FormControl>
                <div style={{ width: '100%' }} className="flex-just-even">
                    <a style={{ color: 'blue' }} className="vert-margin" href="#" onClick={onBulkRequest}>
                        Bulk item?
                    </a>
                    <a id="lookup-serial" style={{ color: 'blue' }} className="vert-margin" href="#" onClick={onToggleModal}>
                        Enter New Item
                    </a>
                </div>
                {modalOpen ? (
                    <Modal modal_id="serial-lookup" dimension={{ width: 500 }} onClose={onToggleModal}>
                        <div className="flex-just-center flex-column flex-align-center">
                            <h4>Select Item To Add</h4>
                            <Autocomplete
                                id="model"
                                className="space-below item-field"
                                disableClearable
                                options={newItemOptions.map((model) => model.item_type)}
                                onChange={(event, value) => handleNewItemSelection(value)}
                                renderInput={(params) => (
                                    <TextField
                                        className="space-below item-field"
                                        {...params}
                                        label="Item Type"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                    />
                                )}
                            />
                            <Button className="item-buttons" variant="outlined" color="primary" onClick={handleNewItemSubmit}>
                                Submit
                            </Button>
                        </div>
                    </Modal>
                ) : null}
            </div>
            <AlertBar
                visible={snackbar.visible}
                onClose={handleCloseSnack}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </div>
    );
};

export default ScanItem;
