import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import IssueModal from "./IssueModal.js";
import Spinner from "../../components/Spinner";
import { useCustomContext } from "../../hoc/Context.js";
import CustomTable from "../../components/Table";
import Button from "@mui/material/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineDownload } from "react-icons/ai";

const TableHeaders = [
  { id: "error-code", name: "Error Code", sortable: true, numeric: false },
  { id: "description", name: "Description" },
  { id: "steps", name: "Steps" },
];

const SearchOptions = [{ id: "error", name: "Error Code", pidx: 0 }];

const IssueTable = (props) => {
  const [issuesClicked, setIssuesClicked] = useState("");
  const [IssuesDB, setIssuesDB] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [SearchVal, setSearchVal] = useState("");
  const [searchParam, setSearchParam] = useState(0);
  const { userState, userDispatch } = useCustomContext();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      socket.emit("selectFromKiosksIssuesForTM", (result) => {
        setIssuesDB(result);
        setLoading(false);
      });
    }

    return () => {
      mounted = false;
    };
  }, []);

  const RefreshTableData = () => {
    socket.emit("selectFromKiosksIssuesForTM", (result) => {
      setIssuesDB([...result]);
    });
  };

  const AddNewItemBtn = () => {
    let issue = "";
    setIssuesClicked(issue);
    document.getElementById("myModal").style.display = "block";
    document.getElementById("modal_kiosk_issues").style.display = "block";
  };

  const RowClick = (e) => {
    const id = e.target.id;
    setIssuesClicked(id);
    document.getElementById("myModal").style.display = "block";
    document.getElementById("modal_kiosk_issues").style.display = "block";
  };

  const handleIssueDownload = () => {
    document.getElementById("issue-download").click();
  };

  function RenderTableButtons() {
    return userState.user.role ? (
      <div>
        <Button
          className="rti-blue-round"
          variant="contained"
          startIcon={<AiOutlineDownload />}
          onClick={handleIssueDownload}>
          Issues
        </Button>
        <CSVLink
          id="issue-download"
          style={{ background: "none", border: "none" }}
          target="_blank"
          className="hidden"
          data={IssuesDB}
          filename={"KioskIssues.csv"}
        ></CSVLink>
        <Button
          className="rti-blue-round"
          variant="contained"
          startIcon={<AiOutlinePlus />}
          onClick={AddNewItemBtn}>
          Issue
        </Button>
      </div>
    ) : undefined;
  }

  function ClearSearch() {
    setSearchVal("");
  }

  function Search(param, searchVal) {
    setSearchVal(searchVal);
    setSearchParam(param);
  }

  const getTableData = () => {
    return IssuesDB.map((issue) => ({
      key: issue.KioskIssueID,
      cells: [
        { data: issue.KioskIssueID }, // returns the error code Number
        { data: issue.Description }, // returns the error code Description
        { data: issue.ToDoNext }, // retuns the error code steps
      ],
    })).filter((row) =>
      SearchVal &&
      searchParam &&
      row.cells[SearchOptions[searchParam - 1].pidx].data
        ? row.cells[SearchOptions[searchParam - 1].pidx].data
            .toLowerCase()
            .includes(SearchVal.toLowerCase())
        : row
    );
  };

  return (
    <div className="ViewedContentContainer" id="OpenContainer">
      <div style={{ height: "100%" }}>
        <div className="modal" id="myModal">
          <div className="modal-content" id="modal_kiosk_issues">
            <IssueModal
              role={userState.user.role ? true : false}
              key={parseInt(issuesClicked.replace(/[^\d.]/g, "")) - 500}
              issueKey={issuesClicked}
              refresh_data={RefreshTableData}
              issueModal={
                IssuesDB.filter(function (id) {
                  return id.KioskIssueID == issuesClicked;
                })[0]
              }
            />
          </div>
        </div>
        <div className="TitleBarContainer">
          <h1>Issues</h1>
        </div>
        <CustomTable
          loading_data={Loading}
          headers={TableHeaders}
          rows={getTableData()}
          searchable
          paginate
          search={Search}
          clear_search={ClearSearch}
          search_options={SearchOptions}
          table_buttons={RenderTableButtons()}
          onClick={RowClick}
        />
      </div>
    </div>
  );
};

export default IssueTable;
