import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MdDeleteOutline } from 'react-icons/md';  // Importing Delete icon from react-icons
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

export const ScannedItemsTable = (props) => {
	const { data, removeItem } = props;
	return (
        <div>
			<div className='vert-margin-med'>
				<TableContainer
					className='item-table'
					component={Paper}>
					<Table
						stickyHeader
						aria-label='simple table'>
						<TableHead>
							<TableRow align='center'>
								<TableCell
									className='item-buttons'
									align='center'>
									Scanned Items
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((item) => (
								<TableRow
									align='center'
									key={item.id}>
									<TableCell
										className='item-buttons'
										align='center'
										id={item.id}>
										<IconButton id={item.id} key={item.id} onClick={removeItem} size="large">
											<MdDeleteOutline
												id={item.id}
												style={{ color: 'darkgray' }}
											/>
										</IconButton>
										{item.barcode}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
    );
};
