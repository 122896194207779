import React, { useEffect, useState } from 'react'
import '../../StyleSheets/TechPortal.css';
import { Navigate } from 'react-router-dom';
import { MdHelp } from "react-icons/md";
import ToolTip from '@mui/material/Tooltip';
import Modal from '../../components/Modal.js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import { getEmailSignature, _hashCode } from '../../utilities/util.js';
import { AES } from 'crypto-js';
import env from '../../utilities/env-loader.cjs';
const TechLogIn = () => {
    const [TechID, setTechID] = useState("");
    const [Password, setPassword] = useState("");
    const [Verifying, setVerifying] = useState(false);
    const [Verified, setVerified] = useState(false);
    const [resetState, setResetState] = useState(0);
    const [Techs, setTechs] = useState([]);
    const [TechObj, setTechObj] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [Form, setForm] = useState({
        Username: true,
        Password: true
    });

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            socket.emit('selectAllFromTechnicians', (result) => {
                setTechs(result);
            })
        }

        return () => {
            mounted = false;
        }
    }, []);

    useEffect(() => {
        $('#pass-reset').toggleClass('flex-box');
    }, [modalOpen]);

    const VerifyCredentials = (e) => {
        e.preventDefault(); // need this to stop from default submit action

        let found = false;
        let techObj;

        Techs.forEach(tech => { // find technician
            if (TechID.trim() == tech.TechnicianID) {
                techObj = tech;
                found = true;
            }
        })

        if (!found) { // error id doesn't match
            setForm(prevState => ({
                ...prevState,
                Username: false
            }));
        } else {
            VerifyPassword(techObj);
        }
    }

    const VerifyPassword = (obj) => {
        const password = AES.encrypt(document.getElementById('tech-password').value, env.USER_AUTH.SECRET_KEY).toString();
        $.post('/api/tech-verification', { username: obj.TechnicianID, password: password }, (res) => {
            if (!res.status) {
                setForm(prevState => ({
                    ...prevState,
                    Password: false
                }));
            } else {
                sessionStorage.setItem('techName', `${obj.FirstName} ${obj.LastName}`);
                sessionStorage.setItem('tid', `${TechID}`);
                setTechObj(obj);
                setVerified(true);
            }
        });
    }

    const TechIDInputHandler = (e) => {
        let id = e.target.value;
        setTechID(id);
        setForm(prevState => ({
            ...prevState,
            Username: true
        }));
    }

    const PasswordInputHandler = (e) => {
        let pass = e.target.value;
        setPassword(pass);
        setForm(prevState => ({
            ...prevState,
            Password: true
        }));
    }

    const RenderUserNameError = () => {
        return !Form.Username ?
            (
                <div>
                    <label className="user-error">**Incorrect username or password, please try again</label><br></br>
                </div>
            ) : null
    }

    const RenderPasswordError = () => {
        return !Form.Password ? (
            <div>
                <label className="pass-error">**Incorrect username or password, please try again</label><br></br>
            </div>
        ) : null
    }

    const toggleModal = () => {
        setModalOpen(prevState => !prevState);
    }

    const handleConfirmReset = () => {
        const tid = document.getElementById('tid').value;
        const phone4 = document.getElementById('phone4').value;
        const delivery_method = document.getElementById('sms-reset').checked ? "sms" : document.getElementById('email-reset').checked ? "email" : null;

        if (delivery_method) {
            setSending(true);
            socket.emit('validateTechByPhone', tid, phone4, (res) => {
                if (!res) {
                    console.error('There was an issue validating this user to reset their password.')
                    setSending(false);
                } else {
                    const expiration = new Date();
                    expiration.setHours(expiration.getHours() + 1);
                    const encoded_request = Buffer.from(expiration.getTime().toString()).toString('base64');
                    const requestCode = _hashCode(encoded_request).toString().slice(-6).toUpperCase();
                    const rid_link = `http://localhost:3000/tech-portal/forgot-pass?rid=${requestCode}&uid=${res[0].tid}&type=tech&reset_state=${resetState}&exp=${expiration.getTime()}`;

                    if (delivery_method == 'email') {
                        const msg = `<span width="100%">` +
                            `<h1 style="text-align:center;">${resetState == 1 ? 'Password Reset' : 'Register Account'}</h1>` +
                            `<h2>THIS LINK EXPIRES IN 1 HOUR</h2>` +
                            `<p>You have requested to ${resetState == 1 ? 'reset your password' : 'register your account'}.</p>` +
                            `<p>If you <b>did not</b> request to ${resetState == 1 ? 'reset your password' : 'register your account'}, please contact tech support immediately.</p>` +
                            `<p>If you <b>did</b> request to ${resetState == 1 ? 'reset your password' : 'register your account'}, then click the link below.</p>` +
                            `<p>Verification #: <b>${requestCode}</b></p>` +
                            `<span>
                            <a href=${rid_link} style="text-align: center;">${resetState == 1 ? 'Reset Password' : 'Register Account'}</a></span>
                        </span>
                        ` +
                            `${getEmailSignature('Auto Message')}` +
                            `</span>`

                        const mailOpts = {
                            to: res[0].email,
                            subject: `${resetState == 1 ? 'Password Reset' : 'Account Registration'} Request - Registration Technology Inc.`,
                            text: msg
                        }

                        $.get('/send', mailOpts, (res) => {
                            if (res.includes('250') || res.includes('200')) {
                                alert('This will be delivered to your email address shortly!')
                                toggleModal();
                                setSending(false);
                            }
                        })
                    } else {

                        const msgOpts = {
                            to: res[0].phone,
                            body: `Your verification # is ${requestCode}.\n\n${resetState == 1 ? 'A password reset' : 'Account registration'} has been requested for your RTI account.\nIf you did NOT request to ${resetState == 1 ? 'reset your password' : 'register your account'}, please contact the tech support immediately.\nIf you did request to ${resetState == 1 ? 'reset your password' : 'register your account'} please click on the link below and follow the instructions on the following page.\n\n${rid_link}\n THIS LINK EXPIRES IN 1 HOUR.`,
                            from: 'Auto Msg',
                            type: 'standard',
                            private: true,
                            time: require('moment')().format('YYYY-MM-DD HH:mm:ss'),
                            type: 'admin'
                        }

                        $.post('/api/send_message_async', { messages: msgOpts }, (res) => {
                            if (res.status == 200) {
                                alert('Your verification link has been sent to your phone.')
                                toggleModal();
                                setSending(false);
                            } else {
                                alert('There was an issue sending your verification link to this phone number')
                                toggleModal();
                                setSending(false);
                            }
                        })
                    }

                }
            })
        } else {
            alert('Please select a delivery method.')
        }
    }

    const handleRegister = () => {
        setResetState(2);
        toggleModal();
    }

    const handleForgotPassword = () => {
        setResetState(1);
        toggleModal();
    }

    return (
        <div className='tech-login-container flex-just-center'>
            {!Verified ? <div className="flex-column flex-just-center">
                {modalOpen ?
                    <Modal modal_id='pass-reset' dimension={{ width: 400 }} onClose={toggleModal}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 368 }}>
                            <h2>{resetState == 1 ? `Password Reset` : `Register Account`}</h2>
                            <p>Fill out the information below. After clicking <b>CONFIRM</b>, a {resetState == 1 ? 'reset' : 'registration'} link will be sent to your delivery method shortly.</p>
                            <div>
                                <TextField id="tid" type="text" variant="standard" label="Tech ID" />
                            </div>
                            <div>
                                <TextField id="phone4" type="text" variant="standard" label="Last 4 of Phone #" />
                            </div>
                            <p>How would you like to receive your {resetState == 1 ? 'reset' : 'registration'} link?</p>
                            <div className='vert-margin flex-just-even'>
                                <FormControlLabel
                                    control={
                                        <Checkbox id="sms-reset" color="primary" />
                                    }
                                    label="SMS"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox id="email-reset" color="primary" />
                                    }
                                    label="Email"
                                />
                            </div>
                            <div>
                                {!sending ?
                                    <Button className="rti-blue-round" variant='contained' onClick={handleConfirmReset}>Confirm</Button> :
                                    <div>
                                        <label>Sending...</label>
                                        <LinearProgress />
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal> : null
                }
                <div className="inner-container-greeting">
                    <h1>Welcome to the Tech Portal</h1>
                </div>
                <div className='flex-just-center flex-wrap'>
                    <div style={{ maxWidth: 700 }} className='flex-align-center flex-just-center flex-wrap'>
                        <div className="inner-container-login">
                            <div className="login-form-container">
                                <div className="login-form-label">
                                    <h1>Login</h1>
                                </div>
                                <div className="form-divider"></div>
                                <div className="login-form-main-body">
                                    <div className="inner-form-body">
                                        <form autoComplete="off">
                                            <div className="tech-id">
                                                <div className='flex-align-center'>
                                                    <label htmlFor="tech-id">Tech ID</label>
                                                    <ToolTip style={{ fontSize: 20 }} title="Your Tech ID is a unique number that was given to you when you first signed in.">
                                                        <HelpCircle style={{ color: 'gainsboro' }} />
                                                    </ToolTip>
                                                </div>
                                                {RenderUserNameError()}
                                                <input type="text" id="tech-id" name="tech-id" onChange={TechIDInputHandler}></input>
                                            </div>
                                            <div className="tech-pass">
                                                <div className='flex-align-center'>
                                                    <label htmlFor="tech-password">Password</label>
                                                </div>
                                                {RenderPasswordError()}
                                                <input type="password" id="tech-password" name="tech-password" onChange={PasswordInputHandler}></input>
                                            </div>
                                            <div>
                                                <a style={{ color: 'blue' }} href='#' onClick={handleForgotPassword}>Forgot password?</a>
                                            </div>
                                            <div className="login-button">
                                                <input type="submit" value="LOGIN" onClick={VerifyCredentials}></input>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div>
                            <div className='flex-column flex-align-center flex-just-center'>
                                <h1>First time signing in?</h1>
                                <Button className='rti-blue-round' onClick={handleRegister}>First time sign in</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <Navigate to="/tech-portal" />}
        </div>
    )
}

export default TechLogIn;
