import React, { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner.js';
import Button from '@mui/material/Button';
import { DateToLocale } from '../API/TextFormatingFunctions.js';
import '../../StyleSheets/TechJobs.css';

const TechJobs = () => {
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState({});

    const jid = new URLSearchParams(window.location.search).get('jid');
    const tid = new URLSearchParams(window.location.search).get('tid');
    
    useEffect(() => {
        retrieveData(true);
    },[]);

    const retrieveData = (_load) => {
        if(_load) setLoading(true);

        if(jid && tid){
            // get job information
            socket.emit('getJobByJobID',tid,jid,(res) => {
                if(!res){
                    console.error(`Jobs.getJobByJobID: There was an issue calling this method`)
                    setLoading(false);
                }else{
                    // do next
                    setData({
                        ...res[0],
                        valid: true
                    })
                    setLoading(false);
                }
            });
        }
    }

    return (
        <div className='flex-just-center' style={{width: '100%'}}>
            {!loading && data.valid ?
                <div className='tech-job-wrapper'>
                    <h1 className='text-center'>Job #: {data.JobID}</h1>
                    <div className='flex-just-between'>
                        <p><b>Assigned:</b> {DateToLocale(new Date(data.AssignedDate))}</p>
                        <p><b>Completed:</b> {DateToLocale(new Date(data.CompletedDate))}</p>
                    </div>
                    <div className='flex-just-between'>
                        <p><b>Dispatched By:</b> {data.Dispatcher}</p>
                        <p><b>Kiosk:</b> {data.KioskID}</p>
                        <p><b>Issue:</b> {data.issue}</p>
                    </div>
                    <div className='divider'></div>
                    <div>
                        <h2>Service Details</h2>
                        <div>
                            <p><b>Work Time:</b> {data.WorkTime}</p>
                            <p><b>Drive Time:</b> {data.DriveTime}</p>
                        </div>
                        <div>
                            <p><b>Datamax Form Feeds:</b> {data.DMHowManyFormFeeds}</p>
                            <p><b>Datamax 1 Test Prints:</b> {data.DMOneTestPrints}</p>
                        </div>
                        <div>
                            <p><b>Datamax 2 Test Prints:</b> {data.DMTwoTestPrints}</p>
                            <p><b>Star Test Prints:</b> {data.StarTestPrints}</p>
                        </div>
                        <div>
                            <p><b>Paper Changed:</b> {data.PaperChanged ? data.PaperChanged : 'None'}</p>
                            <p><b>Extra Star Rolls:</b> {data.HowManyExtraRollsOfStar}</p>
                        </div>
                        <div>
                            <p><b>Sticky Rolls:</b> {data.StickyRolls}</p>
                            <p><b>Updated Cell Carrier Info:</b> {data.DidYouUpdateModemCellCarrierInfo != 'UnKnown' ? data.DidYouUpdateModemCellCarrierInfo : 'No'}</p>
                        </div>
                        <div>
                            <p><b>Updated Page Counts:</b> {data.PageCounts != 'UnKnown' ? data.PageCounts : 'No'}</p>
                        </div>
                        <div>
                            <p><b>Notes</b></p>
                            <textarea disabled style={{width: '75%', height: 100}} defaultValue={data.Notes}></textarea>
                        </div>
                    </div>
                    <div className='flex-just-center vert-margin-med'>
                        <Button variant="outlined" color="primary">resend email</Button>
                    </div>
                </div> : 
                !loading && !data.valid ?
                <div>
                    <h1>404 It seems this job doesn't exist.</h1>
                </div> :
                <Spinner />
            }
        </div>
    )
}

export default TechJobs;