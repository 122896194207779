// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent-counter-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 2.5em;
}

.input-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.count-title-container {
	text-align: center;
}
.counter-title {
	padding: 0;
	margin: 0;
}
.submit-counter-button-container {
	display: flex;
	justify-content: space-evenly;
	padding: 12.5px;
}

.subtraction,
.addition {
	display: flex;
	justify-content: space-around;
	margin: auto;
	padding: 10px 0;
}

.counter-button {
	height: 75px;
	width: 90px;
	border-radius: 4px;
	font-weight: bold;
}
.counter-button:hover {
	cursor: pointer !important;
	background-color: #636988 !important;
}

#sticker-entry {
	width: 232px;
	font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/StyleSheets/StickerCounter.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,kCAAkC;CAClC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;AACnB;AACA;CACC,UAAU;CACV,SAAS;AACV;AACA;CACC,aAAa;CACb,6BAA6B;CAC7B,eAAe;AAChB;;AAEA;;CAEC,aAAa;CACb,6BAA6B;CAC7B,YAAY;CACZ,eAAe;AAChB;;AAEA;CACC,YAAY;CACZ,WAAW;CACX,kBAAkB;CAClB,iBAAiB;AAClB;AACA;CACC,0BAA0B;CAC1B,oCAAoC;AACrC;;AAEA;CACC,YAAY;CACZ,eAAe;AAChB","sourcesContent":[".parent-counter-container {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 1fr 1fr;\n\tgrid-gap: 2.5em;\n}\n\n.input-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\ttext-align: center;\n}\n\n.count-title-container {\n\ttext-align: center;\n}\n.counter-title {\n\tpadding: 0;\n\tmargin: 0;\n}\n.submit-counter-button-container {\n\tdisplay: flex;\n\tjustify-content: space-evenly;\n\tpadding: 12.5px;\n}\n\n.subtraction,\n.addition {\n\tdisplay: flex;\n\tjustify-content: space-around;\n\tmargin: auto;\n\tpadding: 10px 0;\n}\n\n.counter-button {\n\theight: 75px;\n\twidth: 90px;\n\tborder-radius: 4px;\n\tfont-weight: bold;\n}\n.counter-button:hover {\n\tcursor: pointer !important;\n\tbackground-color: #636988 !important;\n}\n\n#sticker-entry {\n\twidth: 232px;\n\tfont-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
