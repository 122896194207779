import React, { useEffect, useState } from 'react';
import { useCustomContext } from './Context';
import { Navigate, Route, Routes } from 'react-router-dom';

/**
 * ProtectedRoute is a higher order component that checks if the user has the required permissions to access the route.
 * @param {Object} props - The component props.
 * @param {Array<string>} props.perms - The required permissions.
 * @param {React.ReactElement} props.element - The component to render if authorized.
 * @param {boolean} props.exact - Whether the route should be matched exactly.
 * @param {string} props.path - The path to match.
 * @param {Function} [props.render] - The render function.
 * @returns {React.ReactElement} The rendered component.
 */
const ProtectedRoute = ({ perms, element, exact, path, render }) => {
    const [auth, setAuth] = useState(null);
    const { userState } = useCustomContext();

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if ((perms.includes(userState.user.permissions) && userState.user.access) || perms === 'all') {
                setAuth(true);
            } else {
                setAuth(false);
            }
        }

        return () => {
            mounted = false;
        };
    }, [perms, userState.user]);

    return (
        <div>
            {auth === true ? (
                <Routes>
                    <Route exact={exact} path={path} element={element} render={render} />
                </Routes>
            ) : auth === false ? (
                <Navigate to='/' />
            ) : null}
        </div>
    );
};

export default ProtectedRoute;
