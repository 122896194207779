import React, { useCallback, useState, memo, useEffect, useRef } from 'react';
import $ from 'jquery';
import Spinner from '../../components/Spinner';
import '../../../src/StyleSheets/GoogleMap.css';
import '../../StyleSheets/Table.css';
import SearchInput from '../../components/SearchInput';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { MdDragIndicator, MdAdd, MdRemove, MdOutlineDelete, MdAccessTime, MdBuild } from 'react-icons/md';
import CheckBox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TechMarker from './TechMarker';
import LinearProgress from '@mui/material/LinearProgress';
import Heatmap from './Heatmap';
import KioskMarker from './KioskMarker';
import MarkerInfo from './MarkerInfo';
import ToolTip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import Modal from '../../components/Modal';
import CustomTable from '../../components/Table';
import { CurrentTime } from '../API/Moment';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { GoogleMap, useJsApiLoader, TrafficLayer, DirectionsRenderer, DirectionsService } from '@react-google-maps/api';

const MAPS_KEY = process.env.MAPS_API_KEY;

const containerStyle = {
	width: '100%',
	height: '700px',
};

const center = {
	lat: 44.4648855,
	lng: -89.5834668,
};

const libs = ['visualization'];

const defaultFilter = {
	days: 0,
	data_type: 'issues',
};

const DEFAULT_TIME = 30;

const TableHeaders = [
	{
		id: 'waypoint',
		name: 'waypoint',
		size: 'small-header',
	},
	{
		id: 'kiosk',
		name: 'kiosk',
		size: 'small-header',
	},
	{
		id: 'server',
		name: 'server',
	},
	{
		id: 'address',
		name: 'address',
	},
	{
		id: 'distance',
		name: 'distance',
		size: 'small-header',
	},
	{
		id: 'drive time',
		name: 'drive time',
	},
	{
		id: 'issue',
		name: 'issue',
	},
	{
		id: 'work time',
		name: 'work time',
		size: 'small-header',
	},
];

function KioskMap() {
	const navigate = useNavigate();

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: MAPS_KEY,
		libraries: libs,
	});

	const getCurTime = () => {
		const _date = new Date();

		return `${_date.getHours().toString().padStart(2, '0')}:${_date.getMinutes().toString().padStart(2, '0')}`;
	};
	const _query = new URLSearchParams(window.location.search);
	_query.set('techs', true);
	_query.set('kiosks', true);

	const _params = Array.from(_query.keys()).reduce(
		(acc, val) => ({
			...acc,
			[val]: _query.get(val),
		}),
		{}
	);

	const [map, setMap] = useState(null);
	const [techs, setTechs] = useState([]);
	const [paper, setPaper] = useState(false);
	const [kiosks, setKiosks] = useState([]);
	const [heatMap, setHeatMap] = useState([]);
	const [fetchingData, setFetchingData] = useState(true);
	const [showTechs, setShowTechs] = useState(_params.techs && JSON.parse(_params.techs));
	const [showKiosks, setShowKiosks] = useState(_params.kiosks && JSON.parse(_params.kiosks));
	const [showHeatMap, setShowHeatMap] = useState(_params.heatMap && JSON.parse(_params.heatMap));
	const [mapFilter, setMapFilter] = useState(defaultFilter.days);
	const [dataFilter, setDataFilter] = useState(defaultFilter.data_type);
	const [listData, setListData] = useState({
		techs: [],
		kiosks: [],
	});
	const [route, setRoute] = useState({
		response: null,
		travelMode: 'DRIVING',
		origin: '',
		destination: '',
		waypoints: [],
	});

	const [waypoints, setWaypoints] = useState([]);
	const [buildRoute, setBuildRoute] = useState(false);
	const [markerSelected, setMarkerSelected] = useState(null);
	const [activeMarker, setActiveMarker] = useState(null);
	const [searchParam, setSearchParam] = useState('kiosk');
	const [resultParam, setResultParam] = useState('kiosk');
	const [searchResults, setSearchResults] = useState([]);
	const [mapCenter, setMapCenter] = useState(center);
	const [displayOpts, setDisplayOpts] = useState(true);
	const [searchOpts, setSearchOpts] = useState(true);
	const [loadingQuery, setLoadingQuery] = useState(false);
	const [showTraffic, setShowTraffic] = useState(_params.traffic && JSON.parse(_params.traffic));
	const [iconDetail, setIconDetail] = useState(_params.detail && JSON.parse(_params.detail));
	const [anchorEl, setAnchorEl] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [tripStart, setTripStart] = useState(getCurTime());
	const [menuSelect, setMenuSelect] = useState('time');
	const [projections, setProjections] = useState([]);
	const [fullProj, setFullProj] = useState([]);
	const [issues, setIssues] = useState([]);
	const [jobSelect, setJobSelect] = useState(null);
	const [routeModal, setRouteModal] = useState(false);
	const [creating, setCreating] = useState(false);
	const paramRef = useRef();
	const resultParamRef = useRef();
	resultParamRef.current = resultParam;
	paramRef.current = searchParam;

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (fetchingData) {
				socket.emit('selectFromIssuesForCreateJob', (result) => {
					setIssues(result);
				});

				socket.emit('getMapAnalytics', mapFilter, ([heatmap, techs, kiosks, alerts, alert_issues]) => {
					const _alertIssues = alert_issues.filter((alert) => alert.total_issues >= 2);

					const _kiosks = kiosks.map((kiosk) => {
						const _found = alerts.find((alert) => alert.KioskID === kiosk.KioskID);
						const _alertIssue = _alertIssues.find((alert) => alert.kiosk_id.substring(0, 6) === kiosk.KioskID);

						return {
							...kiosk,
							issueReported: _found ? true : false,
							totalAlerts: _alertIssue && _alertIssue.total_issues ? true : false,
						};
					});
					setTechs(techs);
					setKiosks(_kiosks);
					setHeatMap(heatmap);
					setListData({
						techs: techs.map((tech) => (
							<option
								key={tech.TechnicianID}
								id={tech.TechnicianID}
								value={`${tech.FirstName} ${tech.LastName}`}
							></option>
						)),
						kiosks: kiosks.map((kiosk) => (
							<option key={kiosk.KioskID} id={kiosk.KioskID} value={`${kiosk.KioskID} / ${kiosk.ServerID}`}></option>
						)),
					});
					setFetchingData(false);
					navigate(`${window.location.pathname}?${_query.toString()}`, { replace: true });
				});
			} else {
				socket.emit('getHeatMapData', mapFilter, dataFilter, (heatmap) => {
					setHeatMap(heatmap);
				});
			}

			if (_params.type && _params.id) {
				onMarkerClick(_params.type, _params.id);
			}
		}
		const sortableElement = document.getElementById('route-table');
		if (sortableElement) {
			sortableElement.addEventListener('update', onCalculateRoute);
		}

		return () => {
			if (sortableElement) {
				sortableElement.removeEventListener('update', onCalculateRoute);
			}
		};
	}, [mapFilter, dataFilter, _params.id, route.destination, route.origin]);

	const debounce = _.debounce(() => {
		const value = document.getElementById('search-input').value;
		const id =
			paramRef.current === 'tech'
				? techs.filter((tech) => `${tech.FirstName} ${tech.LastName}`.toLowerCase() === value.toLowerCase())
				: kiosks.filter((kiosk) => kiosk.KioskID.toLowerCase() === value.substring(0, 6).toLowerCase());

		setLoadingQuery(true);
		if (id.length) {
			searchMap(paramRef.current === 'tech' ? id[0].TechnicianID : id[0].KioskID, (results) => {
				if (results.length) {
					setSearchResults(results);
				}
			});
		}
	}, 400);

	useEffect(() => {
		document.querySelectorAll('#route-modal').forEach((element) => {
			element.classList.toggle('flex-box');
		});
	}, [modalOpen]);

	useEffect(() => {
		document.querySelectorAll('#final-modal').forEach((element) => {
			element.classList.toggle('flex-box');
		});
	}, [routeModal]);

	const onLoad = useCallback((map) => {
		setMap(map);
	}, []);

	const onUnmount = useCallback(() => {
		setMap(null);
	}, []);

	function onMarkerClick(type, id) {
		setMarkerSelected(null);
		try {
			if (!paper) {
				socket.emit('getMapMarkerInfo', id, type, ([info]) => {
					if (!info) {
						console.error(`ERROR: KioskMap.getMapMarkerInfo: There was an issue calling this method`);
					} else {
						setActiveMarker(type);
						setMarkerSelected({
							...info,
							txns: fullProj.find((proj) => proj.Kiosk === id.toUpperCase()),
						});
					}
				});
			} else {
				const _selected = projections.find((proj) => proj.Kiosk === id); // returns
				setActiveMarker('paper');
				setMarkerSelected(_selected);
			}
		} catch (error) {
			console.error(`ERROR: KioskMap.onMarkerClick ${error} - ${new Date()}`);
		}
	}

	const getMarkerInfo = (type, id) => {
		handleURLParams(
			[
				{ key: 'id', val: id },
				{ key: 'type', val: type },
			],
			'add'
		);
	};

	const onMarkerClose = () => {
		setMarkerSelected(null);
		setActiveMarker(null);
		handleURLParams([{ key: 'id' }, { key: 'type' }], 'delete');
	};

	const onChecked = (e) => {
		switch (e.target.id) {
			case 'techs':
				setShowTechs(e.target.checked);
				handleURLParams([{ key: 'techs', val: e.target.checked }], 'add');
				break;
			case 'kiosks':
				setShowKiosks(e.target.checked);
				handleURLParams([{ key: 'kiosks', val: e.target.checked }], 'add');
				break;
			case 'heat-map':
				setShowHeatMap(e.target.checked);
				handleURLParams([{ key: 'heatMap', val: e.target.checked }], 'add');
				break;
			case 'traffic':
				setShowTraffic(e.target.checked);
				handleURLParams([{ key: 'traffic', val: e.target.checked }], 'add');
				break;
		}
	};

	const handleURLParams = (_params, _action) => {
		const urlParams = new URLSearchParams(window.location.search);
		_params.forEach((param) => {
			if (_action === 'add') {
				urlParams.set(`${param.key}`, `${param.val}`);
			} else {
				urlParams.delete(`${param.key}`);
			}
		});
		navigate(`${window.location.pathname}?${urlParams.toString()}`, { replace: true });
	};

	const onHeatMapChange = useCallback(
		(e) => {
			setMapFilter(e.target.value);
		},
		[mapFilter]
	);

	const onHeatMapDataChange = useCallback(
		(e) => {
			setDataFilter(e.target.value);
		},
		[dataFilter]
	);

	const onSearchInput = (value) => {
		const id =
			paramRef.current === 'tech'
				? techs.filter((tech) => `${tech.FirstName} ${tech.LastName}`.toLowerCase() === value.toLowerCase())
				: kiosks.filter((kiosk) => kiosk.KioskID.toLowerCase() === value.substring(0, 6).toLowerCase());
		setLoadingQuery(true);
		if (id.length) {
			searchMap(paramRef.current === 'tech' ? id[0].TechnicianID : id[0].KioskID, (results) => {
				if (results.length) {
					setSearchResults(results);
					setMapCenter({
						lat: parseFloat(results[0].Latitude),
						lng: parseFloat(results[0].Longitude),
					});
					getMarkerInfo(paramRef.current, paramRef.current === 'tech' ? id[0].TechnicianID : id[0].KioskID);
				}
			});
		} else {
			alert(
				`Sorry, it looks like we were unable to find the ${paramRef.current} you were looking for.  Please make sure that the value that you have entered is correct and try again.`
			);
			setLoadingQuery(false);
		}
	};

	const onAddRouteLocation = (_event) => {
		setTimeout(() => {
			onCalculateRoute('add', _event);
		}, 250);
	};

	const onRemoveRouteLocation = (_event) => {
		onCalculateRoute('delete', _event);
	};

	const handleSearch = () => {
		const value = document.getElementById('search-input').value;
		onSearchInput(value);
	};

	const handleClearSearch = () => {
		setSearchResults([]);
		document.getElementById('search-input').value = '';
		document.getElementById('distance').value = 30;
	};

	const searchMap = (id, callback) => {
		const distance = document.getElementById('distance').value;
		const results = document.getElementById('secondary-results').value;
		socket.emit(
			'searchDispatchMap',
			{ search_by: paramRef.current, output: results, id, distance: distance },
			(res) => {
				if (!res) {
					console.error(`KioskMap.searchDispatchMap: There was an issue calling this method`);
					callback([]);
					setLoadingQuery(false);
				} else {
					callback(res);
					setLoadingQuery(false);
				}
			}
		);
	};

	const onPrimaryChange = (e) => {
		const value = e.target.value;
		setSearchResults([]);
		setSearchParam(value);
		document.getElementById('search-input').value = '';
		document.getElementById('distance').value = 30;
	};

	const handleDisplayOpts = () => {
		handleTableSizing(displayOpts ? false : true);
		setDisplayOpts((prevstate) => !prevstate);
		document.querySelectorAll('#map-display-options').forEach((element) => {
			element.style.display = element.style.display === 'none' || element.style.display === '' ? 'block' : 'none';
		});
	};

	const handleTableSizing = (visible) => {
		if (!buildRoute && !displayOpts && !searchOpts) {
			// route builder is open
			document.getElementById('search-results').style.height = '86px';
		} else if (
			(!buildRoute && displayOpts && !searchOpts && visible) ||
			(!buildRoute && !displayOpts && searchOpts && visible)
		) {
			document.getElementById('search-results').style.height = '86px';
		} else if (!buildRoute && displayOpts && searchOpts) {
			document.getElementById('search-results').style.height = '86px';
		} else if (buildRoute && displayOpts && !searchOpts) {
			6;
			document.getElementById('route-wrapper').style.height = '240px';
			document.getElementById('search-results').style.height = '86px';
		} else if (buildRoute && !displayOpts && searchOpts) {
			document.getElementById('route-wrapper').style.height = '240px';
			document.getElementById('search-results').style.height = '86px';
		} else if (buildRoute && !displayOpts && !searchOpts) {
			document.getElementById('route-wrapper').style.height = '140px';
			document.getElementById('search-results').style.height = '86px';
		} else if (displayOpts && searchOpts && !visible) {
			// both options are currently open.  We are closing display options and need to extend table hieght
			document.getElementById('search-results').style.height = '330px';
		} else if (!displayOpts && searchOpts) {
			// only search options is open.  Show display options and shrink table
			if (!buildRoute) {
				if (visible) {
					// display options is being opened to make both open
					document.getElementById('search-results').style.height = '240px';
				} else {
					// search options is being closed, making both closed
					document.getElementById('search-results').style.height = '240px';
				}
			} else {
				if (visible) {
					// display options is being opened to make both open
					document.getElementById('search-results').style.height = '240px';
				} else {
					// search options is being closed, making both closed
					document.getElementById('search-results').style.height = '425px';
				}
			}
		} else if (displayOpts && !searchOpts) {
			// search options is closed.  Closing display options so we must shrink the table
			if (visible) {
				// search options is being opened, making both open
				document.getElementById('search-results').style.height = '240px';
			} else {
				// display options is being closed, making both closed
				document.getElementById('search-results').style.height = '425px';
			}
		} else if (!displayOpts && !searchOpts && visible) {
			// both options are closed, making one visible
			document.getElementById('search-results').style.height = '330px';
		}
	};

	const handleSearchOpts = () => {
		handleTableSizing(searchOpts ? false : true);
		setSearchOpts((prevstate) => !prevstate);
		document.querySelectorAll('#map-search-options').forEach((element) => {
			element.style.display = element.style.display === 'none' || element.style.display === '' ? 'block' : 'none';
		});
	};

	const onDistanceChanged = () => {
		debounce(); // debounce data being searched
	};

	const onResultChange = (e) => {
		debounce();
		setResultParam(e.target.value);
	};

	const onTrafficLoad = () => {
		return;
	};

	const onRouteLoad = () => {
		return;
	};

	const onServiceLoad = () => {
		return;
	};

	const toggleRoute = () => {
		if (searchOpts) {
			document.querySelectorAll('#map-search-options').forEach((element) => {
				element.style.display = element.style.display === 'none' || element.style.display === '' ? 'block' : 'none';
			});
		}

		if (displayOpts) {
			document.querySelectorAll('#map-display-options').forEach((element) => {
				element.style.display = element.style.display === 'none' || element.style.display === '' ? 'block' : 'none';
			});
		}

		setRoute((prevState) => ({
			...prevState,
			response: null,
			origin: '',
			destination: '',
			waypoints: [],
		}));
		setWaypoints([]);
		handleTableSizing(true);
		setDisplayOpts(false);
		setSearchOpts(false);
		setBuildRoute((prevState) => !prevState);
	};

	const onAddRoute = (_info) => {
		if (!route.origin) {
			// needs starting point
			document.getElementById('route-start').value = _info;
			document.getElementById('route-init').click();
		} else {
			// add to waypoint
			const idx = route.waypoints.length;
			document.getElementById(`route-${idx}-val`).value = _info;
		}
	};

	const checkType = (_val) => {
		if (_val.includes('/')) {
			return 'kiosk';
		}

		return 'tech';
	};

	const getAddress = (_val, _type) => {
		return _type === 'kiosk'
			? kiosks.find((kiosk) => `${kiosk.KioskID} / ${kiosk.ServerID}` === _val)
			: techs.find((tech) => `${tech.FirstName} ${tech.LastName}` === _val);
	};

	function onCalculateRoute(_action, _event) {
		if ((waypoints.length && _action !== 'delete') || (waypoints.length && _action === 'update')) {
			// adding or updating waypoints
			const _start = document.getElementById('route-start').value;
			const _startType = checkType(_start);
			const _locations = [];
			const _waypoints = [];
			let _ok = true;
			let _okJob = true;
			const x = document.getElementsByName('route');

			for (let i = 0; i < x.length; i++) {
				const [pref, idx] = x[i].id.split('-');
				const _stopTime = document.getElementById(`route-${i}-time`).attributes[0].value;
				const _job =
					document.getElementById(`route-${i}-job`).attributes.length > 1
						? document.getElementById(`route-${i}-job`).attributes[0].value
						: null;

				const _input = x[i].value;
				const _inputType = checkType(_input);
				const _address = getAddress(_input, _inputType);

				if (_address && _job) {
					_locations.push({
						location: _address.address,
						stopover: true,
					});

					_waypoints.push({
						location: _address.address,
						stopover: true,
						kiosk: _input,
						type: _inputType,
						id: _inputType !== 'tech' ? _address.KioskID : _address.TechnicianID,
						server: _inputType !== 'tech' ? _address.ServerID : '',
						time: _stopTime,
						job: _job,
					});
				} else if (!_address && _event && _event !== 'update') {
					_ok = false;
				} else if (!_job) {
					_okJob = false;
				}
			}

			if (_ok && _action !== 'update' && _okJob) {
				const _startRoute = getAddress(_start, _startType).address;
				const _dest = _locations[_locations.length - 1].location;

				setWaypoints([..._waypoints, { location: '', stopover: true, time: 0, job: '' }]);

				setRoute((prevState) => ({
					...prevState,
					response: null,
					destination: _startRoute,
					origin: _startRoute,
					waypoints: _locations,
				}));
			} else if (!_ok) {
				alert(`Destination cannot be blank.`);
			} else if (!_okJob && _action !== 'update') {
				alert(`You must select a job for this location`);
			}

			if (_action === 'update') {
				const _startRoute = getAddress(_start, _startType).address;
				const _dest = _locations[_locations.length - 1].location;

				setWaypoints([..._waypoints, { location: '', stopover: true, time: 0, job: '' }]);

				setRoute((prevState) => ({
					...prevState,
					response: null,
					origin: _startRoute,
					destination: _startRoute,
					waypoints: _locations,
				}));
			}
		} else if (!waypoints.length && _action === 'add') {
			// adding initial waypoint
			const _start = document.getElementById('route-start').value;
			const _startType = checkType(_start);
			const _startRoute = getAddress(_start, _startType).address;

			setWaypoints((prevState) => [...prevState, { location: '', stopover: true, time: 0, job: '' }]);

			setRoute((prevState) => ({
				...prevState,
				destination: _startRoute,
				origin: _startRoute,
			}));
		} else if (waypoints.length && _action === 'delete') {
			// deleting a waypoint
			const [pref, idx] = _event.target.id.split('-');
			const _start = document.getElementById('route-start').value;
			const _startType = checkType(_start);
			const _startRoute = getAddress(_start, _startType).address;

			document.getElementById(`route-${waypoints.length - 2}-val`).value = '';

			waypoints.splice(idx, 1);
			const _filtered = waypoints.filter((location) => location.location);
			const _waypoints = _filtered.map((location) => ({
				location: location.location,
				stopover: true,
			}));

			_filtered.forEach((location, idx) => {
				document.getElementById(`route-${idx}-val`).value = location.kiosk;
			});

			setWaypoints([...waypoints]);

			setRoute((prevState) => ({
				...prevState,
				response: null,
				origin: _startRoute,
				destination: _startRoute,
				waypoints: _waypoints,
			}));
		}
	}

	const onResultClick = (event) => {
		const id = event.target.id;
		const clicked = searchResults.find((el) => el.id === id);
		if (clicked && resultParam === 'kiosk') {
			getMarkerInfo(resultParam, clicked.kiosk.substring(0, 6));
		} else if (clicked && resultParam === 'tech') {
			getMarkerInfo(resultParam, clicked.tech_id);
		}
	};

	const onToggleSwitch = () => {
		setIconDetail((prevState) => !prevState);
		setPaper(false);
		handleURLParams([{ key: 'detail', val: !iconDetail }], 'add');
	};

	const onTogglePaperIcons = () => {
		setPaper((prevState) => !prevState);
		setIconDetail(false);
		handleURLParams([{ key: 'detail' }], 'remove');
		handleURLParams([{ key: 'paper', val: !paper }], 'add');
	};

	const onDirectionsService = (_res) => {
		if (_res && !route.response) {
			// only change when response has not been set
			if (_res.status === 'OK') {
				setRoute((prevState) => ({
					...prevState,
					response: _res,
				}));
			} else {
				console.error(_res);
			}
		}
	};

	const calcTripTime = () => {
		if (route.response && route.response.routes[0].legs.length) {
			const _points = route.response.routes[0].legs;

			const _driveTime = _points.reduce((total, point) => {
				const _parsed = point.duration.text.split(' ');

				if (_parsed.length === 4) {
					// hours and mins
					total += parseInt(_parsed[0]) * 60 + parseInt(_parsed[2]);
				} else if (_parsed.length === 2) {
					// hours or mins
					if (point.duration.text.match(/mins?/)) {
						total += parseInt(_parsed[0]);
					} else if (point.duration.text.match(/hours?/)) {
						total += parseInt(_parsed[0]) * 60;
					}
				}

				return total;
			}, 0);

			const _storeTime = waypoints
				.filter((loc) => loc.location && loc.time)
				.reduce((total, loc) => (total += parseInt(loc.time)), 0);

			const _totalTime = _driveTime + _storeTime;

			return {
				mins: _totalTime,
				text:
					_totalTime > 59
						? `${Math.floor(_totalTime / 60)} hour(s) ${_totalTime % 60} min(s)`
						: `${_totalTime % 60} min(s)`,
			};
		}

		return { mins: 0, text: '0 min' };
	};

	const onCloseMenu = () => {
		setAnchorEl(null);
		setJobSelect(false);
	};

	const onMenuOpen = (_event) => {
		setAnchorEl(_event.currentTarget);
		const id = _event.target.id;

		if (id === 'stop-time-btn') {
			setMenuSelect('time');
		} else if (id === 'job-select-btn') {
			setMenuSelect('job');
		}
	};

	const onAddTime = (_event) => {
		const [pref, idx] = _event.target.id.split('-');
		const _copy = JSON.parse(JSON.stringify(waypoints));

		if (idx) {
			_copy[idx].time = document.getElementById('stop-time').value;

			setWaypoints(_copy);
			onCloseMenu();
		}
	};

	const onAddJob = (_event) => {
		const [pref, idx] = _event.target.id.split('-');
		const _copy = JSON.parse(JSON.stringify(waypoints));

		if (idx) {
			_copy[idx].job = document.getElementById('job-select').value;

			setWaypoints(_copy);
			onCloseMenu();
		}
	};

	const handleJobSelect = (_event) => {
		const val = _event.target.value;
		if (val === 'Paper Change') {
			setJobSelect('paper');
		} else {
			setJobSelect(false);
		}
	};

	const renderRoutes = () => {
		return waypoints.length
			? waypoints.map((location, idx) => (
					<tr key={`route-${idx}`}>
						<td className='sub-route' key={`route-${idx}`}>
							<div className='vert-margin-small'>
								<div key={`route-${idx}`} id={`route-${idx}`} className='flex-just-between flex-align-center'>
									<SearchInput
										id={`route-${idx}-val`}
										name='route'
										sid={`route-${idx}`}
										listName='waypoint-list'
										list={[...listData.kiosks, ...listData.techs]}
										placeholder='Destination...'
									/>
									{idx === waypoints.length - 1 ? (
										<div className='flex-align-center flex-just-between'>
											<ToolTip id='stop-time-btn' title='Add Stop Time'>
												<IconButton id='stop-time-btn' onClick={onMenuOpen} size='large'>
													<MdAccessTime id='stop-time-btn' />
												</IconButton>
											</ToolTip>
											<ToolTip id='job-select-btn' title='Select Job'>
												<IconButton id='job-select-btn' onClick={onMenuOpen} size='large'>
													<MdBuild id='job-select-btn' />
												</IconButton>
											</ToolTip>
											<ToolTip title='Add Location'>
												<IconButton id={`route-${idx}`} onClick={onAddRouteLocation} size='large'>
													<MdAdd id={`route-${idx}`} />
												</IconButton>
											</ToolTip>
											<Menu id='add-stop-time' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onCloseMenu}>
												<div>
													<div style={{ width: 180 }} className='flex-align-center flex-just-between'>
														{menuSelect === 'time' ? (
															<div>
																<input
																	id='stop-time'
																	style={{ width: 66, margin: 10 }}
																	defaultValue={DEFAULT_TIME}
																	type='number'
																></input>
																<label>Mins</label>
															</div>
														) : (
															<div>
																<select
																	id='job-select'
																	defaultValue={'Maintenance'}
																	style={{ width: 118, margin: 10 }}
																	onChange={handleJobSelect}
																>
																	{issues
																		.filter((issue) => issue.Status === 'Active')
																		.map((issue) => (
																			<option key={issue.Description} value={issue.Description}>
																				{issue.Description}
																			</option>
																		))}
																</select>
															</div>
														)}
														<ToolTip id={`route-${idx}`} title='Add Stop Time'>
															<IconButton
																id={`route-${idx}`}
																onClick={(e) => (menuSelect === 'time' ? onAddTime(e) : onAddJob(e))}
																size='large'
															>
																<MdAdd id={`route-${idx}`} />
															</IconButton>
														</ToolTip>
													</div>
													<div>
														{jobSelect ? (
															<div className='flex-align-center flex-column'>
																<div>
																	<h3>Which Paper?</h3>
																</div>
																<FormControlLabel control={<CheckBox id='paper-star' color='primary' />} label='Star' />
																<FormControlLabel control={<CheckBox id='paper-dm1' color='primary' />} label='DM1' />
																<FormControlLabel control={<CheckBox id='paper-dm2' color='primary' />} label='DM2' />
															</div>
														) : null}
													</div>
												</div>
											</Menu>
										</div>
									) : (
										<ToolTip title='Remove Location'>
											<IconButton id={`route-${idx}`} onClick={onRemoveRouteLocation} size='large'>
												<MdOutlineDelete id={`route-${idx}`} />
											</IconButton>
										</ToolTip>
									)}
									{route.waypoints.length > 1 && idx != waypoints.length - 1 ? (
										<ToolTip title='Reorder'>
											<MdDragIndicator id={`route-${idx}`} className='draggable' />
										</ToolTip>
									) : null}
								</div>
								<div style={{ marginLeft: 6 }}>
									<label style={{ marginRight: 10 }} name={location.time} id={`route-${idx}-time`}>
										{parseInt(location.time) ? `+${location.time} mins` : null}
									</label>
									<label name={location.job} id={`route-${idx}-job`}>
										{location.job ? location.job : null}
									</label>
								</div>
							</div>
						</td>
					</tr>
			  ))
			: null;
	};

	const getTableData = () => {
		if (!route || !route.response || !route.response.routes || !route.response.routes[0].legs) {
			alert('Please hit add on the final location to complete the route.');
			return [];
		}

		const _arr = waypoints.map((loc, idx) => ({
			key: `${loc.id}-${idx}`,
			cells: [
				{ data: idx + 1 },
				{ data: loc.id },
				{ data: loc.server },
				{ data: loc.location },
				{ data: route.response.routes[0].legs[idx].distance.text },
				{ data: route.response.routes[0].legs[idx].duration.text },
				{ data: loc.job ? loc.job : '' },
				{ data: parseInt(loc.time) ? `+${loc.time} Mins` : '' },
			],
		}));

		_arr.pop();

		_arr.unshift({
			key: `${route.origin}-origin`,
			cells: [
				{ data: 'Start' },
				{ data: '' },
				{ data: '' },
				{ data: route.origin },
				{ data: '' },
				{ data: '' },
				{ data: '' },
				{ data: '' },
			],
		});

		_arr.push({
			key: `${route.origin}-dest`,
			cells: [
				{ data: 'End' },
				{ data: '' },
				{ data: '' },
				{ data: route.origin },
				{ data: '' },
				{ data: '' },
				{ data: '' },
				{ data: '' },
			],
		});

		return _arr;
	};

	const renderTableButtons = () => {
		return (
			<div>
				<Button className='rti-blue-round' variant='contained' onClick={toggleFinalRoute}>
					Finalize Route
				</Button>
			</div>
		);
	};

	const toggleFinalRoute = () => {
		setRouteModal((prevState) => !prevState);
	};

	const onToggleModal = () => {
		setModalOpen((prevState) => !prevState);
	};

	const calcEndTime = () => {
		const _mins = calcTripTime().mins;
		const _date = new Date();
		const _start = document.getElementById('trip-start') ? document.getElementById('trip-start').value : null;

		if (_mins && _start) {
			const [hr, min] = _start.split(':');
			_date.setHours(parseInt(hr));
			_date.setMinutes(parseInt(min));
			_date.setMinutes(_date.getMinutes() + _mins);

			return `${_date.getHours().toString().padStart(2, '0')}:${_date.getMinutes().toString().padStart(2, '0')}`;
		}

		return '00:00';
	};

	const handleTripStart = (_event) => {
		const _start = _event.target.value;
		setTripStart(_start);
	};

	const handleFinalizeRoute = () => {
		const _tech = document.getElementById('assigned-tech').value;
		const _found = techs.find((tech) => `${tech.FirstName} ${tech.LastName}`.toUpperCase() === _tech.toUpperCase());
		const _copy = JSON.parse(JSON.stringify(waypoints));

		_copy.pop();

		if (_tech && _found) {
			setCreating(true);
			socket.emit('getLastJobID', (response) => {
				const dateYearDigit = new Date().getFullYear() % 10;
				let jobID = response;
				let jobIDLetter = String.fromCharCode(81 + dateYearDigit);

				if (!jobID || jobID[0] !== jobIDLetter) {
					jobID = jobIDLetter + '0001';
				} else {
					jobID = jobIDLetter + String(parseInt(1 + jobID.slice(1, 5)) + 1).slice(1, 5);
				}

				try {
					if (!jobID || jobID[0] !== jobIDLetter) {
						jobID = jobIDLetter + '0001';
						jobID = jobIDLetter + String(jobID.padStart(4, '0'));
					} else {
						jobID = jobIDLetter + String(parseInt(1 + jobID.slice(1, 5)) + 1).slice(1, 5);
					}
				} catch (error) {
					console.error(
						`ERROR: KioskMap.handleFinalizeRoute batch job creation is not incrementing: ${error} - ${new Date()}`
					);
				}

				jobID = parseInt(jobID.substring(1, 5));

				const _final = _copy.map((loc, idx) => ({
					Key: idx,
					JobID: `${jobIDLetter + String(parseInt(jobID++)).padStart(4, '0')}`,
					KioskID: loc.id,
					Issue: loc.job,
					Dispatcher: localStorage.getItem('FullName'),
					Technician: _tech,
					PartsList: [],
					TrackingNumber: null,
					MessagesSentTo: [
						{
							Name: _tech,
							Number: _found.phone,
							TechList: 'Dispatch',
						},
					],
					AlertID: null,
					Reason: loc.job,
					ETA: `Stop ${idx + 1}`,
					Address: loc.location,
				}));

				const _mapLink = `https://www.google.com/maps/dir/?api=1
          &origin=${encodeURIComponent(route.origin)}
          &waypoints=${encodeURIComponent(_final.map((loc) => loc.Address).join('|'))}
          &destination=${encodeURIComponent(route.destination)}
          &travelmode=driving`;

				socket.emit('shortenURL', _mapLink, (response) => {
					if (!response) {
						console.error(`ERROR: KioskMap.shortenURL: There was an issue calling this method`);
						alert(`SERVER ERROR: There was an issue processing this request.  Please notify your developer.`);
					} else {
						const _short_link = response[0].url;
						socket.emit('createJobsBatch', JSON.stringify(_final), (res) => {
							if (!res) {
								alert(
									`KioskMap.createJobsBatch: There was an issue calling this method. Please notify the developer of this issue.`
								);
								console.error(`KioskMap.createJobsBatch: There was an issue calling this method`);
								setCreating(false);
							} else {
								const msgs = _final.map((msg, idx) => ({
									body:
										`Job ${idx + 1}/${_final.length}\n\n` +
										'Hi ' +
										msg.MessagesSentTo[0].Name.split(' ')[0] +
										', \n' +
										'Kiosk: ' +
										msg.KioskID +
										' needs a visit for a(n) ' +
										msg.Issue +
										'\n' +
										'Your Job# is: ' +
										msg.JobID +
										'.\n' +
										'A link to your route can be found below the last message in this sequence.' +
										'Thank You!',
									to: msg.MessagesSentTo[0].Name,
									from: msg.Dispatcher,
									time: CurrentTime(),
									type: 'tech',
								}));
								msgs.push({
									body: `Below you will find a link to your route.  Please let us know if you have any questions.\n\n${_short_link}`,
									to: _final[0].MessagesSentTo[0].Name,
									from: _final[0].Dispatcher,
									time: CurrentTime(),
									type: 'tech',
								});

								fetch('/api/send_message', {
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
									},
									body: JSON.stringify({ messages: msgs }),
								}).then((res) => {
									if (!res) {
										console.error(`create_job.api.send_message: Error sending messages to technicians for this job.`);
									}
								});

								setRouteModal(false);
								setModalOpen(false);
								toggleRoute();
								setCreating(false);

								alert(
									`Success: All jobs have been created for this route!\n\nYou can find these jobs listed under the jobs tab.`
								);
								msgs.push({
									body: `Below you will find a link to your route.  Please let us know if you have any questions.\n\n${_short_link}`,
									to: _final[0].MessagesSentTo[0].Name,
									from: _final[0].Dispatcher,
									time: CurrentTime(),
									type: 'tech',
								});

								fetch('/api/send_message', {
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
									},
									body: JSON.stringify({ messages: msgs }),
								}).then((res) => {
									if (!res) {
										console.error(`create_job.api.send_message: Error sending messages to technicians for this job.`);
									}
								});

								setRouteModal(false);
								setModalOpen(false);
								toggleRoute();
								setCreating(false);

								alert(
									`Success: All jobs have been created for this route!\n\nYou can find these jobs listed under the jobs tab.`
								);
							}
						});
					}
				});
			});
		} else {
			alert(`You must select a technician to assign to this route.`);
		}
	};

	return isLoaded && !fetchingData ? (
		<div id='map-container' style={{ display: 'flex' }}>
			<div
				id='side-bar'
				style={{
					padding: 14,
					display: 'flex',
					flexDirection: 'column',
					backgroundColor: 'white',
					width: 300,
				}}
			>
				<div id='side-bar-top' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<SearchInput
						id='search-input'
						name='search-input'
						icon
						listName='data-list'
						list={paramRef.current === 'tech' ? listData.techs : listData.kiosks}
						onSearch={onSearchInput}
					/>
					<div
						style={{
							marginTop: 10,
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<div className='search-by'>
							<select id='primary-results' defaultValue={'kiosk'} onChange={onPrimaryChange}>
								<option key='tech' value='tech'>
									Technician
								</option>
								<option key='kiosk' value='kiosk'>
									Kiosk
								</option>
							</select>
						</div>
						<div style={{ display: 'flex' }}>
							<Button className='rti-blue-search small' size='small' variant='contained' onClick={handleClearSearch}>
								Clear
							</Button>
							<Button className='rti-blue-search small' size='small' variant='contained' onClick={handleSearch}>
								Search
							</Button>
						</div>
					</div>
					<div className='search-result-table' id='search-results'>
						{!loadingQuery ? (
							<table style={{ width: '100%' }}>
								<tbody className='map-results'>
									{searchResults.map((el) => (
										<tr id={el.id} className='flex-space-between' key={el.id} onClick={onResultClick}>
											<td id={el.id}>{resultParam === 'kiosk' ? el.kiosk : el.TechName}</td>
											<td id={el.id}>{el.drive_time} mins</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '80%',
									height: '100%',
								}}
							>
								<Spinner />
							</div>
						)}
					</div>
				</div>
				<div
					id='side-bar-bottom'
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
						marginTop: 'auto',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							height: 22,
							borderTop: '1px solid lightgray',
							paddingTop: 6,
							paddingBottom: 12,
						}}
					>
						<h3 style={{ marginRight: 'auto' }}>Search Options</h3>
						<IconButton name={searchOpts ? '-' : '+'} color='default' size='small' onClick={handleSearchOpts}>
							{searchOpts ? <MdRemove /> : <MdAdd />}
						</IconButton>
					</div>
					<div id='map-search-options' style={{ marginBottom: 6 }}>
						<div className='map-options'>
							<h4 style={{ margin: 0 }}>Results</h4>
							<div className='search-by'>
								<select id='secondary-results' defaultValue={'kiosk'} onChange={onResultChange}>
									<option key='tech' value='tech'>
										Technicians
									</option>
									<option key='kiosk' value='kiosk'>
										Kiosks
									</option>
								</select>
							</div>
						</div>
						<div className='map-options'>
							<h4 style={{ margin: 0 }}>Distance</h4>
							<div
								className='search-by'
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									width: 106,
								}}
							>
								<input
									style={{ width: 44 }}
									id='distance'
									type='number'
									defaultValue={90}
									onChange={onDistanceChanged}
								></input>
								<label>mins</label>
							</div>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							height: 22,
							borderTop: '1px solid lightgray',
							paddingTop: 6,
							paddingBottom: 6,
						}}
					>
						<h3 style={{ marginRight: 'auto' }}>Display Options</h3>
						<IconButton name={displayOpts ? '-' : '+'} color='default' size='small' onClick={handleDisplayOpts}>
							{displayOpts ? <MdRemove /> : <MdAdd />}
						</IconButton>
					</div>
					<div id='map-display-options'>
						<div className='map-options'>
							<FormControlLabel
								control={<CheckBox id='techs' color='primary' checked={Boolean(showTechs)} onChange={onChecked} />}
								label='Techs'
							/>
							<FormControlLabel
								control={<CheckBox id='kiosks' color='primary' checked={Boolean(showKiosks)} onChange={onChecked} />}
								label='Kiosks'
							/>
							<FormControlLabel
								control={<CheckBox color='primary' id='heat-map' checked={Boolean(showHeatMap)} onChange={onChecked} />}
								label='Heatmap'
							/>
						</div>
						<div className='map-options'>
							<FormControlLabel
								control={<CheckBox color='primary' id='traffic' checked={Boolean(showTraffic)} onChange={onChecked} />}
								label='Traffic'
							/>
							<FormControlLabel
								control={
									<Switch color='primary' size='small' checked={Boolean(iconDetail)} onChange={onToggleSwitch} />
								}
								label='Colored Icons'
							/>
						</div>
						<div className='map-options'>
							<FormControlLabel
								control={<Switch color='primary' size='small' checked={paper} onChange={onTogglePaperIcons} />}
								label='Upcoming Paper'
							/>
						</div>
						{!buildRoute ? (
							<div className='map-options'>
								<Button className='rti-blue-round' variant='contained' size='small' onClick={toggleRoute}>
									Build Route
								</Button>
							</div>
						) : null}
						<div className={`map-options ${showHeatMap ? '' : 'disable'}`}>
							<div className='search-by'>
								<select id='heat-map-data-type' value={dataFilter} onChange={onHeatMapDataChange}>
									<option value='issues'>Issues</option>
									<option value='alerts'>Alerts</option>
								</select>
							</div>
							<div className='search-by'>
								<select id='heat-map-duration' value={mapFilter} onChange={onHeatMapChange}>
									<option value='0'>Today</option>
									<option value='1'>Yesterday</option>
									<option value='7'>Last 7 days</option>
									<option value='14'>Last 14 days</option>
									<option value='28'>Last 28 days</option>
									<option value='60'>Last 60 days</option>
									<option value='90'>Last 90 days</option>
									<option value='180'>Last 180 days</option>
								</select>
							</div>
						</div>
					</div>
					{!buildRoute ? (
						<div>
							{iconDetail ? (
								<div style={{ borderTop: '1px solid lightgray' }}>
									<h3 style={{ marginRight: 'auto' }}>Key</h3>
									<div className='flex-just-between'>
										<div>
											<img src='http://maps.google.com/mapfiles/ms/icons/blue-dot.png' width='16px'></img>
											<label>Kiosk</label>
										</div>
										<div>
											<img src='http://maps.google.com/mapfiles/ms/icons/red-dot.png' width='16px'></img>
											<label>Kiosk w/ Issue</label>
										</div>
										<div>
											<img src='http://maps.google.com/mapfiles/ms/icons/green-dot.png' width='16px'></img>
											<label>{`Cleaned`}</label>
										</div>
									</div>
									<div className='flex-just-between'>
										<div>
											<img src='http://maps.google.com/mapfiles/ms/icons/yellow-dot.png' width='16px'></img>
											<label>{`Cleaned > 180 Days`}</label>
										</div>
										<div>
											<img src='http://maps.google.com/mapfiles/ms/icons/orange-dot.png' width='16px'></img>
											<label>{`Cleaned > 365 Days`}</label>
										</div>
									</div>
									<div className='flex-just-between'>
										<div className='flex-align-center'>
											<img
												src={`https://www.google.com/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-1-small.png,assets/icons/poi/tactile/pinlet_outline_v2-1-small.png,assets/icons/poi/tactile/pinlet-1-small.png,assets/icons/poi/quantum/pinlet/parking_pinlet-1-small.png&highlight=ff000000,ffffff,eb941d,ffffff?scale=1`}
												width='16px'
											></img>
											<label>Paper Low</label>
										</div>
										<div className='flex-align-center'>
											<img
												src={`https://www.google.com/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-1-small.png,assets/icons/poi/tactile/pinlet_outline_v2-1-small.png,assets/icons/poi/tactile/pinlet-1-small.png,assets/icons/poi/quantum/pinlet/monument_pinlet-1-small.png&highlight=ff000000,ffffff,eb6b6b,ffffff?scale=1`}
												width='16px'
											></img>
											<label>{`Alerts > 1 (Past 3 days)`}</label>
										</div>
									</div>
								</div>
							) : paper ? (
								<div style={{ borderTop: '1px solid lightgray' }}>
									<h3 style={{ marginRight: 'auto' }}>Key</h3>
									<div className='flex-just-between'>
										<div>
											<img
												src={`https://www.google.com/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-1-small.png,assets/icons/poi/tactile/pinlet_outline_v2-1-small.png,assets/icons/poi/tactile/pinlet-1-small.png,assets/icons/poi/quantum/pinlet/parking_pinlet-1-small.png&highlight=ff000000,ffffff,eb941d,ffffff?scale=1`}
												width='12px'
											></img>
											<label>Upcoming Paper Change (30 days)</label>
										</div>
									</div>
								</div>
							) : (
								<div style={{ borderTop: '1px solid lightgray' }}>
									<h3 style={{ marginRight: 'auto' }}>Key</h3>
									<div className='flex-just-between'>
										<div>
											<img src='http://maps.google.com/mapfiles/ms/icons/red-dot.png' width='12px'></img>
											<label>Kiosk</label>
										</div>
									</div>
								</div>
							)}
						</div>
					) : null}
				</div>
				{buildRoute ? (
					<div
						className='flex-column flex-just-between'
						id='side-bar-bottom'
						style={{
							marginTop: 'auto',
							height: '100%',
							borderTop: '1px solid gainsboro',
						}}
					>
						<div id='route-container'>
							<div className='flex-start flex-column'>
								<h3>Trip Time: {calcTripTime().text}</h3>
								<div>
									<div className='flex-align-center vert-margin'>
										<label style={{ marginRight: 10 }}>Start Time</label>
										<input id='trip-start' type='time' value={tripStart} onChange={handleTripStart}></input>
									</div>
									<div className='flex-align-center vert-margin'>
										<label style={{ marginRight: 16 }}>End Time</label>
										<input id='trip-end' disabled type='time' value={calcEndTime()}></input>
									</div>
								</div>
							</div>
							<div id='route-wrapper' className='route-table'>
								<div className='flex-just-between flex-align-center vert-margin-small'>
									<SearchInput
										id='route-start'
										listName='start-list'
										list={[...listData.kiosks, ...listData.techs]}
										placeholder='Start...'
									/>
									{!waypoints.length ? (
										<IconButton id='route-init' onClick={onAddRouteLocation} size='large'>
											<MdAdd id='route-init' />
										</IconButton>
									) : null}
								</div>
								<table>
									<tbody id='route-table'>{renderRoutes()}</tbody>
								</table>
							</div>
						</div>
						<div id='route-btns' className='flex-just-between route-options'>
							<div>
								<Button className='rti-blue-round small' variant='contained' size='small' onClick={onToggleModal}>
									Complete Route
								</Button>
							</div>
							<div>
								<Button className='rti-blue-round small' variant='contained' size='small' onClick={toggleRoute}>
									Close
								</Button>
							</div>
						</div>
					</div>
				) : null}
			</div>
			{modalOpen ? (
				<Modal modal_id='route-modal' dimension={{ width: 1000 }} onClose={onToggleModal}>
					<div>
						<CustomTable paginate headers={TableHeaders} rows={getTableData()} table_buttons={renderTableButtons()} />
					</div>
				</Modal>
			) : null}
			{routeModal ? (
				<Modal modal_id='final-modal' dimension={{ width: 500 }} onClose={toggleFinalRoute}>
					<div className='text-center'>
						<h1>Finalizing Route</h1>
						<div className='flex-column flex-just-center flex-align-center'>
							<p>
								Please select the technician you want to assign this route to. Once this route is complete, each
								waypoint will be created as a separate job assigned directly to the technician you have selected.
							</p>
							<div style={{ width: 200 }} className='vert-margin-med'>
								<SearchInput
									id='assigned-tech'
									listName='tech-assign'
									list={listData.techs}
									placeholder='Search Technician...'
								/>
							</div>
						</div>
						{!creating ? (
							<div className='vert-margin-med'>
								<Button className='rti-blue-round small' variant='contained' size='small' onClick={handleFinalizeRoute}>
									Complete Route
								</Button>
							</div>
						) : (
							<div className='vert-margin-med flex-column flex-just-even flex-align-center'>
								<p>Creating Jobs</p>
								<LinearProgress style={{ width: '55%' }} />
							</div>
						)}
					</div>
				</Modal>
			) : null}
			<GoogleMap
				options={{ streetViewControl: false }}
				mapContainerStyle={containerStyle}
				center={mapCenter}
				zoom={15}
				onLoad={onLoad}
				onUnmount={onUnmount}
			>
				{showTraffic ? <TrafficLayer onLoad={onTrafficLoad} /> : null}
				{buildRoute && route.response !== null && (
					<DirectionsRenderer
						options={{
							directions: route.response,
							preserveViewport: true,
						}}
						onLoad={onRouteLoad}
					/>
				)}
				{buildRoute && route.waypoints.length && route.origin && route.destination && (
					<DirectionsService
						options={{
							destination: route.destination,
							origin: route.origin,
							travelMode: route.travelMode,
							waypoints: route.waypoints,
						}}
						callback={onDirectionsService}
						onLoad={onServiceLoad}
					/>
				)}
				<TechMarker visible={showTechs} data={techs} onMarkerClick={getMarkerInfo} />
				<KioskMarker
					visible={showKiosks}
					data={!paper ? kiosks : projections}
					detail={iconDetail}
					paper={paper}
					onMarkerClick={getMarkerInfo}
				/>
				<Heatmap visible={showHeatMap} data={heatMap} />
				{markerSelected ? (
					<MarkerInfo
						visible={markerSelected ? true : false}
						info_type={activeMarker}
						marker={markerSelected}
						route={buildRoute}
						onMarkerClose={onMarkerClose}
						onAddRoute={onAddRoute}
					/>
				) : null}
			</GoogleMap>
		</div>
	) : (
		<Spinner margin='margin-25'></Spinner>
	);
}

export default memo(KioskMap);
