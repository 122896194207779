// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
    --AccentPink: #e14658;
    --AccentBlue: #636988;
    --Blue: #333a56;
    --DarkBlue: #0A142D;
    --Beige: #f7f5e6;
}

html, body, .appRoot {
    /* overflow: hidden; */
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    font-size: 12px;
    color: var(--DarkBlue);
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
html {
    margin-right: 56px;
    margin-bottom: 36px;
}

.ui-helper-hidden-accessible{
    display: none;
}

.footer {
    /* Display Flex */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: stretch;
    overflow-x: hidden;
    background-color: black;
    clear: both;
    position: fixed;
    bottom: 0;
    left: 60px;
    z-index: 2;
    width: 100%;
    height: 40px;
}

#ui-id-1 {
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    z-index:1;
    position:absolute;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,SAAS;IACT,UAAU;IACV,yBAAyB;IACzB,eAAe;IACf,sBAAsB;IACtB,+DAA+D;AACnE;AACA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,uBAAuB;IACvB,qBAAqB;IACrB,oBAAoB;IACpB,kBAAkB;IAClB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,SAAS;IACT,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,YAAY;IACZ,SAAS;IACT,iBAAiB;AACrB","sourcesContent":["\n:root {\n    --AccentPink: #e14658;\n    --AccentBlue: #636988;\n    --Blue: #333a56;\n    --DarkBlue: #0A142D;\n    --Beige: #f7f5e6;\n}\n\nhtml, body, .appRoot {\n    /* overflow: hidden; */\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    background-color: #FFFFFF;\n    font-size: 12px;\n    color: var(--DarkBlue);\n    font-family: \"Lucida Sans Unicode\", \"Lucida Grande\", sans-serif;\n}\nhtml {\n    margin-right: 56px;\n    margin-bottom: 36px;\n}\n\n.ui-helper-hidden-accessible{\n    display: none;\n}\n\n.footer {\n    /* Display Flex */\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    justify-content: center;\n    align-content: center;\n    align-items: stretch;\n    overflow-x: hidden;\n    background-color: black;\n    clear: both;\n    position: fixed;\n    bottom: 0;\n    left: 60px;\n    z-index: 2;\n    width: 100%;\n    height: 40px;\n}\n\n#ui-id-1 {\n    background-color: white;\n    border: 1px solid black;\n    padding: 5px;\n    z-index:1;\n    position:absolute;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
