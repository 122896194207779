import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';

export const ScannedItemsInput = (props) => {
  const {
    onBarcodeKeyDown,
    qtyValue,
    locationValue,
    locationOptions,
    additionalOptions,
    repurposedYear,
    handleRepurposedChange,
    repurposedCheck,
    handleRepurposeCheck,
    repurposedQty,
    handleRepurposeQty
  } = props;

  return (
    <div className="vert-margin-med full-width">
      <div className="item-field-container full-width">
        <FormControlLabel
          control={<Checkbox checked={repurposedCheck} onChange={handleRepurposeCheck} name="repurposeCheck" color="primary" />}
          label="Repurposed?"
        />
        {repurposedCheck ? (
          <div className="item-field-container full-width">

            <Autocomplete
              freeSolo
              id="repurposedYear"
              className="item-field"
              value={repurposedYear || ''}
              options={additionalOptions || []} // Updated options array
              onChange={handleRepurposedChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sticker Year?"
                  InputProps={{
                    ...params.InputProps,
                    type: 'text', // Corrected type for the input
                  }}
                />
              )}
            />

            <FormControl className="space-below item-field">
              <InputLabel id="repurposed-year-label">Sticker Year?</InputLabel>
              <Select
                labelId="repurposed-year-label"
                id="repurposedYear"
                value={repurposedYear || ''}
                onChange={handleRepurposedChange}
                inputProps={{ maxLength: 4 }}
              >
                {additionalOptions?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="repurpose-qty"
              onChange={handleRepurposeQty}
              className="space-below item-field"
              label="Repurposed Qty"
              value={repurposedQty || ''}
              type="number"
            />
          </div>
        ) : null}

        <TextField
          id="barcode"
          autoFocus
          className="space-below item-field"
          label="RID #"
          placeholder="Scan items..."
          type="text"
          onKeyDown={onBarcodeKeyDown}
        />
        <TextField
          id="qty"
          disabled
          className="space-below item-field"
          label="Qty"
          value={qtyValue || ''}
          type="number"
        />
        <Autocomplete
          freeSolo
          id="location"
          className="space-below item-field"
          value={locationValue || ''}
          disableClearable
          options={locationOptions || []} // Updated options array
          renderInput={(params) => (
            <TextField
              className="space-below item-field"
              value={locationValue || ''}
              {...params}
              label="Destination"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
