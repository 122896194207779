import React from 'react';
import { DateDiff } from '../../utilities/util';

const MAP_ICONS = [
	{
		src: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
		dims: { width: 35, height: 35 },
	},
	{
		src: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
		dims: { width: 35, height: 35 },
	},
	{
		src: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
		dims: { width: 55, height: 55 },
	},
	{
		src: 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png',
		dims: { width: 35, height: 35 },
	},
	{
		src: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
		dims: { width: 35, height: 35 },
	},
	{
		src: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
		dims: { width: 35, height: 35 },
	},
	{
		src: `https://www.google.com/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-2-medium.png,assets/icons/poi/tactile/pinlet_outline_v2-2-medium.png,assets/icons/poi/tactile/pinlet-2-medium.png,assets/icons/poi/quantum/pinlet/parking_pinlet-2-medium.png&highlight=ff000000,ffffff,eb941d,ffffff?scale=1`,
		dims: { width: 36, height: 48 },
	},
	{
		src: `https://www.google.com/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-2-medium.png,assets/icons/poi/tactile/pinlet_outline_v2-2-medium.png,assets/icons/poi/tactile/pinlet-2-medium.png,assets/icons/poi/quantum/pinlet/monument_pinlet-2-medium.png&highlight=ff000000,ffffff,eb6b6b,ffffff?scale=1`,
		dims: { width: 36, height: 48 },
	},
	{
		src: `https://www.google.com/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-2-medium.png,assets/icons/poi/tactile/pinlet_outline_v2-2-medium.png,assets/icons/poi/tactile/pinlet-2-medium.png,assets/icons/poi/quantum/pinlet/parking_pinlet-2-medium.png&highlight=ff000000,ffffff,eb1d1d,ffffff?scale=2`,
		dims: { width: 36, height: 48 },
	},
	{
		src: `https://www.google.com/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-2-medium.png,assets/icons/poi/tactile/pinlet_outline_v2-2-medium.png,assets/icons/poi/tactile/pinlet-2-medium.png,assets/icons/poi/quantum/pinlet/parking_pinlet-2-medium.png&highlight=ff000000,ffffff,249c11,ffffff?scale=1`,
		dims: { width: 36, height: 48 },
	},
];

const DEFAULT_VAL = '0000-00-00 00:00:00';

const KioskMarker = (props) => {
	const { Marker } = require('@react-google-maps/api');
	const _today = new Date();
	const thresh = new Date();
	const _latest = new Date();
	thresh.setDate(thresh.getDate() + 15);
	_latest.setDate(_latest.getDate() + 30);
	const { onMarkerClick } = props;

	const handleEvent = (type, id) => {
		onMarkerClick(type, id);
	};
	return props.visible
		? props.data.map((kiosk) => {
				const _daysClean = kiosk.cleaned != DEFAULT_VAL ? DateDiff(new Date(kiosk.cleaned), _today, 'day') : null;
				const _color =
					kiosk.issueReported && props.detail
						? 2
						: _daysClean && _daysClean < 180 && props.detail
						? 0
						: _daysClean && _daysClean >= 180 && _daysClean < 365 && props.detail
						? 4
						: _daysClean && _daysClean >= 365 && props.detail
						? 3
						: !props.detail
						? 5
						: 1;

				const _paperLow =
					props.paper ||
					(kiosk.dm1_count <= 60 && !kiosk.paper_job) ||
					(kiosk.dm2_count <= 60 && !kiosk.paper_job) ||
					(kiosk.star_count <= 60 && !kiosk.paper_job);

				const _paperCritical =
					(props.paper &&
						kiosk.dm1_projected &&
						!kiosk['Job Assigned'].includes(`${kiosk.printer1_year}`) &&
						new Date(kiosk.dm1_projected).getTime() <= thresh.getTime()) ||
					(props.paper &&
						kiosk.dm2_projected &&
						!kiosk['Job Assigned'].includes(`${kiosk.printer2_year}`) &&
						new Date(kiosk.dm2_projected).getTime() <= thresh.getTime()) ||
					(props.paper &&
						kiosk.star_projected &&
						!kiosk['Job Assigned'].includes(`Star`) &&
						new Date(kiosk.star_projected).getTime() <= thresh.getTime());

				const _paperSoon =
					(props.paper &&
						kiosk.dm1_projected &&
						!kiosk['Job Assigned'].includes(`${kiosk.printer1_year}`) &&
						new Date(kiosk.dm1_projected).getTime() > thresh.getTime() &&
						new Date(kiosk.dm1_projected).getTime() <= _latest.getTime()) ||
					(props.paper &&
						kiosk.dm2_projected &&
						!kiosk['Job Assigned'].includes(`${kiosk.printer2_year}`) &&
						new Date(kiosk.dm2_projected).getTime() > thresh.getTime() &&
						new Date(kiosk.dm2_projected).getTime() <= _latest.getTime()) ||
					(props.paper &&
						kiosk.star_projected &&
						!kiosk['Job Assigned'].includes(`Star`) &&
						new Date(kiosk.star_projected).getTime() > thresh.getTime() &&
						new Date(kiosk.star_projected).getTime() <= _latest.getTime());

				return (
					<Marker
						key={props.paper ? kiosk.Kiosk : kiosk.KioskID}
						title={props.paper ? kiosk.Kiosk : kiosk.KioskID}
						icon={{
							url:
								props.paper && _paperCritical
									? MAP_ICONS[8].src
									: props.paper && _paperSoon
									? MAP_ICONS[6].src
									: props.paper && !_paperCritical && !_paperSoon
									? MAP_ICONS[9].src
									: kiosk.issueReported && props.detail
									? MAP_ICONS[_color].src
									: kiosk.totalAlerts && props.detail
									? MAP_ICONS[7].src
									: MAP_ICONS[_color].src,
							scaledSize:
								_paperLow && props.detail
									? new google.maps.Size(MAP_ICONS[6].dims.width, MAP_ICONS[6].dims.height)
									: kiosk.issueReported && props.detail
									? new google.maps.Size(MAP_ICONS[_color].dims.width, MAP_ICONS[_color].dims.height)
									: kiosk.totalAlerts && props.detail
									? new google.maps.Size(MAP_ICONS[7].dims.width, MAP_ICONS[7].dims.height)
									: null,
						}}
						onClick={() => handleEvent('kiosk', kiosk.KioskID)}
						position={{ lat: kiosk.Latitude, lng: kiosk.Longitude }}
					/>
				);
		  })
		: null;
};

function compareProps(prevProps, nextProps) {
	if (
		prevProps.visible === nextProps.visible &&
		prevProps.detail === nextProps.detail &&
		prevProps.paper === nextProps.paper
	) {
		return true;
	}
	return false;
}

export default React.memo(KioskMarker, compareProps);
