import React, { useState, useEffect } from 'react';
import Spinner from '../../components/Spinner.js';
import CustomTable from '../../components/Table.js';
import Modal from '../../components/Modal.js';
import Button from '@mui/material/Button';
import { FaDownload } from 'react-icons/fa';
import '../../StyleSheets/BugReport.css';
import '../../StyleSheets/TMtable.css';
import { CSVLink } from 'react-csv';
import { useCustomContext } from '../../hoc/Context.js';

const TableHeaders = [
    {id: 'tech', name: 'Technician',sortable: true, numeric: false},
    {id: 'serviced', name: 'Last Serviced'},
    {id: 'drive-time', name: 'Avg. Drive Time',sortable: true, numeric: false},
    {id: 'work-time', name: 'Avg. Work Time',sortable: true, numeric: false},
    {id: 'total', name: 'Total Serviced',sortable: true, numeric: true},
];

const TableHeaders2 = [
    {id: 'date', name: 'Service Date', size: 'small-header'},
    {id: 'drive-time', name: 'Drive Time',sortable: true, numeric: false},
    {id: 'work-time', name: 'Work Time',sortable: true, numeric: false, size: 'small-header'},
    {id: 'job', name: 'Job #',sortable: true, numeric: false, size: 'small-header'},
    {id: 'issue', name: 'Issue',sortable: true, numeric: false},
    {id: 'notes', name: 'Notes', size: 'xlarge-header'},
];

const SearchOptions = [
    {id: 'tech', name: 'Technician', pidx: 0}
];

const TableTabs = [
    {id: 7, name: 'Last 7 days', filter: 7},
    {id: 28, name: 'Last 28 days', filter: 28},
    {id: 60, name: 'Last 60 days', filter: 60},
    {id: 90, name: 'Last 90 days', filter: 90},
    {id: 180, name: 'Last 180 days', filter: 180}
];

//Formats the TechnicianTable Information for each row
const TechAnalytics = () => {
    const [ServiceReports, setServiceReports] = useState([]);
    const [SubReports, setSubReports] = useState([]);
    const [ReportData, setReportData] = useState([]);
    const [activeTab, setActiveTab] = useState(TableTabs[0].id);
    const [searchVal, setSearchVal] = useState('');
    const [searchParam, setSearchParam] = useState(0);
    const [selected, setSelected] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [Loading, setLoading] = useState([]);
    const {userState, userDispatch} = useCustomContext();

    useEffect(() => {
        let mounted = true;

        if (mounted && !modalOpen) {
            socket.emit('selectSRForTechAnalytics', activeTab, 'none', null, (result) => {
                setServiceReports(result);
                setLoading(false);
            });
        }

        if (ReportData.length) {
            document.getElementById('download-report').click();
        }

        return () => {
            mounted = false;
        };
    }, [activeTab, ReportData.length]);

    const search = (param, searchVal) => {
        setSearchVal(searchVal);
        setSearchParam(param);
    };

    const clearSearch = () => {
        setSearchVal('');
    };

    const filterTable = (filter) => {
        setServiceReports([]);
        setLoading(true);
        setActiveTab(filter);
    };

    const handleModal = (e) => {
        setSubReports([]);
        const tech = e.target.id;

        socket.emit('selectSRForTechAnalytics', activeTab, 'tech', tech, (result) => {
            setSubReports(result);
            setSelected(tech);
            setModalOpen(true);
            $('#analytic-modal').toggleClass('flex-box');
        });
    };

    const generateReport = () => {
        const data = SubReports.map(report => ({
            "Service Date": report.ServiceDate.split('T')[0],
            "Drive Time": report.DriveTime,
            "Work Time": report.WorkTime,
            "Technician": report.ServiceTech,
            "Job #": report.JobID,
            "Issue": report.KioskIssue,
            "Notes": report.Notes
        }));

        setReportData(data);
    };

    function renderTableButtons() {
        if (userState.user.role) {
            return (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CSVLink
                        style={{ background: 'none', border: 'none' }}
                        target="_blank"
                        className="hidden"
                        id="download-report"
                        data={ReportData}
                        filename={"tech-analytic-report.csv"}
                    />
                    <Button
                        className="rti-blue-round"
                        variant="contained"
                        startIcon={<FaDownload />} // Using FaDownload instead of GetAppIcon
                        onClick={generateReport}
                    >
                        Download
                    </Button>
                </div>
            );
        }

        return null;
    }

    const handleModalClose = () => {
        setReportData([]);
        setModalOpen(false);
        $('#analytic-modal').toggleClass('flex-box');
    };

    const getTableData = () => {
        return ServiceReports.map(report => ({
            key: report.tech,
            cells: [
                {data: report.tech},
                {data: report.last_serviced.split('T')[0]},
                {data: `${report.average_drive_time} Minutes`},
                {data: `${report.average_work_time} Minutes`},
                {data: report.total_serviced}
            ]
        })).filter(row => searchVal && searchParam ? row.cells[SearchOptions[searchParam - 1].pidx].data.toLowerCase().includes(searchVal.toLowerCase()) : row);
    };

    const getModalData = () => {
        return SubReports.map(report => ({
            key: report.ServiceReportID,
            cells: [
                {data: report.ServiceDate.split('T')[0]},
                {data: report.DriveTime},
                {data: report.WorkTime},
                {data: report.JobID},
                {data: report.KioskIssue},
                {data: report.Notes}
            ]
        }));
    };

    return (
        <div className="ViewedContentContainer" id="OpenContainer">
            <div style={{ height: '100%' }}>
                <div className="TitleBarContainer">
                    <h1 title="Tech Analytics">Tech Analytics</h1>
                </div>
                <CustomTable
                    searchable
                    paginate
                    loading_data={Loading}
                    search={search}
                    clear_search={clearSearch}
                    search_options={SearchOptions}
                    headers={TableHeaders}
                    rows={getTableData()}
                    tabs={TableTabs}
                    tab_style="select"
                    active_tab={activeTab}
                    filter_data={filterTable}
                    onClick={handleModal}
                />
                <Modal
                    modal_id="analytic-modal"
                    dimension={{ width: '1090', height: '670' }}
                    modal_header={selected}
                    onClose={handleModalClose}
                >
                    <div style={{ textAlign: 'center', height: '80%' }}>
                        <CustomTable
                            headers={TableHeaders2}
                            rows={getModalData()}
                            table_buttons={renderTableButtons()}
                            paginate
                            maxSize="medium"
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default TechAnalytics;
