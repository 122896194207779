import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import { FaSave } from 'react-icons/fa'; // New icon import
import AlertBar from '../../components/AlertBar';
import { useCustomContext } from '../../hoc/Context';

const TableHeaders = [
    {id: '0', name: ''},
    {id: 'bug', name: 'Bug Report'},
    {id: 'call-log', name: 'Call Log'},
    {id: 'job-assigned', name: 'Job Assigned'},
    {id: 'job-created', name: 'Job Created'},
    {id: 'service-report', name: 'Service Report'},
    {id: 'shift-report', name: 'Shift Report'},
    {id: 'time-entry', name: 'Time Entry'}
]

const SecondaryHeaders = [
    {id: 'blank-1', name: ''},
    {id: 'blank-2', name: ''},
    {id: 'bug-msg', name: 'SMS'},
    {id: 'bug-eml', name: 'Email'},
    {id: 'call-log-msg', name: 'SMS'},
    {id: 'call-log-eml', name: 'Email'},
    {id: 'job-msg', name: 'SMS'},
    {id: 'job-eml', name: 'Email'},
    {id: 'created-msg', name: 'SMS'},
    {id: 'created-eml', name: 'Email'},
    {id: 'service-msg', name: 'SMS'},
    {id: 'service-eml', name: 'Email'},
    {id: 'shift-msg', name: 'SMS'},
    {id: 'shift-eml', name: 'Email'},
    {id: 'entry-msg', name: 'SMS'},
    {id: 'entry-eml', name: 'Email'},
]

const mappedReports = {
    bug: 'Bug Report',
    call: 'Call Log',
    job: 'Job Assigned',
    created: 'Job Created',
    service: 'Service Report',
    shift: 'Shift Report',
    entry: 'Time Entry'
}

//Formats the TechnicianTable Information for each row
const MobileEmailLists = (props) => {
    const [emailLists, setEmailLists] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [retrievingData, setRetrievingData] = useState(true);
    const [snackbar, setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    });
    const {userState, userDispatch} = useCustomContext();

    useEffect(() => {
        let mounted = true;

        if(mounted){
            if(retrievingData){
                socket.emit('getMsgList',(res) => {
                    if(!res){
                        console.error(`MobileEmailLists.getMsgList: There was an issue calling this method`)
                    } else {
                        setEmailLists(res);
                        setRetrievingData(false);
                        setLoading(false);
                    }
                });
            }            
        }

        return () => {
            mounted = false;
        }
    },[])

    const RefreshTableData = () => {
        socket.emit('getMsgList', (result) => {
            setEmailLists(result);
        });
    }

    const SaveBtn = () => {
        const elements = document.getElementsByClassName('msg-list');
        let results = [];

        for(let i = 0; i < elements.length; i++){
            if(elements[i].checked){
                const [report, type, user] = elements[i].id.split('-');
                results.push({report, type, user});    
            }
        }

        const finalMapped = results.reduce((arr, el) => {
            if(arr[mappedReports[el.report]]){
                el.type == 'msg' ? arr[mappedReports[el.report]].MsgToList.push(el.user) :
                arr[mappedReports[el.report]].EmailToList.push(el.user);
            } else {
                el.type == 'msg' ? arr[mappedReports[el.report]] = {MsgToList: [el.user], EmailToList: []}:
                arr[mappedReports[el.report]] = {MsgToList: [], EmailToList: [el.user]}                
            }

            return arr;
        },{})

        socket.emit('updateMobileEmailLists', Object.entries(finalMapped), (result) => {
            if (result == 1) {
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: "Schedule updated successfully.",
                    severity: 'success'
                }))
                RefreshTableData();
            }
            else {//Notify user of Failure
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: "ERROR: Schedule did not save successfully.",
                    severity: 'error'
                }))
            }
        });
    }

    const RenderTableButtons = () => {
        return userState.user.role ? <Button
            className="rti-blue-round"
            variant="contained"
            endIcon={<FaSave />} // Updated icon
            onClick={SaveBtn}>Save</Button> : null;
    }

    const getTableData = () => {
        return emailLists.map((user, index) => ({
            key: user.FullName,
            cells: [
                {data: user.FullName, span: 2},
                {
                    data: [
                        {type: 'checkbox', name: 'msg-list', span: 2, id: `bug-msg-${user.FullName}`, checked: user.bug_msg},
                        {type: 'checkbox', name: 'msg-list', span: 2, id: `bug-eml-${user.FullName}`, checked: user.bug_eml}
                    ]
                },
                {
                    data: [
                        {type: 'checkbox', name: 'msg-list', span: 2,id: `call-msg-${user.FullName}`, checked: user.call_log_msg},
                        {type: 'checkbox', name: 'msg-list', span: 2,id: `call-eml-${user.FullName}`, checked: user.call_log_eml}
                    ]
                },
                {
                    data: [
                        {type: 'checkbox', name: 'msg-list', span: 2,id: `job-msg-${user.FullName}`, checked: user.job_msg},
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `job-eml-${user.FullName}`, checked: user.job_eml}
                    ]
                },
                {
                    data: [
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `created-msg-${user.FullName}`, checked: user.created_msg},
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `created-eml-${user.FullName}`, checked: user.created_eml}
                    ]
                },
                {
                    data: [
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `service-msg-${user.FullName}`, checked: user.service_msg},
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `service-eml-${user.FullName}`, checked: user.service_eml}
                    ]
                },
                {
                    data: [
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `shift-msg-${user.FullName}`, checked: user.shift_msg},
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `shift-eml-${user.FullName}`, checked: user.shift_eml}
                    ]
                },
                {
                    data: [
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `entry-msg-${user.FullName}`, checked: user.entry_msg},
                        {type: 'checkbox', name: 'msg-list',span: 2,id: `entry-eml-${user.FullName}`, checked: user.entry_eml}
                    ]
                },
            ]
        }))
    }

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({
            ...prevState,
            visible: false
        }))
    }

    return(
        <div className="ViewedContentContainer">
            <div style={{height: '100%'}}>
                <div className="TitleBarContainer">
                    <h1 title="This is how you edit who recieves text message or an email for a bug report, Call log, dispatched jobs, asssigned jobs, submitted service reports, and end of shift reports. If you want someone to recieve a text message for a bug report, check off the box under Bug Report Text and to the right of their name. To edit the Email or Phone number a person is recieving their mesages on, edit the Dispatchers Table. To add a new person to the list, add them to the dispatchers table and they will show up on this page.">
                        Messaging List
                    </h1>
                </div>
                <AlertBar
                    visible={snackbar.visible}
                    onClose={handleCloseSnack}
                    message={snackbar.message}
                    severity={snackbar.severity}
                />
                <CustomTable
                    paginate
                    loading_data={Loading}
                    headers={TableHeaders}
                    rows={getTableData()}
                    secondary_headers={SecondaryHeaders}
                    table_buttons={RenderTableButtons()}
                />                  
            </div>
        </div>
    )      
}

export default MobileEmailLists;
