/**
 * Handle the singular inventory items passed from Dispatch-Council\src\imports\InventorySystem\InventoryItem.js
 * TODO: onKeyPress is deprecated and why handleAutoTab is broken
 */

import React from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Autocomplete from '@mui/material/Autocomplete';
import {FormControl, Select, MenuItem} from "@mui/material";
import { HardwareServiceController } from '../HardwareServiceFormController/HardwareServiceController';
import { SingularItemInfo } from './SingularItemInfo';
import { DateToLocale } from '../../../API/TextFormatingFunctions';

export const SingularInventoryPartController = (props) => {

const {
	bins,
	item,
	models,
	form,
	loading,
	isModem,
	itemType,
	onToggleItem,
	tabIdx,
	handleAutoTab,
	editing,
	servicing,
	newItem,
	errors,
	onNavigateToItem,
	toggleEditing,
	toggleServicing,
	handleUpdateItem,
	onToggleDetach,
	onToggleAudit,
	newRefurb,
	serviceSelect,
	handleNewRefurbSelect,
	carrierOption,
	handleCarrierSelect,
	handleServiceSelect,
	deployCount,
	onAddItem,
	userState,
	prevNotes,
	toggleVisibility,
	maskPass,
	visible
} = props;

const Carriers = [
    "AT&T",
    "Sprint",
    "TMobile",
    "Verizon",
    "US Cellular"
]

const parsed_notes = prevNotes.filter(x => !!x)
const HardwareServiceProps = {
	'item': item,
	'models': models,
	'form': form,
	'newRefurb': newRefurb,
	'serviceSelect': serviceSelect,
	'isModem': isModem,
	'itemType': itemType,
	'prevNotes': prevNotes,
	'handleServiceSelect': handleServiceSelect,
	'onPrint': onPrint,
	'getFormInfo': getFormInfo,
	'toggleServicing': toggleServicing
}

const singularItemInfoProps = {
	'form': form,
	'item': item,
	'loading': loading,
	'itemType': itemType,
	'prevNotes': prevNotes,
	'onToggleAudit': onToggleAudit,
	'toggleEditing': toggleEditing,
	'toggleServicing': toggleServicing,
	'toggleVisibility': toggleVisibility,
	'maskPass': maskPass,
	'visible': visible
}

function onPrint() {
	const tagInfo = {
		'itemNotes': document.getElementById('item-notes') && document.getElementById('item-notes') !== undefined ? document.getElementById('item-notes').value : '',
		'servicesSelected': serviceSelect,
		'new_refurb': newRefurb,
		'deploy_count': deployCount()
	}
	getFormInfo()
	$.post('/api/send_service_tag',{codes: item.barcode, email: userState.user.email, info: tagInfo},(res) => {
	})
}

const getFormInfo = () => {
	const location = document.getElementById('location') && document.getElementById('location') ? document.getElementById('location').value : form && form.location ? form.location : ''
	const controllerData = {
		serial: document.getElementById('serial') && document.getElementById('serial') !== undefined ? document.getElementById('serial').value : form && form.item_info.serial ? form.item_info.serial : '',
		imei: document.getElementById('imei') && document.getElementById('imei') !== undefined ? document.getElementById('imei').value : form && form.item_info.imei ? form.item_info.imei : '',
		sim: document.getElementById('sim') && document.getElementById('sim') !== undefined ? document.getElementById('sim').value : form && form.item_info.sim ? form.item_info.sim : '',
		phone: document.getElementById('phone') && document.getElementById('phone') !== undefined ? document.getElementById('phone').value : form && form.item_info.phone ? form.item_info.phone : '',
		location: location,
		pass: document.getElementById('pass') && document.getElementById('pass') !== undefined ? document.getElementById('pass').value : form && form.item_info.password ? form.item_info.password : '',
		firmware: document.getElementById('firmware') && document.getElementById('firmware') !== undefined ? document.getElementById('firmware').value : form && form.item_info.firmware ? form.item_info.firmware : '',
		carrier:  carrierOption,
		part_num: document.getElementById('part-num') && document.getElementById('part-num') !== undefined ? document.getElementById('part-num').value : form && form.item_info.part_num ? form.item_info.part_num : '',
		model: document.getElementById('model') && document.getElementById('model') !== undefined ? document.getElementById('model').value : form && form.model ? form.model : '',
		item_notes: document.getElementById('item-notes') && document.getElementById('item-notes') !== undefined ? document.getElementById('item-notes').value : '',
		cur_attached_items: document.getElementById('attached-items') && document.getElementById('attached-items') !== undefined ? document.querySelector('#attached-items').dataset.value : form && form.item_info.attached_items ? form.item_info.attached_items.id : '',
		new_action: location.toUpperCase().includes('WI') ? 'OUT' : 'UPDATED',
		servicesSelected: serviceSelect,
		deploy_count: deployCount(),
		timestamp: `${DateToLocale(new Date())} ${localStorage.getItem('FullName')}`
	};
	newItem ? onAddItem(controllerData) : handleUpdateItem(controllerData)
}
const renderPartForm = () => {
    return itemType === 'Parent' ? (
			<div>
				<div className='item-field-flex'>
					<TextField id='barcode'  disabled className='space-below item-field' label='RID #' defaultValue={item.barcode} type='text' onKeyDown={handleAutoTab} />
					<TextField
						inputRef={(input) => tabIdx == 1 && input && input.focus()}
						required
						id='serial'

						error={errors.serial}
						helperText={errors.serial ? 'Error' : null}
						className='space-below item-field'
						label='Serial #'
						defaultValue={(form.item_info && form.item_info.serial) || ''}
						type='text'
						onKeyDown={handleAutoTab}
					/>
				</div>

				{(form.item_info && form.has_carrier) || isModem ? (
					<div className='item-field-flex'>
						<TextField
							inputRef={(input) => tabIdx == 4 && input && input.focus()}
							id='phone'

							className='space-below item-field'
							label='Phone #'
							defaultValue={(form.item_info && form.item_info.phone) || ''}
							type='phone'
							onKeyDown={handleAutoTab}
						/>
						<TextField
							inputRef={(input) => tabIdx == 0 && input && input.focus()}
							id='sim'

							className='space-below item-field'
							label='SIM #'
							defaultValue={form.item_info && form.item_info.sim ? form.item_info.sim : ''}
							type='text'
							onKeyDown={handleAutoTab}
						/>
						<TextField
							inputRef={(input) => tabIdx == 2 && input && input.focus()}
							id='imei'

							required
							error={errors.imei}
							helperText={errors.imei ? 'Error' : null}
							className='space-below item-field'
							label='IMEI'
							defaultValue={(form.item_info && form.item_info.imei) || ''}
							type='text'
							onKeyDown={handleAutoTab}
						/>

						<TextField id='pass'  className='space-below item-field' label='Password' defaultValue={(form.item_info && form.item_info.password) || ''} type='password' />
					</div>
				) : null}

				<div className='item-field-flex'>
					<TextField
						id='part-num'

						required
						error={errors.partNum}
						helperText={errors.partNum ? 'Error' : null}
						className='space-below item-field'
						label='Part #'
						defaultValue={(form.item_info && form.item_info.part_num) || ''}
						type='text'
					/>
					<Autocomplete
						freeSolo
						id='model'
						disabled={!editing ? null : true}
						className='space-below item-field'
						value={(form && form.model)  || ''}
						key={(form && form.model) || ''}
						disableClearable
						options={models.map((model) => model.model)}
						renderInput={(params) => (
							<TextField
								value={(form && form.model) || ''}
								{...params}
								label='Model #'

								InputProps={{
									className: 'space-below item-field',
									...params.InputProps,
									type: 'search',
								}}
							/>
						)}
					/>
				</div>

				<div className='item-field-flex'>
					<Autocomplete
						freeSolo
						id='location'
						value={(form && form.location) || ''}
						className='space-below item-field'
						key={(form && form.location) || ''}
						disableClearable
						options={bins.map((bin) => bin.name)}
						renderInput={(params) => (
							<TextField
								value={(form && form.location) || ''}
								{...params}
								label='Destination'

								InputProps={{
									className: 'space-below item-field',
									...params.InputProps,
									type: 'search',
								}}
							/>
						)}
					/>
				</div>
			</div>
		) : itemType === 'SIM' ? (
			<div>
				<div className='item-field-flex'>
					<TextField id='barcode'  disabled className='space-below item-field' label='RID #' defaultValue={item.barcode} type='text' />

					<TextField
						inputRef={(input) => tabIdx == 0 && input && input.focus()}
						id='sim'

						className='space-below item-field'
						label='SIM #'
						defaultValue={form.item_info && form.item_info.sim ? form.item_info.sim : ''}
						type='text'
						onKeyDown={handleAutoTab}
					/>
					<TextField
						inputRef={(input) => tabIdx == 1 && input && input.focus()}
						id='phone'

						className='space-below item-field'
						label='Phone #'
						defaultValue={(form.item_info && form.item_info.phone) || ''}
						type='phone'
					/>

					<Select
						className='space-below item-field'
						onChange={handleCarrierSelect}
						value={carrierOption ? carrierOption : form.item_info && form.item_info.carrier ? form.item_info.carrier : ''}
						name='carrier-list-dropdown'
						placeholder='Carrier'
						id='carrier'
					>
						{Carriers.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)).map((carrier) => (
							<MenuItem id='carrier' key={carrier} value={carrier}>
								{carrier}
							</MenuItem>
						))}
					</Select>
				</div>

				<div className='item-field-flex'>
					<Autocomplete
						freeSolo
						id='location'
						value={(form && form.location) || ''}
						className='space-below item-field'
						key={(form && form.location) || ''}
						disableClearable
						options={bins.map((bin) => bin.name)}
						renderInput={(params) => (
							<TextField
								value={(form && form.location) || ''}
								{...params}
								label='Destination'

								InputProps={{
									className: 'space-below item-field',
									...params.InputProps,
									type: 'search',
								}}
							/>
						)}
					/>
				</div>
			</div>
		) : itemType === 'Child' ? (
			<div>
				<div className='item-field-flex'>
					<TextField id='barcode'  disabled className='space-below item-field' label='RID #' defaultValue={item.barcode} type='text' />

					<TextField
						inputRef={(input) => tabIdx == 1 && input && input.focus()}
						required
						id='serial'

						error={errors.serial}
						helperText={errors.serial ? 'Error' : null}
						className='space-below item-field'
						label='Serial #'
						type='text'
						defaultValue={(form.item_info && form.item_info.serial) || ''}
						onKeyDown={handleAutoTab}
					/>
				</div>
				<div className='item-field-flex'>
					<Autocomplete
						freeSolo
						id='model'

						className='space-below item-field'
						disabled={!editing ? null : true}
						value={(form && form.model) || item.model}
						key={(form && form.model) || item.model || ''}
						disableClearable
						options={models.map((model) => model.model)}
						renderInput={(params) => (
							<TextField
								className='space-below item-field'
								value={(form && form.model) || item.model || ''}
								{...params}
								label='Model #'
								InputProps={{
									...params.InputProps,
									type: 'search',
								}}
							/>
						)}
					/>

					<Autocomplete
						freeSolo
						id='location'
						className='space-below item-field'
						value={(form && form.location) || ''}
						key={(form && form.location) || ''}
						disableClearable
						options={bins.map((bin) => bin.name)}
						renderInput={(params) => (
							<TextField
								className='space-below item-field'
								value={(form && form.location) || ''}
								{...params}
								label='Destination'

								InputProps={{
									...params.InputProps,
									type: 'search',
								}}
							/>
						)}
					/>

					<TextField
						id='part-num'

						required
						error={errors.partNum}
						helperText={errors.partNum ? 'Error' : null}
						className='table-date-inputspace-below item-field'
						label='Part #'
						defaultValue={(form.item_info && form.item_info.part_num) || ''}
						type='text'
					/>
				</div>
			</div>
		) : null; }



return (
	<div>
		{editing || newItem ? (
			<div className='item-field-container'>
				<div
					className={
						isModem
							? 'vert-margin-med grid-container modem-field-grid'
							: itemType === 'SIM'
							? 'vert-margin-med grid-container SIM-field-grid item-grid-auto-fit'
							: 'vert-margin-med grid-container item-field-grid item-grid-auto-fit'
					}>
					{renderPartForm()}
					<div className='item-field-flex option-field'>
						{itemType === 'SIM' ? null : (
							<FormControl>
								<RadioGroup id='refurb-group' row name='item-state-group' onChange={handleNewRefurbSelect} value={newRefurb ? newRefurb : form && form.refurbished ? 'Refurbished' : 'New'}>
									<FormControlLabel disabled={form && form.refurbished ? true : false} value='Refurbished' label='Refurbished' id='refurb' color='primary' control={<Radio />} />
									<FormControlLabel disabled={form && form.refurbished ? true : false} value='New' label='New' id='new-item' color='primary' control={<Radio />} />
								</RadioGroup>
							</FormControl>
						)}
						{form.item_info && form.item_info.attached_items && form.item_info.attached_items.id && form.item_info.attached_items.id.length ? (
							<div className='item-field'>
								<label className='attached-label'>
									Attached to -{' '}
									<a data-value={form.item_info.attached_items.id} className='attached-item-link' id='attached-items' href='#' onClick={(e) => onNavigateToItem(e, form.item_info.attached_items.id)}>
										{form.item_info.attached_items.id}
									</a>
								</label>

								<Button color='secondary' variant='outlined' size='small' onClick={onToggleDetach}>
									detach
								</Button>
							</div>
						) : (
							<div>
								<Button className='rti-blue-round' onClick={onToggleItem}>
									Attach to Item
								</Button>
							</div>
						)}
					</div>
				</div>

				<div>
					<div className='item-note-field full-width'>
						<span>
							<p>New Notes</p>
						</span>
						<TextField id='item-notes' multiline maxRows={4} variant='outlined' defaultValue='' />
					</div>
					<div className='item-note-field full-width'>
						<span>
							<p>Previous Notes</p>
						</span>
						<TextField
							id='prev-notes'
							multiline
							maxRows={4}
							variant='outlined'
							disabled
							value={
								form && form.barcode === item.barcode
									? parsed_notes
											.map((el) => {
												if (el.item_notes) {
													return `${el.timestamp} ${el.item_notes}`;
												}
												return '';
											})
											.join('\n\n')
									: ''
							}
						/>
					</div>
				</div>
				<div className='flex-just-even full-width bottom-buttons'>
					<div>
						<Button className='item-buttons' variant='outlined' color='secondary' onClick={toggleEditing}>
							Cancel
						</Button>
					</div>
					<div>
						<Button className='item-buttons' variant='outlined' color='primary' onClick={getFormInfo}>
							Save
						</Button>
					</div>
				</div>
			</div>
		) : servicing ? (
			<div>{HardwareServiceController(HardwareServiceProps)}</div>
		) : (
			<div>{SingularItemInfo(singularItemInfoProps)}</div>
		)}
	</div>
);
            }
