import React, {useState,useEffect} from 'react';
import CustomTable from '../../components/Table';
import { useCustomContext } from '../../hoc/Context.js';
import { DateToLocale } from '../API/TextFormatingFunctions';

const TableHeaders = [
    {id: 'received', name: 'received'},
    {id: 'received by', name: 'received by', sortable: true, numeric: false},
    {id: 'item', name: 'item',sortable: true, numeric: false},
    {id: 'po #', name: 'po #',sortable: true, numeric: false},
    {id: 'rid', name: 'rid',sortable: true, numeric: false},
    {id: 'location', name: 'location',sortable: true, numeric: false},
    {id: 'serial/sim', name: 'serial/sim',sortable: true, numeric: false},
    {id: 'imei', name: 'imei',sortable: true, numeric: false},
    {id: 'refurb', name: 'refurb',sortable: true, numeric: false}
]

const SearchOptions = [
    {id: 'received by', name: 'Received By', pidx: 1},
    {id: 'item', name: 'Item', pidx: 2},
    {id: 'rid', name: 'RID', pidx: 4}
]

const TableTabs = [
    {id: '7', name: 'Last 7 Days', filter: '7'},
    {id: '14', name: 'Last 14 Days', filter: '14'},
    {id: '28', name: 'Last 28 Days', filter: '28'},
    {id: '90', name: 'Last 90 Days', filter: '90'},
    {id: '999', name: 'All Time', filter: '999'}
]

const ItemsReceived = () => {
    const [loading,setLoading] = useState(true);
    const [searchVal,setSearchVal] = useState('');
    const [searchParam,setSearchParam] = useState('');
    const [items,setItems] = useState([]);
    const [activeTab,setActiveTab] = useState(TableTabs[0].id);
    const {userState,userDispatch} = useCustomContext();

    useEffect(() => {
        let mounted = true;

        if(mounted){
            retreiveData(true);
        }

        return () => {
            mounted = false;
        }
    },[activeTab]);

    const retreiveData = (load) => {
        if(load) setLoading(true);

        socket.emit('getReceivedItems',parseInt(activeTab),(res) => {
            if(!res){
                console.error(`ItemsReceived.getReceivedItems: There was an issue calling this method`)
                setLoading(false);
            }else{
                setItems(res);
                setLoading(false);
            }
        });
    }

    const renderTablebody = () => {
        return items.map(item => {
            const item_info = JSON.parse(item.item_info);

            return {
                key: item.id,
                cells: [
                    {data: DateToLocale(new Date(item.created))},
                    {data: item.created_by},
                    {data: item.item_name},
                    {data: item.po_num},
                    {data: item.barcode},
                    {data: item.location},
                    {data: item_info && item_info.serial ? item_info.serial : item_info.sim},
                    {data: item_info && item_info.imei ? item_info.imei : ""},
                    {data: item.refurbished ? "YES" : ""}
                ]
            }            
        })
        .filter(row => searchVal && searchParam ? row.cells[SearchOptions[searchParam - 1].pidx].data && row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase()) : row)
    }

    const renderTableButtons = () => {

    }

    const rowClick = () => {

    }

    const Search = (param, searchVal) => {
        setSearchVal(searchVal);
        setSearchParam(param);
    }

    const ClearSearch = () => {
        setSearchVal('');
    }

    const filterTable = (filter) => {
        setActiveTab(filter);
    }

    return(
        <div className='ViewedContentContainer' id='OpenContainer' >
            <div style={{height: '100%'}}>
                <div className='TitleBarContainer'>
                    <h1 >Items Received</h1>
                </div>
                <CustomTable
                    searchable
                    paginate
                    loading_data={loading}
                    search={Search}
                    clear_search={ClearSearch}
                    headers={TableHeaders}
                    search_options={SearchOptions}
                    tabs={TableTabs}
                    tab_style="select"
                    active_tab={activeTab}
                    filter_data={filterTable}
                    rows={renderTablebody()}
                    onClick={rowClick}
                    table_buttons={renderTableButtons()}
                />
            </div>
        </div>
    );
}

export default ItemsReceived;