import React, { useEffect, useState } from 'react';
import TechTimeOffModal from './TechTimeOffModal.js';
import CustomTable from '../../components/Table.js';
import Button from '@mui/material/Button';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineDownload } from 'react-icons/ai';
import AlertBar from '../../components/AlertBar.js';
import { useCustomContext } from '../../hoc/Context.js';
import { CSVLink } from 'react-csv';
import { FormatDateToYMD } from '../API/TextFormatingFunctions.js';

const TableHeaders = [
    {id: 'name', name: 'Technician'},
    {id: 'start', name: 'Start'},
    {id: 'return', name: 'Return'},
    {id: 'notes', name: 'Notes'}
]

const TechTimeOff = (props) => {
    const [Loading, setLoading] = useState(true);
    const [TechCalendarDB, setTechCalendarDB] = useState([]);
    const [TechniciansDB, setTechniciansDB] = useState([]);
    const [retrieveData, setRetrieveData] = useState(true);
    const [selected, setSelected] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    })
    const { userState, userDispatch } = useCustomContext();

    useEffect(() => {
        let mounted = true;
        
        if(mounted){  
            if(retrieveData){   // only retrieve this data once on page load
                $.post(`/api/table_data`,{table:'tech_time_off_table'},(res) => {
                    if(res.status == 'OK'){
                        const [calendar, technicians] = res.data;
                        
                        setTechCalendarDB(calendar);
                        setTechniciansDB(technicians);
                        setRetrieveData(false);
                        setLoading(false);
                    }else{
                        setRetrieveData(false);
                        setLoading(false);
                        setSnackbar(prevState => ({
                            ...prevState,
                            visible: true,
                            message: res.error,
                            severity: 'error'
                        }))
                    }
                })
            }
        }

        return () => {
            mounted = false;
        }
    },[])

    const RefreshTableData = () => {
        socket.emit('selectTechCalendar', (result) => {
            setTechCalendarDB(result);
        });
    }

    const AddNewItemBtn = () => {
        if (userState.user.role) {
            toggleModal();
        }
    }

    function renderTableButtons() {
        return (
            <div style={{display: 'flex'}}>
                {userState.user.role ? <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<AiOutlinePlus />}
                    onClick={AddNewItemBtn}>Add</Button> : undefined}
                <CSVLink style={{ background: 'none', border: 'none' }} target="_blank" className="hidden" id="table-download" data={downloadData()} filename={"tech-time-off.csv"} ></CSVLink>
                <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<AiOutlineDownload />}
                    onClick={() => document.getElementById('table-download').click()}>Download</Button>
            </div>
        ); 
    }

    const downloadData = () => {
        return TechCalendarDB.map(calendar => ({
            "Technician": calendar.TechnicianName,
            "Start": calendar.StartDate,
            "Return": calendar.ReturnDate,
            "Notes": calendar.Notes
        }))
    }

    const getTableData = () => {
        return TechCalendarDB.map(calendar => ({
            key: calendar.TimeOffID,
            cells: [
                {data: calendar.TechnicianName},
                {data: calendar.StartDate},
                {data: calendar.ReturnDate},
                {data: calendar.Notes},
            ]
        }))
    }

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({
            ...prevState,
            visible: false
        }))
    }

    const handleTableClick = (event) => {
        const id = event.target.id;
        const tto = TechCalendarDB.find(el => el.TimeOffID == id);
        setSelected({
            ...tto,
            StartDate: FormatDateToYMD(tto.StartDate),
            ReturnDate: FormatDateToYMD(tto.ReturnDate),
        });
        
        if (userState.user.role) {
            toggleModal();
        }
    }

    const handleModalClose = () => {
        setSelected({});
        toggleModal();
    }

    const toggleModal = () => {
        setModalOpen(prevState => !prevState);
    }

    return(
        <div className="ViewedContentContainer" id="OpenContainer" >
            <div style={{height: '100%'}}>
                <div className="TitleBarContainer">
                    <h1 title="View/Add When techs are avaliable">Tech Time Off</h1>
                </div>
                <AlertBar
                    visible={snackbar.visible}
                    message={snackbar.message}
                    severity={snackbar.severity}
                    onClose={handleCloseSnack}
                />
                <CustomTable
                    paginate
                    loading_data={Loading}
                    headers={TableHeaders}
                    rows={getTableData()}
                    table_buttons={renderTableButtons()}
                    onClick={handleTableClick}
                />

                {modalOpen ? <div style={{display: 'block'}} className="modal" id="myModal" >
                    <div style={{display: 'block'}} className="modal-content" id="modal_techTimeOff">
                        <TechTimeOffModal
                            info={selected}
                            techniciansDB={TechniciansDB}
                            refresh_data={RefreshTableData}
                            role={userState.user.role ? true : false}
                            onClose={handleModalClose}
                        />
                    </div>
                </div> : null}                    
            </div>
        </div>
    )
}

export default TechTimeOff;
