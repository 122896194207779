import React from 'react';
import {
	MdNetworkWifi as WifiIcon,
	MdSignalWifiOff as WifiOffIcon,
	MdPhone as PhoneIcon,
	MdChat as ChatBubbleOutlineIcon,
	MdEmail as EmailIcon,
} from 'react-icons/md'; // Using icons from react-icons
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { FormatPhoneNumber, FormatMultiLineText, DateToYMD, formatMinutesToHours } from '../API/TextFormatingFunctions';
import { InfoWindow } from '@react-google-maps/api';
import $ from 'jquery';

const DEFAULT_DATE = '0000-00-00';
const validateDate = (date) => {
	if (
		date === DEFAULT_DATE ||
		date === null ||
		date === undefined ||
		date === 'NaN-NaN-NaN' ||
		date === new Date(0).toISOString().split('T')[0]
	) {
		return `Not Updated`;
	}
	return DateToYMD(new Date(date));
};

import PropTypes from 'prop-types';

const MarkerInfo = ({ visible = false, marker = null, info_type = '', route = false, onMarkerClose, onAddRoute }) => {
	const handlePhoneCall = () => {
		document.getElementById('store-phone').click();
	};

	const handleEmail = () => {
		document.getElementById('tech-email').click();
	};

	const handleMessage = () => {
		const chatModal = document.getElementById('chat-modal');
		if (chatModal.style.display === 'none' || chatModal.style.display === '') {
			chatModal.style.display = 'block';
		} else {
			chatModal.style.display = 'none';
		}
	};

	const onDispatchJob = (_type) => {
		if (info_type !== 'tech') {
			$('#CreateJobDialog').show();
			$('#CreateJobDialog').draggable();
			document.getElementById('CreateButton').disabled = false;
			document.getElementById('KioskIDInput').value = `${marker.KioskID} ${marker.ServerID}`;

			if (_type === 'maintenance') {
				document.getElementById('IssueInput').value = 'Maintenance';
				document.getElementById('dispatch-reason').value = 'Maintenance';
			}
		}
	};

	const onRouteAdd = () => {
		if (info_type !== 'tech') {
			onAddRoute(`${marker.KioskID} / ${marker.ServerID}`);
		} else {
			onAddRoute(`${marker.FirstName} ${marker.LastName}`);
		}
	};

	const onMarkClean = () => {
		if (info_type !== 'tech') {
			socket.emit('markKioskClean', marker.KioskID, (res) => {
				if (!res) {
					console.error(`MarkerInfo.onMarkClean: There was an issue calling this method`);
				} else {
					alert(`Success! Kiosk has been marked clean.`);
				}
			});
		}
	};

	// TERNARY RETURN STATEMENT
	return visible ? (
		<InfoWindow onCloseClick={onMarkerClose} position={{ lat: marker.Latitude, lng: marker.Longitude }}>
			{info_type === 'tech' ? (
				<div style={{ width: 364 }}>
					<div className='flex-space-between'>
						<h2>{`${marker.FirstName} ${marker.LastName}`}</h2>
						<div className='flex-align-center'>
							<label>{marker.IsAvaliable === 'Yes' ? 'Available' : 'Not Available'}</label>
							<div
								style={{ marginLeft: 12 }}
								className={`avail-badge ${marker.IsAvaliable === 'Yes' ? 'available' : 'not-available'}`}
							></div>
						</div>
					</div>
					<div className='flex-space-between no-margin'>
						<div className='flex-align-center'>
							<p>
								<b>Phone: </b>
								{FormatPhoneNumber(marker.MobileNumber)}
							</p>
							<a id='store-phone' href={`tel:${marker.MobileNumber}`}></a>
							<IconButton size='small' style={{ marginLeft: 12 }} onClick={handlePhoneCall}>
								<PhoneIcon />
							</IconButton>
							<IconButton size='small' style={{ marginLeft: 12 }} onClick={handleMessage}>
								<ChatBubbleOutlineIcon />
							</IconButton>
						</div>
					</div>
					<div className='flex-space-between no-margin'>
						<label>
							<b>Address: </b>
							{marker.Address}
						</label>
						<label>
							<b>City: </b>
							{marker.City}
						</label>
					</div>
					<div style={{ display: 'flex' }}>
						<p style={{ marginRight: 36 }}>
							<b>State: </b>
							{marker.State}
						</p>
						<p>
							<b>Zip: </b>
							{marker.Zip}
						</p>
					</div>
					<div className='flex-align-center'>
						<p>
							<b>Email: </b>
							{marker.PersonalEmail}
						</p>
						<a id='tech-email' href={`mailto:${marker.PersonalEmail}`}></a>
						<IconButton size='small' style={{ marginLeft: 12 }} onClick={handleEmail}>
							<EmailIcon />
						</IconButton>
					</div>
					<p>
						<b>Company: </b>
						{marker.CompanyName}
					</p>
					<div className='flex-just-even vert-margin-med'>
						{route ? (
							<Button className='rti-blue-round' variant='contained' size='small' onClick={onRouteAdd}>
								Add to Route
							</Button>
						) : null}
					</div>
				</div>
			) : (
				<div>
					<div style={{ width: 464 }}>
						<div style={{ alignItems: 'center' }} className='flex-space-between'>
							<h2>{`${marker.KioskID} | ${marker.ServerID}`}</h2>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<label style={{ marginRight: 12 }}>{marker.ActiveOrInactive === 'Active' ? 'online' : 'offline'}</label>
								<div
									style={{ marginRight: 12 }}
									className={`avail-badge ${marker.kiosk_status === 'online' ? 'available' : 'not-available'}`}
								></div>
								{marker.kiosk_status === 'Internet Issue' ? (
									<WifiOffIcon fontSize='medium' />
								) : (
									<WifiIcon fontSize='medium' />
								)}
							</div>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto' }}>
							<label style={{ marginRight: 36, marginLeft: 'auto' }}>
								<b>Modem</b>: {marker.ModemType}
							</label>
							<label>
								<b>Carrier</b>: {marker.Carrier}
							</label>
						</div>
						<div className='flex-space-between no-margin'>
							<div className='flex-align-center'>
								<p>
									<b>Phone: </b>
									{FormatPhoneNumber(marker.StorePhone)}
								</p>
								<a id='store-phone' href={`tel:${marker.StorePhone}`}></a>
								<IconButton size='small' style={{ marginLeft: 12 }} onClick={handlePhoneCall}>
									<PhoneIcon />
								</IconButton>
							</div>
							<p>
								<b>Active: </b>
								{marker.ActiveOrInactive == 'Active' ? 'Yes' : 'No'}
							</p>
							<p>
								<b>Cleaned: </b>
								{validateDate(marker.cleaned) || DateToYMD(new Date(marker.cleaned))}
							</p>
						</div>
						<div className='flex-space-between flex-align-center no-margin'>
							<label>
								<b>Hours: </b>
								{FormatMultiLineText(marker.StoreHours)}
							</label>
							<p>
								<b>Paper Bridge: </b>
								{marker.paper_bridge ? 'Yes' : 'No'}
							</p>
							<p>
								<b>Reset Switch: </b>
								{marker.has_switch ? 'Yes' : 'No'}
							</p>
						</div>
						<div className='flex-space-between no-margin'>
							<p>
								<b>Store: </b>
								{marker.StoreName}
							</p>
							<p>
								<b>Star Firmware: </b>
								{validateDate(marker.star_firmware) || DateToYMD(new Date(marker.star_firmware))}
							</p>
							<p>
								<b>Ski Slope: </b>
								{validateDate(marker.ski_slope_install_date) || DateToYMD(new Date(marker.ski_slope_install_date))}
							</p>
							<p>
								<b>OS Install: </b>
								{validateDate(marker.os_installed) || DateToYMD(new Date(marker.os_installed))}
							</p>
						</div>
						<div>
							<label style={{ marginRight: 36 }}>
								<b>Address: </b>
								{marker.StoreAddress}
							</label>
							<label>
								<b>City: </b>
								{marker.StoreCity}
							</label>
						</div>
						<div style={{ display: 'flex' }}>
							<p style={{ marginRight: 36 }}>
								<b>State: </b>
								{marker.StoreState}
							</p>
							<p style={{ marginRight: 36 }}>
								<b>Zip: </b>
								{marker.StoreZip}
							</p>
						</div>
						<div className='flex-just-even vert-margin-med'>
							<Button className='rti-blue-round' variant='contained' size='small' onClick={onMarkClean}>
								Mark Clean
							</Button>
							<Button
								className='rti-blue-round'
								variant='contained'
								size='small'
								onClick={() => onDispatchJob('dispatch')}
							>
								Dispatch Job
							</Button>
							<Button
								className='rti-blue-round'
								variant='contained'
								size='small'
								onClick={() => onDispatchJob('maintenance')}
							>
								Dispatch Maintenance
							</Button>
							{route ? (
								<Button className='rti-blue-round' variant='contained' size='small' onClick={onRouteAdd}>
									Add to Route
								</Button>
							) : null}
						</div>
						<div style={{ justifyContent: 'center', borderTop: '1px solid gainsboro' }} className='flex-align-center'>
							<div className='flex-align-center'>
								<p style={{ marginRight: 10 }}>
									<b>Star: </b>
									{marker.PrintCountCOVR}
								</p>
								<div
									style={{ marginRight: 26 }}
									className={`avail-badge ${marker.kiosk_status === 'Star Printer' ? 'not-available' : 'available'}`}
								></div>
							</div>
							<div className='flex-align-center'>
								<p style={{ marginRight: 10 }}>
									<b>DM1: </b>
									{marker.PrintCountOne}
								</p>
								<div
									style={{ marginRight: 26 }}
									className={`avail-badge ${marker.kiosk_status === 'Datamax 1' ? 'not-available' : 'available'}`}
								></div>
							</div>
							<div className='flex-align-center'>
								<p style={{ marginRight: 10 }}>
									<b>DM2: </b>
									{marker.PrintCountTwo}
								</p>
								<div
									style={{ marginRight: 26 }}
									className={`avail-badge ${marker.kiosk_status === 'Datamax 2' ? 'not-available' : 'available'}`}
								></div>
							</div>
						</div>
						<div>
							<div className='flex-just-between'>
								<p>
									<b>Daily Avg: </b>
									{marker.daily_avg ? marker.daily_avg : ''}
								</p>{' '}
								<p>
									<b>Runout: </b>
									{marker.runout_date ? DateToYMD(new Date(marker.runout_date)) : ''}
								</p>
								<p>
									<b>Due Date: </b>
									{marker.due_date ? DateToYMD(new Date(marker.due_date)) : 'More than 45 days'}
								</p>
							</div>
							<div className='flex-just-between'>
								<div style={{ width: '50%' }}>
									<p>
										<b>Primary: </b>
										{JSON.parse(marker.PrimaryTechs).join(', ')}
									</p>
								</div>
								<div style={{ width: '50%' }}>
									<p>
										<b>Backup: </b>
										{JSON.parse(marker.BackupTechs).join(', ')}
									</p>
								</div>
							</div>
							<div className='flex-just-between'>
								<div style={{ width: '50%' }}>
									<p>
										<b>Paper Changer: </b>
										{marker.PaperChanger}
									</p>
								</div>
								<div style={{ width: '50%' }}>
									<p>
										<b>Drive Time (Bill Wanta): </b>
										{formatMinutesToHours(marker.drive_time)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</InfoWindow>
	) : null;
};

MarkerInfo.propTypes = {
	visible: PropTypes.bool.isRequired,
	marker: PropTypes.object.isRequired,
	info_type: PropTypes.string.isRequired,
	route: PropTypes.bool.isRequired,
	onMarkerClose: PropTypes.func.isRequired,
	onAddRoute: PropTypes.func.isRequired,
};
export default MarkerInfo;
