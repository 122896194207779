/**
 * Front end file that displays the tech call log table.
 * @component
 *
 * @module TechCallLogTable
 * @category User Interface
 */

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { FiDownload } from 'react-icons/fi';
import CustomTable from '../../components/Table.js';
import { DateToMDY, FormatDateFromYear2, FormatDateToMDY } from '../../imports/API/TextFormatingFunctions.js';
import { CSVLink } from 'react-csv';
import { useCustomContext } from '../../hoc/Context.js';
import env from '../../utilities/env-loader.cjs';
const TechCallLogTable = (props) => {
	const TableHeaders = [
		{ id: 'call-date', name: 'Date of Call', size: 'small-header' },
		{ id: 'dispatcher', name: 'Dispatcher', sortable: true, numeric: false, size: 'small-header' },
		{ id: 'tech', name: 'Technician', sortable: true, numeric: false, size: 'small-header' },
		{ id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false, size: 'small-header' },
		{ id: 'duration', name: 'Duration', sortable: true, numeric: false, size: 'small-header' },
		{ id: 'reason', name: 'Reason', sortable: true, numeric: false },
		{ id: 'notes', name: 'Notes', size: 'xlarge-header' },
	];
	const TableTabs = [
		{ id: 7, name: 'Last 7 days', filter: 7 },
		{ id: 28, name: 'Last 28 days', filter: 28 },
		{ id: 60, name: 'Last 60 days', filter: 60 },
		{ id: 90, name: 'Last 90 days', filter: 90 },
		{ id: 180, name: 'Last 180 days', filter: 180 },
	];
	let datetime = new Date();
	const [Logs, setLogs] = useState([]);
	const [ReportData, setReportData] = useState([]);
	const [Loading, setLoading] = useState(true);
	const [dataRetrieved, setDataRetrieved] = useState(false);
	const [activeTab, setActiveTab] = useState(TableTabs[1].filter);
	const { userState, userDispatch } = useCustomContext();

	useEffect(() => {
		let mounted = true;

		const fetchCallLogs = () => {
			try {
				if (mounted) {
					socket.emit('fetchCallLogs', 'tech-log', activeTab, (result) => {
						if (result.error) {
							throw new Error(result.error);
						}
						setLogs(result[0]);
						setDataRetrieved(true);
						setLoading(false);
					});
				}
			} catch (error) {
				console.error('Error fetching call logs:', error);
				setError(error.message);
				setLoading(false);
			}
		};

		fetchCallLogs();

		if (ReportData.length) {
			document.getElementById('report-download').click();
		}

		return () => {
			mounted = false;
		};
	}, [activeTab]);

    /**
     * Formats a given date string into a specific format.
     *
     * @param {string} dateIn - The date string to be formatted.
     * @returns - {string} The formatted date string.
     * 
     * @example FormatDate('2022-10-01T00:00:00.000Z') returns 'Oct. 1, 2022'
     */
	function FormatDate(dateIn) {
		let temp = dateIn.split('T');

		let [year, month, date] = temp[0].split('-');

		let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

		return monthNames[parseInt(month) - 1] + '. ' + date.trim() + ', ' + year.trim();
	}

    /**
     * Retrieves the report data for the TechCallLogTable.
     * Clears the existing report data and populates it with the new data.
     */
	function GetReport() {
		setReportData([]);
		const data = Logs.map((log) => {
			const day_of_call = new Date(FormatDateToMDY(log.DateOfCall.split('T')[0])).getDay();
			return {
				'Submission Timestamp': new Date(log.SubmissionDate).toLocaleString(),
				'Date of Call': log.DateOfCall.split('T')[0],
				'Day of Call': env.DAYS[day_of_call],
				Dispatcher: log.Dispatcher,
				Tech: log.TechnicianCalling,
				Kiosk: log.KioskID,
				Duration: log.CallDuration + ' minutes',
				'Reason For Calling': log.ReasonForCalling,
				Notes: log.Notes.replace(/,/g, ' '),
			};
		});

		setReportData(data);
	}

    /**
     * Renders the table buttons.
     * @returns - {React.ReactNode} The rendered table buttons.
     */
	function RenderTableButtons() {
		return userState.user.role ? (
			<div style={{ display: 'flex' }}>
				<CSVLink
					id="report-download"
					className="hidden"
					target="_blank"
					style={{ background: 'none', border: 'none' }}
					data={ReportData}
					filename={'TechCallLogs.csv'}
				></CSVLink>
				<Button className="rti-blue-round" variant="contained" startIcon={<FiDownload />} onClick={GetReport}>
					Tech Logs
				</Button>
			</div>
		) : undefined;
	}

    /**
     * Retrieves the table data for the TechCallLogTable.
     * @returns - {Array} An array of objects representing the table data.
     */
	const getTableData = () => {
		return Logs.map((log) => ({
			key: log.id,
			cells: [
				{ data: FormatDate(log.date_of_call) },
				{ data: log.user },
				{ data: log.technician },
				{ data: log.kiosk_location },
				{ data: `${log.call_duration} minutes` },
				{ data: log.reason_for_call },
				{ data: log.notes },
			],
		}));
	};

	const filterTable = (filter) => {
		setActiveTab(filter);
	};

	return (
		<div className="ViewedContentContainer" id="OpenContainer">
			<div style={{ height: '100%' }}>
				<div className="TitleBarContainer">
					<h1>Tech Support Logs</h1>
				</div>
				<CustomTable
					tabs={TableTabs}
					searchable
					tab_style="select"
					active_tab={activeTab}
					paginate
					headers={TableHeaders}
					loading_data={Loading}
					rows={getTableData()}
					table_buttons={RenderTableButtons()}
					filter_data={filterTable}
				/>
			</div>
		</div>
	);
};

export default TechCallLogTable;
