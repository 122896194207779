import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const ReportBody = ({
	LogsData,
	Loading,
	RenderSavedLogs,
	NewReportDropdown,
	HandleDropdown,
	LogForm,
	HandleChangesMade,
	SaveLog,
	HandleSubmitting,
	SavedLogs
}) => {
	return (
		<div className="all-report-body">
			<div id="all-report-container" className="all-report-container">
				{LogsData.AllReports && !Loading ? (
					<div>
						<table className="report-table">
							<tbody id="scroll-body">{RenderSavedLogs()}</tbody>
						</table>
						<div className="new-report-container">
							{NewReportDropdown()}
							<div>
								<Button
									class="report-log-dropdown-button medium-circle"
									name="+"
									color="rti-lightgray"
									size="medium-circle"
									click={HandleDropdown}
								/>
							</div>
						</div>
					</div>
				) : (
					!LogsData.AllReports &&
					!Loading && (
						<div>
							<LogForm
								SavedLogs={SavedLogs}
								form={LogsData.ReportSelected}
								form_input={LogsData.LogSelected}
								changes_made={HandleChangesMade}
							/>
							<div id="form-action-buttons" className="new-report-container">
								<div style={{ display: 'flex' }}>
									<div style={{ marginRight: 'auto' }}>
										<Button name="Save" color="rti-blue" click={SaveLog} />
									</div>
									<div>
										<Button
											name={LogsData.Submitting ? 'Submitting' : 'Submit'}
											color="rti-blue"
											disabled={LogsData.Submitting}
											click={HandleSubmitting}
										/>
									</div>
								</div>
							</div>
						</div>
					)
				)}
			</div>
		</div>
	);
};

ReportBody.propTypes = {
  LogsData: PropTypes.shape({
    AllReports: PropTypes.array,
    ReportSelected: PropTypes.object,
    LogSelected: PropTypes.object,
    Submitting: PropTypes.bool,
  }).isRequired,
  Loading: PropTypes.bool.isRequired,
  RenderSavedLogs: PropTypes.func.isRequired,
  NewReportDropdown: PropTypes.func.isRequired,
  HandleDropdown: PropTypes.func.isRequired,
  LogForm: PropTypes.func.isRequired,
  HandleChangesMade: PropTypes.func.isRequired,
  SaveLog: PropTypes.func.isRequired,
  HandleSubmitting: PropTypes.func.isRequired,
  SavedLogs: PropTypes.array.isRequired,
};

export default ReportBody;
