import React, { useState, useEffect } from 'react';
import { DateToLocale, DateToYMD } from '../API/TextFormatingFunctions';
import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { MdAdd, MdMoreVert, MdGetApp } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AlertBar from '../../components/AlertBar';
import { useCustomContext } from '../../hoc/Context';
import { validateBinLocation } from '../../utilities/util';
import { CSVLink } from 'react-csv';
import { ReceiveCores } from './ReceiveCores';
import { RemovePaperModal } from './InventoryItemModals/RemovePaperModal';
import { EditPaperModal } from './InventoryItemModals/EditPaperModal';
import { StickerContractorJobs } from './StickerContractorUI/StickerContractorJobs';
import '../../StyleSheets/InventorySystem.css';
import { useNavigate } from 'react-router-dom';

const TableHeaders = [
  { id: 'date removed', name: 'date removed', sortable: true, numeric: false },
  { id: 'kiosk', name: 'kiosk', sortable: true, numeric: false },
  { id: 'paper core', name: 'paper core', sortable: true, numeric: false },
  { id: 'job#', name: 'job#', sortable: true, numeric: false },
  { id: 'technician', name: 'technician', sortable: true, numeric: false },
  { id: 'estimated removed', name: 'estimated removed', sortable: true, numeric: true },
  { id: 'sticker year', name: 'sticker year', sortable: true, numeric: true },
  { id: 'qty returned', name: 'qty returned', sortable: true, numeric: true },
  { id: 'date returned', name: 'date returned' },
  { id: 'status', name: 'status', sortable: true, numeric: false },
  { id: '_blank', name: '' },
];

const TableHeaders2 = [
  { id: 'contractor', name: 'contractor' },
  { id: 'components', name: 'components' },
];

const TableHeaders3 = [
  { id: 'assigned', name: 'assigned', numeric: true },
  { id: 'core #', name: 'core #', sortable: true, numeric: true },
  { id: 'job#', name: 'job#', sortable: true, numeric: false },
  { id: 'kiosk', name: 'kiosk', sortable: true, numeric: false },
  { id: 'location', name: 'location', sortable: true, numeric: false },
  { id: 'year', name: 'year', sortable: true, numeric: true },
  { id: 'qty', name: 'qty', sortable: true, numeric: true },
  { id: 'technician', name: 'technician', sortable: true, numeric: false },
];

const SearchOptions = [
  { id: 'kiosk', name: 'Kiosk', pidx: 1 },
  { id: 'tech', name: 'Technician', pidx: 2 },
  { id: 'year', name: 'Sticker Year', pidx: 4 },
  { id: 'status', name: 'Status', pidx: 7 },
];

const SearchOptions2 = [{ id: 'contractor', name: 'Contractor', pidx: 0 }];

const SearchOptions3 = [
  { id: 'kiosk', name: 'Kiosk', pidx: 3 },
  { id: 'job#', name: 'Job#', pidx: 2 },
  { id: 'tech', name: 'Technician', pidx: 6 },
  { id: 'year', name: 'Sticker Year', pidx: 4 },
];

const MenuOpts = [{ name: 'Edit' }, { name: 'Remove' }];

const TableTabs = [
  { id: 'pending', name: 'pending', filter: 'pending' },
  { id: 'returned', name: 'returned', filter: 'returned' },
  { id: 'contractors', name: 'contractors', filter: 'contractors' },
  { id: 'paper cores', name: 'paper cores', filter: 'paper cores' },
  { id: 'receive', name: 'receive', filter: 'receive' },
];

const DATE_DEFAULT = '0000-00-00 00:00:00';

const PaperInventory = ({ history }) => {
  const _query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const _params = Array.from(_query.keys()).reduce(
    (acc, val) => ({
      ...acc,
      [val]: _query.get(val),
    }),
    {}
  );

  const [loading, setLoading] = useState(true);
  const [bins, setBins] = useState([]);
  const [searchVal, setSearchVal] = useState(_params.search ? _params.search : '');
  const [searchParam, setSearchParam] = useState(_params.param ? _params.param : '');
  const [stickers, setStickers] = useState([]);
  const [anchorIndex, setAnchorIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [Techs, setTechs] = useState([]);
  const [Kiosks, setKiosks] = useState([]);
  const [promptOpen, setPromptOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(_params.filter ? _params.filter : TableTabs[0].filter);
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const [contractors, setContractors] = useState([]);
  const [contractorComponents, setContractorComponents] = useState([]);
  const [cores, setCores] = useState([]);
  const [receivedCores, setReceivedCores] = useState([]);
  const [receiving, setReceiving] = useState(false);
  const [items, setItems] = useState([]);
  const [receivingYear, setReceivingYear] = useState('');
  const [stickerContractorJobs, setStickerContractorJobs] = useState([]);
  const [contractorOpen, setContractorOpen] = useState(null);
  const contractorOptionsOpen = Boolean(anchorEl);

  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: '',
    severity: 'success',
  });
  const { userState, userDispatch } = useCustomContext();

  const receivePaperProps = {
    bins,
    receivedCores,
    stickers,
    handleBackButton,
    onScanBulk,
    removeBulkItem,
    onUpdate,
    receivingYear,
  };

  const removePaperProps = {
    togglePrompt: togglePrompt,
    uploading: uploading,
    onRemoveStickers: onRemoveStickers,
  };

  const editPaperProps = {
    toggleModal: toggleModal,
    selected: selected,
    Kiosks: Kiosks,
    Techs: Techs,
    bins: bins,
    uploading: uploading,
    onUpdate: onUpdate,
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      retreiveData(true);

      if (!dataRetrieved) {
        socket.emit('selectFromKiosksForTM', (res) => {
          if (!res) {
            console.error(`PaperInventory.selectFromKiosksForTM: There was an issue calling this method`);
          } else {
            setKiosks(res);
          }
        });

        socket.emit('selectFromTechniciansForTM', (res) => {
          if (!res) {
            console.error(`PaperInventory.selectFromTechniciansForTM: There was an issue calling this method`);
          } else {
            setTechs(res);
          }
        });

        socket.emit('getAllBinLocations', (res) => {
          if (!res) {
            console.error(`PaperInventory.getAllBinLocations: There was an issue calling this method`);
          } else {
            setBins(res);
          }
        });

        socket.emit('getContractors', (res) => {
          if (!res) {
            console.error(`PaperInventory.getContractors: There was an issue calling this method`);
          } else {
            setContractors(res);
          }
        });

        socket.emit('aggregateAllContractorComponents', (res) => {
          if (!res) {
            console.error(`PaperInventory.aggregateAllContractorComponents: There was an issue calling this method`);
          } else {
            let result = {};

            for (let item of res[0]) {
              let location = item['location'];
              let itemName = item['item'];
              let count = item['inventory_barcode_count'];

              if (itemName === null) continue;

              if (!(location in result)) {
                result[location] = {};
              }

              if (!(itemName in result[location])) {
                result[location][itemName] = 0;
              }

              result[location][itemName] += count;
            }
            setContractorComponents(result);
          }
        });

        socket.emit('getInventoryBins', 'BIN-SHREDDR', (res) => {
          if (!res) {
            console.error(`PaperInventory.getInventoryBins: There was an issue calling this method - ${new Date()}`);
          } else {
            setItems(res);
          }
        });
      }

      if (activeTab == TableTabs[3].filter) {
        retrieveCoreData();
      }
      activeTab == TableTabs[4].filter ? setReceiving(true) : setReceiving(false);
    }

    return () => {
      mounted = false;
    };
  }, [activeTab]);

  useEffect(() => {
    $('#stickers').toggleClass('flex-box');
  }, [modalOpen]);

  useEffect(() => {
    $('#job-prompt').toggleClass('flex-box');
  }, [promptOpen]);

  function handleBackButton() {
    setActiveTab('pending');
    handleURLParams([{ key: 'filter', val: 'pending' }], 'add');
  }

  const retrieveCoreData = () => {
    socket.emit('getPaperCores', (res) => {
      if (!res) {
        console.error(`PaperInventory.getPaperCores: There was an issue calling this method`);
      } else {
        setCores(res);
      }
    });
  };

  const retreiveData = (_load) => {
    if (_load) setLoading(true);
    const status_type = activeTab === 'receive' ? 'pending' : activeTab;
    socket.emit('getStickersReturned', status_type, (res) => {
      if (!res) {
        console.error(`PaperInventory.getStickersReturned: There was an issue calling this method`);
        setLoading(false);
      } else {
        setStickers(res.reverse());
        setDataRetrieved(true);
        setLoading(false);
      }
    });
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
    setAnchorIndex(null);
  };

  const handleURLParams = (_params, _action) => {
    const urlParams = new URLSearchParams(window.location.search);
    _params.forEach((param) => {
      if (_action === 'add') {
        urlParams.set(param.key, param.val);
      } else {
        urlParams.delete(param.key);
      }
    });
    navigate(`${window.location.pathname}?${urlParams.toString()}`, { replace: true });
  };

  const handleOptionSelect = (event) => {
    const [id, opt] = event.target.id.split('-');
    const job = stickers.find((el) => el.id == id);
    switch (opt) {
      case 'Edit':
        job.return_date = stickers.return_date;
        setSelected(job);
        onCloseMenu();
        toggleModal();
        break;
      case 'Remove':
        setSelected(job);
        onCloseMenu();
        togglePrompt();
        break;
    }
  };

  const handleOpenOptions = (event) => {
    const [pref, idx] = event.target.id.split('-');
    setAnchorEl(event.currentTarget);
    setAnchorIndex(idx);
  };

  const renderTablebody = () => {
    return stickers
      .map((sticker, index) => ({
        key: sticker.id,
        cells: [
          { data: DateToLocale(new Date(sticker.ServiceDate)) },
          { data: sticker.kiosk_id },
          { data: `PC${sticker.pc_id}` },
          { data: sticker.job_id },
          { data: sticker.technician },
          { data: sticker.sticker_count },
          { data: sticker.paper },
          { data: sticker.count_returned },
          {
            data:
              sticker.return_date && sticker.return_date != DATE_DEFAULT
                ? DateToLocale(new Date(sticker.return_date))
                : '',
          },
          { data: sticker.status.toUpperCase() },
          {
            data: (
              <div>
                <IconButton id={`option-${index}`} aria-haspopup="true" onClick={handleOpenOptions} size="large">
                  <MdMoreVert id={`option-${index}`} />
                </IconButton>
                {index == anchorIndex ? (
                  <Menu
                    id={`selected-menu-op-${index}`}
                    anchorEl={index == anchorIndex ? anchorEl : null}
                    open={index == anchorIndex ? Boolean(anchorEl) : false}
                    onClose={onCloseMenu}
                  >
                    {MenuOpts.map((opt) => (
                      <MenuItem id={`${sticker.id}-${opt.name}`} key={opt.name} onClick={handleOptionSelect}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : null}
              </div>
            ),
          },
        ],
      }))
      .filter((row) =>
        searchVal && searchParam
          ? row.cells[SearchOptions[searchParam - 1].pidx].data &&
            row.cells[SearchOptions[searchParam - 1].pidx].data
              .toString()
              .toUpperCase()
              .includes(searchVal.toUpperCase())
          : row
      );
  };

  const renderContractorBody = () => {
    return contractors
      .map((contractor) => {
        const components = contractorComponents[contractor.location];
        let componentCounts = '';

        if (components) {
          componentCounts = Object.entries(components).reduce((acc, [key, value]) => {
            return acc + `${key}: ${value}, `;
          }, '');
        }

        return {
          key: contractor.id,
          cells: [{ data: contractor.name }, { data: componentCounts }],
        };
      })
      .filter((row) =>
        searchVal && searchParam
          ? row.cells[SearchOptions2[searchParam - 1].pidx].data &&
            row.cells[SearchOptions2[searchParam - 1].pidx].data
              .toString()
              .toUpperCase()
              .includes(searchVal.toUpperCase())
          : row
      );
  };

  const renderCoreBody = () => {
    return cores
      .map((core) => ({
        key: core.id,
        cells: [
          { data: DateToLocale(new Date(core.assign_date)) },
          { data: `PC${core.id}` },
          { data: core.job_id ? core.job_id : '-' },
          { data: core.kiosk_id ? core.kiosk_id : '-' },
          { data: core.location ? core.location : '-' },
          { data: core.paper },
          { data: core.qty },
          { data: core.technician },
        ],
      }))
      .filter((row) =>
        searchVal && searchParam
          ? row.cells[SearchOptions3[searchParam - 1].pidx].data &&
            row.cells[SearchOptions3[searchParam - 1].pidx].data
              .toString()
              .toUpperCase()
              .includes(searchVal.toUpperCase())
          : row
      );
  };

  const onAddEntry = () => {
    setSelected({ add: true });
    toggleModal();
  };

  const handleTableDownload = () => {
    document.getElementById('table-download').click();
  };

  const renderTableButtons = () => {
    return activeTab == TableTabs[0].filter || (activeTab == TableTabs[1].filter && userState.user.role) ? (
      <div className="flex-align-center">
        <Button className="rti-blue-round" variant="contained" onClick={onAddEntry} startIcon={<MdAdd />}>
          Add Entry
        </Button>
        <Button className="rti-blue-round" variant="contained" onClick={handleTableDownload} startIcon={<MdGetApp />}>
          Download
        </Button>
        <CSVLink
          id="table-download"
          style={{ background: 'none', color: 'none', border: 'none' }}
          target="_blank"
          className="hidden"
          data={stickers}
          filename={`${activeTab} Paper Inventory.csv`}
        ></CSVLink>
      </div>
    ) : activeTab == TableTabs[2].filter &&
      userState.user
        .role ? null : //     <Button className='rti-blue-round' variant="contained" startIcon={<AddIcon />}>Contractor</Button> // <div className='flex-align-center'>
    // </div>
    activeTab == TableTabs[3].filter && userState.user.role ? (
      <div className="flex-align-center">
        <Button className="rti-blue-round" variant="contained" onClick={handleTableDownload} startIcon={<MdGetApp />}>
          Download
        </Button>
        <CSVLink
          id="table-download"
          style={{ background: 'none', color: 'none', border: 'none' }}
          target="_blank"
          className="hidden"
          data={cores}
          filename={`${activeTab} Inventory.csv`}
        ></CSVLink>
      </div>
    ) : null;
  };

  const Search = (param, searchVal) => {
    setSearchVal(searchVal);
    setSearchParam(param);
    handleURLParams(
      [
        { key: 'search', val: searchVal },
        { key: 'param', val: param },
      ],
      'add'
    );
  };

  const ClearSearch = () => {
    setSearchVal('');
    handleURLParams([{ key: 'search' }, { key: 'param' }], 'remove');
  };

  function toggleModal() {
    setModalOpen((prevState) => !prevState);
  }

  function togglePrompt() {
    setPromptOpen((prevState) => !prevState);
  }

  const errorHandler = (stc_id, technician, year, kiosk, location, binFound, returned, count) => {
    if (!stc_id && !technician) {
      setUploading(false);
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: Please select a technician.`,
        severity: 'warning',
      }));
    } else if (!binFound) {
      setUploading(false);
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: Our system doesn't recognize this bin location. Please enter a valid bin location and try again.`,
        severity: 'warning',
      }));
    } else if (!receiving && !kiosk) {
      setUploading(false);
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: Please enter a valid kiosk value.`,
        severity: 'warning',
      }));
    } else if (!receiving && !technician) {
      setUploading(false);
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: Please select a technician.`,
        severity: 'warning',
      }));
    } else if (!receiving && !count) {
      setUploading(false);
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: Quantity removed cannot be empty.`,
        severity: 'warning',
      }));
    } else if (!year) {
      setUploading(false);
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: Please select a valid sticker year.`,
        severity: 'warning',
      }));
    } else if (!receiving ? (location && !returned) || (returned && !location) : !location) {
      setUploading(false);
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: !receiving
          ? `WARNING: Destination and quantity returned cannot be empty.`
          : `WARNING: Destination cannot be empty.`,
        severity: 'warning',
      }));
    }
  };

  const insertStickerCoreTracking = (inputs, stickers, job_id, type) => {
    socket.emit('insertStickerTracking', stickers, job_id, async (res) => {
      if (!res) {
        alert('SERVER ERROR');
        console.error(`PaperInventory.insertStickerTracking: There was an issue calling this method`);
        setUploading(false);
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `SERVER ERROR: There was an issue calling this method. Please notify the developer of this issue.`,
          severity: 'error',
        }));
      } else {
        await updateIntoInventory(inputs, 'add');
        setUploading(false);
        retreiveData(false);
        !receiving ? (type ? togglePrompt() : toggleModal()) : window.location.reload(true);
        setReceivedCores([]);
        setSelected({});
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `Success! Sticker tracking added.`,
          severity: 'success',
        }));
      }
    });
  };

  const updateStickerCoreTracking = (inputs, stickers, type) => {
    socket.emit('updateStickerTracking', stickers, async (res) => {
      if (!res) {
        alert('SERVER ERROR');
        console.error(`PaperInventory.updateStickerTracking: There was an issue calling this method`);
        setUploading(false);
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `SERVER ERROR: There was an issue calling this method. Please notify the developer of this issue.`,
          severity: 'error',
        }));
      } else {
        await updateIntoInventory(inputs, 'add');

        setUploading(false);
        retreiveData(false);
        !receiving ? (type ? togglePrompt() : toggleModal()) : window.location.reload(true);
        setReceivedCores([]);
        setSelected({});
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `Success! Sticker tracking updated.`,
          severity: 'success',
        }));
      }
    });
  };

  async function onUpdate(controllerData, _type) {
    const year =
      controllerData && controllerData.paper
        ? controllerData.paper
        : document.getElementById('year')
        ? document.getElementById('year').value
        : selected.paper;
    const returned = document.getElementById('returned')
      ? document.getElementById('returned').value
      : selected && selected.count_returned
      ? selected.count_returned
      : controllerData && controllerData.count_returned
      ? controllerData.count_returned
      : null;
    const return_date = document.getElementById('return-date')
      ? document.getElementById('return-date').value
      : selected && selected.return_date
      ? selected.return_date
      : new Date().toLocaleDateString('fr-CA');
    const tech = document.getElementById('tech')
      ? document.getElementById('tech').value
      : selected && selected.technician
      ? selected.technician
      : null;
    const count =
      controllerData && controllerData.sticker_count
        ? controllerData.sticker_count
        : document.getElementById('on-hand')
        ? document.getElementById('on-hand').value
        : selected && selected.sticker_count
        ? selected.sticker_count
        : null;
    const location = document.getElementById('location') ? document.getElementById('location').value : null;
    const technician =
      controllerData && controllerData.technician
        ? Techs.find((el) => `${el.FirstName} ${el.LastName}`.toUpperCase() == controllerData.technician.toUpperCase())
        : Techs.find((el) => `${el.FirstName} ${el.LastName}`.toUpperCase() == tech.toUpperCase())
        ? Techs.find((el) => `${el.FirstName} ${el.LastName}`.toUpperCase() == tech.toUpperCase())
        : null;
    const kiosk =
      controllerData && controllerData.kiosk_id
        ? controllerData.kiosk_id
        : document.getElementById('kiosk')
        ? document.getElementById('kiosk').value
        : selected && selected.kiosk_id
        ? selected.kiosk_id
        : null;
    const stc_id = controllerData && controllerData.id ? controllerData.id : selected.id;
    const pc_id = controllerData && controllerData.pc_id ? controllerData.pc_id : selected.pc_id;
    const job_id = controllerData && controllerData.job_id ? controllerData.job_id : selected.job_id;
    const first = [...items].shift();
    const second = first[0];
    const model = await getItemByModel(`WI-${year}`);

    setUploading(true);

    let binFound = true;

    errorHandler(stc_id, technician, year, kiosk, location, binFound, returned, count);

    if (location) {
      binFound = await validateBinLocation(location);
    }

    const inputs = [
      {
        serial: '',
        imei: '',
        qty: receiving ? count : returned,
        location: location,
        model: `WI-${year}`,
        barcode: `PC${receiving ? pc_id : selected.pc_id}`,
        info: { serial: '', imei: '', phone: '', sim: '', password: '', firmware: '', carrier: '', part_num: '' },
        action: 'IN',
        user: localStorage.getItem('FullName'),
        refurb: 0,
        item_id: model.found ? model.item_id : null,
        year: year,
        count: count,
        returned: returned,
      },
    ];

    const stickers = {
      stc_id: stc_id,
      pc_id: pc_id,
      job_id: returned ? '' : job_id,
      count: count,
      kiosk: returned ? '' : kiosk,
      tech: returned ? '' : technician.TechnicianID,
      year: year,
      returned: returned ? returned : receiving ? count : null,
      return_date: returned ? return_date : new Date().toLocaleDateString('fr-CA'),
      status: receiving ? 'returned' : returned ? 'returned' : 'pending',
      user: localStorage.getItem('FullName'),
      location: location,
      receiving: receiving,
    };

    !receiving ? await calculateDiscrepancy(inputs, second) : null;

    stc_id || receiving
      ? updateStickerCoreTracking(inputs, stickers, _type)
      : !stc_id
      ? insertStickerCoreTracking(stickers, job_id, _type)
      : null;
  }

  const getItemByModel = (model) => {
    return new Promise((resolve, reject) => {
      socket.emit('getItemByModel', model, (res) => {
        if (!res) {
          console.error(`InventoryPart.getItemByModel: There was an issue calling this method`);
          reject(false);
        } else {
          if (!res.length) {
            resolve({ found: false });
          } else {
            resolve({ found: true, item_id: res[0].id, units: res[0].units });
          }
        }
      });
    });
  };

  const updateIntoInventory = (vals, action) => {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit('updateInventory', vals, vals[0].info, action, (res) => {
          if (!res) {
            console.error(`InventoryPart.updateIntoInventory: There was an issue calling this method`);
            reject(false);
          } else {
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `Success! Item ${
                action == 'add' ? 'added into inventory' : action == 'update' ? 'updated' : ''
              }.`,
              severity: 'success',
            }));

            resolve(true);
          }
        });
      } catch (error) {
        console.error(`InventoryPart.updateIntoInventory: ${error}`);
        reject(error);
      }
    });
  };

  function onRemoveStickers() {
    onUpdate('deleted');
  }

  const filterTable = (filter) => {
    setActiveTab(filter);
    handleURLParams([{ key: 'filter', val: filter }], 'add');
  };

  const handleCloseSnack = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const onRowClick = (event) => {
    if (activeTab == TableTabs[2].filter) {
      const id = event.target.id;
      const _contractor = contractors.find((contractor) => id == contractor.id);
      socket.emit('getStickerContractorInv', _contractor.id, (res) => {
        if (!res) {
          console.error(`PaperInventory.getStickerContractorInv: There was an issue calling this method`);
        } else {
          if (res.length) {
            const _binId = res[0];

            if (_binId) {
              navigate(`/inventory/bin/${_binId.code}`);
            }
          } else {
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `WARNING: This contractor does not have any sticker items assigned to them currently.`,
              severity: 'warning',
            }));
          }
        }
      });
    }
  };

  function onScanBulk(event) {
    if (event.keyCode === 13) {
      const barcode = document.getElementById('barcode').value;
      const valid = validateBulkScan(barcode);

      if (valid.valid === true) {
        const job = stickers.find((el) => el.pc_id == barcode.substring(2));
        !receivingYear.length ? setReceivingYear(job.paper) : null;
        !receivedCores.includes(job) ? setReceivedCores((prevState) => [...prevState, job]) : null;
        document.getElementById('barcode').value = '';
        document.getElementById('barcode').focus();
      } else {
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `WARNING: ${valid.msg}`,
          severity: 'warning',
        }));
      }
    }
  }

  function validateBulkScan(_barcode) {
    const barcode_int = _barcode.substring(2);
    document.getElementById('barcode').value = '';
    const found_sticker = stickers.find((el) => el.pc_id == barcode_int);
    if (!found_sticker) {
      return {
        valid: false,
        msg: `Oops! Looks like we can't find a pending record for this core. Please double check that you are scanning the correct barcode.`,
      };
    } else if (receivedCores.find((el) => el.pc_id == barcode_int)) {
      return {
        valid: false,
        msg: `Oops! Looks like you have already scanned this item in. Please double check that you are scanning the correct barcode.`,
      };
    } else if (receivedCores.length && found_sticker.paper !== receivingYear) {
      return {
        valid: false,
        msg: `Oops! Looks like you have ${receivingYear} stickers being received. Please double check that you are scanning the correct barcode.`,
      };
    } else {
      return { valid: true };
    }
  }

  function removeBulkItem(event) {
    const { id } = event.target;
    const removedCore = receivedCores.find((el) => el.id == id);
    const remaining_cores = receivedCores.filter((item) => item !== removedCore);
    setReceivedCores(receivedCores.filter((item) => item !== removedCore));

    updateStickerYear(remaining_cores);
  }

  const updateStickerYear = (remaining_cores) => {
    remaining_cores.length ? setReceivingYear(remaining_cores[0].paper) : setReceivingYear('');
  };

  async function calculateDiscrepancy(input, second) {
    const returned = input && input[0].returned ? parseInt(input[0].returned) : 0;
    const count = input && input[0].count ? parseInt(input[0].count) : 0;
    const discrepancy = count - returned;

    const inputs = [
      {
        qty: parseInt(discrepancy) > 0 ? parseInt(discrepancy) : 0,
        location: 'BIN-SHREDDR',
        barcode: input[0].model,
        model: input[0].model,
        info: input[0].info,
        action: 'IN',
        user: input[0].user,
        refurb: 0,
        item_id: second.item_id ? second.item_id : null,
        child_id: second.child_id ? second.child_id : null,
      },
    ];

    if (parseInt(discrepancy) > 0) {
      await updateIntoInventory(inputs, 'add');
    }
  }

  return (
    <div>
      <AlertBar
        visible={snackbar.visible}
        onClose={handleCloseSnack}
        message={snackbar.message}
        severity={snackbar.severity}
      />
      {activeTab == TableTabs[4].filter ? (
        <div className="flex-align-center item-field-container">{ReceiveCores(receivePaperProps)}</div>
      ) : (
        <div style={{ width: '100%' }} className="ViewedContentContainer" id="OpenContainer">
          <div style={{ height: '100%', width: '100%' }}>
            <div className="TitleBarContainer">
              <h1>Paper Inventory</h1>
            </div>

            {promptOpen ? RemovePaperModal(removePaperProps) : null}

            {modalOpen ? EditPaperModal(editPaperProps) : null}

            <CustomTable
              searchable
              paginate
              rpp={50}
              loading_data={loading}
              search={Search}
              clear_search={ClearSearch}
              headers={
                activeTab == TableTabs[0].filter || activeTab == TableTabs[1].filter
                  ? TableHeaders
                  : activeTab == TableTabs[2].filter
                  ? TableHeaders2
                  : TableHeaders3
              }
              search_options={
                activeTab == TableTabs[0].filter || activeTab == TableTabs[1].filter
                  ? SearchOptions
                  : activeTab == TableTabs[2].filter
                  ? SearchOptions2
                  : SearchOptions3
              }
              tabs={TableTabs}
              tab_style="button"
              active_tab={activeTab}
              filter_data={filterTable}
              rows={
                activeTab == TableTabs[0].filter || activeTab == TableTabs[1].filter
                  ? renderTablebody()
                  : activeTab == TableTabs[2].filter
                  ? renderContractorBody()
                  : renderCoreBody()
              }
              table_buttons={renderTableButtons()}
              onClick={onRowClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaperInventory;
