import React, { useState, useEffect } from 'react';
import '../../StyleSheets/BugReport.css';
import '../../StyleSheets/TMtable.css';
import CustomTable from '../../components/Table';
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner';
import Button from '@mui/material/Button';
import { RiDownloadLine } from 'react-icons/ri';
import { useCustomContext } from '../../hoc/Context';
import { CSVLink } from 'react-csv';
import { FormatDateToMDY } from '../API/TextFormatingFunctions';

const TableHeaders = [
    {id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false},
    {id: 'serviced', name: 'Last Serviced'},
    {id: 'drive-time', name: 'Avg. Drive Time', sortable: true, numeric: false},
    {id: 'work-time', name: 'Avg. Work Time', sortable: true, numeric: false},
]

const TableHeaders2 = [
    {id: 'date', name: 'Service Date', size: 'small-header'},
    {id: 'drive-time', name: 'Drive Time', sortable: true, numeric: false},
    {id: 'work-time', name: 'Work Time', sortable: true, numeric: false},
    {id: 'technician', name: 'Technician', size: 'small-header'},
    {id: 'job', name: 'Job #', size: 'small-header', sortable: true, numeric: false},
    {id: 'issue', name: 'Issue', sortable: true, numeric: false},
    {id: 'notes', name: 'Notes', size: 'xlarge-header'},
]

const SearchOptions = [
    {id: 'kiosk', name: 'Kiosk', pidx: 0}
]

const TableTabs = [
    {id: 7, name: 'Last 7 days', filter: 7},
    {id: 28, name: 'Last 28 days', filter: 28},
    {id: 60, name: 'Last 60 days', filter: 60},
    {id: 90, name: 'Last 90 days', filter: 90},
    {id: 180, name: 'Last 180 days', filter: 180},
    {id: 2, name: 'Last 2 years', filter: 2},
]

const ServiceReportAnalytics = (props) => {
    const [serviceReports, setServiceReports] = useState([]);
    const [subReports, setSubReports] = useState([]);
    const [ReportData, setReportData] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [searchParam, setSearchParam] = useState(0);
    const [activeTab, setActiveTab] = useState(TableTabs[5].id);
    const [Loading, setLoading] = useState(true);
    const [selected, setSelected] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const { userState, userDispatch } = useCustomContext();

    useEffect(() => {
        let mounted = true;
        if (mounted && !modalOpen) {
            socket.emit('selectSRForAnalytics', activeTab, 'none', null, (result) => {
                setServiceReports(result);
                setLoading(false);
            });
        }

        if (ReportData.length) {
            document.getElementById('download-report').click();
        }
        
        return () => {
            mounted = false;
        }
    }, [activeTab, ReportData.length]);

    const search = (param, searchVal) => {
        setSearchVal(searchVal);
        setSearchParam(param);
    }

    const clearSearch = () => {
        setSearchVal('');
    }

    const filterTable = (filter) => {
        setServiceReports([]);
        setLoading(true);
        setActiveTab(filter);
    }

    const handleModal = (e) => {
        setSubReports([]);
        const kiosk = e.target.id;

        socket.emit('selectSRForAnalytics', activeTab, 'kiosks', kiosk, (result) => {
            setSubReports(result);
            setSelected(kiosk);
            setModalOpen(true);
            $('#analytic-modal').toggleClass('flex-box');
        });
    }

    const generateReport = () => {
        const data = subReports.map(report => ({
            "Service Date": report.ServiceDate.split('T')[0],
            "Drive Time": report.DriveTime,
            "Work Time": report.WorkTime,
            "Technician": report.ServiceTech,
            "Job #": report.JobID,
            "Issue": report.KioskIssue,
            "Notes": report.Notes
        }))

        setReportData(data);
    }

    function renderTableButtons(){
        if (userState.user.role) {
            return (
                <div style={{display: 'flex', justifyContent:'flex-end'}}>
                    <CSVLink style={{ background: 'none', border: 'none' }} target="_blank" className="hidden" id="download-report" data={ReportData} filename={"kiosk-analytic-report.csv"} ></CSVLink>
                    <Button
                        className="rti-blue-round"
                        variant="contained"
                        startIcon={<RiDownloadLine />}
                        onClick={generateReport}>Download</Button>
                </div>
            );
        }

        return null;        
    }

    const handleModalClose = () => {
        setReportData([]);
        setModalOpen(false);
        $('#analytic-modal').toggleClass('flex-box');
    }

    const getTableData = () => {
        return serviceReports.map(report => ({
            key: report.KioskID,
            cells: [
                {data: report.KioskID},
                {data: FormatDateToMDY(report.last_serviced.split('T')[0])},
                {data: `${report.average_drive_time} Minutes`},
                {data: `${report.average_work_time} Minutes`}
            ]
        }))
        .filter(row => searchVal && searchParam ? row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase()) : row);
    }

    const getModalData = () => {
        return subReports.map(report => ({
            key: report.ServiceReportID,
            cells: [
                {data: FormatDateToMDY(report.ServiceDate.split('T')[0])},
                {data: report.DriveTime},
                {data: report.WorkTime},
                {data: report.ServiceTech},
                {data: report.JobID},
                {data: report.KioskIssue},
                {data: report.Notes},
            ]
        }))
    }

    return(
        <div className="ViewedContentContainer" id="OpenContainer" >
            <div style={{height: '100%'}}>
                <div className="TitleBarContainer">
                    <h1 title="Service report analytics">Service Report Analytics</h1>
                </div>
                <CustomTable 
                    searchable
                    paginate
                    loading_data={Loading}
                    tabs={TableTabs}
                    tab_style="select"
                    active_tab={activeTab}
                    filter_data={filterTable}
                    headers={TableHeaders}
                    rows={getTableData()}
                    search={search}
                    clear_search={clearSearch}
                    search_options={SearchOptions}
                    onClick={handleModal}
                />

                <Modal modal_id="analytic-modal" dimension={{width:'1090',height:'670'}} modal_header={selected} onClose={handleModalClose}>
                    <div style={{ textAlign: 'center', height: '80%' }}>
                        <CustomTable 
                            headers={TableHeaders2}
                            rows={getModalData()}
                            table_buttons={renderTableButtons()}
                            paginate
                            maxSize="medium"
                        />
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default ServiceReportAnalytics;
