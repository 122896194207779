/**
 * Creates a personalized logger to make logging more streamlined within the codebase.
 * 
 * @returns {Object} An object containing logging methods.
 * @returns {Function} returns.error - Logs an error message.
 * @param {string} returns.error._file - The file where the error occurred.
 * @param {string} returns.error._func - The function where the error occurred.
 * @param {string} returns.error._msg - The error message.
 * 
 * @returns {Function} returns.warn - Logs a warning message.
 * @param {string} returns.warn._file - The file where the warning occurred.
 * @param {string} returns.warn._func - The function where the warning occurred.
 * @param {string} returns.warn._msg - The warning message.
 * 
 * @returns {Function} returns.log - Logs a general message.
 * @param {string} returns.log._msg - The log message.
 * @param {string} [returns.log._func] - The function where the log occurred (optional).
 * @method logger
 */
// personalized logger to make logging more streamlined within the codebase.
export const logger = () => ({
    error: (_file, _func, _msg) => {
        console.error(`${_file}/${_func}: ${_msg}`);
    },
    warn: (_file, _func, _msg) => {
        console.warn(`${_file}/${_func}: ${_msg}`);
    },
    log: (_msg, _func) => {    // can add file name here, but not necessary
        // if function name is not available, then log message.
        console.log(_func ? `${_func}: ${_msg}` : _msg);
    }
});