import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { AiOutlineDelete } from 'react-icons/ai';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

export const ReceiveCores = (props) => {
    const {bins, receivedCores, stickers, handleBackButton, onScanBulk, removeBulkItem, onUpdate, receivingYear} = props
    const getFormInfo = () => {
        const location = document.getElementById('location') && document.getElementById('location') ? document.getElementById('location').value : ''
        const controllerData = {
            location: location,
            selected_item: document.getElementById('item') ? document.getElementById('item').value : '',
            'receivedCores': receivedCores
        };
        controllerData.receivedCores.map((el) => onUpdate(el, 'update'))
    }
    return (
        <div className='item-field-container'>
            <div className='bulk-title'>
                <h1>{`Receive Paper`}</h1>
                <p>
                    Please scan each individual barcode of the item you want to enter into inventory. Once complete, click <b>ENTER</b> to add the items into inventory.
                </p>
            </div>
            <div className='vert-margin-med grid-container modem-field-grid item-grid-auto-fit'>
                <div className='item-field-container full-width item-info-grid'>
                    <TextField id='barcode' autoFocus className='space-below item-field' label='RID #' placeholder='Scan items...' type='text' onKeyDown={onScanBulk} />

                    <TextField id='qty' disabled className='space-below item-field' label='Qty' value={receivedCores.length} type='number' />
                    <Autocomplete
                        freeSolo
                        id='location'
                        className='space-below item-field'
                        value={''}
                        disableClearable
                        options={bins.map((bin) => bin.name)}
                        renderInput={(params) => (
                            <TextField
                                className='space-below item-field'
                                {...params}
                                label='Destination'
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                </div>
                <TableContainer className='item-table' component={Paper}>
                    <Table stickyHeader aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className='item-buttons' align='center'>
                                    Scanned Items - {receivingYear}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {receivedCores.map((item) => (
                                <TableRow align='center' key={item.id}>
                                    <TableCell className='item-buttons' align='center' id={item.id}>
                                        <IconButton id={item.id} key={item.id} onClick={removeBulkItem} size="large">
                                            <AiOutlineDelete id={item.id} style={{ color: 'darkgray' }} />
                                        </IconButton>
                                        PC{item.pc_id}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className='flex-just-even vert-margin-med HOCContainer bottom-buttons'>
                <div>
                    <Button className='item-buttons' variant='outlined' color='secondary' onClick={handleBackButton}>
                        Back
                    </Button>
                </div>

                <div>
                    <Button className='item-buttons' variant='outlined' color='primary' onClick={getFormInfo}>
                        Enter
                    </Button>
                </div>
            </div>
        </div>
    );
}
