import React, { useState, useEffect} from 'react';
import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import AlertBar from '../../components/AlertBar';
import { FaPlus, FaEllipsisV } from 'react-icons/fa';
import Modal from '../../components/Modal';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const TableHeaders = [
    { id: 'question', name: 'question', sortable: true, numeric: false },
    { id: 'solution', name: 'solution', size: 'xlarge-header' },
    {id: '_blank', name: ''}
]

const SearchOptions = [
    { id: 'question', name: 'Question', pidx: 0 }
]

const MenuOptions = [
    "Edit",
    "Delete"
]

const RTISolutions = () => {
    const [solutions,setSolutions] = useState([]);
    const [deleting,setDeleting] = useState(false);
    const [loading,setLoading] = useState(true);
    const [edit,setEdit] = useState(false);
    const [anchorEl,setAnchorEl] = useState(null);
    const [anchorIndex,setAnchorIndex] = useState(null);
    const [selected,setSelected] = useState(null);
    const [adding,setAdding] = useState(false);
    const [searchVal,setSearchVal] = useState('');
    const [searchParam,setSearchParam] = useState('');
    const [open,setOpen] = useState(false);
    const [snackbar,setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    })

    useEffect(() => {
        retrieveData(true,'');
    },[]);
    
    useEffect(() => {
        // open hook
        $('#solution-modal').toggleClass('flex-box');
    },[open]);

    useEffect(() => {
        // edit hook
        $('#edit-solution').toggleClass('flex-box');
    },[edit]);

    useEffect(() => {
        // delete hook
        $('#delete-solution').toggleClass('flex-box');
    },[deleting]);

    const retrieveData = (_load, _query) => {
        if(_load) setLoading(true);

        socket.emit('getRTISolutions', _query,(res) => {
            if(!res){
                console.error(`RTISolutions.getRTISolutions: There was an issue calling this method`)
                setLoading(false);
            }else{
                setSolutions(res);
                setLoading(false);
            }
        });
    }

    const handleOpenMenu = (event) => {
        const [pref, index] = event.target.id.split('-');
        setAnchorIndex(index);
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setAnchorIndex(null);
    }

    const handleMenuSelect = (_event) => {
        const [sid, opt] = _event.target.id.split('-');        
        const solution = solutions.find(sol => sol.id == sid);

        switch(opt){
            case MenuOptions[0]: setSelected(solution); toggleEditModal();
                break;
            case MenuOptions[1]: setSelected(solution); toggleDeleteModal();
                break;
        }

        handleCloseMenu();
    }

    const getTableData = () => {
        return solutions.map((solution, index) => ({
            key: solution.id,
            cells: [
                { data: JSON.parse(solution.title) },
                { data: JSON.parse(solution.solution) },
                { data: (
                    <div>
                        <IconButton
                            id={`option-${ index }`}
                            aria-haspopup="true"
                            onClick={ handleOpenMenu }
                            size="large">
                            <FaEllipsisV id={ `option-${ index }` } />  {/* Replaced MoreVertIcon with FaEllipsisV */}
                        </IconButton>
                        {index == anchorIndex ? <Menu id={ `selected-menu-op-${ index }` } anchorEl={ index == anchorIndex ? anchorEl : null } open={ index == anchorIndex ? Boolean(anchorEl) : false } onClose={ handleCloseMenu }>
                            { MenuOptions.map(opt => (
                                <MenuItem id={ `${ solution.id }-${ opt }` } key={ opt } onClick={ handleMenuSelect }>
                                    { opt }
                                </MenuItem>
                            ))}
                        </Menu> : null }
                    </div>
                )}
            ]
        }))
        .filter(row => searchVal && searchParam ? row.cells[SearchOptions[searchParam - 1].pidx].data && row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase()) : row);
    }

    const renderTableButtons = () => {
        return (
            <div>
                <Button
                    className='rti-blue-round'
                    variant='contained'
                    startIcon={<FaPlus />}
                    onClick={toggleModal}
                >
                    solution
                </Button>
            </div>
        )
    }

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({
            ...prevState,
            visible: false
        }))
    }

    const search = (param, searchVal) => {
        setSearchVal(searchVal)
        setSearchParam(param)
    }

    const clearSearch = () => {
        setSearchVal('');
    }

    const toggleModal = () => {
        setOpen(prevState => !prevState);
    }

    const toggleEditModal = () => {
        setEdit(prevState => !prevState);
    }

    const toggleDeleteModal = () => {
        setDeleting(prevState => !prevState);
    }

    const addSolution = () => {
        const data = {
            title: document.getElementById('question').value,
            solution: document.getElementById('solution').value
        }

        if(data.title && data.solution){
            setAdding(true);
            socket.emit('addRTISolution', data, (res) => {
                if(!res){
                    console.error(`RTISolutions.addRTISolution: There was an issue calling this method`)
                    setSnackbar({
                        visible: true,
                        severity: 'error',
                        message: `SERVER ERROR: There was an issue adding this RTI solution. Please notify the developer of this issue.`
                    })
                    setAdding(false);
                }else{
                    setAdding(false);
                    toggleModal();
                    setSolutions(res[0]);
                }
            });
        }else{
            setSnackbar({
                visible: true,
                severity: 'warning',
                message: `Please check that all fields have been filled out!`
            })
        }
    }

    const handleEditSolution = () => {
        const data = {
            id: selected.id,
            title: document.getElementById('question').value,
            solution: document.getElementById('solution').value
        }

        setAdding(true);
        socket.emit('editRTISolution', data, (res) => {
            if(!res){
                console.error(`RTISolutions.editRTISolution: There was an issue calling this method`)
                setSnackbar({
                    visible: true,
                    severity: 'error',
                    message: `SERVER ERROR: There was an issue editing this RTI solution. Please notify the developer of this issue.`
                });
                setAdding(false);
            }else{
                retrieveData(false,'');
                setAdding(false);
                toggleEditModal();
            }
        });
    }

    const handleRemoveSolution = () => {
        socket.emit('removeRTISolution', selected.id, (res) => {
            if(!res){
                console.error(`RTISolutions.removeRTISolution: There was an issue calling this method`)
                setSnackbar({
                    visible: true,
                    severity: 'error',
                    message: `SERVER ERROR: There was an issue removing this RTI solution. Please notify the developer of this issue.`
                });
            }else{
                retrieveData(false,'');
                toggleDeleteModal();
            }
        });
    }

    return (
        <div className='ViewedContentContainer'>
            <div className='#this is the content container'>
                <div className='TitleBarContainer'>
                    <h1>Registration Technology F.A.Q.</h1>
                </div>

                <AlertBar
                    visible={ snackbar.visible }
                    onClose={ handleCloseSnack }
                    message={ snackbar.message }
                    severity={ snackbar.severity }
                />

                {open ?
                    <Modal modal_id='solution-modal' dimension={{width: 700}} modal_header="Add RTI Solution" onClose={ toggleModal }>
                        <div className='text-center flex-column flex-just-center flex-align-center vert-margin-med'>
                            <TextField id="question" style={{width: 300}} InputLabelProps={{ shrink: true }} label="Question" placeholder='How do I add a RTI solution?' type="text" />
                            <div className='flex-column flex-align-start vert-margin-med' style={{width: 300}}>
                                <label>Solution</label>
                                <textarea id="solution" style={{width: '100%', height: 250}}></textarea>
                            </div>
                            {!adding ? <div>
                                <Button className='rti-blue-round' variant="contained" onClick={ addSolution }>add RTI solution</Button>
                            </div> :
                            <div style={{width: '100%'}}>
                                <label>Adding RTI Solution</label>
                                <LinearProgress />
                            </div>
                            }
                        </div>
                    </Modal> : null
                }
                {deleting ?
                    <Modal modal_id='delete-solution' dimension={{width: 500}} onClose={ toggleDeleteModal }>
                        <div className='text-center flex-column flex-just-center flex-align-center vert-margin-med'>
                            <h2>Are you sure you want to remove this RTI solution?</h2>
                            <div className="flex-just-even vert-margin-med" style={{width: '50%'}}>
                                <Button color='secondary' variant="contained" onClick={ toggleDeleteModal }>no</Button>
                                <Button className='rti-blue-round' color="primary" variant="contained" onClick={ handleRemoveSolution }>yes</Button>
                            </div>
                        </div>
                    </Modal> : null
                }
                {edit ?
                    <Modal modal_id='edit-solution' dimension={{width: 700}} modal_header="Edit RTI Solution" onClose={ toggleEditModal }>
                        <div className='text-center flex-column flex-just-center flex-align-center vert-margin-med'>
                            <TextField id="question" style={{width: 300}} InputLabelProps={{shrink: true}} label="Question" placeholder='How do I add a RTI solution?' defaultValue={ JSON.parse(selected.title) } type="text" />
                            <div className='flex-column flex-align-start vert-margin-med' style={{width: 300}}>
                                <label>Solution</label>
                                <textarea id="solution" style={{width: '100%', height: 250}} defaultValue={ JSON.parse(selected.solution) }></textarea>
                            </div>
                            {!adding ? <div>
                                <Button className='rti-blue-round' variant="contained" onClick={ handleEditSolution }>save changes</Button>
                            </div> :
                            <div style={{width: '100%'}}>
                                <label>Updating Solution</label>
                                <LinearProgress />
                            </div>
                            }
                        </div>
                    </Modal> : null
                }
                <CustomTable
                    paginate
                    searchable
                    search={search}
                    clear_search={clearSearch}
                    search_options={SearchOptions}
                    loading_data={loading}
                    headers={TableHeaders}
                    rows={getTableData()}
                    table_buttons={renderTableButtons()}
                    onRefresh={() => retrieveData(true,'') }
                />
            </div>
        </div>
    )
}

export default RTISolutions;
