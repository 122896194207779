/**
 * Represents a component for time entry.
 *
 * @component
 * @param {Object} props - The properties for the component.
 * @returns - {JSX.Element} The rendered component.
 * @category User Interface
 */
import React, { useEffect, useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrentTime } from '../API/Moment.js';
import { FormatFullShortDate, FormatTime, FormatDateTime } from '../API/TextFormatingFunctions.js';
import axios from 'axios';
import '../../StyleSheets/TimeEntry.css';

const ENTRY_DEFAULT = '';

const TimeEntry = forwardRef((props, ref) => {
  const [punchInfo, setPunchInfo] = useState('');
  const [dontPunchIn, setDontPunchIn] = useState(false);
  const [fridayTime, setFridayTime] = useState('');
  const [monthTime, setMonthTime] = useState('');
  const [lastEntryId, setLastEntryId] = useState(null);
  const [lastAction, setLastAction] = useState('');
  const [lastEntry, setLastEntry] = useState(new Date());
  const navigate = useNavigate();
  const month = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      GetLastTimeEntry();
    }

    return () => {
      mounted = false;
    };
  }, []);

  /**
   * Generates a message based on the value of `punchInfo` and `lastEntry`.
   *
   * @returns - {string} The generated message.
   */
  const GenerateMessage = () => {
    if (punchInfo === 'first') {
      return 'This is your first time entry.';
    }

    const [datetime] = lastEntry.toString().split('.');
    const [date, time] = datetime.split('T');

    return `You last punched ${lastAction} on ${FormatFullShortDate(new Date(`${date} ${time} UTC`))} at ${FormatTime(
      new Date(`${date} ${time} UTC`)
    )}. ${CalculateTime(punchInfo)}.`;
  };

  /**
   * Calculates the time elapsed since a given time.
   *
   * @param {string} _time - The time in the format "HH:mm".
   * @returns - {string} - The formatted time elapsed in hours and minutes.
   */
  const CalculateTime = (_time) => {
    const [hours, minutes] = _time.split(':');
    return parseInt(hours) ? `${hours} hr(s) ${minutes} min(s) ago` : `${minutes} min(s) ago`;
  };

  /**
   * Unchecks the other checkbox option based on the clicked checkbox.
   * @param {Event} e - The event object representing the click event.
   */
  const UncheckOther = (e) => {
    let uncheck = 'punchInChoice';
    if (e.target.id === 'punchInChoice') {
      uncheck = 'punchOutChoice';
    }
    document.getElementById(uncheck).checked = false;
  };

  /**
   * Closes the time entry dialog and clears the time entry notes.
   */
  const TimeEntryClose = () => {
    document.getElementById('TimeEntryNotes').value = '';
    document.getElementById('TimeEntryDialog').style.display = 'none';
  };

  /**
   * Sends a time entry based on the selected punch-in or punch-out choice.
   * @async
   * @function TimeEntrySend
   * @returns - {Promise<void>}
   */
  const TimeEntrySend = async () => {
    document.getElementById('TimeEntrySendBtn').disabled = true;
    const id = lastEntryId;
    const fullName = props.user.fullName;
    const notes = document.getElementById('TimeEntryNotes').value.replace(/['`"?]/g, '');

    if (document.getElementById('punchInChoice').checked === true) {
      if (!dontPunchIn) {
        const entries = await GetEntryTotals(fullName);
        socket.emit('insertTimeEntry', fullName, notes, async (response) => {
          if (response) {
            SendTextMessage('Punching In', entries.last_entry + ' ago', notes, entries.weekly, entries.monthly);
            SendEmail('Punching In', entries.last_entry + ' ago', notes, entries.weekly, entries.monthly);
            document.getElementById('punchInChoice').checked = false;
            document.getElementById('punchOutChoice').checked = true;
            GetLastTimeEntry();
            TimeEntryClose();
          }
        });
      }
      setDontPunchIn(false);
    } else if (document.getElementById('punchOutChoice').checked === true) {
      const entries = await GetEntryTotals(fullName);
      socket.emit('updateTimeEntry', notes, id, async (response) => {
        if (response) {
          SendTextMessage('Punching Out', entries.last_entry, notes, entries.weekly, entries.monthly);
          SendEmail('Punching Out', entries.last_entry, notes, entries.weekly, entries.monthly);
          TimeEntryClose();
          RemoveUserCreds();
          navigate('/login');
        }
      });
    }
  };

  /**
   * Retrieves the entry totals for a given user.
   * @param {_user} _user - The user for whom to retrieve the entry totals.
   * @returns - {Promise} - A promise that resolves to an object containing the monthly, weekly, and last entry totals.
   * @throws {Error} - If there was an issue calling the method.
   */
  const GetEntryTotals = (_user) => {
    return new Promise((resolve, reject) => {
      socket.emit('GetEntryTotals', _user, ([monthly, weekly, last_entry]) => {
        if (!monthly && !daily && !last_entry) {
          console.error(`TimeEntry.GetEntryTotals: There was an issue calling this method`);
          reject(false);
        } else {
          resolve({
            monthly: `${monthly.length ? monthly[0].time_month : ENTRY_DEFAULT}`,
            weekly: `${weekly.length ? weekly[0].time_week : ENTRY_DEFAULT}`,
            last_entry: `${last_entry.length ? last_entry[0].time_current : ENTRY_DEFAULT}`,
          });
        }
      });
    });
  };

  /**
   * Removes user credentials from local storage.
   */
  const RemoveUserCreds = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('FullName');
    localStorage.removeItem('local-dispatch-console-user');
  };

  /**
   * Sends a text message with the specified parameters.
   *
   * @param {string} _type - The type of the message.
   * @param {string} _work_time - The work time.
   * @param {string} _notes - The notes for the message.
   * @param {string} _week - The total time worked this week.
   * @param {string} _month - The total time worked since the beginning of the month.
   */
  const SendTextMessage = (_type, _work_time, _notes, _week, _month) => {
    let msgList = JSON.parse(props.lists.MsgTo); // MsgTo get the number, MsgList get the name
    msgList.push(props.user.phoneNumber);
    let date = '';

    let mobileModeSend = msgList;

    let workTime = `Last Worked ${_work_time}.`;

    if (fridayTime !== null) {
      date = FormatDateTime(new Date());
      if (_type === 'Punching Out') {
        workTime =
          `Worked for ${_work_time}. \n` +
          `Total time worked this week: (${_week}). \n` +
          `Total time worked since ${month[new Date().getMonth()]} 1st (${_month}).`;
      }
    } else {
      date = FormatDateTime(new Date());
      if (_type === 'Punching Out') {
        workTime =
          `Worked for ${_work_time}. \n` +
          `Total time worked this week: (${_week}). \n` +
          `Total time worked since ${month[new Date().getMonth()]} 1st (${_month}).`;
      }
    }

    let msg = `${props.user.fullName} \n` + `${_type} \n` + `${date} \n` + `${workTime} \n\n` + `${_notes} \n`;
    for (let i = 0; i < mobileModeSend.length; i++) {
      const mobileModeMsg = {
        body: `Time Entry \n ${msg}`,
        to: mobileModeSend[i],
        from: `Auto Msg`,
        time: CurrentTime(),
        type: 'admin',
      };
      axios.post('/api/send_message_async', { messages: mobileModeMsg }, (response) => {
        return;
      });
    }
  };

  /**
   * Sends an email with the specified parameters.
   *
   * @param {string} _type - The type of the email.
   * @param {string} _work_time - The work time.
   * @param {string} _notes - The notes for the email.
   * @param {string} _week - The total time worked this week.
   * @param {string} _month - The total time worked since the beginning of the month.
   */
  const SendEmail = (_type, _work_time, _notes, _week, _month) => {
    let workTime = `Last Worked ${_work_time}.`;
    let date = '';

    if (fridayTime !== null) {
      date = FormatDateTime(new Date());
      if (_type === 'Punching Out') {
        workTime =
          `Worked for ${_work_time}. \n` +
          `Total time worked this week: (${_week}). \n` +
          `Total time worked since ${month[new Date().getMonth()]} 1st (${_month}).`;
      }
    } else {
      date = FormatDateTime(new Date());
      if (_type === 'Punching Out') {
        workTime =
          `Worked for ${_work_time}. \n` +
          `Total time worked this week: (${fridayTime}). \n` +
          `Total time worked since ${month[new Date().getMonth()]} 1st (${_month}).`;
      }
    }

    let to = props.lists.EmailTo;
    let text =
      `<div width="100%",style="text-align:center;">` +
      `<h1 style="text-align:center;">Time Entry</h1>` +
      `<table style="background-color:black; width: 400px;">` +
      `<tbody>` +
      `<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${props.user.fullName}</td></tr>` +
      `<tr><td style="background-color:bisque;">Type</td><td style="background-color:white;">${_type}</td></tr>` +
      `<tr><td style="background-color:bisque;">Submitted</td><td style="background-color:white;">${date}</td></tr>` +
      `<tr><td style="background-color:bisque;">Time</td><td style="background-color:white;">${workTime}</td></tr>` +
      `<tr><td style="background-color:bisque;">Notes</td><td style="background-color:white;">${_notes}</td></tr>` +
      `</tbody>` +
      `</table >` +
      `<p>-Mobile Mode</p>` +
      `</div >`;
    axios.get(
      '/send',
      { params: { to: to, subject: `RTI Time Entry - ${props.user.fullName}`, text: text } },
      function (data) {}
    );
  };

  /**
   * Retrieves the last time entry for the user.
   * @function GetLastTimeEntry
   * @returns - {void}
   */
  const GetLastTimeEntry = () => {
    const _user = props.user.fullName;
    socket.emit('GetEntryTotals', _user, ([monthly, weekly, last_entry]) => {
      if (!monthly && !daily && !last_entry) {
        console.error(`TimeEntry.GetEntryTotals: There was an issue calling this method`);
      } else {
        let uncheck = 'punchInChoice';
        if (last_entry[0].last_punch === 'in') {
          uncheck = 'punchOutChoice';
        }
        if (last_entry[0].last_punch === 'first') {
          document.getElementById('punchOutChoice').disabled = true;
        }
        const uncheckElement = document.getElementById(uncheck);
        if (uncheckElement) {
          uncheckElement.checked = true;
        }
        setLastEntry(last_entry.length ? last_entry[0].last_entry : new Date());
        setLastAction(last_entry.length ? last_entry[0].last_punch : '');
        setLastEntryId(last_entry.length ? last_entry[0].entry_id : ENTRY_DEFAULT);
        setPunchInfo(`${last_entry.length ? last_entry[0].time_current : ENTRY_DEFAULT}`);
        setFridayTime(`${weekly.length ? weekly[0].time_week : ENTRY_DEFAULT}`);
        setMonthTime(`${monthly.length ? monthly[0].time_month : ENTRY_DEFAULT}`);
      }
    });
  };

  return (
    <div className="CustomDialog" id="TimeEntryDialog" ref={ref} style={{ width: '294px', zIndex: '102' }}>
      <h1>Time Entry</h1>
      <p id="TimeEntryMessageP" style={{ fontSize: '12px', fontWeight: 'bold', color: '#000000' }}>
        Please be punched in at your desk and ready for work <label style={{ color: '#e14658' }}>10 minutes</label>{' '}
        prior to your scheduled times - Thank You!
      </p>
      <p>Hello {props.user.fullName},</p>
      <p>{GenerateMessage()}</p>
      <p>Total time worked this week: {fridayTime}</p>
      <p>
        Total time worked since {month[new Date().getMonth()]} 1st: {monthTime}
      </p>
      <input type="radio" id="punchInChoice" name="punchInChoice" value="Punch In" onClick={UncheckOther} />
      <label name="punchInChoice">Punch In</label>
      <br />
      <input type="radio" id="punchOutChoice" name="punchOutChoice" value="Punch Out" onClick={UncheckOther} />
      <label name="punchOutChoice">Punch Out</label>
      <h3>Notes</h3>
      <textarea className="TimeEntryNotes" id="TimeEntryNotes"></textarea>
      <div>
        <button className="RTIButton" id="TimeEntryClose" onClick={TimeEntryClose}>
          Close
        </button>
        <button
          className="RTIButton"
          id="TimeEntrySendBtn"
          disabled={false}
          onClick={TimeEntrySend}
          style={{ float: 'right' }}
        >
          Send
        </button>
      </div>
    </div>
  );
});

export default TimeEntry;
