/**
 * KioskAlerts component manages and displays alerts for kiosks.
 * 
 * @component
 * @param {Object} props - The properties object.
 * @param {Function} props.SetKioskIDIssueForCreateJob - Function to set the Kiosk ID issue for creating a job.
 * @param {Object} props.alertInfo - Information about the alert.
 * @param {Array} props.issues - List of issues.
 * @param {Array} props.CallLogHistory - History of call logs.
 * 
 * @returns {JSX.Element} The rendered KioskAlerts component.
 * 
 * @example
 * <KioskAlerts 
 *   SetKioskIDIssueForCreateJob={SetKioskIDIssueForCreateJob} 
 *   alertInfo={alertInfo} 
 *   issues={issues} 
 *   CallLogHistory={CallLogHistory} 
 * />
 * 
 * @function
 * @name KioskAlerts
 * 
 * @description
 * This component fetches alert data from the database, updates the state, and renders the alerts.
 * It also handles new, clear, and incomplete alerts by playing corresponding sounds and updating the alert data.
 * 
 * @property {Array} kioskAlertData - State to store the alert data.
 * @property {boolean} openAlertsNavi - State to manage the visibility of the alerts navigation.
 * @property {boolean} alertStatus - State to manage the alert status.
 * 
 * @method
 * @name PullDataFromDatabaseAndUpdateAlerts
 * @description Fetches alert data from the database and updates the state.
 * @param {string} alertType - Type of the alert ('new', 'clear', 'incomplete').
 * 
 * @method
 * @name handleNewAlert
 * @description Handles new alert event.
 * 
 * @method
 * @name handleClearAlert
 * @description Handles clear alert event.
 * 
 * @method
 * @name handleIncompleteAlert
 * @description Handles incomplete alert event.
 * 
 * @method
 * @name playAudio
 * @description Plays the specified audio.
 * @param {string} audioId - The ID of the audio element to play.
 * 
 * @method
 * @name OpenAndFocusAlert
 * @description Opens and focuses on the specified alert.
 * @param {Event} e - The event object.
 * 
 * @method
 * @name OpenCloseKioskAlerts
 * @description Toggles the visibility of the kiosk alerts.
 * 
 * @method
 * @name RenderKioskAlertsVisible
 * @description Renders the visible kiosk alerts.
 * 
 * @method
 * @name RenderKioskAlertsHidden
 * @description Renders the hidden kiosk alerts.
 * 
 * @method
 * @name SetBackgroundColor
 * @description Sets the background color based on the alert status.
 * @param {string} string - The string to determine the color.
 * @param {string} colorFor - The context for the color ('AlertID', 'Alert').
 * @param {string} kioskstatus - The status of the kiosk.
 * 
 * @method
 * @name GetAlertColor
 * @description Gets the color for the alert based on its status.
 * @param {Object} alert_info - The alert information.
 * @returns {string} The color for the alert.
 */
import React, { useState, useEffect } from 'react';
import KioskAlert from './KioskAlert.js';
import alert from '../../../public/Images/alert.png';
import AlertSound from '../../../public/Sounds/AlertSound.mp3';
import ClearAlertSound from '../../../public/Sounds/ClearAlertSound.mp3';
import IncompleteAlertSound from '../../../public/Sounds/IncompleteAlertSound.mp3';
import '../../StyleSheets/KioskAlerts.css';
import axios from 'axios';
import { isArray } from 'lodash';

const KioskAlerts = (props) => {
  const [kioskAlertData, setKioskAlertData] = useState([]);
  const [openAlertsNavi, setOpenAlertsNavi] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);

  useEffect(() => {
    PullDataFromDatabaseAndUpdateAlerts('nothing'); // gets initial data

    socket.on('newAlert', handleNewAlert);
    socket.on('clearAlert', handleClearAlert);
    socket.on('incompleteAlert', handleIncompleteAlert);

    return () => {
      socket.off('newAlert', handleNewAlert);
      socket.off('clearAlert', handleClearAlert);
      socket.off('incompleteAlert', handleIncompleteAlert);
    };
  }, []);

  const handleNewAlert = () => {
    setKioskAlertData([]);
    setAlertStatus(true);
    PullDataFromDatabaseAndUpdateAlerts('new');
  };

  const handleClearAlert = () => {
    setKioskAlertData([]);
    setAlertStatus(true);
    PullDataFromDatabaseAndUpdateAlerts('clear');
  };

  const handleIncompleteAlert = () => {
    setKioskAlertData([]);
    setAlertStatus(true);
    PullDataFromDatabaseAndUpdateAlerts('incomplete');
  };

  // created 24JUL26
  const PullDataFromDatabaseAndUpdateAlerts = async (alertType) => {
    socket.emit('selectFromKioskAlert', async (result) => {
      if (result === undefined || result === null) {
        console.error(
          `ERROR: KioskAlerts.PullDataFromDatabaseAndUpdateAlerts: There was an issue retrieving kiosk alert data!`
        );
      } else {
        if (result.length === 0) {
          kioskAlertData;
        } else {
          const zips = result.map((e) => e.StoreZip);

          try {
            const response = await axios.post(`/api/weather_data`, { zips: zips });

            if (response.status === 200 && zips.length) {
              let temp = [...result];
              const { data } = response.data;

              Object.values(temp).forEach((alert) => {
                alert.Temp = data[alert.StoreZip].temp;
                alert.Icon = data[alert.StoreZip].icon;
                alert.Weather = data[alert.StoreZip].weather;
              });

              setKioskAlertData(temp);
            } else {
              setKioskAlertData(result);
            }

            if (alertType === 'new') {
              playAudio('AlertSound');
            }

            if (alertType === 'clear') {
              playAudio('ClearAlertSound');
            }

            if (alertType === 'incomplete') {
              playAudio('IncompleteAlertSound');
            }
          } catch (error) {
            console.error(`ERROR: fetching weather data: ${error}`);
            setKioskAlertData(result);
          }
        }
      }
    });
  };

  // const PullDataFromDatabaseAndUpdateAlerts = async (alertType) => {
  //   socket.emit('selectFromKioskAlert', (result) => {
  //     if (!result || result.length === 0) {
  //       console.error(
  //         `ERROR: KioskAlerts.PullDataFromDatabaseAndUpdateAlerts: There was an issue retrieving kiosk alert data!`
  //       );
  //     } else {
  //       const zips = result.map((e) => e.StoreZip);

  //       axios.post(`/api/weather_data`, { zips: zips }, (weather) => {
  //         if (weather.status === 200 && zips.length) {
  //           const data = weather.data;
  //           let temp = [...result];
  //           if (temp && temp.length) {
  //             temp.forEach((alert) => {
  //               alert.Temp = data[alert.StoreZip].temp;
  //               alert.Icon = data[alert.StoreZip].icon;
  //               alert.Weather = data[alert.StoreZip].weather;
  //             });
  //             setKioskAlertData(temp);
  //           } else {
  //             setKioskAlertData(result);
  //           }
  //         } else {
  //           setKioskAlertData(result);
  //         }

  //         if (alertType === 'new') {
  //           playAudio('AlertSound');
  //         }

  //         if (alertType === 'clear') {
  //           playAudio('ClearAlertSound');
  //         }

  //         if (alertType === 'incomplete') {
  //           playAudio('IncompleteAlertSound');
  //         }
  //       });
  //     }
  //   });
  // };

  const playAudio = (audioId) => {
    let audio = document.getElementById(audioId);
    audio.muted = false;
    audio.play();
  };

  const OpenAndFocusAlert = (e) => {
    OpenCloseKioskAlerts();
    const AlertContainer = document.getElementsByClassName('AlertContainer');
    for (let i = 0; i < AlertContainer.length; i++) {
      if (AlertContainer[i].id === e.target.id) {
        AlertContainer[i].scrollIntoView();
        break;
      }
    }
  };

  const OpenCloseKioskAlerts = () => {
    try {
      const ToDoNext = document.getElementsByClassName('ToDoNext');
      for (let i = 0; i < ToDoNext.length; i++) {
        let AlertID = ToDoNext[i].id.substring(1);
        ToDoNext[i].style.backgroundColor = document.getElementById(AlertID).style.backgroundColor;
      }

      const windowWidth = window.innerWidth;
      let AlertsWidth = 400;

      if (AlertsWidth + 56 > windowWidth) {
        AlertsWidth = windowWidth - 56;
      }

      if (!openAlertsNavi) {
        document.getElementById('AlertImg').style.backgroundColor = '';
        document.getElementById('KioskAlertsContainer').style.width = AlertsWidth + 56 + 'px';
        document.getElementById('KioskAlertsHidden').style.width = AlertsWidth + 'px';
        document.getElementById('KioskAlertsHidden').style.paddingRight = '15px';
        setOpenAlertsNavi(true);
        setAlertStatus(false);
      } else {
        document.getElementById('KioskAlertsContainer').style.width = '56px';
        document.getElementById('KioskAlertsHidden').style.width = '0';
        document.getElementById('KioskAlertsHidden').style.paddingRight = '0';
        setOpenAlertsNavi(false);
        setAlertStatus(false);
      }
    } catch (error) {
      console.error(`ERROR: KioskAlerts.OpenCloseKioskAlerts: ${error} - ${new Date()}`);
    }
  };

  const RenderKioskAlertsVisible = () => {
    return kioskAlertData.map((kioskAlert, index) => (
      <button
        className="KioskAlertSideButton"
        id={kioskAlert.AlertID}
        key={index}
        onClick={OpenAndFocusAlert}
        style={SetBackgroundColor(
          JSON.parse(kioskAlert.ToDoNext)[kioskAlert.StepAlertIsAt],
          'AlertID',
          kioskAlert.KioskStatus
        )}
      >
        {kioskAlert.KioskID}{' '}
      </button>
    ));
  };

  const SetBackgroundColor = (string, colorFor, kioskstatus) => {
    if (kioskstatus === 'Cleared') {
      return { backgroundColor: '#80ff80' };
    } else if (string) {
      if (colorFor === 'AlertID') {
        if (string.substring(0, 4) === 'Wait') {
          return { backgroundColor: '#ffff80' };
        }
        return { backgroundColor: '#ff8080' };
      } else if (colorFor === 'Alert') {
        return { backgroundColor: '#ffbaba' };
      }
    }
  };

  const RenderKioskAlertsHidden = () => {
    return kioskAlertData.map((kioskAlert, index) => (
      <KioskAlert
        key={index}
        color={GetAlertColor(kioskAlert)}
        onClose={OpenCloseKioskAlerts}
        kioskAlert={kioskAlert}
        SetKioskIDIssueForCreateJob={props.SetKioskIDIssueForCreateJob}
        indexnum={index}
        alertInfo={props.alertInfo}
        issues={props.issues}
        callLogs={props.CallLogHistory}
      />
    ));
  };

  const GetAlertColor = (alert_info) => {
    const issue_description = JSON.parse(alert_info.ToDoNext);
    const current_issue = issue_description[alert_info.StepAlertIsAt];
    return alert_info.KioskStatus === 'Cleared'
      ? '#80ff80'
      : current_issue.substring(0, 4) === 'Wait'
      ? '#ffff80'
      : '#ff8080';
  };

  return (
    <div className="KioskAlertsContainer" id="KioskAlertsContainer">
      <div className="GreyBackground" id="GreyBackground"></div>
      <div className="KioskAlertsVisible" id="KioskAlertsVisible">
        <img
          className={`AlertImg ${alertStatus ? 'flash' : ''}`}
          id="AlertImg"
          src={alert}
          alt="Alert"
          title="Kiosk Alerts"
          onClick={OpenCloseKioskAlerts}
        />
        {RenderKioskAlertsVisible()}
      </div>
      <div className="KioskAlertsHidden" id="KioskAlertsHidden">
        {RenderKioskAlertsHidden()}
      </div>
      <audio id="AlertSound" src={AlertSound} muted={true}></audio>
      <audio id="ClearAlertSound" src={ClearAlertSound} muted={true}></audio>
      <audio id="IncompleteAlertSound" src={IncompleteAlertSound} muted={true}></audio>
    </div>
  );
};

export default KioskAlerts;
