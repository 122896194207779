import React from 'react';

/**
 * @description component that displays generic not found page
 * @function NotFound
 * @returns - generic 404 page
 */
const NotFound = () => {
    return(
        <div className='not-found'>
            <h1>404 Page not found</h1>
            <h3>The page you are looking for does not exist</h3>
        </div>
    )
}

export default NotFound