import React, { useState, useEffect } from 'react';
import CustomTable from '../../../components/Table';
import { DateToLocale } from '../../API/TextFormatingFunctions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdMoreVert } from 'react-icons/md';

const TableHeaders = [
	{ id: 'created', name: 'Created' },
	{ id: 'job', name: 'Job #', sortable: true, numeric: false },
	{ id: 'user', name: 'User', sortable: true, numeric: false },
	{ id: 'paper', name: 'Paper Year' },
	{ id: 'status', name: 'Status', sortable: true, numeric: false },
	{ id: 'components', name: 'Components', sortable: true, numeric: false },
	{ id: 'contractor', name: 'Contractor', sortable: true, numeric: false },
	{ id: 'options-column', name: ' ', sortable: false, numeric: false },
];

const SearchOptions = [
	{ id: 'year', name: 'Sticker Year', pidx: 0 },
	{ id: 'user', name: 'User', pidx: 9 },
];

export const StickerContractorJobs = (props) => {
	const {
		ClearSearch,
		Search,
		loading,
		stickerContractorJobs,
		toggleContractor,
		handleContractorOptionSelect,
		handleOpenContractorOptions,
		handleContractorOptionClose,
		anchorEl,
		contractorOptionsOpen,
		toggleBuild,
		activeTab,
		TableTabs,
		filterTable,
		RefreshTableData,
	} = props;

	const MenuOptions = activeTab === 'Pending' ? ['Mark as Completed', 'Delete Job'] : ['Delete Job'];

	function renderTableButtons() {
		return (
            <div style={{ display: 'flex' }}>
				<Button className='rti-blue-round' variant='contained' onClick={toggleContractor}>
					Assign Job
				</Button>
			</div>
        );
	}

	const rowClick = () => {
		toggleBuild();
	};

	const renderTablebody = () => {
		return stickerContractorJobs.map((job) => {
			// Parse components string into an array
			let components = activeTab === 'Pending' ? JSON.parse(job.remaining_components) : JSON.parse(job.components);
	
			// Count occurrences of each item in the components array
			let componentCounts = [];
			const count =
			components && components.length
			? components.reduce(function (obj, v) {
				if (v.item_name.includes("Repurposed")) {
					obj[v.item_name] = (obj[v.item_name] || 0) + v.qty;
					} else {
						obj[v.item_name] = (obj[v.item_name] || 0) + 1;
					}
					return obj;
				}, {})
				: 'N/A';
		
		for (const [key, value] of Object.entries(count)) {
			componentCounts.push(`${key}: ${value}`);
		}
		
			return {
				key: job.job_id,
				cells: [
					{ data: DateToLocale(new Date(job.created)) },
					{ data: job.job_id },
					{ data: job.user !== null ? job.user : 'N/A' },
					{ data: job.sticker_year !== null ? job.sticker_year : 'N/A' },
					{ data: job.status ? job.status : 'N/A' },
					{ data: componentCounts.length ? `${[...componentCounts]}` : 'N/A' },
					{ data: job.contractor ? job.contractor : 'N/A' },
					{
						data: (
							<div>
								<IconButton
                                    style={{ padding: '10px' }}
                                    aria-label='more'
                                    aria-controls='long-menu'
                                    aria-haspopup='true'
                                    onClick={(event) => handleOpenContractorOptions(event, job.job_id)}
                                    size="large">
									<MdMoreVert />
								</IconButton>
								<Menu
									id='long-menu'
									anchorEl={anchorEl}
									open={contractorOptionsOpen}
									onClose={handleContractorOptionClose}
									PaperProps={{
										style: {
											maxHeight: 48 * 4.5,
											width: '20ch',
										},
									}}>
									{MenuOptions.map((option) => (
										<MenuItem
											key={option}
											onClick={() => handleContractorOptionSelect(job.job_id, option)}>
											{option}
										</MenuItem>
									))}
								</Menu>
							</div>
						),
					},
				],
			};
		});
	};
	

	return (
		<div>
			<CustomTable
				searchable
				paginate
				rpp={50}
				loading_data={loading}
				search={Search}
				clear_search={ClearSearch}
				headers={TableHeaders}
				search_options={SearchOptions}
				rows={renderTablebody()}
				filter_data={filterTable}
				// onClick={rowClick}
				active_tab={activeTab}
				onRefresh={RefreshTableData}
				tabs={TableTabs}
				tab_style='button'
				table_buttons={renderTableButtons()}
			/>
		</div>
	);
};
