// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tech-job-wrapper {
    font-size: 16px;
    width: 75%;
}

.job-tbl-wrapper {
    margin: 50px 0px;
}

.job-tbl-wrapper h1 {
    margin: 0;
}

#tech-job-left {
    width: 100%;
}

#tech-job-right {
    width: 500px;
}

/* @media screen and (max-width: 650px) {
    #tech-job-left {
        width: 70%;
    }

    #tech-job-right {
        width: 70%;
    }
} */`, "",{"version":3,"sources":["webpack://./src/StyleSheets/TechJobs.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;;;;;;;;GAQG","sourcesContent":[".tech-job-wrapper {\n    font-size: 16px;\n    width: 75%;\n}\n\n.job-tbl-wrapper {\n    margin: 50px 0px;\n}\n\n.job-tbl-wrapper h1 {\n    margin: 0;\n}\n\n#tech-job-left {\n    width: 100%;\n}\n\n#tech-job-right {\n    width: 500px;\n}\n\n/* @media screen and (max-width: 650px) {\n    #tech-job-left {\n        width: 70%;\n    }\n\n    #tech-job-right {\n        width: 70%;\n    }\n} */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
