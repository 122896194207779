import React from 'react';
import Modal from '../../../components/Modal';
import { TextField, Button } from '@mui/material';

export const ItemLocationModal = (props) => {
	const { toggleLocation, handleUpdateLocation } = props;
	return (
		<div>
			{' '}
			<Modal modal_id='location-modal' dimension={{ width: 400 }} modal_header='Choose a Location' onClose={toggleLocation}>
				<div className='vert-margin-med flex-column flex-align-center'>
					<div>
						<TextField id='bin-location' InputLabelProps={{ shrink: true }} label='Bin Location' placeholder='5A-1...' type='text' />
					</div>
					<div className='vert-margin-med'>
						<Button className='rti-blue-round' variant='contained' onClick={handleUpdateLocation}>
							Update
						</Button>
					</div>
				</div>
			</Modal>{' '}
		</div>
	);
};
