import React from 'react';
import { ScannedItemsTable } from './ScannedItemsTable';
import { ScannedItemsInput } from './ScannedItemsInput';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { MdDeleteOutline } from 'react-icons/md';  // Updated import from react-icons
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

export const BulkInventoryController = (props) => {
    const { item, form, bins, bulkScan, onScanBulk, removeBulkItem, onAddItem } = props;
    const getFormInfo = () => {
        const location = document.getElementById('location') && document.getElementById('location') ? document.getElementById('location').value : '';
        const controllerData = {
            location: location,
            selected_item: document.getElementById('item') ? document.getElementById('item').value : '',
        };
        onAddItem(controllerData);
    };
    return (
        <div className='item-field-container'>
            <div className='bulk-title'>
                <h1>{item.item}</h1>
                <p>
                    Please scan each individual barcode of the item you want to enter into inventory. Once complete, click <b>ENTER</b> to add the items into inventory.
                </p>
            </div>

            <div className='vert-margin-med grid-container modem-field-grid item-grid-auto-fit'>
                <div className='item-field-container full-width item-info-grid'>
                    <ScannedItemsInput
                        onBarcodeKeyDown={onScanBulk}
                        qtyValue={bulkScan.length}
                        locationValue={form && form.location}
                        locationOptions={bins.map((bin) => bin.name)}
                    />

                    {!item.barcode ? (
                        <div className='search-by'>
                            <input
                                style={{ padding: '2px 0px', margin: 0, width: 200 }}
                                className='table-date-input space-below'
                                id='item'
                                list='item-list'
                                name='item-list-dropdown'
                                placeholder='Search items...'></input>
                            <datalist id='item-list'>
                                {bulk
                                    .sort((a, b) => (a.item_name < b.item_name ? -1 : a.item_name > b.item_name ? 1 : 0))
                                    .map((item) => (
                                        <option
                                            key={item.id}
                                            value={item.item_name}>
                                            {item.item_name}
                                        </option>
                                    ))}
                            </datalist>
                        </div>
                    ) : null}
                </div>
                <ScannedItemsTable
                    data={bulkScan}
                    removeItem={removeBulkItem}
                />
            </div>
            <div className='flex-just-even vert-margin-med full-width'>
                <div>
                    <Button
                        className='item-buttons'
                        variant='outlined'
                        color='primary'
                        onClick={getFormInfo}>
                        Enter
                    </Button>
                </div>
            </div>
        </div>
    );
};
