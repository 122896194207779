import { FormatDate, FormatDate2, FormatTime } from '../API/TextFormatingFunctions.js';

const Job = (props) => {
  const jobColor =
    props.jobBody.JobStatus === 'Awaiting ETAs' && !props.jobBody.Responses
      ? { backgroundColor: '#ffff80' }
      : props.jobBody.JobStatus === 'Awaiting ETAs' && props.jobBody.Responses
      ? { backgroundColor: '#ffbaba' }
      : { backgroundColor: null };

  function getPaper() {
    const kiosk = props.kiosksInfo.filter((kiosk) => kiosk.KioskID === props.jobBody.KioskID)[0];
    return kiosk ? `Star: ${kiosk.PrintCountCOVR}\nDM1: ${kiosk.PrintCountOne}\nDM2: ${kiosk.PrintCountTwo}` : '';
  }

  return ({
    key: props.jobBody.JobID,
    color: jobColor,
    cells: [
      { data: `${props.jobBody.KioskID}\n${props.jobBody.ServerID}` },
      { data: props.jobBody.JobID },
      { data: props.jobBody.Dispatcher },
      { data: `${FormatDate2(props.jobBody.CreatedDate)}\n${FormatTime(props.jobBody.CreatedDate)}` },
      { data: props.jobBody.Description },
      { data: getPaper() },
      { data: props.jobBody.JobStatus },
      { data: `${FormatDate(props.jobBody.AssignedDate)}\n${FormatTime(props.jobBody.AssignedDate)}` },
      { data: props.jobBody.Technician ? props.jobBody.Technician : 'Assign Tech' },
      {
        data: props.jobBody.EstimateTimeArrival
          ? props.jobBody.EstimateTimeArrival.replace(/\"/g, '')
          : props.jobBody.EstimateTimeArrival,
      },
    ],
  });
};

export default Job;
