// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner {
    border: 6px solid transparent; /* Light grey */
    border-top: 6px solid #A4A9CB; /* Blue */
    border-left: 0px solid;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    animation: spin 0.75s linear infinite;
    position: relative;
    left: 45%;
    margin-top: 10%;
    margin-bottom: 10%;
}

.Spinner-small {
    border: 6px solid transparent; /* Light grey */
    border-top: 6px solid #A4A9CB; /* Blue */
    border-left: 0px solid;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    animation: spin 0.75s linear infinite;
    position: relative;
    left: 45%;
    margin-top: 10%;
    margin-bottom: 10%;
}

.margin-25 {
    margin-top: 25%;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.margin-none {
    width: 100%;
}

.chat-margin {
    margin-top: 64%;
    margin-right: 8%;
}

.margin-small {
    width: 112px;
    margin: auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`, "",{"version":3,"sources":["webpack://./src/StyleSheets/Spinner.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B,EAAE,eAAe;IAC9C,6BAA6B,EAAE,SAAS;IACxC,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,6BAA6B,EAAE,eAAe;IAC9C,6BAA6B,EAAE,SAAS;IACxC,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC","sourcesContent":[".Spinner {\n    border: 6px solid transparent; /* Light grey */\n    border-top: 6px solid #A4A9CB; /* Blue */\n    border-left: 0px solid;\n    border-radius: 100%;\n    width: 50px;\n    height: 50px;\n    animation: spin 0.75s linear infinite;\n    position: relative;\n    left: 45%;\n    margin-top: 10%;\n    margin-bottom: 10%;\n}\n\n.Spinner-small {\n    border: 6px solid transparent; /* Light grey */\n    border-top: 6px solid #A4A9CB; /* Blue */\n    border-left: 0px solid;\n    border-radius: 100%;\n    width: 20px;\n    height: 20px;\n    animation: spin 0.75s linear infinite;\n    position: relative;\n    left: 45%;\n    margin-top: 10%;\n    margin-bottom: 10%;\n}\n\n.margin-25 {\n    margin-top: 25%;\n}\n\n.center {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    height: 100%;\n}\n\n.margin-none {\n    width: 100%;\n}\n\n.chat-margin {\n    margin-top: 64%;\n    margin-right: 8%;\n}\n\n.margin-small {\n    width: 112px;\n    margin: auto;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
