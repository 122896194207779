import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import '../StyleSheets/Table.css';
import PropTypes from 'prop-types';

const SearchBar = ({clear_search, search, options = [], tabs = [], dates = null, onLimitDate, tab_style = 'select', active_tab = '', filter_table, select_only}) => {
    const [searchVal,setSearchVal] = useState('');
    const [searchBy,setSearchBy] = useState(1);
    const [activeTab,setActiveTab] = useState(active_tab);

    const onSearchChanged = (e) => {
        setSearchBy(e.target.value);
        if(select_only){
            search(e.target.value);
        }
    }

    const onTextChanged = (e) => {
        setSearchVal(e.target.value);
    }

    const clearSearch = () => {
        setSearchVal('');
        clear_search();
    }

    const handleSearch = () => {
        if(searchValidation()){
            search(searchBy, searchVal)
        }
    }

    const clickSearch = (e) => {
        if(e.keyCode === 13){
            if(searchValidation()){
                search(searchBy, searchVal);
            }
        }
    }

    const searchValidation = () => {
        if(!searchVal){
            alert(`Search field cannot be blank.  Please enter a value to search and try again.`)
            return false
        }
        return true;
    }

    const handleTableFilter = (tab_id,filter) => {
        setActiveTab(tab_id);
        filter_table(filter);
    }

    const RenderTabFilters = () => {
        if(tabs.length && tab_style == 'button'){
            return (
                <div id="button-container" style={{marginRight: 'auto'}}>
                    <div style={{display:'flex'}}>
                        {tabs.map(tab => (<Button
                            variant="contained"
                            key={tab.id}
                            className={`rti-clear-search ${activeTab == tab.id ? "active" : ""}`}
                            id={tab.id}
                            onClick={() => handleTableFilter(tab.id,tab.filter)}>{tab.name}</Button>))}
                    </div>
                </div>
            );
        }else if(tabs.length && tab_style == 'select'){
            return (
                <div style={{marginRight: 'auto'}}>
                    <div className="search-by">
                        <select value={active_tab} onChange={(e) => filter_table(e.target.value)}>
                            {tabs.map(tab => (<option key={tab.id} value={tab.filter}>{tab.name}</option>))}
                        </select>
                    </div>
                </div>
            )
        }
    }

    const RenderDateInput = () => {
        return (
            <div style={{display: 'flex', alignItems:'center', width: 'fit-content'}}>
                <TextField id="date-input-after" className="table-date-input" label="After" type="date" defaultValue={dates.after} />
                <TextField id="date-input-before" className="table-date-input" label="Before" type="date" defaultValue={dates.before} />
                <Button className="rti-blue-search" variant="contained" onClick={onLimitDate}>Limit</Button>
            </div>
        )
    } 
    return (
        <div className={`search-bar ${tabs.length && options.length || dates != null && options.length || dates != null && tabs.length ? 'flex-just-between' : 'flex-end'}`}>
            {tabs.length ? RenderTabFilters() : null}
            {dates != null ? RenderDateInput() : null}
            {options.length && select_only ? (
                <div className="search-by" style={{ margin: 0 }}>
                    <div className="inner-search-container">
                        <select className="search-selection" id="search-selection" value={searchBy} onChange={onSearchChanged}>
                            {options.map((option, index) => (
                                <option key={option.name} value={index + 1}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            ) : options.length && !select_only ? (
                <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div className="search-by" style={{ margin: 0 }}>
                        <div className="inner-search-container">
                            <select className="search-selection" id="search-selection" value={searchBy} onChange={onSearchChanged}>
                                {options.map((option, index) => (
                                    <option key={option.name} value={index + 1}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="search-by-value" style={{ margin: 0 }}>
                        <div className="inner-search-container">
                            <TextField
                                id="search-input"
                                label="Search"
                                variant="outlined"
                                size="small"
                                value={searchVal}
                                onKeyDown={clickSearch}
                                onChange={onTextChanged}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button className="rti-blue-search" variant="contained" onClick={clearSearch}>Clear</Button>
                        <Button className="rti-blue-search" variant="contained" id="search-button" onClick={handleSearch}>Search</Button>
                    </div>
                </div>
            ) : null}
        </div>
    );
    
}

SearchBar.propTypes = {
  clear_search: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  tabs: PropTypes.array.isRequired,
  dates: PropTypes.array.isRequired,
  onLimitDate: PropTypes.func.isRequired,
  tab_style: PropTypes.object.isRequired,
  active_tab: PropTypes.string.isRequired,
  filter_table: PropTypes.func.isRequired,
  select_only: PropTypes.bool.isRequired,
};

export default SearchBar;