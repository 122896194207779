import React, { useState, useEffect } from "react";
import "../../../StyleSheets/InventorySystem.css";
import CustomTable from "../../../components/Table";
import Button from "@mui/material/Button";
import { AiOutlineDownload as GetAppIcon } from "react-icons/ai";
import Spinner from "../../../components/Spinner";
import * as XLSX from "xlsx";
import { useNavigate } from 'react-router-dom';

const TableTabs = [
  { id: "in-kiosks", name: "Kiosks", filter: "inKiosks" },
  { id: "employees", name: "Employees", filter: "withEmployees" },
  { id: "paper-cores", name: "Paper Cores", filter: "onCores" },
  { id: "in-bundles", name: "Bundles", filter: "inBundles" },
  { id: "contractors", name: "Contractors", filter: "withContractors" },
  { id: "components", name: "Components", filter: "finishedComponents" },
  { id: "due-back", name: "Rolls Due", filter: "dueBack" },
  { id: "test-prints", name: "Test Prints", filter: "testPrints" },
  { id: "open-jobs", name: "Open Jobs", filter: "openJobs" },
  {
    id: "completed-transactions",
    name: "Transactions",
    filter: "completedTransactions",
  },
];

const SearchOptions = [
  { id: "odd-year", name: "2025", pidx: 1 },
  { id: "even-year", name: "2026", pidx: 2 },
];

const StickerAuditTable = ({ history }) => {
  const navigate = useNavigate();
  const _query = new URLSearchParams(window.location.search);

  const _params = Array.from(_query.keys()).reduce(
    (acc, val) => ({
      ...acc,
      [val]: _query.get(val),
    }),
    {}
  );

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(
    _params.filter ? _params.filter : TableTabs[0].filter
  );
  const [stickerYear, setStickerYear] = useState("2025");
  const [stickerData, setStickerData] = useState({});

  useEffect(() => {
    if (!stickerData) {
      setLoading(true);
    }
    socket.emit(`granular${stickerYear}StickerAudit`, (res) => {
      if (!res) {
        console.error(
          `StickerAuditTable.granular${stickerYear}StickerAudit: There was an issue calling this method`
        );
      } else {
        setStickerData({
          inKiosks: res[0],
          withEmployees: res[1],
          withContractors: res[2],
          onCores: res[3],
          inBundles: res[4],
          finishedComponents: res[5],
          dueBack: res[6],
          testPrints: res[7],
          openJobs: res[8],
          completedTransactions: res[9],
        });
        setLoading(false);
      }
    });
  }, [activeTab, stickerYear]);

  const exportToCSV = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Iterate over each part of stickerData
    Object.keys(stickerData).forEach((key) => {
      // Generate headers for the current dataset
      const headers = generateHeaders(stickerData[key]);
      // Format the data with headers included
      const dataWithHeaders = [
        headers.map((header) => header.name),
        ...stickerData[key].map((row) =>
          headers.map((header) => row[header.id])
        ),
      ];
      // Convert the formatted data to a worksheet
      const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, key);
    });
    // Write the workbook to a file
    XLSX.writeFile(wb, `${stickerYear}-StickerAuditReport.xlsx`);
  };

  const handleURLParams = (_params, _action) => {
    const urlParams = new URLSearchParams(window.location.search);
    _params.forEach((param) => {
      if (_action === "add") {
        urlParams.set(`${param.key}`, `${param.val}`);
      } else {
        urlParams.delete(`${param.key}`);
      }
    });
    navigate(`${window.location.pathname}?${urlParams.toString()}`, { replace: true });
  };

  const filterTable = (filter) => {
    setActiveTab(filter);
    handleURLParams([{ key: "filter", val: filter }], "add");
  };

  const Search = (searchBy) => {
    const sticker_handler = searchBy == 1 ? "2025" : "2026";
    setStickerYear(sticker_handler);
    handleURLParams([{ key: "search", val: searchBy }], "add");
  };

  const generateHeaders = (data) => {
    const headers =
      data && data.length > 0
        ? Object.keys(data[0]).map((key) => ({
            id: key,
            name: key,
            sortable: true,
            numeric: typeof data[0][key] === "number",
          }))
        : [
            { id: "placeholder1", name: "-", sortable: false, numeric: false },
            { id: "placeholder2", name: "-", sortable: false, numeric: false },
            { id: "placeholder3", name: "-", sortable: false, numeric: false },
            { id: "placeholder4", name: "-", sortable: false, numeric: false },
            { id: "placeholder5", name: "-", sortable: false, numeric: false },
          ];

    // Ensure there are at least 6 headers
    while (headers.length < 6) {
      headers.push({
        id: `placeholder${headers.length + 1}`,
        name: "",
        sortable: false,
        numeric: false,
      });
    }

    return headers;
  };

  const isISODateString = (value) => {
    // Determine if the date is an ISO string (YYYY-MM-DDTHH:mm:ss.sssZ)
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return isoDateRegex.test(value);
  };

  const formatDate = (value) => {
    // If the date is a ISO string (YYYY-MM-DDTHH:mm:ss.sssZ) then format it to a standard date.
    const date = new Date(value);
    return date.toLocaleDateString();
  };

  const renderTableBody = (data) => {
    if (!data || data.length === 0) {
      return [
        {
          key: 0,
          cells: Array(6).fill({ data: "-" }), // Ensure there are at least 6 cells
        },
      ];
    }

    return data.map((row, index) => {
      const cells = Object.values(row).map((value) => ({
        data: isISODateString(value) ? formatDate(value) : value,
      }));

      // Ensure there are at least 6 cells in each row
      while (cells.length < 6) {
        cells.push({ data: "" });
      }

      return { key: index, cells };
    });
  };

  const headers = generateHeaders(stickerData[activeTab] || []);
  const rows = renderTableBody(stickerData[activeTab] || []);

  const renderTableButtons = () => {
    return (
      <div className="flex-align-center">
        <Button
          className="rti-blue-round"
          variant="contained"
          onClick={(e) => exportToCSV(stickerData, "test-download")}
          startIcon={<GetAppIcon />}
        >
          Download Report
        </Button>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="ViewedContentContainer">
        <div className="TitleBarContainer">
        <h1>Sticker Audit</h1>
        </div>
        <CustomTable
          paginate
          rpp={50}
          loading_data={loading}
          headers={headers}
          tabs={TableTabs}
          tab_style="button"
          filter_data={filterTable}
          active_tab={activeTab}
          rows={rows}
          search_options={SearchOptions}
          searchable
          select_only={true}
          search={Search}
          table_buttons={renderTableButtons()}
        />
        </div>
      )}
    </div>
  );
};

export default StickerAuditTable;
