import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import env from '../utilities/env-loader.cjs';
import { useCustomContext } from './Context';
import { jwtVerify } from 'jose';
import PropTypes from 'prop-types';

/**
 * InvAuth component to verify user authentication for inventory.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactElement} The rendered component.
 */
const InvAuth = (props) => {
	const [Auth, setAuth] = useState(null);
	const { userDispatch } = useCustomContext();

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			verify_user();
		}

		return () => {
			mounted = false;
		};
	}, []);

	/**
	 * Verifies the user by decoding and validating the JWT.
	 * @async
	 */
	async function verify_user() {
		const username = window.localStorage.getItem('user');
		const token = window.localStorage.getItem('local-dispatch-console-user');
		const jwtSecret = env.SERVER.JWT_SECRET;

		if (username && token) {
			try {
				const { payload } = await jwtVerify(token, new TextEncoder().encode(jwtSecret), {
					algorithms: ['HS256'],
				});

				const auth = payload;
				const response = await new Promise((resolve) => {
					socket.emit('VerifyUserCreds', username, auth.id, resolve);
				});

				if (!response) {
					console.error('ERROR: There was an issue validating this user');
					setAuth(false);
				} else if (response.length > 0) {
					const user = await new Promise((resolve) => {
						socket.emit('getUserByUsername', username, resolve);
					});

					if (!user) {
						console.error("ERROR: There was an issue getting this user's information");
						setAuth(false);
					} else {
						const user_obj = {
							uid: user[0].UserID,
							username: username,
							fullName: window.localStorage.getItem('FullName'),
							phoneNumber: user[0].PhoneNumber,
							role: user[0].UserType === env.USER_PERMS[0] || user[0].UserType === env.USER_PERMS[2],
							access: user[0].UserType !== env.USER_PERMS[4],
							permissions: user[0].UserType,
							user_logs: user[0].UserLogs,
							email: user[0].Email,
							roles: user[0].Role || null,
						};
						userDispatch({ type: 'SET_USER', user: user_obj });
						socket.emit('logUserAction', user_obj.uid, 'LOGIN', `User ${user_obj.username} logged in`);
						setAuth(true);
					}
				} else {
					setAuth(false);
				}
			} catch (error) {
				console.error(`ERROR: There was an issue verifying this user: ${error} on ${new Date()}`);
				setAuth(false);
			}
		} else {
			setAuth(false);
		}
	}

	return (
		<div className='app-main-body'>
			{Auth === true ? props.children : Auth === false ? <Navigate to={'inventory'} /> : null}
		</div>
	);
};

InvAuth.propTypes = {
	children: PropTypes.node.isRequired,
};

export default InvAuth;
