import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';

/**
 * Auth component to check if the user is authenticated.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactElement} The rendered component.
 */
const Auth = (props) => {
  /**
   * Checks if the user is authenticated.
   * @returns {boolean} True if authenticated, false otherwise.
   */
  const Authenticated = () => {
    let data = window.sessionStorage.getItem("techName");

    return data !== null;
  };

  return (
    <div>
      {Authenticated() ? props.children : <Navigate to="/tech-portal/login" />}
    </div>
  );
};

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
