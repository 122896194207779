import React from 'react';
import { AiOutlineEye as VisibilityIcon, AiOutlineEyeInvisible as VisibilityOffIcon } from 'react-icons/ai';
import { AiOutlineHistory as HistoryIcon, AiOutlineEdit as EditIcon, AiOutlineTool as BuildIcon } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export const SingularItemInfo = (props) => {
    const { form, item, loading, itemType, prevNotes, onToggleAudit, toggleEditing, toggleServicing, toggleVisibility, maskPass, visible } = props;
    const parsed_notes = prevNotes.filter(x => !!x);

    const renderItemInformation = () => {
        return itemType === 'Parent' && item.has_carrier && !loading ? 
        <div className='item-field-container'>
            <div className='grid-container item-info-grid'>
                <div className='item-info-field'>{form && form.item_info.serial ? 'S/N: ' : null}{form.item_info.serial || null}</div>
                <div className='item-info-field'>{form && form.model ? 'Model: ' : null}{form.model || null}</div>
                <div className='item-info-field'>{form && form.item_info.imei ? 'IMEI: ' : null}{form.item_info.imei || null}</div>
                <div className='item-info-field'>{form && (form.item_info.sim !== undefined &&  form.item_info.sim !== null) ? 'SIM: ' : ''}  {form.item_info.sim || ""}</div>
                <div className='item-info-field'>{form && form.item_info.phone ? 'Phone #: ' : null}{form.item_info.phone || null}</div>			
                <div className='item-info-field'>{form && form.item_info.password ? 'Password: ' : null}{maskPass(form.item_info.password) || null}<IconButton onClick={toggleVisibility} size="large">{!visible ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton></div>
                <div className='item-info-field'>{form && form.location ? 'Location: ' : null} {form.location || null}</div>
            </div>
        </div>
        :
        itemType === 'Parent' && !loading ? 
        <div className='item-field-container'>
            <div className='grid-container item-info-grid'>
                <div className='item-info-field'>{form && form.item_info.serial ? 'S/N: ' : null}{form.item_info.serial || null}</div>
                <div className='item-info-field'>{form && form.model ? 'Model: ' : null}{form.model || null}</div>
                <div className='item-info-field'>{form && form.item_info.part_num ? 'Part #: ' : null}{form.item_info.part_num || null}</div>
                <div className='item-info-field'>{form && form.location ? 'Location: ' : null} {form.location || null}</div>
            </div>
        </div>
        :
        itemType === 'Child' || itemType === 'SIM' && !loading ? 
        <div className='item-field-container'>
            <div className='grid-container item-info-grid'>
                <div className='item-info-field'>{form && form.item_info.serial ? 'S/N: ' : null}{form.item_info.serial || null}</div>
                <div className='item-info-field'>{form && form.model ? 'Model: ' : null}{form.model || null}</div>
                <div className='item-info-field'>{form && form.item_info.part_num ? 'Part #: ' : null}{form.item_info.part_num || null}</div>
                <div className='item-info-field'>{form && form.item_info.phone ? 'Phone #: ' : null}{form.item_info.phone || null}</div>			
                <div className='item-info-field'>{form && form.item_info.carrier ? 'Carrier: ' : null}{form.item_info.carrier || null}</div>
                <div className='item-info-field'>{form && form.item_info.sim ? 'SIM: ' : null}{form.item_info.sim || null}</div>
                <div className='item-info-field'>{form && form.location ? 'Location: ' : null} {form.location || null}</div>
            </div>
        </div>	
        :
        itemType != 'Parent' && !loading && !item.bulk ?
        <div className='item-field-container'>
            <div className='grid-container item-info-grid'>
                <div className='item-info-field'>{form && form.item_info.serial ? 'S/N: ' : null}{form.item_info.serial || null}</div>
                <div className='item-info-field'>{form && form.model ? 'Model: ' : null}{form.model || null}</div>
                <div className='item-info-field'>{form && form.item_info.part_num ? 'Part #: ' : null}{form.item_info.part_num || null}</div>
                <div className='item-info-field'>{form && form.item_info.firmware ? 'Firmware: ' : null}{form.item_info.sim || null}</div>
                <div className='item-info-field'>{form && form.location ? 'Location: ' : null} {form.location || null}</div>
            </div>
        </div>	
        : null;
    };

    return (
        <div>
            <div className='item-field-container'>
                {renderItemInformation()}
                <div>
                    <div className='item-note-field full-width'>
                        <span>
                            <p>Notes</p>
                        </span>
                        <TextField
                            id='prev-notes'
                            multiline
                            maxRows={4}
                            variant='outlined'
                            disabled
                            value={
                                form && form.barcode === item.barcode
                                    ? parsed_notes
                                            .map((el) => {
                                                if (el.item_notes) {
                                                    return `${el.timestamp} ${el.item_notes}`;
                                                }
                                                return '';
                                            })
                                            .join('\n\n')
                                    : ''
                            }
                        />
                    </div>
                </div>
                <div className='flex-just-even full-width bottom-buttons'>
                    <div>
                        <Button className='item-buttons' variant='outlined' color='primary' startIcon={<HistoryIcon />} onClick={onToggleAudit}>
                            Retrieve Audit
                        </Button>
                    </div>
                    <div>
                        <Button className='item-buttons' variant='outlined' color='primary' startIcon={<EditIcon />} onClick={toggleEditing}>
                            Edit
                        </Button>
                    </div>
                    {itemType !== 'Child' && item.family !== 'STICKER' ? (
                        <div>
                            <Button className='item-buttons' variant='outlined' color='primary' startIcon={<BuildIcon />} onClick={toggleServicing}>
                                Service
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
