import React, { useState } from 'react'
import '../../StyleSheets/TechPortal.css';
import RTILogo from '../../../public/Images/regitechLogo.png';
import {Link} from 'react-router-dom';
import NavBar from '../../components/NavBar.js';
import Spinner from '../../components/Spinner.js';

const TechPortalHome = ({children}) => {
    const [loading,setLoading] = useState(false);

    return (
        <div className="portal-main-body">
            <NavBar></NavBar>
            <div className='container-page'>
                {!loading ? children : <Spinner />}
            </div>
        </div>
    )
}

export default TechPortalHome;