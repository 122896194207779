import React, { useState, useEffect, useRef } from 'react';
import { useCustomContext } from '../../hoc/Context.js';
import CustomTable from '../../components/Table.js';
import Button from '@mui/material/Button';
import { FaPlus } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Modal from '../../components/Modal.js';
import IconButton from '@mui/material/IconButton';
import { FaEllipsisV } from 'react-icons/fa';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CSVLink } from 'react-csv';
import AlertBar from '../../components/AlertBar.js';
import { calcAvailPTO, getPTO } from '../../utilities/util.js';
import env from '../../utilities/env-loader.cjs';
import bcrypt from 'bcryptjs';
//Formats the UsersTable Information for each row
const TableHeaders = [
	{ id: 'username', name: 'Username', sortable: true, numeric: false },
	{ id: 'perms', name: 'Permission', sortable: true, numeric: false },
	{ id: 'name', name: 'Name', sortable: true, numeric: false },
	{ id: 'phone', name: 'Phone #' },
	{ id: 'email', name: 'Email' },
];

const SearchOptions = [{ id: 'name', name: 'Name', pidx: 2 }];

const UsersTable = () => {
	const [searchedVal, setSearchedVal] = useState('');
	const [searchParam, setSearchParam] = useState(0);
	const [DispatchersDB, setDispatchersDB] = useState([]);
	const [Loading, setLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [selected, setSelected] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [ptoModal, setPtoModal] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});
	const { userState } = useCustomContext();
	const selectedRef = useRef();

	// only allow master and accounting roles to have access to make changes to user profile.
	// TODO: Possibly need to add a restriction so that you can only change your own profile if you are not master or accounting roles.
	// Changed this so that users can set their own passwords and update their information.
	const accountingPerms =
		userState.user.permissions === env.USER_PERMS[0] ||
		userState.user.permissions === env.USER_PERMS[1] ||
		userState.user.permissions === env.USER_PERMS[2] ||
		userState.user.roles === env.USER_ROLES[2];

	useEffect(() => {
		socket.emit('selectFromDispatchersForTM', (result) => {
			setDispatchersDB(result);
			setLoading(false);
		});

		selectedRef.current = selected;

		$('#user-modal').toggleClass('flex-box', modalOpen);
		$('#adjust-pto').toggleClass('flex-box', ptoModal);
	}, [selected, modalOpen, ptoModal]);

	const RefreshTableData = () => {
		setLoading(true);
		socket.emit('selectFromDispatchersForTM', (result) => {
			setDispatchersDB(result);
			setLoading(false);
		});
	};

	const AddNewItemBtn = () => {
		if (userState.user.role) {
			setModalOpen(true);
		}
	};

	const RowClick = async (e) => {
		const id = e.target.id;
		const getDispatchers = DispatchersDB;

		let dispatcher_info = getDispatchers.filter((el) => {
			return String(el.UserID) === id;
		});
		const { accrued, time_off, actual } = await getPTO(id);
		// const avail_pto = calcAvailPTO(accrued[0].accrued_pto, time_off); // commented out until PTO is figured out - JMA 8/20/2024

		if (dispatcher_info) {
			dispatcher_info[0].Birthday = dispatcher_info[0].Birthday ? dispatcher_info[0].Birthday.split('T')[0] : null;
			//   dispatcher_info.AccruedPTO = accrued[0].accrued_pto ? accrued[0].accrued_pto.toFixed(1) : null; // commented out until PTO is figured out - JMA 8/20/2024
			//   dispatcher_info.AvailPTO = dispatcher_info.PTO.toFixed(1); // commented out until PTO is figured out - JMA 8/20/2024
		}

		setSelected(dispatcher_info[0]);
		setModalOpen(true);
	};

	const ClearSearch = () => {
		setSearchedVal('');
	};

	const search = (param, searchVal) => {
		setSearchedVal(searchVal);
		setSearchParam(param);
	};

	function RenderTableButtons() {
		return userState.user.role ? (
			<div style={{ display: 'flex' }}>
				<Button className='rti-blue-round' variant='contained' startIcon={<FaPlus />} onClick={AddNewItemBtn}>
					User
				</Button>
				<CSVLink
					style={{ background: 'none', border: 'none' }}
					target='_blank'
					className='hidden'
					id='table-download'
					data={downloadData()}
					filename={'users.csv'}
				></CSVLink>
				<Button className='rti-blue-round' variant='contained' startIcon={<FaDownload />} onClick={handleTableDownload}>
					Users
				</Button>
			</div>
		) : null;
	}

	const handleTableDownload = () => {
		document.getElementById('table-download').click();
	};

	const downloadData = () => {
		return DispatchersDB.map((dispatcher) => {
				return {
					Username: dispatcher.Username,
					Permissions: dispatcher.UserType,
					Name: `${dispatcher.FirstName} ${dispatcher.LastName}`,
					'Phone #': dispatcher.PhoneNumber,
					Email: dispatcher.Email,
				};
		});
	};

	const getTableData = () => {
		return DispatchersDB.map((user) => {
				return {
					key: user.UserID,
					cells: [
						{ data: user.Username },
						{ data: user.UserType },
						{ data: user.FullName },
						{ data: user.PhoneNumber },
						{ data: user.Email },
					],
				};
		});
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setSelected({});
	};

	const handleAdd = async () => {
		const password = document.getElementById('password').value;
		const hashedPassword = await bcrypt.hash(password, 10);

		const user_info = {
			Username: document.getElementById('username').value,
			Password: hashedPassword,
			UserType: document.getElementById('type').value,
			FirstName: document.getElementById('fname').value,
			LastName: document.getElementById('lname').value,
			PhoneNumber: document.getElementById('phone').value,
			Email: document.getElementById('email').value,
			Birthday: document.getElementById('bday').value,
			// AccrualRate: document.getElementById('accrual-rate').value, // commented out until PTO is figured out - JMA 8/20/2024
			// AccrualType: document.getElementById('accrual-type').value // commented out until PTO is figured out - JMA 8/20/2024
		};

		if (valid(user_info)) {
			socket.emit('createUser', user_info, (boolean) => {
				if (boolean) {
					 // Log user action
					socket.emit('logUserAction', userState.user.uid, 'CREATE', `User ${user_info.Username} created with role ${user_info.UserType}`);
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'Success! User created.',
						severity: 'success',
					}));
					RefreshTableData();
				} else {
					//Notify user of Failure
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'WARNING: There were errors within the form.  Please make sure you fill out all fields correctly.',
						severity: 'warning',
					}));
				}
			});
		} else {
			alert(`Oops!  Looks like you forgot to fill out a required field.  Please try again.`);
		}
	};

	const handleDelete = () => {
		if (
			prompt(`Are you sure you want to remove this user?  To confirm, please type DELETE in the box below`) === 'DELETE'
		) {
			const user_id = selected.UserID;
			socket.emit('deleteDispatcherForTM', user_id, (boolean) => {
				if (boolean) {
					 // Log user action
					socket.emit('logUserAction', userState.user.uid, 'DELETE', `User ${selected.Username} deleted`);
					//Close CreateJobModal
					RefreshTableData();
				} else {
					//Notify user of Failure
					alert('User was not deleted.');
				}
			});
		}
	};

	const ResetPassword = () => {
		const password = document.getElementById('password').value;
		socket.emit('updateUserPassword', password, selected.UserID, (response) => {
			if (!response) {
				alert(`ERROR: There was an issue resetting this user's password.`);
				setMenuOpen(false);
				setAnchorEl(null);
			} else {
				setMenuOpen(false);
				setAnchorEl(null);
				alert(`Password reset successfully!`);
			}
		});
	};

	const handleSave = async () => {
		const password = document.getElementById('password').value;
		const hashedPassword = await bcrypt.hash(password, 10);

		const user_info = {
			UserID: selected.UserID,
			Username: document.getElementById('username').value,
			UserPassword: hashedPassword,
			UserType: document.getElementById('type').value,
			FirstName: document.getElementById('fname').value,
			LastName: document.getElementById('lname').value,
			PhoneNumber: document.getElementById('phone').value,
			Email: document.getElementById('email').value,
			Birthday: document.getElementById('bday').value,
			AccrualRate: document.getElementById('accrual-rate').value,
			AccrualType: document.getElementById('accrual-type').value,
		};

		socket.emit('updateDispatcherForTM', user_info, (boolean) => {
			// Log user action
			socket.emit('logUserAction', userState.user.uid, 'UPDATE', `User ${user_info.Username} updated with new details`);
			if (boolean) {
				//Close CreateJobModal
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'Success! Changes saved.',
					severity: 'success',
				}));
				RefreshTableData();
			} else {
				//Notify user of Failure
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'WARNING: There were errors within the form.  Please make sure you fill out all fields correctly.',
					severity: 'warning',
				}));
			}
		});
	};

	const valid = (data) => {
		if (!data.Username && !data.Password && !data.UserType && !data.FirstName && !data.LastName) {
			return false;
		}
		return true;
	};

	const handleMenuClose = () => {
		setMenuOpen(false);
		setAnchorEl(null);
	};

	const handleOpenMenu = (e) => {
		setAnchorEl(e.currentTarget);
		setMenuOpen(true);
	};

	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	const handleAdjustPTO = () => {
		const user_info = {
			UserID: selected.UserID,
			Username: document.getElementById('username').value,
			Password: document.getElementById('password').value,
			UserType: document.getElementById('type').value,
			FirstName: document.getElementById('fname').value,
			LastName: document.getElementById('lname').value,
			PhoneNumber: document.getElementById('phone').value,
			Email: document.getElementById('email').value,
			Birthday: document.getElementById('bday').value,
			AccrualRate: document.getElementById('accrual-rate').value,
			AccrualType: document.getElementById('accrual-type').value,
			PTO: document.getElementById('adjusted-pto').value,
		};

		// PUT method here
		$.ajax('/api/users', {
			type: 'PUT',
			data: user_info,
			success: (res) => {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'Success! PTO has been adjusted.',
					severity: 'success',
				}));

				setSelected((prev) => ({
					...prev,
					user_info,
				}));
				RefreshTableData();
				togglePTOModal();
			},
			error: (error) => {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: `ERROR: ${error}.`,
					severity: 'error',
				}));
			},
		});
	};

	const togglePTOModal = () => {
		handleMenuClose();
		setPtoModal((prevState) => !prevState);
	};

	return (
		<div className='ViewedContentContainer' id='OpenContainer'>
			<div style={{ height: '100%' }}>
				{modalOpen ? (
					<Modal modal_id='user-modal' dimension={{ width: 500 }} onClose={handleModalClose}>
						<div id='inner-modal'>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Avatar style={{ width: 80, height: 80, fontSize: '2.0rem' }}>
										{selected.FirstName && selected.LastName ? `${selected.FirstName[0]}${selected.LastName[0]}` : 'NA'}
									</Avatar>
									<div className='search-by'>
										<div className='inner-search-container'>
											<select
												className='search-selection'
												id='type'
												defaultValue={selected ? selected.UserType : 'user'}
											>
												<option key={0} value='user'>
													User
												</option>
												<option key={1} value='noAcc'>
													NoAcc
												</option>
												<option key={2} value='admin'>
													Admin
												</option>
												<option key={3} value='master'>
													Master
												</option>
											</select>
										</div>
									</div>
								</div>
								<div>
									<TextField
										style={{ width: 124 }}
										InputLabelProps={{ shrink: true }}
										name='Birthday'
										defaultValue={selected ? selected.Birthday : '0000-00-00'}
										className='text-field'
										id='bday'
										label='Birthday'
										required={selected.UserID ? false : true}
										type='date'
									/>
								</div>
							</div>
							<h2>User Information</h2>
							<div style={{ margin: '24px 0px' }}>
								<div className='form-field-container'>
									<TextField
										style={{ width: 200 }}
										name='FirstName'
										defaultValue={selected ? selected.FirstName : ''}
										className='text-field'
										id='fname'
										label='First'
										required={selected.UserID ? false : true}
										type='text'
									/>
									<TextField
										style={{ width: 200 }}
										name='LastName'
										defaultValue={selected ? selected.LastName : ''}
										className='text-field'
										id='lname'
										label='Last'
										required={selected.UserID ? false : true}
										type='text'
									/>
								</div>
								<div className='form-field-container'>
									<TextField
										name='Username'
										style={{ width: 200 }}
										defaultValue={selected ? selected.Username : ''}
										className='text-field'
										id='username'
										label='Username'
										required={selected.UserID ? false : true}
										type='text'
									/>
									<TextField
										name='Password'
										defaultValue=''
										className='text-field'
										id='password'
										label='Password'
										required={selected.UserID ? false : true}
										type='password'
									/>
								</div>
							</div>
							<div style={{ marginBottom: 30 }}>
								<TextField
									style={{ width: 150 }}
									name='PhoneNumber'
									defaultValue={selected ? selected.PhoneNumber : ''}
									className='text-field'
									id='phone'
									label='Phone #'
									type='phone'
								/>
								<TextField
									style={{ width: 300 }}
									name='Email'
									defaultValue={selected ? selected.Email : ''}
									className='text-field'
									id='email'
									label='Email'
									type='email'
								/>
							</div>
							<h2>Paid Time Off</h2>
							<div style={{ margin: '24px 0px' }}>
								{(selected && userState.user.uid) || accountingPerms ? (
									<div className='flex-box'>
										{accountingPerms && selected.UserID ? (
											<div style={{ marginRight: 50 }} className='flex-align-center'>
												<TextField
													className='text-field'
													style={{ width: 75 }}
													id='accrual-rate'
													InputLabelProps={{ shrink: true }}
													defaultValue={selected ? selected.AccrualRate : '0'}
													type='number'
													label='Accrual Rate'
												/>
												<div className='input-block'>
													<label className='input-label'>Accrual Type</label>
													<div className='search-by'>
														<select id='accrual-type' defaultValue={selected ? selected.AccrualType : 'hourly'}>
															<option key='0' value='hourly'>
																Hourly
															</option>
															<option key='1' value='salary'>
																Salary
															</option>
														</select>
													</div>
												</div>
											</div>
										) : null}
										{(selected && userState.user.uid) || accountingPerms ? (
											<div style={{ width: 224 }} className='flex-just-between'>
												<div>
													<h3>Available Time Off:</h3>
													<h3>Accrued Time Off:</h3>
												</div>
												<div>
													<h3 style={{ fontWeight: 'normal' }}>
														{selected && selected.AvailPTO > 0 ? selected.AvailPTO : 'N/A'} Hours
													</h3>
													<h3 style={{ fontWeight: 'normal' }}>
														{selected && selected.AccruedPTO > 0 ? selected.AccruedPTO : 'N/A'} Hours
													</h3>
												</div>
											</div>
										) : null}
									</div>
								) : null}
							</div>
							{accountingPerms && selected.UserID ? (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button className='secondary' onClick={handleDelete}>
										Delete
									</Button>
									<Divider orientation='vertical' flexItem />
									<Button className='primary' onClick={handleSave}>
										Save
									</Button>
									<IconButton color='default' aria-haspopup='true' onClick={handleOpenMenu} size='large'>
										<FaEllipsisV />
									</IconButton>
									<Menu id='more-options' anchorEl={anchorEl} keepMounted open={menuOpen} onClose={handleMenuClose}>
										<MenuItem key={0} onClick={ResetPassword}>
											Reset Password
										</MenuItem>
										<MenuItem key={1} onClick={togglePTOModal}>
											Adjust PTO
										</MenuItem>
									</Menu>
								</div>
							) : accountingPerms && !selected.UserID ? (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Divider orientation='vertical' flexItem />
									<Button className='primary' onClick={handleAdd}>
										Create
									</Button>
								</div>
							) : null}
						</div>
					</Modal>
				) : null}
				{ptoModal ? (
					<Modal modal_id='adjust-pto' dimension={{ width: 500 }} modal_header='Adjust PTO' onClose={togglePTOModal}>
						<div className='text-center'>
							<div className='vert-margin'>
								<TextField
									style={{ width: 75 }}
									id='adjusted-pto'
									InputLabelProps={{ shrink: true }}
									defaultValue={selected ? selected.AvailPTO : '0'}
									type='number'
								/>
							</div>
							<div className='vert-margin-med'>
								<Button className='rti-blue-round' variant='contained' onClick={handleAdjustPTO}>
									Make Adjustment
								</Button>
							</div>
						</div>
					</Modal>
				) : null}
				<div className='TitleBarContainer'>
					<h1>Users</h1>
				</div>
				<AlertBar
					visible={snackbar.visible}
					message={snackbar.message}
					severity={snackbar.severity}
					onClose={handleCloseSnack}
				/>
				<CustomTable
					searchable
					paginate
					loading_data={Loading}
					search={search}
					clear_search={ClearSearch}
					search_options={SearchOptions}
					headers={TableHeaders}
					rows={getTableData()}
					table_buttons={RenderTableButtons()}
					onRefresh={RefreshTableData}
					onClick={RowClick}
				/>
			</div>
		</div>
	);
};

export default UsersTable;
