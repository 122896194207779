import React, { useState,useEffect } from 'react';
import { GetFriday, FormatDate, FormatTime, FormatNA, FormatLocalDateTimeFromMonth } from '../../imports/API/TextFormatingFunctions.js';
import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import { FaDownload } from 'react-icons/fa';
import { useCustomContext } from '../../hoc/Context.js';
import { CSVLink } from 'react-csv';

const TableHeaders = [
    {id: 'name', name: 'Name'},
    {id: 'in', name: 'In'},
    {id: 'out', name: 'Out'},
    {id: 'time', name: 'Work Time'},
    {id: 'notes', name: 'Notes'}
];

const PersonalTimeSheet = (props) => {

    const [afterDate,setAfterDate] = useState(GetFriday("previous"));
    const [beforeDate,setBeforeDate] = useState(GetFriday("next"));
    const [TimeEntryDB, setTimeEntryDB] = useState([]);
    const [Loading,setLoading] = useState(true);
    const {userState,userDispatch} = useCustomContext();

    useEffect(() => {
        let mounted = true;
        
        if(mounted){
            socket.emit('selectDatesFromTimeEntry', afterDate, beforeDate, (result) => { // + " 05:00:00"
                const results = result.filter(user => user.FullName === userState.user.fullName).map(user => ({
                    FullName: user.FullName,
                    Notes: user.Notes,
                    PunchIn: FormatLocalDateTimeFromMonth(user.PunchIn),
                    PunchOut: FormatLocalDateTimeFromMonth(user.PunchOut),
                    TimeEntryID: user.TimeEntryID,
                    WorkTimeTotal: user.WorkTimeTotal
                }));

                setTimeEntryDB(results);
                setLoading(false);
            });
        }

        return () => {
            mounted = false;
        };
    },[]);

    const limitData = (after, before) => {
        setLoading(true);
        socket.emit('selectDatesFromTimeEntry', after, before, (result) => { // + " 05:00:00"
            const results = result.filter(user => user.FullName === userState.user.fullName).map(user => ({
                FullName: user.FullName,
                Notes: user.Notes,
                PunchIn: FormatLocalDateTimeFromMonth(user.PunchIn),
                PunchOut: FormatLocalDateTimeFromMonth(user.PunchOut),
                TimeEntryID: user.TimeEntryID,
                WorkTimeTotal: user.WorkTimeTotal
            }));
            
            setTimeEntryDB(results);
            setLoading(false);
        });
    };

    const getTableData = () => {
        return TimeEntryDB.map(entry => ({
            key: entry.TimeEntryID,
            cells: [
                {data: entry.FullName},
                {data: `${FormatNA(FormatDate(entry.PunchIn))}\n${FormatNA(FormatTime(entry.PunchIn))}`},
                {data: `${FormatNA(FormatDate(entry.PunchOut))}\n${FormatNA(FormatTime(entry.PunchOut))}`},
                {data: FormatNA(entry.WorkTimeTotal)},
                {data: entry.Notes}
            ]
        }));
    };

    const renderTableButtons = () => {
        return (
            <div style={{display: 'flex'}}>
                <CSVLink style={{ background: 'none', border: 'none' }} target="_blank" className="hidden" id="table-download" data={downloadData()} filename={"time-sheet.csv"} ></CSVLink>
                <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<FaDownload />} // Using FaDownload icon here
                    onClick={() => document.getElementById('table-download').click()}>Download</Button>
            </div>
        );
    };

    const downloadData = () => {
        return TimeEntryDB.map(entry => ({
            "Name": entry.FullName,
            "In": `${FormatNA(FormatDate(entry.PunchIn))}\n${FormatNA(FormatTime(entry.PunchIn))}`,
            "Out": `${FormatNA(FormatDate(entry.PunchOut))}\n${FormatNA(FormatTime(entry.PunchOut))}`,
            "Work Time": FormatNA(entry.WorkTimeTotal),
            "Notes": entry.Notes
        }));
    };

    return(
        <div className="ViewedContentContainer" id="OpenContainer" >
            <div style={{height: '100%'}}>
                <div className="TitleBarContainer">
                    <h1>Your Time Sheet</h1>
                </div>
                <CustomTable
                    paginate
                    loading_data={Loading}
                    dates={{after:afterDate,before:beforeDate}}
                    headers={TableHeaders}
                    rows={getTableData()}
                    onLimitDate={limitData}
                    table_buttons={renderTableButtons()}
                />
            </div>
        </div>
    );
};

export default PersonalTimeSheet;
