// Handle the singular inventory items when entering the service form from Dispatch-Council\src\imports\InventorySystem\SingularInventoryPartModel.js

/**
 * TODO: Add Serial Number next to the RID # in prep for changing the inventory system to SN's
 * TODO: If marked as recycled, move to the recycle bin (may have to create a destination for this) might need to think about this more, in case the piece with the RID/SN gets reused in the future
 */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, Select, MenuItem } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// MISC HARDWARE - PRINTER - MODEM - STICKER

export const HardwareServiceController = (props) => {
	const { item, models, form, newRefurb, serviceSelect, isModem, itemType, prevNotes, handleServiceSelect, onPrint, getFormInfo, toggleServicing } = props;
	const parsed_notes = prevNotes.filter(x => !!x)
	const modemServices = ['Updated Firmware', 'Configured', 'Factory Reset']
	const printerServices = ['Printhead', 'Configured', 'Calibrated', 'Sensor', 'Presenter', 'Cutter', 'Motherboard']
	const miscServices = ['Cleaned', 'Configured', 'Calibrated', 'CMOS', 'PICO', 'Re-formatted', 'Re-Imaged']
	const serviceItemType = item.family === 'MISC HARDWARE' ? miscServices : item.family === 'PRINTER' ? printerServices : item.family === 'MODEM' ? modemServices : null
	return (
		<div>
			<div className='item-field-container'>
				<div className='item-info-field just-center'>
					{item && item.barcode ? 'RID # ' : null}
					{item.barcode || null}
				</div>

				<div className='service-buttons'>
					<FormGroup row>
						{serviceItemType ? serviceItemType.map((el) => (
							<FormControlLabel key={el} control={<Checkbox onChange={handleServiceSelect} name={el} value={el} color='primary' />} label={el} />
						)) : null}
					</FormGroup>

					<FormControl>
						<RadioGroup id='refurb-group' row name='item-state-group' onChange={handleServiceSelect}>
							<FormControlLabel disabled={newRefurb ? true : false} value='Refurbished' label='Refurbished' id='refurb' color='primary' control={<Radio />} />
							<FormControlLabel disabled={newRefurb ? true : false} value='New' label='New' id='new-item' color='primary' control={<Radio />} />
							<FormControlLabel disabled={newRefurb ? true : false} value='Recycled' label='Recycled' id='recycle-item' color='primary' control={<Radio />} />
						</RadioGroup>
					</FormControl>

					<div className='flex-column flex-align-center displayField-pcore'>
						<div className='item-note-field full-width'>
							<span>
								<p>New Notes</p>
							</span>
							<TextField id='item-notes' multiline maxRows={4} variant='outlined' defaultValue='' />
						</div>
						<div className='item-note-field full-width'>
							<span>
								<p>Previous Notes</p>
							</span>
							<TextField
								id='prev-notes'
								multiline
								maxRows={4}
								variant='outlined'
								disabled
								value={form && form.barcode === item.barcode ? parsed_notes.map((el) => `${el.timestamp} ${el.item_notes}`).join('\n\n') : ''}
							/>
						</div>
					</div>
				</div>
				<div className='flex-just-even  full-width bottom-buttons'>
					<div>
						<Button className='item-buttons' variant='outlined' color='secondary' onClick={toggleServicing}>
							Cancel
						</Button>
					</div>
					<div>
						<Button className='item-buttons' variant='outlined' color='primary' onClick={onPrint}>
							Print
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
