import React, { Component} from 'react';
import { DateToYMD, FormatDateToYMD } from '../API/TextFormatingFunctions.js';

// Formats the TechnicianModal Content
const TechTimeOffModal = ({info,techniciansDB,refresh_data,role,onClose}) => {
    const generateDropdown = () => {
        return techniciansDB.map(tech => (
            <option value={tech.TechnicianID} key={tech.TechnicianID}>{tech.FirstName + " " + tech.LastName}</option>
        ));
    }

    const TimeOffAddButton = () => {
        let dropdown = document.getElementById("TechDropdown");

        let techName = dropdown.options[dropdown.selectedIndex].text
        let techID = dropdown.options[dropdown.selectedIndex].value
        let startDate = document.getElementById("StartDate").value;
        let returnDate = document.getElementById("ReturnDate").value;
        let notes = document.getElementById("TechnicianNotesInput").value;

        // Format Dates
        startDate = startDate.split("-")
        startDate = startDate[1]+"/"+startDate[2]+"/"+startDate[0]

        returnDate = returnDate.split("-")
        returnDate = returnDate[1]+"/"+returnDate[2]+"/"+returnDate[0]

        let objectToInsert = {
            TechnicianName: techName,
            TechnicianID: techID,
            StartDate: startDate,
            ReturnDate: returnDate,
            Notes: notes,
        }

        socket.emit('insertTimeOffRequest', objectToInsert, (boolean) => {
            if (boolean) { 
                refresh_data();
                (document.getElementById('modal_techTimeOff')).style.display = "none";
                (document.getElementById('myModal')).style.display = "none";
            }
            else {//Notify user of Failure
                alert("You did not fill the form out correctly");
            }
        });
    }

    const TechnicianCloseButton = () => {
        onClose();
    }

    const handleRemove = () => {
        socket.emit('deleteTimeOff',info.TimeOffID,(res) => {
            if(!res){
                alert('SERVER ERROR: There was an issue removing this time off request.')
                console.error(`TechTimeOffModal.deleteTimeOff: There was an issue calling this method`)
            }else{
                alert('Time entry has been removed.')
                refresh_data();
                onClose();
            }
        });
    }

    return (
            <div className="TechnicianForm" >
                <div className="FirstLayer" >
                    <div className="TechnicianNameInput">
                        <label>Technician Name</label>
                        <br />
                        <select className="TechDropdown" id="TechDropdown" defaultValue={info.TechnicianID || techniciansDB[0]}>{generateDropdown()}</select>
                    </div>
                </div>

                <div className="SecondLayer" >
                    <label>Start Date</label>
                    <br />
                    <input type="date" id="StartDate" defaultValue={info.StartDate || DateToYMD(new Date())}></input>
                </div>
                
                <div className="ThirdLayer" >
                    <label>Return Date</label>
                    <br />
                    <input type="date" id="ReturnDate" defaultValue={info.ReturnDate || DateToYMD(new Date())}></input>
                </div>

                <div className="ForthLayer">
                    <div className="TechnicianNotes" >
                        <label>Notes</label>
                        <br />
                        <textarea className="TechnicianNotesInput" id="TechnicianNotesInput" defaultValue={info.Notes || ""}></textarea>
                    </div>
                </div>

                <div>
                    <div className='flex-just-between'>
                        <button className="RTIButton" id="TECHFormClose" onClick={TechnicianCloseButton}>CLOSE</button>
                        {role && info.TechnicianID ? <button className="RTIButton" id="TECHFormAdd" onClick={handleRemove}>REMOVE</button> : null}
                    </div>
                    {role && !info.TechnicianID ? 
                        <div className='flex-just-between'>
                            <button className="RTIButton" id="TECHFormAdd" onClick={TimeOffAddButton}>ADD</button>
                        </div> : null                        
                    }
                </div>
            </div>
        );
}

export default TechTimeOffModal;