import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { FaCloudUploadAlt } from 'react-icons/fa'; // Import from react-icons
import PropTypes from 'prop-types';

const FileUpload = ({ onUpload }) => {
    const [file, setFile] = useState('');

    const handleFileInput = (e) => {
        if (e.target.files.length) {
            const filename = e.target.files[0].name;
            setFile(filename);

            if (onUpload) {
                onUpload(true);
            }
        }
    };

    const handleFileUpload = () => {
        document.getElementById('file-upload').click();
    };

    return (
        <div style={{ margin: '30px' }} className="flex-align-center flex-column">
            <IconButton style={{ width: 156, height: 156 }} onClick={handleFileUpload} size="large">
                <FaCloudUploadAlt className="cloud-upload" size={48} />
            </IconButton>
            <input
                hidden
                type="file"
                accept=".csv"
                id="file-upload"
                onChange={handleFileInput}
            />
            <br />
            <label>{file}</label>
        </div>
    );
};

FileUpload.propTypes = {
    onUpload: PropTypes.func.isRequired,
};

export default FileUpload;
