import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { FiDownload } from 'react-icons/fi'; 
import CustomTable from '../../components/Table';
import Modal from '../../components/Modal';
import "../../StyleSheets/TableManagement.css";
import { CSVLink } from 'react-csv';

const TableHeaders = [
    {id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false},
    {id: 'issues', name: 'Total Issues', sortable: true, numeric: true}
];

const TableHeaders2 = [
    {id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false},
    {id: 'issue-date', name: 'Date of Issue'},
    {id: 'issue', name: 'Issue', sortable: true, numeric: false},
    {id: 'last-step', name: 'Last Step', sortable: true, numeric: false}
];

const TableTabs = [
    {id: 3, name: 'Last 3 days', filter: 3},
    {id: 7, name: 'Last 7 days', filter: 7},
    {id: 14, name: 'Last 14 days', filter: 14},
    {id: 28, name: 'Last 28 days', filter: 28}
];

const IssueTracking = (props) => {
    const [ActiveTab, setActiveTab] = useState(TableTabs[0].filter);
    const [Loading, setLoading] = useState(true);
    const [KioskSelected, setKioskSelected] = useState(null);
    const [Issues, setIssues] = useState([]);
    const [CompressedIssues, setCompressedIssues] = useState({});

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            socket.emit('getKioskIssuesByInterval', ActiveTab, (res) => {
                if (!res) {
                    console.error(`There was an issue getting kiosk issues from the database.`);
                    setCompressedIssues({});
                    setLoading(false);
                } else {
                    let results = {};
                    res.forEach(issue => results[issue.KioskID] ? results[issue.KioskID] = results[issue.KioskID] + 1 : results[issue.KioskID] = 1);
                    setIssues(res);
                    setCompressedIssues(results);
                    setLoading(false);
                }
            });
        }

        return () => {
            mounted = false;
        };
    }, [ActiveTab]);

    function FilterTable(filter) {
        setLoading(true);
        setActiveTab(filter);
    }

    const handleModal = (e) => {
        const id = e.target.id;
        setKioskSelected(id);
        $('#issue_modal').toggleClass('flex-box');
    };

    const getTableData = () => {
        return Object.keys(CompressedIssues).map(key => ({
            kiosk: key, total: CompressedIssues[key]
        }))
        .sort((a, b) => b.total - a.total)
        .map(kiosk => ({
            key: kiosk.kiosk,
            cells: [
                {data: kiosk.kiosk},
                {data: kiosk.total}
            ]
        }));
    };

    const getModalData = () => {
        return Issues.filter(kiosk => kiosk.KioskID == KioskSelected).sort((a, b) => 
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
        .map((kiosk, index) => ({
            key: `${kiosk.KioskID}-${index}`,
            cells: [
                {data: kiosk.KioskID},
                {data: new Date(kiosk.timestamp).toLocaleDateString()},
                {data: kiosk.description},
                {data: kiosk.last_step},
            ]
        }));
    };

    const renderTableButtons = () => {
        return (
            <div style={{display: 'flex'}}>
                <CSVLink style={{ background: 'none', border: 'none' }} target="_blank" className="hidden" id="table-download" data={downloadData()} filename={"kiosk-issues.csv"} ></CSVLink>
                <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<FiDownload />} // Using react-icons/fi for download icon
                    onClick={() => document.getElementById('table-download').click()}>Issues</Button>
            </div>
        );
    };

    const downloadData = () => {
        return Issues.map(kiosk => ({
            "Kiosk": kiosk.KioskID,
            "Timestamp": kiosk.timestamp.split('T')[0],
            "Description": kiosk.description,
            "Last Step": kiosk.last_step
        })).sort((a, b) => a.KioskID < b.KioskID ? -1 : a.KioskID > b.KioskID ? 1 : 0);
    };

    return(
        <div className="ViewedContentContainer" id="OpenContainer" >
            <div style={{ height: '100%' }}>
                <div className="TitleBarContainer">
                    <h1 title="Kiosk analytics">Issue Tracking</h1>
                </div>

                <CustomTable 
                    searchable
                    paginate
                    active_tab={ActiveTab}
                    loading_data={Loading}
                    tabs={TableTabs}
                    tab_style="select"
                    filter_data={FilterTable}
                    headers={TableHeaders}
                    rows={getTableData()}
                    onClick={handleModal}
                    table_buttons={renderTableButtons()}
                />

                <Modal modal_id="issue_modal" dimension={{width: 700, height: 620}} modal_header={KioskSelected}>
                    <div style={{ textAlign: 'center' }}>
                        <CustomTable 
                            headers={TableHeaders2}
                            rows={getModalData()}
                            paginate
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default IssueTracking;
