import React from 'react';
import Modal from '../../../components/Modal';
import DatabaseErrorLogger from '../../../components/DatabaseErrorLogger';
import { Input, InputAdornment, Switch, FormControlLabel, Checkbox } from '@mui/material';
import { TextField, LinearProgress, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const BuildItemModal = (props) => {
	const {
		itemTotals,
		builds,
		filteredBuild,
		building,
		selectedBuild,
		bin,
		bins,
		buildCount,
		toggleBuild,
		selectBuild,
		handleClearItems,
		handleBuildQty,
		buildingMessage,
		onAddItem,
		loading,
		handleCustomAmountCheck,
		customChecked,
		// handleCustomBuildAmount,
		customBuildAmount,
	} = props;
	const buildsForSelect = !filteredBuild.length ? builds : filteredBuild;
	const selectedBuildObject = buildsForSelect.find((element) => element.id === selectedBuild);
	const selectedBuildRequirements =
		selectedBuildObject && selectedBuildObject.requirements ? JSON.parse(selectedBuildObject.requirements) : '';

	const getFormInfo = () => {
		const location =
			document.getElementById('location') && document.getElementById('location')
				? document.getElementById('location').value
				: '';
		const controllerData = {
			location: location,
			id: selectedBuildObject ? selectedBuildObject.id : '',
			code_id: selectedBuildObject ? selectedBuildObject.code_id : '',
			model: selectedBuildObject ? selectedBuildObject.model : '',
			action: 'Completed',
		};

		onAddItem(controllerData);
	};

	return (
		<div>
			<Modal modal_id='build-items' dimension={{ width: 850 }} modal_header='Build Items' onClose={toggleBuild}>
				<div className='item-field-container'>
					<div className='centered-content-wide flex-just-even'>
						<div className='C1'>
							<TableContainer className='item-table' component={Paper}>
								<Table stickyHeader aria-label='simple table item-field-container'>
									<TableHead>
										<TableRow>
											<TableCell className='item-buttons' align='center'>
												Available Items
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{itemTotals.map((item) => (
											<TableRow key={item.item_id}>
												<TableCell id={item.item_id} className='item-buttons' align='center'>
													{item.item_name} ({item.total ? item.total : item.units})
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</div>

						{selectedBuild && !bin.startsWith('BIN-KIOSK') ? (
							<div className='C2'>
								<TableContainer className='item-table' component={Paper}>
									<Table stickyHeader aria-label='simple table item-field-container'>
										<TableHead>
											<TableRow>
												<TableCell className='item-buttons' align='center'>
													{buildsForSelect.find((element) => element.id === selectedBuild)
														? buildsForSelect.find((element) => element.id === selectedBuild).item_name
														: ''}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedBuildRequirements && selectedBuildRequirements.length
												? selectedBuildRequirements.map((idObj) => {
														const matchingItem = itemTotals.find((item) => item.item_id === idObj.item);
														return matchingItem ? (
															<TableRow key={matchingItem.item_name}>
																<TableCell align='center'>
																	<h3>{matchingItem.item_name}</h3>
																</TableCell>
															</TableRow>
														) : null;
												  })
												: null}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						) : (
							<div className='C2'>
								<TableContainer className='item-table' component={Paper}>
									<Table className='simple table item-field-container'>
										<TableHead>
											<TableRow>
												<TableCell className='item-buttons' align='center'>
													Selected Barcodes
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{itemTotals.map((item) => (
												<TableRow key={item.item_id}>
													<TableCell id={item.item_id} className='item-buttons' align='center'>
														{item.items[0].barcode} ({item.total ? item.total : item.units})
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						)}

						<div className='C3'>
							<TableContainer className='item-table' component={Paper}>
								<Table stickyHeader aria-label='simple table item-field-container'>
									<TableHead>
										<TableRow>
											<TableCell className='item-buttons' align='center'>
												Available Builds
											</TableCell>
										</TableRow>
									</TableHead>
								</Table>
								{builds.length || bin.startsWith('BIN-KIOSK') ? (
									<div className='item-field-container centered-content-wide build-item-input'>
										<Select
											labelId='demo-simple-select-outlined-label'
											id='build'
											className='space-below item-field centered-content-wide'
											value={selectedBuild}
											onChange={selectBuild}
											label='Available Builds'
										>
											{bin && bin.startsWith('BIN-KIOSK') ? (
												<MenuItem id={'kiosk-build'} key={'kiosk-build'} value={'Production Kiosk'}>Production Kiosk</MenuItem>
											) : (
												buildsForSelect.map((build) => (
													<MenuItem id={build.id} key={build.id} value={build.id}>
														{build.item_name}
													</MenuItem>
												))
											)}
										</Select>

										{bin && bin.startsWith('BIN-KIOSK') ? (
											<TextField
                      className='space-below item-field centered-content-wide'
												id='build-qty'
												disabled
												label='Qty to Build'
												value={buildCount}
												type='number'
											/>
										) : (
											<TextField
												className='space-below item-field centered-content-wide'
												value={customBuildAmount > 0 ? customBuildAmount : ''}
												onChange={handleBuildQty}
												disabled={buildCount > 1 || !customChecked ? true : false || selectedBuild ? false : true}
												label='Custom Amount'
												type='number'
												margin='none'
												InputProps={{
													min: 0,
													endAdornment: (
														<InputAdornment position='end'>
															<FormControlLabel
																control={
																	<Switch
																		checked={buildCount <= 1 && customChecked}
																		size='small'
																		onChange={handleCustomAmountCheck}
																		disabled={!selectedBuild || buildCount > 1}
																	/>
																}
															/>
														</InputAdornment>
													),
												}}
											/>
										)}

										<Autocomplete
											freeSolo
											id='location'
											className='space-below item-field centered-content-wide'
											value={''}
											disableClearable
											options={bins.map((bin) => bin.name)}
											renderInput={(params) => (
												<TextField
													className='space-below item-field centered-content-wide'
													{...params}
													label='Destination'
													InputProps={{
														...params.InputProps,
														type: 'search',
													}}
												/>
											)}
										/>
									</div>
								) : (
									<div>
										<p>There are no builds that match the items in this bin.</p>
									</div>
								)}
							</TableContainer>
						</div>
					</div>
				</div>

				{!building ? (
					<div>
						<div className='flex-just-center flex-column vert-margin-med'>
							<div>{buildingMessage}</div>
							<div className='flex-row flex-just-center vert-margin-small'>
								<Button className='rti-blue-round' variant='contained' onClick={handleClearItems}>
									Clear
								</Button>
								<Button className='rti-blue-round' variant='contained' onClick={getFormInfo}>
									Build
								</Button>
							</div>
						</div>
					</div>
				) : null}
				{loading ? <LinearProgress /> : null}
			</Modal>
		</div>
	);
};
