import React, { useState, useEffect } from 'react';
import '../../StyleSheets/BugReport.css';

export default function TimeOffRequest({ fullName, onAlert, allUsers, userID }) {
  const [notes, setNotes] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('08:00');
  const [returnDate, setReturnDate] = useState('');
  const [returnTime, setReturnTime] = useState('08:00');

  useEffect(() => {
    getTodaysDate();
    getTomorrowsDate();
  }, []);

  const ButtonClose = () => {
    document.getElementById('EmpTimeOffRequestDialog').value = '';
    $('#EmpTimeOffRequestDialog').hide();
  };

  const getTodaysDate = () => {
    let todaysDate = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    todaysDate = todaysDate.toLocaleDateString('en-US', options);

    const month = todaysDate.slice(-10, -8);
    const day = todaysDate.slice(-7, -5);
    const year = todaysDate.slice(-4);

    const today = year + '-' + month + '-' + day;
    setStartDate(today);
  };

  const getTomorrowsDate = () => {
    const todaysDate = new Date();
    let tomorrow = new Date(todaysDate);
    tomorrow.setDate(todaysDate.getDate() + 1);

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    tomorrow = tomorrow.toLocaleDateString('en-US', options);

    const month = tomorrow.slice(-10, -8);
    const day = tomorrow.slice(-7, -5);
    const year = tomorrow.slice(-4);

    const tomorrowDate = year + '-' + month + '-' + day;
    setReturnDate(tomorrowDate);
  };

  const AddNewItemBtn = () => {
    if (notes === '') {
      onAlert({
        message: 'Notes field cannot be blank',
        severity: 'warning',
      });
      document.getElementById('TimeOffNotes').style.borderColor = 'red';
    } else {
      const status = 'Pending';
      const formattedStartDate = formatDate(startDate, startTime);
      const formattedReturnDate = formatDate(returnDate, returnTime);

      const objectToInsert = {
        FullName: fullName,
        StartDate: formattedStartDate,
        ReturnDate: formattedReturnDate,
        Notes: sanitizeNotes(notes),
        Status: status,
      };

      const userEmail = allUsers.filter((user) => user.UserID === userID).map((user) => user.Email);

      socket.emit('insertEmpTimeOffRequest', objectToInsert, (boolean) => {
        if (boolean) {
          SendEmail(
            [userEmail[0], 'brian@registration-technology.com'],
            objectToInsert.StartDate,
            objectToInsert.ReturnDate,
            objectToInsert.Notes,
            objectToInsert.Status
          );
          ButtonClose();
          setNotes('');
          onAlert({
            message: "Time off request received, you'll receive an email shortly.",
            severity: 'success',
          });
        } else {
          onAlert({
            message: 'Error submitting time off request',
            severity: 'error',
          });
        }
      });
    }
  };

  const formatDate = (date, time) => {
    const dateParts = date.split('-');
    const formattedDate = dateParts[1] + '/' + dateParts[2] + '/' + dateParts[0];

    const timeParts = time.split(':');
    let hours = parseInt(timeParts[0]);
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    return formattedDate + ' ' + hours + ':' + timeParts[1] + ' ' + period;
  };

  const sanitizeNotes = (notes) => {
    return notes.replace(/\\/g, '').replace(/'/g, '').replace(/"/g, '');
  };

  const SendEmail = (userEmail, startDate, returnDate, notes, status) => {
    const to = userEmail;
    const text =
      `<div width="100%",style="text-align:center;">` +
      `<h1 style="text-align:center;">Time Off Request</h1>` +
      `<table style="background-color:black; width: 400px;">` +
      `<tbody>` +
      `<tr><td style="background-color:bisque; width: 100px;">Employee</td><td style="background-color:white;">${fullName}</td></tr>` +
      `<tr><td style="background-color:bisque;">Start Date</td><td style="background-color:white;">${startDate}</td></tr>` +
      `<tr><td style="background-color:bisque;">Return Date</td><td style="background-color:white;">${returnDate}</td></tr>` +
      `<tr><td style="background-color:bisque;">Notes</td><td style="background-color:white;">${notes}</td></tr>` +
      `<tr><td style="background-color:bisque;">Status</td><td style="background-color:white;">${status}</td></tr>` +
      `</tbody>` +
      `</table >` +
      `<p><b>Please go to the console to review this request</b></p>` +
      `</div >`;
    $.get('/send', { to: to, subject: `RTI Time Off Request - ${fullName}`, text: text }, function (data) {});
  };

  return (
    <div className="CustomDialog" id="EmpTimeOffRequestDialog">
      <h1>Time Off Request</h1>
      <h2>Hi, {fullName}</h2>
      <p>Please enter the required information about your time off request.</p>
      <p>You'll receive an email when your request has been approved or rejected.</p>

      <div>
        <label>Start Date</label>
        <br />
        <input type="date" id="StartDate" value={startDate} onChange={(e) => setStartDate(e.target.value)}></input>
        <input type="time" id="StartTime" value={startTime} onChange={(e) => setStartTime(e.target.value)}></input>
        <br />
      </div>

      <div>
        <label>Return Date</label>
        <br />
        <input type="date" id="ReturnDate" value={returnDate} onChange={(e) => setReturnDate(e.target.value)}></input>
        <input type="time" id="ReturnTime" value={returnTime} onChange={(e) => setReturnTime(e.target.value)}></input>
        <br />
      </div>

      <div className="Notes">
        <label>Notes</label>
        <br />
        <textarea
          className="notes-field"
          id="TimeOffNotes"
          style={{ height: '150px' }}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        ></textarea>
      </div>

      <div>
        <button className="RTIButton" id="BugReportClose" onClick={ButtonClose}>
          Close
        </button>
        <button className="RTIButton" id="AddNewItemBtn" onClick={AddNewItemBtn} style={{ float: 'right' }}>
          Add
        </button>
      </div>
    </div>
  );
}
