/**
 * Component for logging errors to a database.
 *
 * @param {Object} props - The component props.
 * @param {Error} props.error - The error object.
 * @param {Object} props.errorInfo - Additional error information.
 *
 * @returns {null} This component does not render anything.
 *
 * @example
 * <DatabaseErrorLogger error={error} errorInfo={errorInfo} />
 */
import { useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const DatabaseErrorLogger = (props) => {
	const { error } = props;
	// this will use the stack to grab the file name generating the error

	useEffect(() => {
		const handleComponentError = async (error) => {
			await logErrorToDatabase(error);
		};

		if (error) {
			handleComponentError(error);
		}

		async function logErrorToDatabase(error) {
			// api endpoint
			const apiUrl = '/api/database_error_logging';

			const DATETIME = new Date(Date.now()).toISOString().split('T').join(' ');
			const { fileName, functionName } = await extractFileNameFromStackTrace(error.stack);

			const priority = determinePriority(error);
			const severity = determineSeverity(error);

			// payload with error information
			const payload = {
				fileNameID: fileName,
				functionNameID: functionName,
				timestampID: DATETIME,
				errorMessage: error.message,
				stackTrace: error.stack,
				Priority: priority,
				Severity: severity,
			};

			try {
				const response = await axios.post(apiUrl, payload);
				console.info(`Error log SUCCESS ${response.data}`);
			} catch (error) {
				console.error(`Error logging into database: ${error}`);
			}
		}

		function determinePriority(error) {
			if (
				error.message.includes('Critical') ||
				error.message.includes('Failed') ||
				error.message.includes('not defined') ||
				error.message.includes('not found')
			) {
				return 'High';
			} else if (error.message.includes('Warning') || error.message.includes('Deprecated')) {
				return 'Medium';
			} else {
				return 'Low';
			}
		}

		function determineSeverity(error) {
			// Example logic to determine severity
			if (error.stack.includes('TypeError') || error.stack.includes('SyntaxError')) {
				return 'Critical';
			} else if (error.stack.includes('ReferenceError') || error.stack.includes('RangeError')) {
				return 'High';
			} else {
				return 'Low';
			}
		}

		async function extractFileNameFromStackTrace(stackTrace) {
			const regex = /at\s+([^\s]+)\s+\(([^)]+)\)/;
			const match = stackTrace.match(regex);

			const isMatch = match ? true : false;
			const functionName = match[1];
			const fileName = match[2];
			if (isMatch) return { functionName, fileName };
			else return { functionName, fileName };
		}
	}, []);

	return null;
};

DatabaseErrorLogger.propTypes = {
	error: PropTypes.object.isRequired,
	errorInfo: PropTypes.object,
};

export default DatabaseErrorLogger;
