import React, { useEffect, useState } from 'react';
import AlertBar from '../../components/AlertBar.js';
import CustomTable from '../../components/Table.js';
import { AiOutlineSave } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import Button from '@mui/material/Button';
import { FormatEmailTemplateTime } from '../API/TextFormatingFunctions.js';
import { useCustomContext } from '../../hoc/Context.js';
import { CSVLink } from 'react-csv';
import $ from 'jquery';

const TableHeaders = [
	{ id: 'time', name: 'Time' },
	{ id: 'sunday', name: 'Sunday' },
	{ id: 'monday', name: 'Monday' },
	{ id: 'tuesday', name: 'Tuesday' },
	{ id: 'wednesday', name: 'Wednesday' },
	{ id: 'thursday', name: 'Thursday' },
	{ id: 'friday', name: 'Friday' },
	{ id: 'saturday', name: 'Saturday' },
];

const TechSupportSchedule = () => {
	const [schedule, setSchedule] = useState([]);
	const [dispatchers, setDispatchers] = useState([]);
	const [UserList, setUserList] = useState({});
	const [Loading, setLoading] = useState(true);
	const [retrieveData, setRetrieveData] = useState(true);
	const [snackbar, setSnackbar] = useState({
		visible: false,
		message: '',
		severity: 'success',
	});
	const { userState } = useCustomContext();

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (retrieveData) {
				// only retrieve this data once on page load
				$.post(`/api/table_data`, { table: 'tech_support_table' }, (res) => {
					if (res.status === 200) {
						const [schedule, dispatchers] = res.data;
						const usersList = dispatchers[0];
						usersList.map((user) => {
							UserList[user.UserID] = user.FullName;
						});

						setSchedule(schedule);
						setDispatchers(dispatchers[0]);
						setUserList(UserList);
						setRetrieveData(false);
						setLoading(false);
					} else {
						setRetrieveData(false);
						setLoading(false);
						setSnackbar((prevState) => ({
							...prevState,
							visible: true,
							message: res.error,
							severity: 'error',
						}));
					}
				});
			}
		}

		return () => {
			mounted = false;
		};
	}, []);

	const RefreshTableData = () => {
		socket.emit('selectTechSupportScheduleForTM', (result) => {
			setSchedule(result);
		});
	};

	const SaveToDB = () => {
		socket.emit('updateScheduleForTM', schedule, (result) => {
			if (result) {
				//Close CreateJobModal
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'Schedule updated successfully.',
					severity: 'success',
				}));
				RefreshTableData();
			} else {
				//Notify user of Failure
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: 'ERROR: Schedule did not save successfully.',
					severity: 'error',
				}));
			}
		});
	};

	const AssignDispatcher = (e) => {
		if (userState.user.role) {
			let updated_schedule = [...schedule];
			const [index, day] = e.target.id.split('-');

			updated_schedule[index][day] = document.getElementById('TSSDispatcherSelect').value;
			setSchedule(updated_schedule);
		}
	};

	function RenderTableButtons() {
		return userState.user.role ? (
			<div style={{ display: 'flex' }}>
				<Button className='rti-blue-round' variant='contained' endIcon={<AiOutlineSave />} onClick={SaveToDB}>
					Save
				</Button>
				<CSVLink
					style={{ background: 'none', border: 'none' }}
					target='_blank'
					className='hidden'
					id='table-download'
					data={downloadData()}
					filename={'support-schedule.csv'}
				></CSVLink>
				<Button
					className='rti-blue-round'
					variant='contained'
					startIcon={<FiDownload />}
					onClick={() => document.getElementById('table-download').click()}
				>
					Schedule
				</Button>
			</div>
		) : undefined;
	}

	const downloadData = () => {
		return schedule.map((user) => ({
			Time: FormatEmailTemplateTime(user.Time),
			Sunday: UserList[user.Sunday],
			Monday: UserList[user.Monday],
			Tuesday: UserList[user.Tuesday],
			Wednesday: UserList[user.Wednesday],
			Thursday: UserList[user.Thursday],
			Friday: UserList[user.Friday],
			Saturday: UserList[user.Saturday],
		}));
	};

	const getTableData = () => {
		return schedule.map((user, index) => ({
			key: index,
			cells: [
				{ data: FormatEmailTemplateTime(user.Time) },
				{ data: UserList[user.Sunday], id: `${index}-Sunday` },
				{ data: UserList[user.Monday], id: `${index}-Monday` },
				{ data: UserList[user.Tuesday], id: `${index}-Tuesday` },
				{ data: UserList[user.Wednesday], id: `${index}-Wednesday` },
				{ data: UserList[user.Thursday], id: `${index}-Thursday` },
				{ data: UserList[user.Friday], id: `${index}-Friday` },
				{ data: UserList[user.Saturday], id: `${index}-Saturday` },
			],
		}));
	};

	const handleCloseSnack = () => {
		setSnackbar((prevState) => ({
			...prevState,
			visible: false,
		}));
	};

	return (
		<div className='ViewedContentContainer' id='TSSContainer'>
			<div style={{ height: '100%' }}>
				<div className='TitleBarContainer'>
					<h1 title='Edit who recieves a tech support call. The list of names are pulled from the dispatchers table. So if you need to change the phone number of the person recieving the call, change it in the Dispatchers table. To assign a new person to a time, select their name from the dropdown menu and click in the cell you wish to place them in. If you want them to recieve a call between 8am and 830am on sunday, click the top right cell, to the right of 8:00am and just below Sunday.'>
						Tech Support Schedule
					</h1>
				</div>

				{userState.user.role ? (
					<div className='TopBarContainer' style={{ width: '98%', margin: 'auto' }}>
						<select className='TSSDispatcherSelect' id='TSSDispatcherSelect'>
							{dispatchers.map((dispatcher, index) => (
								<option key={`${dispatcher.UserID}-${index}`} value={dispatcher.UserID}>
									{dispatcher.FullName}
								</option>
							))}
						</select>
					</div>
				) : undefined}
				<AlertBar
					visible={snackbar.visible}
					onClose={handleCloseSnack}
					message={snackbar.message}
					severity={snackbar.severity}
				/>
				<CustomTable
					paginate
					searchable
					loading_data={Loading}
					headers={TableHeaders}
					rows={getTableData()}
					onClick={AssignDispatcher}
					table_buttons={RenderTableButtons()}
				/>
			</div>
		</div>
	);
};

export default TechSupportSchedule;
