import React from 'react';
import Modal from '../../../components/Modal';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { AiOutlineScan as ScanIcon } from 'react-icons/ai'; // Updated icon import
import '../../../StyleSheets/InventorySystem.css'

export const AttachItemModal = (props) => {
    const { onToggleItem, validateModem } = props;
    return (
        <div>
            <Modal modal_id='attach-item' dimension={{ width: 500 }} modal_header='Attach Items' onClose={onToggleItem}>
                <div className='text-center'>
                    <p>
                        Scan the RID# of the item this part will go into, then click <b>OK</b>.
                    </p>
                    <div className='innerContainer-pcore'>
                        <FormControl variant='standard'>
                            <Input
                                style={{ width: 232, fontSize: 16 }}
                                id='modem-rid'
                                placeholder='Scan barcode...'
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <ScanIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className='vert-margin-med'>
                        <Button className='rti-blue-round' onClick={validateModem}>
                            Ok
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
