// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 4px;
}

.search-container input {
    border: none;
    outline: none;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/StyleSheets/SearchInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;AACf","sourcesContent":[".search-container {\n    display: flex;\n    align-items: center;\n    border: 1px solid lightgray;\n    border-radius: 8px;\n    padding: 4px;\n}\n\n.search-container input {\n    border: none;\n    outline: none;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
