import React from 'react';
import Modal from '../../../components/Modal';
import { ScannedItemsTable } from '../InventoryItemControllers/BulkInventoryController/ScannedItemsTable';
import { ScannedItemsInput } from '../InventoryItemControllers/BulkInventoryController/ScannedItemsInput';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

export const StickerContractorJobModal = (props) => {
	const {
		toggleContractor,
		handleTransferItems,
		buildItems,
		removeContractorComponent,
		onScanContractorComponents,
		contractorComponentScan,
		bins,
		bin,
		onAddItem,
		loading,
		handleRepurposedChange,
		handleRepurposeCheck,
		repurposedYear,
		repurposedCheck,
		repurposedQty,
		handleRepurposeQty
	} = props;
	
	const getFormInfo = () => {
		const location =
			document.getElementById('location') && document.getElementById('location')
				? document.getElementById('location').value
				: '';
		const controllerData = {
			location: location,
			id: document.getElementById('barcode') ? document.getElementById('barcode').value : '',
			repurposedYear: document.getElementById('repurposedYear') ? document.getElementById('repurposedYear').value : '',
			action: 'Pending',
		};

		onAddItem(controllerData);
	};

	return (
		<div className="item-field-container">
			<div className="centered-content-wide flex-just-even">
				<Modal
					modal_id="contractor"
					dimension={{ width: 600 }}
					modal_header="Sticker Contractor Job"
					onClose={toggleContractor}
				>
					<div className="grid-container item-grid-auto-fit">
						<ScannedItemsInput
							onBarcodeKeyDown={onScanContractorComponents}
							qtyValue={contractorComponentScan.length}
							locationValue={bin}
							locationOptions={bins.map((bin) => bin.name)}
							repurposedYear={repurposedYear}
							repurposedCheck={repurposedCheck}
							additionalOptions={['2024', '2025', '2026']}
							handleRepurposedChange={handleRepurposedChange}
							handleRepurposeCheck={handleRepurposeCheck}
							handleRepurposeQty={handleRepurposeQty}
							repurposedQty = {repurposedQty}
						/>
						<ScannedItemsTable data={contractorComponentScan} removeItem={removeContractorComponent} />
					</div>
					<div className="flex-just-even vert-margin-med full-width">
						<div>
							<Button className="item-buttons" variant="outlined" color="primary" onClick={getFormInfo}>
								Enter
							</Button>
						</div>
					</div>
					{loading ? <LinearProgress /> : null}
				</Modal>
			</div>
		</div>
	);
};
