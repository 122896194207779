import React from 'react';
import Modal from '../../../components/Modal';
import Button from '@mui/material/Button';

export const DetachItemModal = (props) => {
	const {onToggleDetach, onDetachItem } = props;
	return (
		<div>
			{' '}
			<Modal modal_id='detach-item' dimension={{ width: 500 }} modal_header='' onClose={onToggleDetach}>
				<div className='text-center'>
					<h3>Are you sure you want to detach this item?</h3>
					<div className='flex-just-even vert-margin-med'>
						<Button variant='outlined' color='secondary' size='small' onClick={onToggleDetach}>
							No
						</Button>
						<Button variant='outlined' color='primary' size='small' onClick={onDetachItem}>
							Yes
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};
