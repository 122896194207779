import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { AiOutlineSearch } from 'react-icons/ai'; // Replacing SearchIcon
import Input from '@mui/material/Input';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { MdExpandMore } from 'react-icons/md'; // Replacing ExpandMoreIcon
import Button from '@mui/material/Button';
import Spinner from '../../components/Spinner.js';
import '../../StyleSheets/Help.css';
import { useNavigate } from 'react-router-dom';

const Help = () => {
    const navigate = useNavigate();

    const [expanded, setExpanded] = useState(false);
    const [search, setSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [solutions, setSolutions] = useState([]);

    const _query = new URLSearchParams(window.location.search);

    const _params = Array.from(_query.keys()).reduce((acc, val) => ({
        ...acc, [val]: _query.get(val)
    }), {});

    useEffect(() => {
        let mounted = true;
    
        if (mounted) {
            retrieveData(true, _params.search ? _params.search : "");
    
            if (_params.search) {
                setSearch(true);
            }
        }
    
        return () => {
            mounted = false;
        }
    }, []);
    
    const retrieveData = (_load, _query) => {
        if (_load) setLoading(true);
    
        socket.emit('getTechSolutions', _query, (res) => {
            if (!res) {
                console.error(`Help.getTechSolutions: There was an issue calling this method`)
                setLoading(false);
            } else {
                setSolutions(res);
                setLoading(false);
            }
        });
    };
    const handleSearch = (e) => {
        if (e.keyCode === 13) {
            const searchVal = document.getElementById('search-input').value;
            if (searchVal) {
                setSearch(true);
                handleURLParams([{
                    key: 'search',
                    val: searchVal
                }], 'add');
                retrieveData(true, searchVal);
            }
        }
    }

    const handleURLParams = (_params, _action) => {
        const urlParams = new URLSearchParams(window.location.search);
        _params.forEach(param => {
            if (_action === 'add') {
                urlParams.set(`${param.key}`, `${param.val}`);
            } else {
                urlParams.delete(`${param.key}`);
            }
        });
        navigate(`${window.location.pathname}?${urlParams.toString()}`, { replace: true });
    };

    const clearSearch = () => {
        setSearch(false);
        handleURLParams([{
            key: 'search'
        }], 'remove');
        document.getElementById('search-input').value = '';
        retrieveData(true, '')
    }

    const handlePanelClick = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const renderAccordian = () => {
        return solutions.length ? solutions
            .map(q => (
                <Accordion style={{ maxWidth: 620 }} key={q.id} expanded={expanded === q.id} onChange={handlePanelClick(q.id)}>
                    <AccordionSummary
                        expandIcon={<MdExpandMore />}
                        id={q.id}
                    >
                        <h3>{JSON.parse(q.title)}</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className='help-solution'>{JSON.parse(q.solution)}</p>
                    </AccordionDetails>
                </Accordion>
            )) : (
                <div className='text-center'>
                    <h2>Sorry, we couldn't find what you're looking for.</h2>
                    <h2>Please try searching for something else.</h2>
                </div>
            )
    }

    return (
        <div className='help-container'>
            <div className='help-wrapper'>
                <div style={{ padding: 48 }}>
                    <h1 className='text-center'>Tech Solutions</h1>
                </div>
                <div className='flex-column flex-align-center flex-just-center'>
                    <FormControl variant="standard">
                        <Input
                            style={{ width: 400, fontSize: 16 }}
                            id="search-input"
                            autoFocus
                            placeholder='Search...'
                            startAdornment={
                                <InputAdornment position="start">
                                    <AiOutlineSearch />
                                </InputAdornment>}
                            onKeyDown={handleSearch}
                        />
                    </FormControl>
                    {search ? <div className='vert-margin-med'>
                        <Button className='rti-blue-round' variant="contained" onClick={clearSearch}>clear</Button>
                    </div> : null}
                </div>
                {!loading ? <div className='vert-margin-med'>
                    {renderAccordian()}
                </div> : <Spinner />}
            </div>
        </div>
    )
}

export default Help;
