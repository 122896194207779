import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Modal from "../../components/Modal.js";
import AlertBar from "../../components/AlertBar.js";
import Button from "@mui/material/Button";
import { MdAdd } from 'react-icons/md';
import { MdGetApp } from 'react-icons/md';
import CustomTable from "../../components/Table.js";
import "../../StyleSheets/TimeEntry.css";
import {
  GetFriday,
  FormatDate,
  FormatTime,
  FormatNA,
  FormatDateTimeLocal,
  FormatLocalDateTimeFromMonth,
  FormatLocalDateToUTC,
  DateToLocale,
  ConvertTwelveToTwentyFour,
} from "../../imports/API/TextFormatingFunctions.js";
import { useCustomContext } from "../../hoc/Context.js";

const TableHeaders = [
  { id: "name", name: "Name", sortable: true, numeric: false },
  { id: "time-in", name: "In" },
  { id: "time-out", name: "Out" },
  { id: "work-time", name: "Work Time" },
  { id: "notes", name: "Notes" },
];

//Formats the TechnicianTable Information for each row
const TimeEntryTable = (props) => {
  const [afterDate, setAfterDate] = useState(GetFriday("previous"));
  const [beforeDate, setBeforeDate] = useState(GetFriday("next"));
  const [Loading, setLoading] = useState(true);
  const [TimeEntryDB, setTimeEntryDB] = useState([]);
  const [EntrySelected, setEntrySelected] = useState({});
  const [TimeIn, setTimeIn] = useState(null);
  const [TimeOut, setTimeOut] = useState(null);
  const [Users, setUsers] = useState([]);
  const [retrieveData, setRetrieveData] = useState([]);
  const [entryModal, setEntryModal] = useState(false);
  const [addEntryModal, setAddEntryModal] = useState(false);
  const [entryData, setEntryData] = useState([]);
  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: "",
    severity: "success",
  });
  const { userState, userDispatch } = useCustomContext();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (retrieveData) {
        // only retrieve this data once on page load
        $.post(`/api/table_data`, { table: "time_entry_table" }, (res) => {
          if (res.status == "OK") {
            const [entries, users] = res.data;
            let results = [];
            for (let i = 0; i < entries.length; i++) {
              results.push({
                FullName: entries[i].FullName,
                Notes: entries[i].Notes,
                PunchIn: FormatLocalDateTimeFromMonth(entries[i].PunchIn),
                PunchOut: FormatLocalDateTimeFromMonth(entries[i].PunchOut),
                TimeEntryID: entries[i].TimeEntryID,
                WorkTimeTotal: entries[i].WorkTimeTotal,
                Changes: entries[i].Changes,
              });
            }
            setTimeEntryDB(results);

            setEntryData(
              results.map((el) => ({
                ...el,
                PunchIn: DateToLocale(new Date(el.PunchIn)),
                PunchOut: el.PunchOut ? DateToLocale(new Date(el.PunchOut)) : ""
              }))
            );
            setUsers(users.filter((e) => e.Activity == "Active"));
            setRetrieveData(false);
            setLoading(false);
          } else {
            setRetrieveData(false);
            setLoading(false);
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: res.error,
              severity: "error",
            }));
          }
        });
      }
    }

    return () => {
      mounted = false;
    };
  }, [EntrySelected]);

  useEffect(() => {
    $("#entry-modal").toggleClass("flex-box");
  }, [entryModal]);

  useEffect(() => {
    $("#add-entry-modal").toggleClass("flex-box");
  }, [addEntryModal]);

  function RefreshTableData() {
    socket.emit("selectDatesFromTimeEntry", afterDate, beforeDate, (result) => {
      let results = [];
      for (let i = 0; i < result.length; i++) {
        results.push({
          FullName: result[i].FullName,
          Notes: result[i].Notes,
          PunchIn: FormatLocalDateTimeFromMonth(result[i].PunchIn),
          PunchOut: FormatLocalDateTimeFromMonth(result[i].PunchOut),
          TimeEntryID: result[i].TimeEntryID,
          WorkTimeTotal: result[i].WorkTimeTotal,
          Changes: result[i].Changes,
        });
        return results;
      }

      setEntrySelected({});
      setTimeIn(null);
      setTimeOut(null);
      setTimeEntryDB(results);
      setEntryData(
        results.map((el) => ({
          ...el,
          PunchIn: DateToLocale(new Date(el.PunchIn)),
          PunchOut: el.PunchOut ? DateToLocale(new Date(el.PunchOut)) : ""
        }))
      );
      setEntryModal(false);
      setAddEntryModal(false);
      setLoading(false);
    });
  }

  const GetTimeEntryDetails = (e) => {
    if (userState.user.role) {
      let id = e.target.id;
      let entry_details = TimeEntryDB.filter((e) => e.TimeEntryID == id)[0];

      setTimeIn(FormatDateTimeLocal(entry_details.PunchIn));
      setTimeOut(FormatDateTimeLocal(entry_details.PunchOut));
      setEntrySelected(entry_details);
      setEntryModal(true);
    }
  };

  const AdjustTimeEntry = (type) => {
    let local_time_in = new Date(TimeIn);
    let local_time_out = new Date(TimeOut);
    const time_entry_in = FormatLocalDateToUTC(local_time_in);
    const time_entry_out = FormatLocalDateToUTC(local_time_out);
    const user =
      type == "insert"
        ? document.getElementById("user_entry").value
        : EntrySelected.FullName;
    const _changes = document.getElementById("entry-changes").value;
    const check_in =
      time_entry_in != "1970-01-01 00:00:00" ? time_entry_in : null;
    const check_out =
      time_entry_out != "1970-01-01 00:00:00" ? time_entry_out : null;
    let total_time = `${GetTotalWorkTime()}:00`;

    if (user) {
      if ((type == "insert" && check_in) || type == "update") {
        socket.emit(
          "adjustTimeEntry",
          {
            ID: EntrySelected.TimeEntryID ? EntrySelected.TimeEntryID : null,
            FullName: user,
            TimeIn: check_in ? time_entry_in : null,
            TimeOut: check_out ? time_entry_out : null,
            TotalTime: total_time != "00:00:00" ? total_time : null,
            Notes: ` Time Entry ${
              type == "update" ? "Adjusted" : "Created"
            } - ${localStorage.getItem("FullName")}`,
            Changes: _changes,
          },
          type,
          (response) => {
            if (!response) {
              setSnackbar((prevState) => ({
                ...prevState,
                visible: true,
                message: `ERROR: There was an issue ${
                  type == "update" ? "adjusting" : "creating"
                } this time entry`,
                severity: "error",
              }));
            } else {
              type == "update" ? setEntryModal(false) : setAddEntryModal(false);
              RefreshTableData();
              setSnackbar((prevState) => ({
                ...prevState,
                visible: true,
                message: `Time entry has been ${
                  type == "update" ? "updated" : "created"
                }`,
                severity: "success",
              }));
            }
          }
        );
      } else {
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `Oops!  Looks like you forgot to set a punch in time for this user.`,
          severity: "warning",
        }));
      }
    } else {
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: A user must be selected before trying to ${
          type == "update" ? "adjust" : "create"
        } this time entry.`,
        severity: "warning",
      }));
    }
  };

  function GetTotalWorkTime() {
    if (TimeIn && TimeOut) {

      // punch in time
      const time_in = new Date(TimeIn).getTime();

      // punch out time
      const time_out = new Date(TimeOut).getTime();

      let diff = (time_out - time_in) / 1000;
      let hours = Math.abs(Math.floor(diff / 3600));
      let minutes = Math.abs(Math.floor(diff / 60) % 60);

      hours = hours.toString().length == 1 ? `0${hours}` : hours;
      minutes = minutes.toString().length == 1 ? `0${minutes}` : minutes;

      return `${hours}:${minutes}`;
    } else {
      return "";
    }
  }

  const handleTimeEntryDownload = () => {
    document.getElementById("time-sheet-download").click();
  };

  function RenderTableButtons() {
    return userState.user.role ? (
      <div style={{ display: "flex" }}>
        <div>
          <Button
            className="rti-blue-round"
            variant="contained"
            startIcon={<MdGetApp />}
            onClick={handleTimeEntryDownload}>
            Time Sheets
          </Button>
        </div>
        <div>
          <CSVLink
            id="time-sheet-download"
            style={{ background: "none", color: "none", border: "none" }}
            target="_blank"
            className="hidden"
            data={entryData}
            filename={"TimeEntry.csv"}
          ></CSVLink>
        </div>
        <div>
          <Button
            className="rti-blue-round"
            variant="contained"
            startIcon={<MdAdd />}
            onClick={() => setAddEntryModal(true)}>
            Entry
          </Button>
        </div>
      </div>
    ) : undefined;
  }

  const handleLimitDate = (after, before) => {
    setLoading(true);

    socket.emit("selectDatesFromTimeEntry", after, before, (result) => {
      const results = result.map((entry) => ({
        FullName: entry.FullName,
        Notes: entry.Notes,
        PunchIn: FormatLocalDateTimeFromMonth(entry.PunchIn),
        PunchOut: FormatLocalDateTimeFromMonth(entry.PunchOut),
        TimeEntryID: entry.TimeEntryID,
        WorkTimeTotal: entry.WorkTimeTotal,
      }));

      setTimeEntryDB(results);
      setEntryData(
        results.map((el) => ({
          ...el,
          PunchIn: DateToLocale(new Date(el.PunchIn)),
          PunchOut: el.PunchOut ? DateToLocale(new Date(el.PunchOut)) : ""
        }))
      );
      setAfterDate(after);
      setBeforeDate(before);
      setLoading(false);
    });
  };

  const getTableData = () => {
    return TimeEntryDB.map((entry) => ({
      key: entry.TimeEntryID,
      cells: [
        { data: entry.FullName },
        {
          data: `${FormatNA(FormatDate(entry.PunchIn))} ${FormatNA(
            FormatTime(entry.PunchIn)
          )}`,
        },
        {
          data: `${FormatNA(FormatDate(entry.PunchOut))} ${FormatNA(
            FormatTime(entry.PunchOut)
          )}`,
        },
        { data: FormatNA(entry.WorkTimeTotal) },
        { data: entry.Notes },
      ],
    }));
  };

  const handleCloseSnack = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const onCloseModal = (modal) => {
    switch (modal) {
      case "entry-modal":
        setEntryModal(false);
        break;
      case "add-entry-modal":
        setAddEntryModal(false);
        break;
    }
  };

  return (
    <div className="ViewedContentContainer" id="OpenContainer">
      <div style={{ height: "100%" }}>
        {entryModal ? (
          <Modal
            modal_id="entry-modal"
            dimension={{ width: 500, height: 500 }}
            modal_header="Time Entry"
            onClose={() => onCloseModal("entry-modal")}
          >
            <div className="entry-modal" style={{ textAlign: "center" }}>
              <div style={{ marginBottom: "48px" }}>
                <h2>{EntrySelected.FullName}</h2>
              </div>
              <div style={{ display: "flex", margin: "30px 0px" }}>
                <div>
                  <label>Time In</label>
                  <input
                    type="datetime-local"
                    value={TimeIn || ""}
                    onChange={(e) => setTimeIn(e.target.value)}
                  ></input>
                </div>
                <div>
                  <label>Time Out</label>
                  <input
                    type="datetime-local"
                    value={TimeOut || ""}
                    onChange={(e) => setTimeOut(e.target.value)}
                  ></input>
                </div>
              </div>
              <div style={{ margin: "32px 0px" }}>
                <label>
                  <b>Work Time: {GetTotalWorkTime()}</b>
                </label>
              </div>
              <div className="vert-margin-med flex-just-between">
                <div>
                  <label>Entry Notes</label>
                  <br></br>
                  <textarea
                    disabled
                    style={{ width: "200px", height: "80px" }}
                    value={EntrySelected.Notes}
                  ></textarea>
                </div>
                <div>
                  <label>Admin Changes</label>
                  <br></br>
                  <textarea
                    id="entry-changes"
                    style={{ width: "200px", height: "80px" }}
                  ></textarea>
                </div>
              </div>

              <div>
                <Button
                  className="rti-blue-round"
                  variant="contained"
                  onClick={() => AdjustTimeEntry("update")}>
                  Adjust Entry
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
        {addEntryModal ? (
          <Modal
            modal_id="add-entry-modal"
            dimension={{ width: 500, height: 500 }}
            modal_header="Add Time Entry"
            onClose={() => onCloseModal("add-entry-modal")}
          >
            <div className="entry-modal" style={{ textAlign: "center" }}>
              <div style={{ marginBottom: "48px" }}>
                <label>User</label>
                <br></br>
                <input
                  id="user_entry"
                  list="user_list"
                  placeholder="Search User..."
                ></input>
                <datalist id="user_list">
                  {Users.map((user) => (
                    <option key={user.UserID} value={user.FullName}>
                      {user.FullName}
                    </option>
                  ))}
                </datalist>
              </div>
              <div style={{ display: "flex", margin: "30px 0px" }}>
                <div>
                  <label>Time In</label>
                  <input
                    type="datetime-local"
                    value={TimeIn || ""}
                    onChange={(e) => setTimeIn(e.target.value)}
                  ></input>
                </div>
                <div>
                  <label>Time Out</label>
                  <input
                    type="datetime-local"
                    value={TimeOut || ""}
                    onChange={(e) => setTimeOut(e.target.value)}
                  ></input>
                </div>
              </div>
              <div style={{ margin: "32px 0px" }}>
                <label>
                  <b>Work Time: {GetTotalWorkTime()}</b>
                </label>
              </div>
              <div className="vert-margin-med flex-just-center">
                <div>
                  <label>Admin Changes</label>
                  <br></br>
                  <textarea
                    id="entry-changes"
                    style={{ width: "250px", height: "80px" }}
                  ></textarea>
                </div>
              </div>
              <div>
                <Button
                  className="rti-blue-round"
                  variant="contained"
                  onClick={() => AdjustTimeEntry("insert")}>
                  Add Entry
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
        <div className="TitleBarContainer">
          <h1>Time Entries</h1>
        </div>
        <AlertBar
          visible={snackbar.visible}
          onClose={handleCloseSnack}
          message={snackbar.message}
          severity={snackbar.severity}
        />
        <CustomTable
          loading_data={Loading}
          paginate
          headers={TableHeaders}
          rows={getTableData()}
          table_buttons={RenderTableButtons()}
          onClick={GetTimeEntryDetails}
          onRefresh={RefreshTableData}
          dates={{ after: afterDate, before: beforeDate }}
          onLimitDate={handleLimitDate}
        />
      </div>
    </div>
  );
};

export default TimeEntryTable;
