import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const ShortURL = ({ match }) => {
  const { id } = match.params;
  let ready = false; // Define the missing variable

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (id) {
        socket.emit("retrieveURL", id, (res) => {
          if (!res) {
            console.error(
              `ERROR: ShortURL.retrieveURL: There was an issue calling this method on ${new Date()}`
            );
          } else {
            window.location.href = `${res.long_url}`;
          }
        });
      }
    }

    return () => {
      mounted = false;
    };
  });

  return (
    <div
      className="flex-align-center flex-just-center"
      style={{ height: "100%" }}
    >
      {ready ? null : (
        <div className="flex-align-center flex-column">
          <h1>Redirecting</h1>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

ShortURL.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ShortURL;
