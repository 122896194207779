import { useContext, createContext } from 'react';

/**
 * @typedef {Object} ContextType
 * @property {Object} userState - The state of the user.
 * @property {Function} userDispatch - The dispatch function for user actions.
 */

/**
 * @type {React.Context<ContextType>}
 */
const Context = createContext();

/**
 * Custom hook to use the Context.
 * @returns {ContextType} The context value.
 */
export const useCustomContext = () => useContext(Context);

export default Context;