import React, { useState, useEffect} from 'react';
import CustomTable from '../../components/Table.js';
import Button from '@mui/material/Button';
import AlertBar from '../../components/AlertBar.js';
import { MdAdd } from 'react-icons/md';
import Modal from '../../components/Modal.js';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import { MdMoreVert } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const TableHeaders = [
    {id: 'question', name: 'question',sortable: true, numeric: false},
    {id: 'solution', name: 'solution',size: 'xlarge-header'},
    {id: '_blank', name: ''}
]

const SearchOptions = [
    {id: 'question', name: 'Question', pidx: 0}
]

const MenuOptions = [
    "Edit",
    "Delete"
]

const TechSolutions = () => {
    const [solutions,setSolutions] = useState([]);
    const [deleting,setDeleting] = useState(false);
    const [loading,setLoading] = useState(true);
    const [edit,setEdit] = useState(false);
    const [anchorEl,setAnchorEl] = useState(null);
    const [anchorIndex,setAnchorIndex] = useState(null);
    const [selected,setSelected] = useState(null);
    const [adding,setAdding] = useState(false);
    const [searchVal,setSearchVal] = useState('');
    const [searchParam,setSearchParam] = useState('');
    const [open,setOpen] = useState(false);
    const [snackbar,setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    })

    useEffect(() => {
        retrieveData(true,'');
    },[]);

    useEffect(() => {
        const solutionModal = document.getElementById('solution-modal');
        if (solutionModal) {
            solutionModal.classList.toggle('flex-box');
        }
    }, [open]);
    
    useEffect(() => {
        const deleteSolution = document.getElementById('delete-solution');
        if (deleteSolution) {
            deleteSolution.classList.toggle('flex-box');
        }
    }, [deleting]);
    
    useEffect(() => {
        const editSolution = document.getElementById('edit-solution');
        if (editSolution) {
            editSolution.classList.toggle('flex-box');
        }
    }, [edit]);
    
    const retrieveData = (_load, _query) => {
        if(_load) setLoading(true);

        socket.emit('getTechSolutions',_query,(res) => {
            if(!res){
                console.error(`Help.getTechSolutions: There was an issue calling this method`)
                setLoading(false);
            }else{
                setSolutions(res);
                setLoading(false);
            }
        });
    }

    const handleOpenMenu = (_event) => {
        const [pref,idx] = _event.target.id.split('-');
        setAnchorIndex(idx);
        setAnchorEl(_event.currentTarget);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setAnchorIndex(null);
    }

    const handleMenuSelect = (_event) => {
        const [sid,opt] = _event.target.id.split('-');
        
        const solution = solutions.find(sol => sol.id == sid);

        switch(opt){
            case MenuOptions[0]: setSelected(solution); toggleEditModal();
                break;
            case MenuOptions[1]: setSelected(solution); toggleDeleteModal();
                break;
        }

        handleCloseMenu();
    }

    const getTableData = () => {
        return solutions.map((sol,index) => ({
            key: sol.id,
            cells: [
                {data: JSON.parse(sol.title)},
                {data: JSON.parse(sol.solution)},
                {data: (
                    <div>
                        <IconButton
                            id={`option-${index}`}
                            aria-haspopup="true"
                            onClick={handleOpenMenu}
                            size="large">
                            <MdMoreVert id={`option-${index}`} />
                        </IconButton>
                        {index == anchorIndex ? <Menu id={`selected-menu-op-${index}`} anchorEl={index == anchorIndex ? anchorEl : null} open={index == anchorIndex ? Boolean(anchorEl) : false} onClose={handleCloseMenu}>
                            {MenuOptions.map(opt => (
                                <MenuItem id={`${sol.id}-${opt}`} key={opt} onClick={handleMenuSelect}>
                                    {opt}
                                </MenuItem>
                            ))}
                        </Menu> : null}
                    </div>
                )}
            ]
        }))
        .filter(row => searchVal && searchParam ? row.cells[SearchOptions[searchParam - 1].pidx].data && row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase()) : row);
    }

    const renderTableButtons = () => {
        return (
            <div>
                <Button className='rti-blue-round' variant='contained' startIcon={<MdAdd />} onClick={toggleModal}>solution</Button>
            </div>
        )
    }

    const handleAddSolution = () => {
        const data = {
            title: document.getElementById('question').value,
            solution: document.getElementById('solution').value
        }

        if(data.title && data.solution){
            setAdding(true);
            socket.emit('addTechSolution',data,(res) => {
                if(!res){
                    console.error(`TechSolutions.addTechSolution: There was an issue calling this method`)
                    setSnackbar({
                        visible: true,
                        severity: 'error',
                        message: `SERVER ERROR: There was an issue adding this tech solution.  Please notify the developer of this issue.`
                    })
                    setAdding(false);
                }else{
                    setAdding(false);
                    toggleModal();
                    setSolutions(res[0]);
                }
            });
        }else{
            setSnackbar({
                visible: true,
                severity: 'warning',
                message: `Please check that all fields have been filled out!`
            })
        }
    }

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({
            ...prevState,
            visible: false
        }))
    }

    const search = (param, searchVal) => {
        setSearchVal(searchVal)
        setSearchParam(param)
    }

    const clearSearch = () => {
        setSearchVal('');
    }

    const toggleModal = () => {
        setOpen(prevState => !prevState);
    }

    const toggleDeleteModal = () => {
        setDeleting(prevState => !prevState);
    }

    const handleDeleteSolution = () => {
        socket.emit('removeTechSolution',selected.id,(res) => {
            if(!res){
                console.error(`TechSolutions.removeTechSolution: There was an issue calling this method`)
                setSnackbar({
                    visible: true,
                    severity: 'error',
                    message: `SERVER ERROR: There was an issue removing this tech solution.  Please notify the developer of this issue.`
                });
            }else{
                retrieveData(false,'');
                toggleDeleteModal();
            }
        });
    }

    const toggleEditModal = () => {
        setEdit(prevState => !prevState);
    }

    const handleEditSolution = () => {
        const data = {
            id: selected.id,
            title: document.getElementById('question').value,
            solution: document.getElementById('solution').value
        }

        setAdding(true);
        socket.emit('editTechSolution',data,(res) => {
            if(!res){
                console.error(`TechSolutions.editTechSolution: There was an issue calling this method`)
                setSnackbar({
                    visible: true,
                    severity: 'error',
                    message: `SERVER ERROR: There was an issue editing this tech solution.  Please notify the developer of this issue.`
                });
                setAdding(false);
            }else{
                retrieveData(false,'');
                setAdding(false);
                toggleEditModal();
            }
        });
    }

    return (
        <div className="ViewedContentContainer">
            <div style={{height: '100%'}}>
                <div className="TitleBarContainer">
                    <h1>Tech Solutions</h1>
                </div>

                <AlertBar
                    visible={snackbar.visible}
                    onClose={handleCloseSnack}
                    message={snackbar.message}
                    severity={snackbar.severity}
                />
                {open ?
                    <Modal modal_id='solution-modal' dimension={{width: 700}} modal_header="Add Tech Solution" onClose={toggleModal}>
                        <div className='text-center flex-column flex-just-center flex-align-center vert-margin-med'>
                            <TextField style={{width: 300}} id="question" InputLabelProps={{shrink: true}} label="Question" placeholder='How do I add a tech solution?' type="text" />
                            <div style={{width: 300}} className='flex-column flex-align-start vert-margin-med'>
                                <label>Solution</label>
                                <textarea style={{width: '100%', height: 250}} id="solution"></textarea>
                            </div>
                            {!adding ? <div>
                                <Button className='rti-blue-round' variant="contained" onClick={handleAddSolution}>add solution</Button>
                            </div> :
                            <div style={{width: '100%'}}>
                                <label>Adding Solution</label>
                                <LinearProgress />
                            </div>
                            }
                        </div>
                    </Modal> : null
                }
                {deleting ?
                    <Modal modal_id='delete-solution' dimension={{width: 500}} onClose={toggleDeleteModal}>
                        <div className='text-center flex-column flex-just-center flex-align-center vert-margin-med'>
                            <h2>Are you sure you want to remove this tech solution?</h2>
                            <div style={{width: '50%'}} className="flex-just-even vert-margin-med">
                                <Button color='secondary' variant="contained" onClick={toggleDeleteModal}>no</Button>
                                <Button className='rti-blue-round' color="primary" variant="contained" onClick={handleDeleteSolution}>yes</Button>
                            </div>
                        </div>
                    </Modal> : null
                }
                {edit ?
                    <Modal modal_id='edit-solution' dimension={{width: 700}} modal_header="Edit Tech Solution" onClose={toggleEditModal}>
                        <div className='text-center flex-column flex-just-center flex-align-center vert-margin-med'>
                            <TextField style={{width: 300}} id="question" InputLabelProps={{shrink: true}} label="Question" placeholder='How do I add a tech solution?' defaultValue={JSON.parse(selected.title)} type="text" />
                            <div style={{width: 300}} className='flex-column flex-align-start vert-margin-med'>
                                <label>Solution</label>
                                <textarea style={{width: '100%', height: 250}} id="solution" defaultValue={JSON.parse(selected.solution)}></textarea>
                            </div>
                            {!adding ? <div>
                                <Button className='rti-blue-round' variant="contained" onClick={handleEditSolution}>save changes</Button>
                            </div> :
                            <div style={{width: '100%'}}>
                                <label>Updating Solution</label>
                                <LinearProgress />
                            </div>
                            }
                        </div>
                    </Modal> : null
                }
                <CustomTable
                    paginate
                    searchable
                    search={search}
                    clear_search={clearSearch}
                    search_options={SearchOptions}
                    loading_data={loading}
                    headers={TableHeaders}
                    rows={getTableData()}
                    table_buttons={renderTableButtons()}
                    onRefresh={() => retrieveData(true,'')}
                />
            </div>
        </div>
    )
}

export default TechSolutions;