import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import { AiOutlineReload } from 'react-icons/ai';  // Imported icon from react-icons
import PropTypes from 'prop-types';
import '../StyleSheets/Table.css';

const TableActions = ({table_buttons, 
        paginate = false, 
        length = 0, 
        rowsPerPage = 0, 
        page = 0, 
        onPageChange, 
        onRowsPerPageChange, 
        onRefresh}) => {
    return (
        <div className="table-actions-container">
            {table_buttons ? <div className="table-butons">
                {table_buttons}
            </div> : null}            
            {paginate ? <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                className="table-paging"
                count={length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            /> : null}
            <IconButton color="default" onClick={onRefresh} size="large">
                <AiOutlineReload />  {/* Replaced RefreshIcon */}
            </IconButton>
        </div>
    );
}

TableActions.propTypes = {
  table_buttons: PropTypes.array,
  paginate: PropTypes.bool,
  length: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default TableActions;
