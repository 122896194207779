import React from 'react';
import technicianMapMarker from '../../../public/Images/technicianMapMarker.png';

const TechMarker = (props) => {
	const handleMarkerClick = (id) => (event) => {
		props.onMarkerClick('tech', id);
	};

	const { Marker } = require('@react-google-maps/api');

	return props.visible
		? props.data.map((tech) => (
				<Marker
					id={tech.TechnicianID}
					icon={{ url: technicianMapMarker, scaledSize: new google.maps.Size(26, 40) }}
					key={tech.TechnicianID}
					onClick={handleMarkerClick(tech.TechnicianID)}
					title={`${tech.FirstName} ${tech.LastName}`}
					position={{ lat: tech.Latitude, lng: tech.Longitude }}
				/>
		  ))
		: null;
};

function compareProps(prevProps, nextProps) {
	if (prevProps.visible === nextProps.visible) {
		return true;
	}
	return false;
}

export default React.memo(TechMarker, compareProps);
