import React from 'react';
import Button from '@mui/material/Button';

export const PaperCoreInfo = (props) => {
	const { header, formData, onToggleAudit, handleEditPaper, handleReturnPaper } = props;
	return (
		<div>
			<div className='item-field-container'>
				<h1>{header}</h1>
				<div className='grid-container item-info-grid'>
					<div className='item-info-field'>
						{formData && formData.job ? 'Job #: ' : null}
						{formData.job || null}
					</div>
					<div className='item-info-field'>
						{formData && formData.kiosk && formData.kiosk.id ? 'Kiosk: ' : null}
						{formData.kiosk.id || null}
					</div>
					<div className='item-info-field'>
						{formData && formData.paper ? 'Paper: ' : null}
						{formData.paper || null}
					</div>
					<div className='item-info-field'>
						{formData && formData.qty ? 'Qty: ' : null}
						{formData.qty || null}
					</div>
					<div className='item-info-field'>
						{formData && formData.tech ? 'Technician: ' : null}
						{formData.tech || null}
					</div>
					<div className='item-info-field'>
						{formData && formData.location ? 'Location: ' : null}
						{formData.location || null}
					</div>
				</div>
				<Button className='item-buttons' variant='text' color='primary' onClick={onToggleAudit}>
					Retrieve Audit
				</Button>
				<div className='flex-just-even full-width bottom-buttons'>

					<Button className='item-buttons' variant='outlined' color='primary' onClick={handleEditPaper}>
						Edit
					</Button>

					<Button className='item-buttons' variant='outlined' color='primary' onClick={handleReturnPaper}>
						Return
					</Button>
				</div>
			</div>
		</div>
	);
};
