import React from 'react';
import CustomTable from '../../../components/Table';
import Modal from '../../../components/Modal';
import '../../../StyleSheets/InventorySystem.css'


export const ItemAuditModal = (props) => {
	const { auditData, onToggleAudit, auditType } = props;
	
	const TableHeaders = [
		{ id: 'timestamp', name: 'timestamp' },
		{ id: 'user', name: 'user', size: 'xsmall-header' },
		{ id: 'qty', name: 'qty' },
		{ id: 'location', name: 'location', size: 'xsmall-header' },
		{ id: 'status', name: 'status' },
	];

	auditType === 'Paper' ? TableHeaders.splice(1,1) && TableHeaders.push(		
	{id: 'job', name: 'job #',size: 'xsmall-header'},
	{id: 'technician', name: 'technician'},
	{id: 'paper', name: 'paper',size: 'xsmall-header'}) : null
	
	return (
		<div>
			<Modal modal_id='item-audit' dimension={{ width: 725 }} modal_header='Item Audit' onClose={onToggleAudit}>
				<div>
					<CustomTable paginate rpp={10} noMargin headers={TableHeaders} rows={auditData()} />
				</div>
			</Modal>
		</div>
	);
};
