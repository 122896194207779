import React from 'react';
import Modal from '../../../components/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AiOutlineSwap } from 'react-icons/ai'; // Replacing TransferIcon
import { LinearProgress } from '@mui/material';

export const TransferItemModal = (props) => {
	const { items, bins, toggleModal, stickerCount, handleTransferItems, renderTransferItems, parent, name, loading } = props;

    const getFormInfo = () =>{
        const controllerData = {
            location: document.getElementById('bin-destination') ? document.getElementById('bin-destination').value : null,
            transfer_qty: document.getElementById('transfer-qty') ? document.getElementById('transfer-qty').value : null
        }
        handleTransferItems(controllerData)
    }
	return (
		<div>
			<Modal modal_id='transfer-items' dimension={{ width: 640 }} modal_header='Transfer Items' onClose={toggleModal}>
				<div className='transfer-modal'>
					<div className='icon-container'>
						<AiOutlineSwap className='icon' /> {/* Replaced TransferIcon */}
					</div>
					<div className='content-container'>
						<div className='flex-just-between'>
							<div className='flex-start flex-column'>
								{items.length && !items[0].bulk_bin && parent !== 'Sticker Counter' ? (
									<div className='table-container'>
										<table className='transfer-table'>
											<tbody className='table-body'>{renderTransferItems()}</tbody>
										</table>
									</div>
								) : items.length && items[0].bulk_bin || parent === 'Sticker Counter'? (
									<div>
										<div>
										<div className='flex-start flex-column'>
											<h3 className='destination-heading'>{parent === 'Sticker Counter' ? `${name} Stickers` : `Items To Transfer`}</h3>
											<div className='input-block'>
												<div className='search-by'>
													<TextField className='qty-input' id='transfer-qty' autoFocus label='Qty to Transfer' type='number' defaultValue={1} />
													<p className='available-qty'>Available Qty: {parent === 'Sticker Counter' ? `${stickerCount}` : items[0].item_qty}</p>
												</div>
											</div>
										</div>
									</div>
									</div>
								) : null}
								<br />
							</div>
							<div className='flex-start flex-column'>
								<h3 className='destination-heading'>Destination</h3>
								<div className='input-block'>
									<label className='input-label required'>Destination</label>
									<div className='search-by'>
										<input className='destination-input' id='bin-destination' list='location-list' name='location-list-dropdown' placeholder='Destination'></input>
										<datalist id='location-list'>
											{bins.map((bin) => (
												<option key={bin.id} value={bin.name}>
													{bin.name}
												</option>
											))}
										</datalist>
									</div>
								</div>
							</div>
						</div>
						<div className='flex-just-center'>
							<Button className='transfer-button' variant='contained' onClick={getFormInfo}>
								Transfer
							</Button>
						</div>
					</div>
				</div>
				{loading ? <LinearProgress /> : null}

			</Modal>
		</div>
	);
};
