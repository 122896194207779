// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.RTIGoogleMapContainer {
    position: relative !important;
}

.KiosksLocationList:hover,
.TechniciansLocationList:hover {
    background-color: darkseagreen;
}

.draggable:hover {
    cursor: grab;
}

.draggable:active {
    cursor: grabbing;
}

.sub-route {
    border-left: 1px solid gainsboro;
}

.sub-route .search-container {
    margin-left: 6px;
}

.sub-route p {
    margin-left: 6px;
}

#map-container {
    font-family: 'Roboto', sans-serif;
}

.GoogleListNavi button {
    border-radius: 5px;
    background-color: #52658F;
    border: 1px solid black;
    margin: 0;
    font-size: 13px;
    font-weight: bold;
}

    .GoogleListNavi button:hover {
        background-color: #e14658;
    }

.search-result-table {
    height:240px;
    margin-top:14px;
    border-top:1px solid lightgray;
    overflow:auto;
    transition: height 0.5s;
}

.route-table {
    /* height: 270px; */
    overflow: auto;
    width: 60%;

}

.route-options {
    display: flex;
    padding: 6px;
    justify-content: space-between;
    align-items: center;
}

#route-container{
width: 375px;
}`, "",{"version":3,"sources":["webpack://./src/StyleSheets/GoogleMap.css"],"names":[],"mappings":";;AAEA;IACI,6BAA6B;AACjC;;AAEA;;IAEI,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,uBAAuB;IACvB,SAAS;IACT,eAAe;IACf,iBAAiB;AACrB;;IAEI;QACI,yBAAyB;IAC7B;;AAEJ;IACI,YAAY;IACZ,eAAe;IACf,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,UAAU;;AAEd;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;AACA,YAAY;AACZ","sourcesContent":["\n\n.RTIGoogleMapContainer {\n    position: relative !important;\n}\n\n.KiosksLocationList:hover,\n.TechniciansLocationList:hover {\n    background-color: darkseagreen;\n}\n\n.draggable:hover {\n    cursor: grab;\n}\n\n.draggable:active {\n    cursor: grabbing;\n}\n\n.sub-route {\n    border-left: 1px solid gainsboro;\n}\n\n.sub-route .search-container {\n    margin-left: 6px;\n}\n\n.sub-route p {\n    margin-left: 6px;\n}\n\n#map-container {\n    font-family: 'Roboto', sans-serif;\n}\n\n.GoogleListNavi button {\n    border-radius: 5px;\n    background-color: #52658F;\n    border: 1px solid black;\n    margin: 0;\n    font-size: 13px;\n    font-weight: bold;\n}\n\n    .GoogleListNavi button:hover {\n        background-color: #e14658;\n    }\n\n.search-result-table {\n    height:240px;\n    margin-top:14px;\n    border-top:1px solid lightgray;\n    overflow:auto;\n    transition: height 0.5s;\n}\n\n.route-table {\n    /* height: 270px; */\n    overflow: auto;\n    width: 60%;\n\n}\n\n.route-options {\n    display: flex;\n    padding: 6px;\n    justify-content: space-between;\n    align-items: center;\n}\n\n#route-container{\nwidth: 375px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
