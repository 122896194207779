import React from 'react';
import Modal from '../../../components/Modal';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import { FormatYear } from '../../../utilities/util';
import Autocomplete from '@mui/material/Autocomplete';

export const EditPaperModal = (props) => {
	const { toggleModal, selected, Kiosks, Techs, bins, uploading, onUpdate } = props;
	const sticker_years = [FormatYear(0).toString(), FormatYear(1).toString(), FormatYear(2).toString(), FormatYear(3).toString()];
	return (
		<div>
			<Modal modal_id='stickers' dimension={{ width: 475 }} modal_header='' onClose={toggleModal}>
				<div className='item-field-container'>
					<h2>{selected.add ? 'Add Entry' : 'Return Paper'}</h2>
					<div className='divider' id='divider'></div>
					<div className='grid-container item-field-grid item-grid-auto-fit'>
						<div className='item-info-field'>
							<Autocomplete
								freeSolo
								id='kiosk'
								className='space-below item-field'
								value={selected && selected.kiosk_id ? selected.kiosk_id : ''}
								key={selected && selected.kiosk_id ? selected.kiosk_id : ''}
								disableClearable
								options={Kiosks.map((kiosk) => kiosk.KioskID)}
								disabled={selected.add ? false : true}
								renderInput={(params) => (
									<TextField
										value={selected && selected.kiosk_id ? selected.kiosk_id : ''}
										{...params}
										label='Kiosk'
										InputProps={{
											...params.InputProps,
											type: 'search',
										}}
									/>
								)}
							/>

							<Autocomplete
								freeSolo
								id='tech'
								className='space-below item-field'
								value={selected && selected.technician ? selected.technician : ''}
								key={selected && selected.technician ? selected.technician : ''}
								disableClearable
								options={Techs.map((tech) => `${tech.FirstName} ${tech.LastName}`)}
								disabled={selected.add ? false : true}
								renderInput={(params) => (
									<TextField
										value={selected && selected.technician ? selected.technician : ''}
										{...params}
										label='Technician'
										InputProps={{
											...params.InputProps,
											type: 'search',
										}}
									/>
								)}
							/>
						</div>

						<div className='item-info-field'>
							<Autocomplete
								freeSolo
								id='paper'
								className='space-below item-field'
								value={selected && selected.paper ? selected.paper : ''}
								key={selected && selected.paper ? selected.paper : ''}
								disableClearable
								options={sticker_years.map((year) => year)}
								renderInput={(params) => (
									<TextField
										value={selected && selected.paper ? selected.paper : ''}
										{...params}
										label='Sticker Year'
										InputProps={{
											...params.InputProps,
											type: 'search',
										}}
									/>
								)}
							/>
							<TextField
								className='space-below item-field'
								id='on-hand'
								disabled={selected.add ? false : true}
								defaultValue={(selected && selected.sticker_count) || ''}
								label='Qty Removed'
								type='number'
							/>
						</div>

						<div className='item-info-field'>
							<TextField
								id='returned'
								hiddenLabel
								className='item-field space-below'
								label='Return Qty'
								placeholder={'Return Qty'}
								defaultValue={selected && selected.count_returned ? selected.count_returned : ''}
								type='text'
							/>
							<Autocomplete
								freeSolo
								id='location'
								className='space-below item-field'
								value={selected && selected.location ? selected.location : '' || ''}
								key={selected && selected.location ? selected.location : '' || ''}
								disableClearable
								options={bins.map((bin) => bin.name)}
								renderInput={(params) => (
									<TextField
										value={''}
										{...params}
										label='Destination'
										InputProps={{
											...params.InputProps,
											type: 'search',
										}}
									/>
								)}
							/>
						</div>
						<div className='item-info-field'>
							{!selected.add ? <TextField autoFocus id='job' className='space-below item-field' label='Job #' placeholder='Job #' defaultValue={selected.job_id || ''} type='text' /> : null}
							<TextField
								id='return-date'
								hiddenLabel
								className='space-below item-field'
								label='Return Date'
								defaultValue={selected && selected.return_date ? selected.return_date : new Date().toLocaleDateString('fr-CA')}
								type='date'
							/>
						</div>
					</div>
					{!uploading ? (
						<div className='flex-just-even  full-width bottom-buttons'>
							<Button className='item-buttons' variant='outlined' color='primary' onClick={onUpdate}>
								{selected.add ? 'Add Entry' : 'Update'}
							</Button>
						</div>
					) : (
						<div className='full-width'>
							<label>Updating...</label>
							<LinearProgress />
						</div>
					)}
				</div>
			</Modal>
		</div>
	);
};
