import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DatabaseErrorLogger from './DatabaseErrorLogger';
import './ErrorBoundary.css';

/**
 * ErrorBoundary is a React component that catches JavaScript errors anywhere in its child component tree,
 * logs those errors, and displays a fallback UI instead of the component tree that crashed.
 *
 * @class ErrorBoundary
 * @extends {Component}
 * @param {Object} props - The properties passed to the component.
 * @property {boolean} state.hasError - Indicates if an error has been caught.
 * @property {Error|null} state.error - The error that was caught.
 * @property {Object|null} state.errorInfo - Additional information about the error.
 *
 * @method static getDerivedStateFromError
 * @param {Error} error - The error that was thrown.
 * @returns {Object} The updated state with hasError set to true and the error.
 *
 * @method componentDidCatch
 * @param {Error} error - The error that was thrown.
 * @param {Object} errorInfo - Additional information about the error.
 *
 * @method render
 * @returns {React.ReactNode} The fallback UI if an error is caught, otherwise the child components.
 */
class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.hasError && !this.state.hasError) {
			this.setState({ error: null, errorInfo: null });
		}
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className='center-screen error-boundary'>
					<h1>Something went wrong.</h1>
					<h2>To return to the home page, please press the link below.</h2>
					<h3>If the problem persists, please submit a bug report or contact support.</h3>
					<div className='login-link'>
						<Link to='/login'>click here</Link>
					</div>
					<DatabaseErrorLogger error={this.state.error} errorInfo={this.state.errorInfo} />
				</div>
			);
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
