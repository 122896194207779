import React, { useEffect, useState } from 'react';
import Spinner from '../../components/Spinner';
import AlertBar from '../../components/AlertBar';
import { DateToLocale } from '../API/TextFormatingFunctions';
import { useCustomContext } from '../../hoc/Context.js';
import { ItemAuditModal } from './InventoryItemModals/ItemAuditModal';
import { PaperCoreInfo } from './PaperCoreControllers/PaperCoreInfo';
import { PaperCoreEditing } from './PaperCoreControllers/PaperCoreEditing';
import '../../StyleSheets/InventorySystem.css';
import {  validateBinLocation } from '../../utilities/util';

const PaperCores = () => {
    const [core,path,subPath,id] = window.location.pathname.split('/');
    const [loading,setLoading] = useState(true);
    const [coreState,setCoreState] = useState(0);
    const [editing,setEditing] = useState(false);
    const [returning,setReturning] = useState(false);
    const [deallocating,setDeallocating] = useState(false);
    const [retrievingAudit,setRetrievingAudit] = useState(false);
    const [audit,setAudit] = useState([]);
    const [bins,setBins] = useState([]);
    const [auditOpen,setAuditOpen] = useState(false);
    const [yearOption, setYearOption] = useState('')
    const [items, setItems] = useState([])
    const [formData,setFormData] = useState({
        job: null,
        paper: null,
        qty: null,
        tech: null,
        technicianID: null,
        core_id: null,
        stc_id: null,
        count: null,
        assigner: null,
        location: null,
        kiosk: {
            id: null,
            address: null
        },
        assign_date: null,
        assigned: 0,
        spliced: 0
    })

    const [snackbar,setSnackbar] = useState({
        visible: false,
        message: '',
        severity: 'success'
    })

    const {userState,userDispatch} = useCustomContext();

    const editCoreProps = {
        'subTitle': returning ? 'Return Paper' : deallocating ? 'Deallocate Paper' : 'Assign Paper',
        'coreID': id.substring(2,id.length),
        'yearOption': yearOption,
        'formData': formData,
        'bins': bins,
        'onInputFocus': onInputFocus,
        'handleScannerInput': handleScannerInput,
        'handleBackButton': handleBackButton,
        'handleEditCore': handleEditCore,
        'handleSpliceCheck': handleSpliceCheck,
        'editing': editing,
        'deallocating': deallocating,
        'returning': returning,
    }
    const coreInfoProps = {
        'header': coreState ? 'Paper Allocated' : 'Please assign paper to this core using the inputs below.',
        'formData': formData,
        'onToggleAudit': onToggleAudit,
        'handleEditPaper': handleEditPaper,
        'handleReturnPaper': handleReturnPaper
    }
    const auditItemModalProps = {
        'auditData': auditData,
        'onToggleAudit': onToggleAudit,
        'auditType': 'Paper'
    }

    useEffect(() => {
        //auditing a singular item
        $('#item-audit').toggleClass('flex-box');
    },[auditOpen]);

    useEffect(() => {
        let mounted = true;

        if(mounted){
            retrieveCoreData();
        }

        return () => {
            mounted = false;
        }
    },[id, returning, editing, deallocating]);

    function onToggleAudit (){
        setAuditOpen(prevState => !prevState);
    }

    function handleSpliceCheck(event){
        // Destructure due to setState being an async function to avoid error
        const {checked} = event.target
        checked ? setFormData(prevState => ({...prevState, spliced: 1})) : setFormData(prevState => ({...prevState, spliced: 0}))
    }

    const retrieveCoreData = () => {
        setLoading(true);
        const found_core_id = id.substring(2,id.length);

        socket.emit('getAllBinLocations',(res) => {
            if(!res){
                console.error(`InventoryItem.getAllBinLocations: There was an issue calling this method`)
            }else{
                handleAudit()
                setBins(res.filter(bin => bin.family == 'STICKER'));
            }
        });

        socket.emit('getInventoryBins', 'BIN-SHREDDR', (res) => {
            if (!res) {
                console.error(`PaperInventory.getInventoryBins: There was an issue calling this method - ${new Date()}`);
            } else {
                setItems(res);
            }
        });

        socket.emit('getPaperCore',found_core_id,(res) => {
            if(!res){
                console.error(`PaperCores.getPaperCore: There was an issue calling this method`)
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: 'SERVER ERROR: There was an issue retrieving data for this core. Please contact the developer.',
                    severity: 'error'
                }));
                setLoading(false);
            }else{
                if(res.length && res[0].assigned){ // has paper assigned
                    // set formdata
                    setFormData(prevState => ({
                        ...prevState,
                        core_id: found_core_id,
                        barcode: 'PC' + found_core_id,
                        stc_id: res[0].stc_id,
                        count: res[0].stc_count,
                        job: res[0].job_id,
                        paper: res[0].paper,
                        qty: res[0].qty,
                        location: res[0].location,
                        kiosk: {
                            ...prevState.kiosk,
                            id: res[0].kiosk_id,
                        },
                        tech: res[0].tech,
                        technicianID: res[0].TechnicianID,
                        assigner: res[0].user,
                        assign_date: res[0].assign_date,
                        assigned: res[0].assigned,
                        spliced: res[0].spliced,
                        KioskID_Loc: res[0].KioskID_Loc
                    }))
                    setCoreState(1); //core is assigned
                    setLoading(false);
                }else if(res.length && !res[0].assigned){
                    // Gets here but does not set the formData correctly.
                    setFormData({core_id: found_core_id})
                    // setFormData(prevState => ({...prevState, core_id: found_core_id}))
                    resetFormData()
                    setCoreState(0);    // core is unassigned
                    setLoading(false);
                }
                else{
                    // formdata should be empty
                    resetFormData()
                    setCoreState(0);    // core is unassigned
                    setLoading(false);
                }
            }
        });
    }

    const resetFormData = () => {
        // Resets all the input fields from an item when transferring to an existing item that does not have any values associated with that barcode
        setFormData({
            job: null,
            paper: null,
            qty: null,
            tech: null,
            technicianID: null,
            stc_id: null,
            count: null,
            assigner: null,
            location: null,
            kiosk: {
                id: null,
                address: null
            },
            assign_date: null,
            assigned: 0,
            spliced: 0
        })

        const field_array = [
            "job",
            "paper",
            "qty",
            "tech",
            "technicianID",
            "stc_id",
            "count",
            "assigner",
            "location",
            "kiosk",
            "address",
            "assign_date",
            "assigned",
            "spliced",
        ]

        field_array.map(el => document.getElementById(el) ? document.getElementById(el).value = '' : null)
    }

    const validate = (controllerData) => {
			return new Promise(async (resolve) => {
                const core_id = controllerData.core_id
				const paper = controllerData.paper;
				const qty = controllerData.qty;
				const bin_location = controllerData.location_input;
				const bin_found = await validateBinLocation(bin_location);
				const bin_located = bin_location && bin_location.toUpperCase().startsWith('WI') ? true : bin_found;
				const paper_core_id = formData.found_core_id;
				const kiosk = controllerData.kiosk;
				const location_input = controllerData.location_input;
				const technician = formData.tech;
				const returned = controllerData.returned;

				if (id) {
					if (!bin_location) {
						resolve({ valid: false, status: 'warning', msg: 'WARNING: You must assign a location for this core.' });
					} else if (!bin_located) {
						resolve({ valid: false, status: 'warning', msg: 'WARNING: The location you have selected does not exist. Please try again.' });
					} else if (!paper) {
						resolve({ valid: false, status: 'warning', msg: 'WARNING: Paper type cannot be blank.' });
					} else if ((editing && !qty) || (returning && !returned)) {
						resolve({ valid: false, status: 'warning', msg: 'WARNING: Paper quantity must have a value greater than 0.' });
					}
                    // commented due to the tech being found via job number
                    // else if (!paper_core_id && !technician) {
					// 	resolve({ valid: false, status: 'warning', msg: 'WARNING: Please select a technician.' });
					// }
                    else if (!kiosk && !bin_found) {
						resolve({ valid: false, status: 'warning', msg: `WARNING: Our system doesn't recognize this bin location. Please enter a valid bin location and try again.` });
					}
                    // else if (!kiosk && !returning) {
					// 	resolve({ valid: false, status: 'warning', msg: `WARNING: Please enter a valid kiosk value.` });
					// }
                    else if ((returning && location_input && !returned)) {
						resolve({ valid: false, status: 'warning', msg: `WARNING: Quantity returned cannot be empty.` });
					}
                    else if ((returning && returned && !location_input)) {
						resolve({ valid: false, status: 'warning', msg: `WARNING: The location you have selected does not exist. Please try again.`});
					}
                    else if ((deallocating && location_input && !returned)) {
						resolve({ valid: false, status: 'warning', msg: `WARNING: Quantity returned cannot be empty.` });
					}
                    else if ((deallocating && returned && !location_input)) {
						resolve({ valid: false, status: 'warning', msg: `WARNING: The location you have selected does not exist. Please try again.`});
					}
				} else {
					resolve({ valid: false, status: 'warning', msg: 'WARNING: No core exists with this number.' });
				}

				resolve({ valid: true });
			});
		};

    async function assignCore(args, inputs, type) {
        socket.emit('assignPaperCore',args,async (res) => {
            if(!res){
                console.error(`PaperCores.assignPaperCore: There was an issue calling this method`)
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: `SERVER ERROR: There was an issue making this request from the server.  Please notify the developer of this issue.`,
                    severity: 'error'
                }));
                setEditing(false);

            }else{

                setFormData(prevState => ({
                    ...prevState,
                    core_id: res.data[0].id,
                    barcode: 'PC' + inputs[0].id,
                    // barcode: 'PC' + res.data[0].id,
                    stc_id: res.data[0].stc_id,
                    count: res.data[0].stc_count,
                    job: inputs[0].job_id ? inputs[0].job_id : '' ,
                    paper: inputs[0].paper,
                    qty: inputs[0].qty,
                    // qty: returning ? inputs[0].qty : res.data[0].qty,
                    // location: res.data[0].location,
                    location: inputs[0].location,
                    kiosk: {
                        ...prevState.kiosk,
                        id: returning || deallocating ? '' : inputs[0].location,
                    },
                    tech: returning ? '' : res.data[0].tech,
                    technicianID: res.data[0].TechnicianID,
                    assigner: inputs[0].user,
                    assign_date: res.data[0].assign_date,
                    spliced: inputs[0].spliced,
                    KioskID_Loc: inputs[0].KioskID_Loc
                }));

                await updateIntoInventory(inputs,'update');

                setCoreState(1);    // core has been assigned paper
                editing ? setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: `Success! Paper has been assigned to this core.`,
                    severity: 'success'
                })) : null;
                setEditing(false);
            }
        });
    }

	const getItemByItemID = (item_id) => {
		// get item by item_id. Applies to bulk items and singular items
		return new Promise((resolve, reject) => {
			socket.emit('getItemById', item_id, (res) => {
				if (!res) {
					console.error(`InventoryBin.getItemByItemID: There was an issue calling this method`);
					reject(false);
				} else {
					if (!res.length) {
						setErrors({
							item_id: true,
						});
						resolve({ found: false });
					} else {
						resolve(res[0].model);
					}
				}
			});
		});
	};

    async function handleEditCore(controllerData, core_status) {
			const valid = await validate(controllerData);
			if (valid.valid) {
                const core_id = controllerData.core_id
				const paper_job_id = formData.job;
				const stc_id = formData.stc_id;
				const stc_count = formData.count;
				const year = formData.paper;
				const returned = controllerData.returned;
				const return_date = controllerData.return_date;
				const kiosk = controllerData.kiosk;
				const location_input = controllerData.location_input;
				const technician = formData.tech;
				const spliced = controllerData.spliced;
				const _bin = controllerData._bin;
				const returned_date_timestamp = new Date().toLocaleDateString('fr-CA');
				const job = controllerData.job;
				const paper = controllerData.paper;
				const qty = controllerData.qty;
				const type = controllerData.type;
                const first = [...items].shift(); // get the first array in the array items
                const second = first[0]; // get the first object in the array gathered by first
                const KioskID_Loc = formData && formData.length && formData.KioskID_Loc.length  ? formData.KioskID_Loc : 'WI0000'
				const model = await getItemByModel(`WI-${paper}`); // sticker year model
                const found = await getItemByItemID(model.item_id)
                const repurposedRIDObj = await findNextRID(paper)
                const repurposedRID = repurposedRIDObj.generated_barcode
                // Handle assign logic here
				setFormData((prevState) => ({
					...prevState,
					job: editing ? job : '',
					paper,
					qty: editing ? qty : returned,
					location: _bin && _bin == 'KIOSK' ? location_input : _bin.code,
					kiosk: {
						...prevState.kiosk,
						id: editing ? kiosk : '',
					},
					tech: returning ? '' : null,
					technicianID: returning ? '' : null,
					assigner: localStorage.getItem('FullName'),
					assign_date: returned_date_timestamp,
					spliced,
					core_id: core_id,
					stc_id: stc_id,
					count: stc_count,
                    KioskID_Loc: KioskID_Loc
				}));

				const args = {
                    ...formData,
					job,
					paper,
					qty,
					kiosk,
					assign_date: new Date().toISOString(),
					location: _bin && _bin == 'KIOSK' ? location_input : _bin.code,
					core_id: core_id,
					core_status: core_status,
                    user: localStorage.getItem('FullName'),
                    KioskID_Loc: KioskID_Loc
				};
                
				const inputs = [
					{
						serial: '',
						imei: '',
						qty: parseInt( returned > 0 ? returned : qty),
						location: _bin && _bin == 'KIOSK' ? location_input : _bin.code,
						model: model.item_id,
						barcode: _bin && _bin.code && _bin.code.includes('RPRPOS') ? repurposedRID : core_id ? `PC${core_id}` : null,
						info: { serial: '', imei: '', phone: '', sim: '', password: '', firmware: '', carrier: '', part_num: '' },
						action: _bin && (_bin == 'KIOSK' || _bin.offsite || !_bin.code.includes('RPRPOS')) ? 'OUT' : 'IN',
						user: localStorage.getItem('FullName'),
						refurb: 0,
						item_id: model.found ? model.item_id : null,
						pcid: core_id,
						spliced: controllerData.spliced,
						item_id: model.found ? model.item_id : null,
                        returned: returned,
                        count: stc_count,
                        KioskID_Loc: KioskID_Loc,
                        paper: paper,
                        job: job
					},
				];
				const stickers = {
					pc_id: core_id,
					stc_id: stc_id,
					job_id: returning ? '' : paper_job_id,
                    qty: qty > 0 && !returned ? qty : returned,
					count: stc_count,
					kiosk: returning ? '' : kiosk,
					tech: returning ? '' : technician,
					year: year,
					returned: returned ? returned : null,
					return_date: returned ? return_date : null,
					status: returned ? 'returned' : 'pending',
					location: _bin && _bin == 'KIOSK' ? location_input : _bin.code,
					spliced: returning || deallocating ? 0 : spliced,
                    user: localStorage.getItem('FullName'),
                    KioskID_Loc: KioskID_Loc
				};
				if (_bin && model) {
					assignCore(args, inputs, type);
                    deductFromLargeStickers(stickers.qty, stickers.year, stickers.pc_id)
				}
                if (returned && returning) {
                    try{
                        calculateDiscrepancy(inputs, second)
                        stc_id && returning ? updateStickerCoreTracking(stickers, _bin) : !stc_id && returning ? insertStickerCoreTracking([stickers], stickers.job_id, _bin) : null;
                        setReturning(false);
                    }catch(error){
                        setSnackbar((prevState) => ({
                            ...prevState,
                            visible: true,
                            message: `ERROR: There was a problem assigning paper to this core. Please notify the developer of this issue.`,
                            severity: 'error',
                        }));
                    }

				}
			} else {
				setSnackbar((prevState) => ({
					...prevState,
					visible: true,
					message: valid.msg,
					severity: valid.status,
				}));
			}
		}

        // TODO: Make it so this only fires if any of the values are over 0
        // Update it so that the barcode is not the model and is the new model created for repurpose stickers.
    async function calculateDiscrepancy(input, second){
        const returned = input && input[0].returned ? parseInt(input[0].returned) : 0
        const count = input && input[0].count ? parseInt(input[0].count) : 0
        const discrepancy = count - returned

        const inputs = [
                    {
                        qty: parseInt(discrepancy) > 0 ? parseInt(discrepancy) : 0, // update item qty in destination
                        location: 'BIN-SHREDDR',
                        barcode: input[0].model,
                        model: input[0].model,
                        info: input[0].info,
                        action: 'IN',
                        user: input[0].user,
                        refurb: 0,
                        item_id: second.item_id ? second.item_id : null,
                        child_id: second.child_id ? second.child_id : null,
                    },
                ];
                if(parseInt(discrepancy) > 0){
                    await updateIntoInventory(inputs,'add');
                }
    }
    
    const findNextRID = (item_name) => {
        return new Promise((resolve, reject) => {
            socket.emit('FindNextInvBarcode', (res) => {
                if (!res) {
                    const error = new Error('SERVER ERROR: There was an issue calling this method. Please contact the developer for this issue.');
                    reject(error);
                    console.error(`PaperInventory.findNextRID: There was an issue calling this method`);
                } else {
                    const foundRID = res[0].find((el) => el.item_type.includes(item_name));
                    resolve(foundRID);
                }
            });
        });
    };
    
	const updateStickerCount = (binID, stickerCount) => {
		// Adds the new counts to the respective bin in the DB if the paper is being removed from the barcode.
        // Allows the qty to be updated without the PC_ID
		return new Promise((resolve, reject) => {
			socket.emit('updateRepurposeCount', binID, stickerCount, (res) => {
				if(!res) {
					setSnackbar((prevState) => ({
						...prevState,
						visible: true,
						message: 'SERVER ERROR: There was an issue updating the repurposed sticker count.  Please notify a developer of this issue.',
						severity: 'error',
					}));
                    console.error(`PaperCores.updateStickerCount: There was an issue calling this method`);
					reject(false);
				} else {
					resolve(true);
				}
			});
		});
	};

    const updateIntoInventory = (vals,action) => {
        return new Promise(async(resolve,reject) => {
            try {
                socket.emit('updateInventory',vals,vals[0].info,action,(res) => {
                    if(!res){
                        console.error(`PaperCores.updateIntoInventory: There was an issue calling this method`);
                        reject(false);
                    }else{
                        setSnackbar(prevState => ({
                            ...prevState,
                            visible: true,
                            message: `Success! Item ${action == 'add' ? 'added into inventory' : action == 'update' ? 'updated' : ''}.`,
                            severity: 'success'
                        }));
                        resolve(true);
                    }
                });
            } catch (error) {
                console.error(`PaperCores.updateIntoInventory: ${error}`);
                reject(error);
            }
        })
    }
    const deductFromLargeStickers = (sticker_qty, sticker_year, pc_id) => {
        return new Promise(async(resolve,reject) => {
            try {
                socket.emit('deductFromLargeStickers',sticker_qty, sticker_year, pc_id,(res) => {
                    if(!res){
                        console.error(`PaperCores.deductFromLargeStickers: There was an issue calling this method`);
                        reject(false);
                    }else{
                        resolve(true);
                    }
                });
            } catch (error) {
                console.error(`PaperCores.deductFromLargeStickers: ${error}`);
                reject(error);
            }
        })
    }

    const insertStickerCoreTracking = (stickers, job_id, bin) => {
        const repurpose_bin_code = 'BIN-RPRPOS'

        socket.emit('insertStickerTracking',stickers, job_id, async (res) => {
            if(!res){
                alert('SERVER ERROR')
                console.error(`PaperInventory.insertStickerCoreTracking: There was an issue calling this method`)
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: `SERVER ERROR: There was an issue calling this method. Please notify the developer of this issue.`,
                    severity: 'error'
                }));
            }else{
                if(stickers[0].returned){
                    if(bin.code.includes(repurpose_bin_code)){
                        setDeallocating(true)
                        if(bin.qty <= 0 ){
                            bin.qty = 0
                        }
                        const new_repurposed_count = parseInt(bin.qty) + parseInt(stickers[0].returned)
                        deallocatePaperCoreSetter(stickers[0].pc_id, bin, stickers[0].returned)
                        updateStickerCount(bin.id, new_repurposed_count) // update the count of the repurposed bin without moving the paper core to the bin
                    };
                }
            }
        });

    }

    const updateStickerCoreTracking = (stickers, bin) => {
        const repurpose_bin_code = 'BIN-RPRPOS'

        socket.emit('updateStickerTracking',stickers,async (res) => {
            if(!res){
                alert('SERVER ERROR');
                console.error(`PaperCores.updateStickerTracking: There was an issue calling this method`)
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: `SERVER ERROR: There was an issue calling this method. Please notify the developer of this issue.`,
                    severity: 'error'
                }));
            }else{
                if(stickers.returned){
                    if(bin !== 'KIOSK' && bin.code.includes(repurpose_bin_code)){
                        setDeallocating(true)
                        if(bin.qty <= 0 ){
                            bin.qty = 0
                        }
                        const new_repurposed_count = parseInt(bin.qty) + parseInt(stickers.returned)
                        deallocatePaperCoreSetter(stickers.pc_id, bin, stickers.returned)
                        updateStickerCount(bin.id, new_repurposed_count) // update the count of the repurposed bin without moving the paper core to the bin
                    };
                }else if(bin === 'KIOSK'){
                    null
                }else{
                    setSnackbar(prevState => ({
                        ...prevState,
                        visible: true,
                        message: 'WARNING: Please enter an amount to return.',
                        severity: 'warning'
                    }));
                }

                returning ? setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: `Success! Stickers have been returned.`,
                    severity: 'success'
                })) : null;
            }
        });
    }

    function deallocatePaperCoreSetter(paper_core_id, _bin, returned) {
        socket.emit('deallocatePaperCore',paper_core_id, returned, async (res) => {
            if(res.error){
                console.error(`PaperCores.deallocatePaperCore: There was an issue calling this method`);
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: `SERVER ERROR: There was an issue making this request from the server.  Please notify the developer of this issue.`,
                    severity: 'error'
                }));
                setDeallocating(false);
            }else{
                setCoreState(0);    // paper was deallocated from core
                setDeallocating(false);
                deallocating ? setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: `Success! Paper has been deallocated.`,
                    severity: 'success'
                })) : null;
            }
        });
    }
    const getItemByModel = (model) => {
        return new Promise((resolve,reject) => {
            socket.emit('getItemByModel',model,(res) => {
                if(!res){
                    console.error(`InventoryPart.getItemByModel: There was an issue calling this method`);
                    reject(false);
                }else{
                    if(!res.length){
                        resolve({found: false})
                    }else{
                        resolve({found: true, item_id: res[0].id, units: res[0].units})
                    }
                }
            });
        })
    }

    function handleEditPaper() {
        setEditing(true);
        setReturning(false);
        setDeallocating(false);
    }

    function handleReturnPaper() {
        setReturning(true);
        setEditing(false);
        setDeallocating(false);
    }

    function handleBackButton() {
        setEditing(false);
        setReturning(false);
        setDeallocating(false);
    }

    function handleScannerInput(event){
        const val = event.target.value;
        // used to catch scanner input to prefill fields
        if(val.includes('$')){
            const input = val;
            const [newVal,oldVal] = input.split('$');
            const [jobNum,kioskNum,paperVal,paperQty] = newVal.substring(0,input.length).split('%');
            document.getElementById('job').value = jobNum;
            document.getElementById('paper').value = paperVal;
            document.getElementById('qty').value = paperQty;
            document.getElementById('kiosk').value = kioskNum;
            document.getElementById('location').value = kioskNum;
        }
    }

    const handleAudit = () => {
        setRetrievingAudit(true);
        const found_core_id = id.substring(2,id.length);

        socket.emit('getCoreAudit',found_core_id,(res) => {
            if(!res){
                console.error(`PaperCores.getCoreAudit: There was an issue calling this method`)
                setSnackbar(prevState => ({
                    ...prevState,
                    visible: true,
                    message: 'SERVER ERROR: The server returned an error for this request. Please notify the developer of this issue.',
                    severity: 'error'
                }));
                setRetrievingAudit(false);
            }else{
                setAudit(res);
                setRetrievingAudit(false);
            }
        });
    }

    function auditData() {
        return audit.map(entry => ({
            key: entry.id,
            cells: [
                {data: DateToLocale(new Date(entry.timestamp))},
                {data: entry.qty},
                {data: formData.location},
                {data: entry.status == 'deallocated' || entry.status == 'returned' ? 'RETURNED' : entry.status.toUpperCase()},
                {data: entry.job_id},
                {data: entry.tech},
                {data: entry.paper},
            ]
        }))
    }

    function onInputFocus() {
        $('#job')[0].setSelectionRange(0,0);
    }

    const handleCloseSnack = () => {
        setSnackbar(prevState => ({
            ...prevState,
            visible: false
        }))
    }

    return (
        <div>
				{auditOpen ? ItemAuditModal(auditItemModalProps) : null}
				<AlertBar visible={snackbar.visible} onClose={handleCloseSnack} message={snackbar.message} severity={snackbar.severity} />
                {!loading ? (
                    <div className='flex-align-center item-field-container'>
                        {!coreState ? PaperCoreEditing(editCoreProps) : coreState && editing || coreState && returning || coreState && deallocating ? PaperCoreEditing(editCoreProps) : coreState && !editing ? PaperCoreInfo(coreInfoProps) : 'WOOPS SOMETHING WENT WRONG'}
                    </div>
                ) :
                (<Spinner margin="center" />)
                }
        </div>
    )
}

export default PaperCores;