/*
 * AddressField component renders a form with fields for Address, City, State, Zip, and County.
 * It uses Material-UI's TextField and Grid components for layout and styling.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.Address - The address value.
 * @param {string} props.City - The city value.
 * @param {string} props.State - The state value.
 * @param {string} props.Zip - The zip code value.
 * @param {string} props.County - The county value.
 * @param {function} props.onChange - The function to call when any of the input values change.
 *
 * @returns - {JSX.Element} The rendered AddressField component.
 */
import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

export const AddressField = (props) => {
	const { Address, City, State, Zip, County, onChange } = props;


	const handleChange = (e, value) => {
		onChange(e, value);
	
	}


	return (
			<div className=" inherit-padding flex-wrap">
				<Grid container sx={{ flexGrow: 1 }} rowSpacing={1.5} columnSpacing={0}>


					<Grid item xs={4}>
							<TextField
								id="kioskAddress"
								className="StoreAddressInput"
								label="Address"
								maxLength="60"
                                size="small"
								InputLabelProps={{ shrink: true }}
								defaultValue={Address || ''}
								type="text"
								onChange={(e) => handleChange(e, 'StoreAddress')}
							/>
					</Grid>
					<Grid item xs={4}>
							<TextField
								id="kioskCity"
								className="StorCityInput"
                                size="small"
								label="City"
								maxLength="20"
								InputLabelProps={{ shrink: true }}
								defaultValue={City || ''}
								type="text"
								onChange={(e) => handleChange(e, 'StoreCity')}

							/>
						
					</Grid>
                    <Grid item xs={3}>
						
                        <TextField
                            id="kioskState"
                            size="small"

                            className="StoreStateInput"
                            label="State"
                            maxLength="2"
                            InputLabelProps={{ shrink: true }}
                            defaultValue={State || ''}
                            type="text"
							onChange={(e) => handleChange(e, 'StoreState')}

                        />
                    
                </Grid>

					<Grid item xs={4}>
							<TextField
								id="kioskZip"
								className="StoreZipInput"
								label="Zip"
                                size="small"

								maxLength="5"
								InputLabelProps={{ shrink: true }}
								defaultValue={Zip || ''}
								type="text"
								onChange={(e) => handleChange(e, 'StoreZip')}

							/>
						
					</Grid>
					<Grid item xs={2}>
							<TextField
								id="kioskCounty"
								className="kioskCounty"
								label="County"
                                size="small"

								maxLength="20"
								InputLabelProps={{ shrink: true }}
								defaultValue={County || ''}
								type="text"
								onChange={(e) => handleChange(e, 'StoreCounty')}

							/>
						
					</Grid>
				</Grid>
			</div>
	);
};

AddressField.propTypes = {
  Address: PropTypes.string,
  City: PropTypes.string,
  State: PropTypes.string,
  Zip: PropTypes.string,
  County: PropTypes.string,
  onChange: PropTypes.func,
};
