import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { FaDownload } from 'react-icons/fa';
import CustomTable from '../../components/Table';
import { CSVLink } from 'react-csv';

const TableHeaders = [
    {id: 'job', name: 'Job #',sortable: true, numeric: false},
    {id: 'kiosk', name: 'Kiosk',sortable: true, numeric: false},
    {id: 'issue', name: 'Issue',sortable: true, numeric: false},
    {id: 'dispatcher', name: 'Dispatcher',sortable: true, numeric: false},
    {id: 'created', name: 'Created'},
    {id: 'tech', name: 'Technician',sortable: true, numeric: false},
    {id: 'eta', name: 'ETA'}
];

const TableTabs = [
    {id: 7, name: 'Last 7 days', filter: 7},
    {id: 28, name: 'Last 28 days', filter: 28},
    {id: 60, name: 'Last 60 days', filter: 60},
    {id: 90, name: 'Last 90 days', filter: 90},
    {id: 180, name: 'Last 180 days', filter: 180}
];

//Formats the AlertTable Information for each row
const JobResponseTable = (props) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState(TableTabs[0].filter);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            socket.emit('selectAllCompletedJobs', filter, (result) => {
                setJobs(result.sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime()));
                setLoading(false);
            });
        }

        return () => {
            mounted = false;
        };
    }, [filter]);

    const filterTable = (filter) => {
        setJobs([]);
        setFilter(filter);
        setLoading(true);
    };

    const getTableData = () => {
        return jobs.map(job => ({
            key: job.JobID,
            cells: [
                { data: job.JobID },
                { data: job.KioskID },
                { data: job.Description },
                { data: job.Dispatcher },
                { data: job.date_created.split('T')[0] },
                { data: job.tech },
                { data: job.eta }
            ]
        }));
    };

    const renderTableButtons = () => {
        return (
            <div style={{ display: 'flex' }}>
                <CSVLink style={{ background: 'none', border: 'none' }} target="_blank" className="hidden" id="table-download" data={downloadData()} filename={"job-responses.csv"}></CSVLink>
                <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<FaDownload />} // Update the icon here
                    onClick={() => document.getElementById('table-download').click()}>Download</Button>
            </div>
        );
    };

    const downloadData = () => {
        return jobs.map(job => ({
            "Job #": job.JobID,
            "Kiosk": job.KioskID,
            "Issue": job.Description,
            "Dispatcher": job.Dispatcher,
            "Created": job.date_created.split('T')[0],
            "Technician": job.tech,
            "ETA": job.eta
        }));
    };

    return (
        <div className="ViewedContentContainer" id="OpenContainer">
            <div style={{ height: '100%' }}>
                <div className="TitleBarContainer">
                    <h1 title="Response Analytics">Response Analytics</h1>
                </div>
                <CustomTable
                    searchable
                    paginate
                    loading_data={loading}
                    tabs={TableTabs}
                    tab_style="select"
                    active_tab={filter}
                    filter_data={filterTable}
                    headers={TableHeaders}
                    rows={getTableData()}
                    table_buttons={renderTableButtons()}
                />
            </div>
        </div>
    );
};

export default JobResponseTable;
