import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/Table';
import Modal from '../../components/Modal';
import Button from '@mui/material/Button';
import { MdGetApp } from 'react-icons/md';
import { CSVLink } from 'react-csv';

const TableHeaders = [
	{ id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
	{ id: 'last-issue', name: 'Last Issue', sortable: true, numeric: false },
	{
		id: 'timestamp',
		name: 'Timestamp',
		sortable: true,
		numeric: false,
		time: true,
	},
	{ id: 'status', name: 'Status', sortable: true, numeric: false },
	{
		id: 'reported-issues',
		name: 'Reported Issues',
		sortable: true,
		numeric: true,
	},
];

const TableHeaders2 = [
	{ id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
	{ id: 'last-issue', name: 'Issue', sortable: true, numeric: false },
	{ id: 'message', name: 'Kiosk Message' },
	{
		id: 'timestamp',
		name: 'Timestamp',
		sortable: true,
		numeric: false,
		time: true,
	},
	{ id: 'status', name: 'Status', sortable: true, numeric: false },
	{ id: 'fix', name: 'Fix', sortable: true, numeric: false },
];

const SearchOptions = [{ id: 'kiosk', name: 'Kiosk', pidx: 0 }];

const TableTabs = [
	{ id: 7, name: 'Last 7 days', filter: 7 },
	{ id: 28, name: 'Last 28 days', filter: 28 },
	{ id: 60, name: 'Last 60 days', filter: 60 },
	{ id: 90, name: 'Last 90 days', filter: 90 },
	{ id: 180, name: 'Last 180 days', filter: 180 },
	{ id: 365, name: 'Last 365 days', filter: 365 },
];

// pushing remote
//Formats the AlertTable Information for each row
const AlertAnalytics = (props) => {
	const [alerts, setAlerts] = useState([]);
	const [Loading, setLoading] = useState(true);
	const [searchVal, setSearchVal] = useState('');
	const [searchParam, setSearchParam] = useState(0);
	const [compressed, setCompressed] = useState([]);
	const [selected, setSelected] = useState([]);
	const [filter, setFilter] = useState(TableTabs[0].filter);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			socket.emit('getAlertsWithDesc', filter, (result) => {
				if (!result) {
					console.error(`There was an issue retrieving info from the alerts database`);
					setLoading(false);
				} else {
					let compress_alerts = {};
					result.forEach((alert) => {
						compress_alerts[alert.KioskID]
							? (compress_alerts[alert.KioskID].Total = compress_alerts[alert.KioskID].Total + 1)
							: (compress_alerts[alert.KioskID] = {
									Issue: alert.Description,
									Timestamp: alert.OccuranceDateTimeStamp,
									Status: alert.KioskStatus,
									Total: 1,
							  });
					});
					setAlerts(result);
					setCompressed(compress_alerts);
					setLoading(false);
				}
			});
		}

		return () => {
			mounted = false;
		};
	}, [filter]);

	const Search = (param, searchVal) => {
		setSearchVal(searchVal);
		setSearchParam(param);
	};

	const RefreshTableData = () => {
		setLoading(true);
		socket.emit('getAlertsWithDesc', filter, (result) => {
			if (!result) {
				console.error(`There was an issue retrieving info from the alerts database`);
				setLoading(false);
			} else {
				let compress_alerts = {};
				result.forEach((alert) => {
					compress_alerts[alert.KioskID]
						? (compress_alerts[alert.KioskID].Total = compress_alerts[alert.KioskID].Total + 1)
						: (compress_alerts[alert.KioskID] = {
								Issue: alert.Description,
								// Timestamp: new Date(alert.OccuranceDateTimeStamp).toISOString().split('T')[0],
								Timestamp: alert.OccuranceDateTimeStamp,
								Status: alert.KioskStatus,
								Total: 1,
						  });
				});
				setAlerts(result);
				setCompressed(compress_alerts);
				setLoading(false);
			}
		});
	};

	const ClearSearch = () => {
		setSearchVal('');
	};

	const getTableData = () => {
		return Object.keys(compressed)
			.map((kiosk) => ({
				key: kiosk,
				cells: [
					{ data: kiosk },
					{ data: compressed[kiosk].Issue },
					{
						data: `${new Date(compressed[kiosk].Timestamp).toISOString().split('T')[0]} ${new Date(
							compressed[kiosk].Timestamp
						)
							.toISOString()
							.split('T')[1]
							.split('.')[0]
							.slice(0, 5)}`,
					},
					{ data: compressed[kiosk].Status },
					{ data: compressed[kiosk].Total },
				],
			}))
			.sort((a, b) => b.cells[4].data - a.cells[4].data)
			.filter((row) =>
				searchVal && searchParam
					? row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
					: row
			);
	};

	const filterData = (filter) => {
		setLoading(true);
		setFilter(filter);
	};

	const handleModalClose = () => {
		setSelected([]);
		$('#analytic-modal').toggleClass('flex-box');
	};

	const handleRowClick = (event) => {
		const id = event.target.id;
		const data = alerts.filter((alert) => alert.KioskID == id);
		setSelected(data);
		$('#analytic-modal').toggleClass('flex-box');
	};

	const getModalData = () => {
		return selected
			.map((alert, index) => ({
				key: `${alert.KioskID}-${index}`,
				timestamp: new Date(alert.OccuranceDateTimeStamp),
				cells: [
					{ data: alert.KioskID },
					{ data: alert.Description },
					{ data: alert.AlertMessage },
					{
						data: `${new Date(alert.OccuranceDateTimeStamp).toISOString().split('T')[0]} ${new Date(
							alert.OccuranceDateTimeStamp
						)
							.toISOString()
							.split('T')[1]
							.split('.')[0]
							.slice(0, 5)}`,
					},
					{ data: alert.KioskStatus },
					{
						data: alert.JobID ? 'Dispatched' : getFixedStatus(alert.last_step),
					},
				],
			}))
			.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
	};

	const getFixedStatus = (status) => {
		return status.includes('Call store')
			? 'Power Cycle'
			: status.includes('Wait')
			? 'Resolved on Own'
			: status.includes('Reboot') ||
			  status.includes('Is a tech at the kiosk?') ||
			  status.includes('Check heartbeat screen')
			? 'Reboot'
			: status.includes('Clear')
			? 'Cleared Manually'
			: '';
	};

	const renderTableButtons = () => {
		return (
			<div>
				<Button className='rti-blue-round' variant='contained' startIcon={<MdGetApp />} onClick={handleTableDownload}>
					Download
				</Button>
				<CSVLink
					id='modal-download'
					style={{ background: 'none', border: 'none' }}
					target='_blank'
					className='hidden'
					filename='kiosk-alerts.csv'
					data={downloadData()}
				></CSVLink>
			</div>
		);
	};

	const handleTableDownload = () => {
		document.getElementById('modal-download').click();
	};

	const downloadData = () => {
		return selected.map((alert) => ({
			Kiosk: alert.KioskID,
			Issue: alert.Description,
			'Kiosk Message': alert.AlertMessage,
			Timestamp: new Date(alert.OccuranceDateTimeStamp).toISOString().split('T')[0],
			Status: alert.KioskStatus,
		}));
	};

	return (
		<div className='ViewedContentContainer' id='OpenContainer'>
			<div style={{ height: '100%' }}>
				<div className='TitleBarContainer'>
					<h1 title='All kiosk alerts'>All Kiosk Alerts</h1>
				</div>

				<Modal
					modal_id='analytic-modal'
					dimension={{ width: '1090', height: '670' }}
					modal_header={selected.length ? selected[0].KioskID : ''}
					onClose={handleModalClose}
				>
					<div style={{ textAlign: 'center', height: '80%' }}>
						<CustomTable
							headers={TableHeaders2}
							rows={getModalData()}
							table_buttons={renderTableButtons()}
							paginate
							maxSize='medium'
						/>
					</div>
				</Modal>

				<CustomTable
					searchable
					paginate
					tabs={TableTabs}
					tab_style='select'
					filter_data={filterData}
					active_tab={filter}
					loading_data={Loading}
					search={Search}
					clear_search={ClearSearch}
					search_options={SearchOptions}
					headers={TableHeaders}
					rows={getTableData()}
					onClick={handleRowClick}
					onRefresh={RefreshTableData}
				/>
			</div>
		</div>
	);
};

export default AlertAnalytics;
