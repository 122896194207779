import { isString } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const Modal = ({
  modal_id = '',
  modal_display = null,
  dimension = null,
  modal_header = '',
  modal_sub_header = null,
  children,
  onClose,
}) => {
  useEffect(() => {
    const el = document.getElementById(`${modal_id}`);
    el.addEventListener('click', handleClickEvent);
    return () => {
      el.removeEventListener('click', handleClickEvent);
    };
  }, []);

  const handleClickEvent = (e) => {
    if (isString(e.target.className) && e.target.className.includes('quote-modal')) {
      onClose ? onClose() : document.getElementById(`${modal_id}`).classList.toggle('flex-box');
    }
  };

    return (
        <div id={modal_id} className="quote-modal">
            <div className="quote-body" style={modal_display ? {width: `auto`, height: `${dimension.height}px`, display: "block"} : {width: `auto`, height: `${dimension.height}px`}}>
                <div className="inner-modal-body">
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <h1 style={{margin: 'auto', padding: '20px'}}>{modal_header}</h1>
                        <Button style={{fontSize: '28px'}} color="rti-close" click={() => onClose ? onClose() : document.getElementById(`${modal_id}`).classList.toggle('flex-box')}>&times;</Button>
                    </div>
                    {<h3 style={{width: '60%', textAlign: 'center', margin: 'auto'}}>{modal_sub_header}</h3>}
                    {children}
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
  modal_id: PropTypes.string.isRequired,
  modal_display: PropTypes.bool.isRequired,
  dimension: PropTypes.string.isRequired,
  modal_header: PropTypes.string.isRequired,
  modal_sub_header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
