// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

    #BugReportDialog .BugReportText {
        max-height: 200px;
        max-width: 290px;
        min-height: 200px;
        min-width: 290px;
    }

    #BugReportDialog .BugReportSendBtn {
        float: right;
        height: 30px;
        width: 80px;
        margin: auto;
    }

    #BugReportDialog .BugReportClose {
        float: left;
        height: 30px;
        width: 80px;
        margin: auto;
    }
`, "",{"version":3,"sources":["webpack://./src/StyleSheets/BugReport.css"],"names":[],"mappings":";;IAEI;QACI,iBAAiB;QACjB,gBAAgB;QAChB,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,YAAY;QACZ,YAAY;QACZ,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,WAAW;QACX,YAAY;IAChB","sourcesContent":["\n\n    #BugReportDialog .BugReportText {\n        max-height: 200px;\n        max-width: 290px;\n        min-height: 200px;\n        min-width: 290px;\n    }\n\n    #BugReportDialog .BugReportSendBtn {\n        float: right;\n        height: 30px;\n        width: 80px;\n        margin: auto;\n    }\n\n    #BugReportDialog .BugReportClose {\n        float: left;\n        height: 30px;\n        width: 80px;\n        margin: auto;\n    }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
