import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormatYear } from '../../../utilities/util';
// todo: when deallocating, it does not clear out the location list until the page is refreshed

export const PaperCoreEditing = (props) => {
	const {
		subTitle,
		coreID,
		yearOption,
		formData,
		bins,
		onInputFocus,
		handleScannerInput,
		handleBackButton,
		handleEditCore,
		handleSpliceCheck,
		editing,
		deallocating,
		returning,
	} = props;
	const sticker_years = [FormatYear(0).toString(), FormatYear(1).toString(), FormatYear(2).toString(), FormatYear(3).toString()];
	const getFormInfo = () => {
		// Gathers the data from the form to pass to the model to update inventory with
		const location_input = document.getElementById('location').value.toUpperCase();
		const controllerData = {
			core_id: coreID,
			job: document.getElementById('job').value.toUpperCase(),
			paper: document.getElementById('paper').value.toUpperCase(),
			qty: document.getElementById('qty') ? document.getElementById('qty').value : null,
			kiosk: document.getElementById('kiosk') ? document.getElementById('kiosk').value.toUpperCase() : null,
			location_input: location_input,
			_bin:
				location_input.startsWith('WI') && location_input.length == 6
					? 'KIOSK'
					: bins.find((bin) => bin.name.toUpperCase() == location_input.toUpperCase() || bin.code.toUpperCase() == location_input.toUpperCase()),
			type: formData.paper && formData.assigned && formData.qty ? 'update' : returning ? 'returned' : deallocating ? 'deallocated' : 'add',
			returned: document.getElementById('returned') ? document.getElementById('returned').value : 0,
			return_date: document.getElementById('return-date') ? document.getElementById('return-date').value : new Date().toLocaleDateString('fr-CA'),
			spliced: formData.spliced
		};
		handleEditCore(controllerData, 'updated')
	};

	return (
		<div>
			<div className='item-field-container'>
				<h1>{subTitle}</h1>
				<div className='divider' id='divider'></div>
				<div className='grid-container item-field-grid item-grid-auto-fit'>
					<div className='item-info-field'>
						<TextField
							onFocus={onInputFocus}
							autoFocus
							id='job'
							className='space-below item-field'
							label='Job #'
							placeholder='Job #'
							defaultValue={formData.job || ''}
							type='text'
							onChange={handleScannerInput}
						/>
						<TextField
							id='kiosk'
							inputProps={{
								// 'aria-label': 'Kiosk',
								
							}}
							// hiddenLabel
							className='space-below item-field'
							label='Kiosk'
							placeholder='Kiosk'
							defaultValue={formData && formData.kiosk && formData.kiosk.id ? formData.kiosk.id : ''}
							type='text'
						/>
					</div>

					<div className='item-info-field'>
						<Autocomplete
							freeSolo
							id='paper'
							className='space-below item-field'
							value={yearOption ? yearOption : formData && formData.paper ? formData.paper : ''}
							key={yearOption ? yearOption : formData && formData.paper ? formData.paper : ''}
							disableClearable
							options={sticker_years.map((year) => year)}
							renderInput={(params) => (
								<TextField
									value={yearOption ? yearOption : formData && formData.paper ? formData.paper : ''}
									{...params}
									placeholder='Sticker Year'
									label='Sticker Year'
									InputProps={{
										...params.InputProps,
										type: 'search',
									}}
								/>
							)}
						/>

						<TextField
							id={returning ? 'returned' : 'qty'}
							// hiddenLabel
							className='item-field space-below'
							label={returning ? 'Return Qty' : 'Qty'}
							placeholder={returning ? 'Return Qty' : 'Qty'}
							defaultValue={formData && formData.qty && !returning && !deallocating ? formData.qty : ''}
							type='text'
						/>
					</div>

					<div className='item-info-field'>
						<Autocomplete
							freeSolo
							id='location'
							className='space-below item-field'
							value={!returning && !deallocating && formData && formData.location ? formData.location : '' || ''}
							key={(formData && formData.location) || ''}
							disableClearable
							options={bins.map((bin) => bin.name)}
							renderInput={(params) => (
								<TextField
									value={!returning && !deallocating && formData ? formData.location : '' || ''}
									{...params}
									placeholder='Destination'
									label='Destination'
									InputProps={{
										...params.InputProps,
										type: 'search',
									}}
								/>
							)}
						/>
						{returning ? (
							<TextField
								id='return-date'
								// // hiddenLabel
								className='space-below item-field'
								placeholder='Return Date'
								label='Return Date'
								defaultValue={formData && formData.return_date ? formData.return_date : new Date().toLocaleDateString('fr-CA')}
								type='date'
							/>
						) : editing ? (
							<FormControlLabel
								key='spliced'
								label='Spliced?'
								control={<Checkbox onChange={handleSpliceCheck} checked={formData.spliced == true ? true : false} name='spliced' value='spliced' color='primary' />}
							/>
						) : null}
					</div>
				</div>

				<div className='flex-just-even  full-width bottom-buttons'>
					<div>
						<Button className='item-buttons' variant='outlined' color='primary' onClick={handleBackButton}>
							Back
						</Button>
					</div>
					<div>
						<Button className='item-buttons' variant='outlined' color='primary' onClick={getFormInfo}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
