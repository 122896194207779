// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snack div {
    font-size: 13px !important;
}

.error div {
    background-color: #e14658 !important;
}

.success div {
    background-color: #4caf50 !important;
}

.warning div {
    background-color: #ff9800 !important;
}`, "",{"version":3,"sources":["webpack://./src/StyleSheets/Snack.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".snack div {\n    font-size: 13px !important;\n}\n\n.error div {\n    background-color: #e14658 !important;\n}\n\n.success div {\n    background-color: #4caf50 !important;\n}\n\n.warning div {\n    background-color: #ff9800 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
