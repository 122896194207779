import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { FaDownload } from 'react-icons/fa';  // Updated import
import CustomTable from '../../components/Table';
import Modal from '../../components/Modal';
import { CSVLink } from 'react-csv';

const TableHeaders = [
    { id: 'tech', name: 'Technician' }
];

const TableHeaders2 = [
    { id: 'tech', name: 'Technician' },
    { id: 'drive-time', name: 'Drive Time' },
];

const SearchOptions = [
    { id: 'tech', name: 'Technician' }
];

const DriveTimeTable = (props) => {
    const [MapDriveTimes, setMapDriveTimes] = useState([]);
    const [Techs, setTechs] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [selected, setSelected] = useState('');

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            socket.emit('selectFromTechniciansForTM', (result) => {
                setTechs(result.sort((a, b) => a.FirstName - b.FirstName));
                setLoading(false);
            });
        }

        return () => {
            mounted = false;
        }
    }, []);

    const handleModal = (e) => {
        const id = e.target.id;
        setMapDriveTimes([]);
        setLoading(true);

        socket.emit('selectAllMapDriveTimesForTech', id, (result) => {
            setMapDriveTimes(result);
            setLoading(false);
            setSelected(result[0].TechName);
            $('#drivetime-modal').toggleClass('flex-box');
        });
    }

    const getTableData = () => {
        return Techs.map(tech => ({
            key: tech.TechnicianID,
            cells: [
                { data: `${tech.FirstName} ${tech.LastName}` }
            ]
        }));
    }

    const getModalData = () => {
        return MapDriveTimes.map((tech, index) => ({
            key: tech.DriveTimeID,
            cells: [
                { data: tech.KioskID },
                { data: tech.DriveTime }
            ]
        }));
    }

    const renderTableButtons = () => {
        return (
            <div style={{ display: 'flex' }}>
                <CSVLink style={{ background: 'none', border: 'none' }} target="_blank" className="hidden" id="table-download" data={downloadData()} filename={"drive-times.csv"} ></CSVLink>
                <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<FaDownload />}  // Updated icon usage
                    onClick={() => document.getElementById('table-download').click()}>Download</Button>
            </div>
        );
    }

    const downloadData = () => {
        return MapDriveTimes.map(tech => ({
            "Technician": tech.TechName,
            "Kiosk": tech.KioskID,
            "Drive Time": tech.DriveTime
        }));
    }

    return (
        <div className="ViewedContentContainer" id="OpenContainer" >
            <div style={{ height: '100%' }}>
                <div className="TitleBarContainer">
                    <h1>Drive Times</h1>
                </div>
                <CustomTable
                    paginate
                    loading_data={Loading}
                    headers={TableHeaders}
                    rows={getTableData()}
                    onClick={handleModal}
                />

                <Modal modal_id="drivetime-modal" dimension={{ width: 620, height: 688 }} modal_header={selected}>
                    <CustomTable
                        paginate
                        headers={TableHeaders2}
                        rows={getModalData()}
                        maxSize="medium"
                        table_buttons={renderTableButtons()}
                    />
                </Modal>
            </div>
        </div>
    )
}

export default DriveTimeTable;
