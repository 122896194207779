import React from 'react';
import { MdAttachMoney as DollarIcon } from 'react-icons/md';
import { MdPhotoCamera as PhotoCameraIcon } from 'react-icons/md';
import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Modal from '../../../components/Modal';
import '../../../StyleSheets/InventorySystem.css'

export const NewItemModal = (props) => {
	const { onModalClose, onCreateItem, uploading, errors, vendors } = props;
	return (
		<div>
			<Modal modal_id='new-item' dimension={{ width: 500 }} modal_header='New Inventory Item' onClose={onModalClose}>
				<div id='inner-modal'>
					<div className='flex-space-between flex-align-center vert-margin-med'>
						<Avatar variant='rounded' style={{ width: 80, height: 80, fontSize: '2.0rem' }} src={null}>
							N/A
						</Avatar>
						<div className='avatar-overlay' id='avatar-overlay'>
							<PhotoCameraIcon style={{ color: 'whitesmoke', fontSize: 46 }} />
						</div>
						<TextField
							style={{ minWidth: 310 }}
							id='new-item-name'
							required
							error={errors.itemName}
							helperText={errors.itemName ? 'Error' : null}
							label='Name of Item'
							placeholder='eg. Pepwave'
							type='text'
						/>
					</div>
					<div className='flex-space-between vert-margin-med'>
						<TextField
							id='new-item-model'
							required
							error={errors.newModel}
							helperText={errors.newModel ? 'Error' : null}
							label='Model #'
							placeholder='eg. SF14032'
							defaultValue={modelNum}
							type='text'
						/>
						<TextField id='new-item-units' required error={errors.units} helperText={errors.units ? 'Error' : null} label='Units per Item' defaultValue={1} type='number' />
						<div className='flex-align-end'>
							<DollarIcon style={{ marginBottom: 5 }} />
							<TextField id='new-item-price' required error={errors.price} helperText={errors.price ? 'Error' : null} label='Price per Unit' defaultValue={0.0} type='number' />
						</div>
					</div>
					<div style={{ margin: '46px 0px' }} className='flex-space-between flex-align-end'>
						<div className='input-block'>
							<label className='input-label'>Vendor</label>
							<div className='search-by'>
								<input style={{ margin: 0, width: 200 }} id='new-item-vendor' list='vendor-list' name='vendor-list-dropdown' placeholder='Search vendor...'></input>
								<datalist id='vendor-list'>
									<option key={0} value='New Vendor'>
										New Vendor
									</option>
									{vendors.map((vendor) => (
										<option key={vendor.id} value={vendor.name}>
											{vendor.name}
										</option>
									))}
								</datalist>
							</div>
						</div>
						<textarea style={{ width: '50%', height: 108 }} id='new-item-description' placeholder='Item description...'></textarea>
					</div>
					{!uploading ? (
						<div className='flex-end'>
							<Button variant='text' color='primary' onClick={onCreateItem}>
								Create Item
							</Button>
						</div>
					) : (
						<div style={{ textAlign: 'center' }}>
							<label>Creating item...</label>
							<LinearProgress />
						</div>
					)}
				</div>
			</Modal>
		</div>
	);
};
