require('dotenv').config();

const env = {
    DB: {
        HOST: process.env.DB_HOST,
        PORT: process.env.DB_PORT,
        NAME: process.env.DB_NAME
    },
    SERVER: {
        PORT: process.env.SERVER_PORT,
        JWT_SECRET: process.env.SERVER_JWT_SECRET
    },
    EMAIL: {
        SERVICE: process.env.EMAIL_SERVICE,
        HOST: process.env.EMAIL_HOST,
        PORT: process.env.EMAIL_PORT
    },
    GOOGLE_MAP_DEF: process.env.GOOGLE_MAP_DEF,
    AWS_SMTP: {
        HOST: process.env.AWS_SMTP_HOST,
        PORT: process.env.AWS_SMTP_PORT
    },
    USER_AUTH: {
        SECRET_KEY: process.env.USER_AUTH_SECRET
    },
    GOOGLE_MAPS: {
        API: process.env.GOOGLE_MAPS_API
    },
    TIMEZONE: {
        LOCAL: process.env.TIMEZONE_LOCAL
    },
    FEDEX: {
        API_KEY: process.env.FEDEX_API_KEY,
        SECRET: process.env.FEDEX_SECRET,
        URL: process.env.FEDEX_URL,
        ACC: process.env.FEDEX_ACC
    },
    "USER_PERMS": ["master", "dev", "admin", "user", "noAcc"],
    "USER_ROLES": ["dispatch", "warehouse", "accounting"],
    "DAYS": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "DAILY_PAPER_AVG_31": [
      5.6, 4.4, 4.2, 3.3, 3.6, 3.4, 2.7, 2.6, 2.2, 2.9, 2.5, 2.9, 2.6, 2.8, 2.6,
      2.33, 2.58, 2.45, 2.64, 2.58, 2.44, 2.26, 2.59, 2.35, 2.52, 3.48, 3.19, 3.2,
      3.65, 5.31, 8.26
    ],
    "DAILY_PAPER_AVG_30": [
      5.65, 4.97, 3.64, 3.49, 3.18, 3.29, 3.17, 3.03, 2.95, 2.67, 2.56, 2.46,
      2.39, 2.64, 2.44, 2.54, 2.27, 2.0, 2.31, 2.76, 2.87, 2.48, 2.57, 2.6, 2.7,
      3.01, 3.64, 4.54, 5.54, 9.64
    ],
    "DAILY_PAPER_AVG_29": [
      7.16, 5.09, 4.15, 3.51, 3.33, 3.15, 3.02, 2.89, 2.67, 2.71, 2.6, 2.48, 2.51,
      2.56, 2.67, 2.55, 2.53, 2.51, 2.55, 2.73, 2.67, 2.62, 2.69, 3.1, 3.56, 4.15,
      4.8, 7.18, 10.28
    ],
    "DAILY_PAPER_AVG_28": [
      4.04, 5.16, 3.09, 1.94, 2.34, 2.47, 2.34, 2.82, 3.45, 2.82, 2.24, 2.58,
      2.68, 2.61, 3.11, 4.0, 2.98, 2.33, 2.13, 2.32, 2.57, 3.68, 4.96, 4.4, 3.7,
      5.34, 6.49, 11.39
    ],
    "EARLY_EVEN_STICKER_YEAR": [0, 1, 4, 18, 13, 5, 2, 1, 1, 0, 0, 11],
    "PRIMARY_EVEN_STICKER_YEAR": [
      63, 88, 92, 94, 96, 98, 98, 99, 99, 100, 100, 90
    ],
    "RUNOUT_EVEN_STICKER_YEAR": [37, 11, 4, 2, 0, 0, 0, 0, 0, 0, 0, 0],
    "EARLY_ODD_STICKER_YEAR": [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 5, 10],
    "PRIMARY_ODD_STICKER_YEAR": [
      63, 88, 92, 81, 86, 95, 97, 99, 99, 100, 100, 90
    ],
    "RUNOUT_ODD_STICKER_YEAR": [37, 12, 7, 5, 3, 2, 1, 1, 0, 0, 0, 0],
    "STICKER_THRESH": [30, 40, 60],
    "TIME_OF_DAY": {
      "MORNING": {
        "DISPLAY": "Morning",
        "RANGE": [4, 11]
      },
      "AFTERNOON": {
        "DISPLAY": "Afternoon",
        "RANGE": [12, 15]
      },
      "EVENING": {
        "DISPLAY": "Evening",
        "RANGE": [16, 23]
      }
    },
    "FULL_STICKER_ROLL": 700,
    "STAR_ROLL": 690,
    "TEST_PRINTS": 30,
    "WORK_HOURS": [8, 16],
    "ENV": "development",
    "LOCAL_URL": "http://localhost:3000",
    "PROD_URL": "https://console.ez-tab.net"
};


module.exports = env;