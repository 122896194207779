// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    #TimeEntryDialog .TimeEntryNotes {
        max-height: 100px;
        max-width: 290px;
        min-height: 100px;
        min-width: 290px;
    }

    #TimeEntryDialog .TimeEntrySendBtn {
        float: right;
        height: 30px;
        width: 80px;
        margin: auto;
    }

    #TimeEntryDialog .TimeEntryClose {
        float: left;
        height: 30px;
        width: 80px;
        margin: auto;
    }

.entry-modal label{
    font-size: 16px;
    margin: 0px 10px;
}`, "",{"version":3,"sources":["webpack://./src/StyleSheets/TimeEntry.css"],"names":[],"mappings":";IACI;QACI,iBAAiB;QACjB,gBAAgB;QAChB,iBAAiB;QACjB,gBAAgB;IACpB;;IAEA;QACI,YAAY;QACZ,YAAY;QACZ,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,WAAW;QACX,YAAY;IAChB;;AAEJ;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["\n    #TimeEntryDialog .TimeEntryNotes {\n        max-height: 100px;\n        max-width: 290px;\n        min-height: 100px;\n        min-width: 290px;\n    }\n\n    #TimeEntryDialog .TimeEntrySendBtn {\n        float: right;\n        height: 30px;\n        width: 80px;\n        margin: auto;\n    }\n\n    #TimeEntryDialog .TimeEntryClose {\n        float: left;\n        height: 30px;\n        width: 80px;\n        margin: auto;\n    }\n\n.entry-modal label{\n    font-size: 16px;\n    margin: 0px 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
