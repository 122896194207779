import React, { useState, useEffect } from 'react';
import '../../StyleSheets/TMmodal.css';
import Spinner from '../../components/Spinner';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { AddressField } from '../../components/AddressField.js';
import IconButton from '@mui/material/IconButton';
import { MdAdd, MdRemove } from 'react-icons/md';
import axios from 'axios';
import PropTypes from 'prop-types';

export const KioskModal = (props) => {
	const kioskKeyExists = !!props.kioskKey;
	const [kioskID, setKioskID] = useState(kioskKeyExists ? props.kioskModal.KioskID : '');
	const [kioskID_Loc, setKioskID_Loc] = useState(kioskKeyExists ? props.kioskModal.KioskID_Loc : '');

	const [storeHours, setStoreHours] = useState(kioskKeyExists ? JSON.parse(props.kioskModal.StoreHours) : []);
	const [storeHoursValue, setStoreHoursValue] = useState('');
	const [storeName, setStoreName] = useState(kioskKeyExists ? props.kioskModal.StoreName : '');
	const [storePhone, setStorePhone] = useState(kioskKeyExists ? props.kioskModal.StorePhone : '');
	const [storeAddress, setStoreAddress] = useState(kioskKeyExists ? props.kioskModal.StoreAddress : '');
	const [storeCounty, setStoreCounty] = useState(kioskKeyExists ? props.kioskModal.StoreCounty : '');
	const [storeCity, setStoreCity] = useState(kioskKeyExists ? props.kioskModal.StoreCity : '');
	const [storeState, setStoreState] = useState(kioskKeyExists ? props.kioskModal.StoreState : '');
	const [storeZip, setStoreZip] = useState(kioskKeyExists ? props.kioskModal.StoreZip : '');
	const [hasSwitch, setHasSwitch] = useState(kioskKeyExists ? props.kioskModal.has_switch : false);
	const [paperBridge, setPaperBridge] = useState(kioskKeyExists ? props.kioskModal.paper_bridge : false);
	const [latitude, setLatitude] = useState(kioskKeyExists ? props.kioskModal.Latitude : false);
	const [longitude, setLongitude] = useState(kioskKeyExists ? props.kioskModal.Longitude : false);
	const [starFirmware, setStarFirmware] = useState(
		kioskKeyExists && props.kioskModal.star_firmware ? formatDate(props.kioskModal.star_firmware) : ''
	);
	const [osInstall, setOsInstall] = useState(
		kioskKeyExists && props.kioskModal.os_installed ? formatDate(props.kioskModal.os_installed) : ''
	);
	const [lastCleaned, setLastCleaned] = useState(
		kioskKeyExists && props.kioskModal.cleaned ? formatDate(props.kioskModal.cleaned) : ''
	);

	const [kioskInstallDate, setKioskInstallDate] = useState(
		kioskKeyExists && props.kioskModal.KioskInstallDate
			? props.kioskModal.KioskInstallDate.split('T')[0]
			: new Date().toISOString().split('T')[0]
	);

	const [skiSlope, setSkiSlope] = useState(kioskKeyExists && props.kioskModal.ski_slope ? 1 : 0);

	const [primaryTechsInput, setPrimaryTechsInput] = useState('');
	const [primaryTechs, setPrimaryTechs] = useState(kioskKeyExists ? JSON.parse(props.kioskModal.PrimaryTechs) : []);
	const [primaryTechValue, setPrimaryTechValue] = useState('');
	const [backupTechsInput, setBackupTechsInput] = useState('');
	const [backupTechs, setBackupTechs] = useState(kioskKeyExists ? JSON.parse(props.kioskModal.BackupTechs) : []);
	const [backupTechValue, setBackupTechValue] = useState('');
	const [paperTechInput, setPaperTechInput] = useState('');
	const [paperTechValue, setPaperTechValue] = useState('');
	const [paperTech, setPaperTech] = useState(kioskKeyExists ? props.kioskModal.PaperChanger : '');
	const [serverID, setServerID] = useState(kioskKeyExists ? props.kioskModal.ServerID : '');
	const [modemType, setModemType] = useState(kioskKeyExists ? props.kioskModal.ModemType : '');
	const [carrier, setCarrier] = useState(kioskKeyExists ? props.kioskModal.Carrier : '');
	const [carrierNumber, setCarrierNumber] = useState(kioskKeyExists ? props.kioskModal.CarrierNumber : '');
	const [notes, setNotes] = useState(kioskKeyExists ? props.kioskModal.Notes : '');
	const [activeOrInactive, setActiveOrInactive] = useState(kioskKeyExists ? props.kioskModal.ActiveOrInactive : '');
	const [numberOfStarRolls, setNumberOfStarRolls] = useState(kioskKeyExists ? props.kioskModal.NumberOfStarRolls : '');
	const [techDriveTimeList, setTechDriveTimeList] = useState([]);
	const [kioskOld, setKioskOld] = useState(kioskKeyExists ? props.kioskModal : {});
	const [techs, setTechs] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const handleTechnicians = (result) => {
			setTechs(result);
			setLoading(false);
		};

		socket.emit('selectAllFromTechnicians', handleTechnicians);

		// clean up: remove the event listener
		return () => {
			socket.off('selectAllFromTechnicians', handleTechnicians);
		};
	}, []);

	/**
	 * Formats a timestamp into a string representation of a date in the format "YYYY-MM-DD".
	 *
	 * @param {number} timestamp - The timestamp to be formatted.
	 * @returns {string} The formatted date string.
	 */
	function formatDate(timestamp) {
		const date = new Date(timestamp);
		const year = date.getUTCFullYear();
		const month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Months are 0-based in JS
		const day = ('0' + date.getUTCDate()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	}

	function deepEqual(obj1, obj2) {
		if (obj1 === obj2) return true;

		if (obj1 === null || typeof obj1 !== 'object' || obj2 === null || typeof obj2 !== 'object') return false;

		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (let key of keys1) {
			if (!keys2.includes(key)) {
				return false;
			}

			const val1 = obj1[key];
			const val2 = obj2[key];

			// handle array comparison
			if (Array.isArray(val1) && Array.isArray(val2)) {
				if (val1.length !== val2.length) {
					return false;
				}
				for (let i = 0; i < val1.length; i++) {
					if (!deepEqual(val1[i], val2[i])) {
						return false;
					}
				}
			} else if (typeof val1 === 'object' && typeof val2 === 'object') {
				if (!deepEqual(val1, val2)) {
					return false;
				}
			} else if (val1 !== val2) {
				return false;
			}
		}
		return true;
	}

	function normalizeData(data) {
		return {
			ActiveOrInactive: data.ActiveOrInactive,
			Carrier: data.Carrier,
			CarrierNumber: data.CarrierNumber,
			KioskID: data.KioskID,
			KioskID_Loc: data.KioskID_Loc,
			KioskInstallDate: new Date(data.KioskInstallDate),
			ModemType: data.ModemType,
			Notes: data.Notes,
			NumberOfStarRolls: data.NumberOfStarRolls,
			PrimaryTechs: FormatTechs(Array.isArray(data.PrimaryTechs) ? data.PrimaryTechs : JSON.parse(data.PrimaryTechs)),
			BackupTechs: FormatTechs(Array.isArray(data.BackupTechs) ? data.BackupTechs : JSON.parse(data.BackupTechs) || []),
			PaperChanger: data.PaperChanger,
			ServerID: data.ServerID,
			StoreAddress: data.StoreAddress,
			StoreCity: data.StoreCity,
			StoreCounty: data.StoreCounty,
			StoreHours: Array.isArray(data.StoreHours) ? data.StoreHours : JSON.parse(data.StoreHours),
			StoreName: data.StoreName,
			StorePhone: data.StorePhone,
			StoreState: data.StoreState,
			StoreZip: data.StoreZip,
			cleaned: data.cleaned ? data.cleaned : '',
			has_switch: data.has_switch !== undefined ? data.has_switch : 0,
			os_installed: data.os_installed ? data.os_installed.split('T')[0] : osInstall,
			paper_bridge: data.paper_bridge !== undefined ? data.paper_bridge : 0,
			ski_slope: data.ski_slope !== undefined ? data.ski_slope : 0,
			star_firmware: data.star_firmware ? data.star_firmware.split('T')[0] : '',
			Latitude: data.Latitude,
			Longitude: data.Longitude,
		};
	}

	/**
	 * Handles the onChange event for the KioskModal component.
	 *
	 * @param {Event} e - The event object.
	 * @param {string} whichValue - The value indicating which field is being changed.
	 * @returns {void}
	 */
	const handleInputChange = (setter) => (e) => setter(e.target.value);

	const handleCheckboxChange = (setter) => (e) => setter(e.target.checked);

	const handleArrayChange = (setter, array, value) => {
		const newArray = array.slice();
		if (newArray.indexOf(value) > -1) {
			return false;
		}
		newArray.push(value);
		setter(newArray);
	};

	const handleArrayRemove = (setter, array, value) => {
		const newArray = array.slice();
		const index = newArray.indexOf(value);
		if (index > -1) {
			newArray.splice(index, 1);
		}
		setter(newArray);
	};

	const onChange = (e, whichValue) => {
		const value = e.target.value;
		switch (whichValue) {
			case 'KioskID':
				setKioskID(value);
				break;
			case 'KioskInstallDate':
				setKioskInstallDate(value);
				break;
			case 'StoreHours':
				setStoreHours(value);
				break;
			case 'StoreName':
				setStoreName(value);
				break;
			case 'StorePhone':
				setStorePhone(value);
				break;
			case 'StoreAddress':
				setStoreAddress(value);
				break;
			case 'StoreCounty':
				setStoreCounty(value);
				break;
			case 'StoreCity':
				setStoreCity(value);
				break;
			case 'StoreState':
				setStoreState(value);
				break;
			case 'StoreZip':
				setStoreZip(value);
				break;
			case 'PrimaryTechsInput':
				setPrimaryTechsInput(value);
				break;
			case 'PrimaryTechs':
				setPrimaryTechs(value);
				break;
			case 'BackupTechsInput':
				setBackupTechsInput(value);
				break;
			case 'BackupTechs':
				setBackupTechs(value);
				break;
			case 'PaperTechInput':
				setPaperTechInput(value);
				break;
			case 'PaperTech':
				setPaperTech(value);
				break;
			case 'ServerID':
				setServerID(value);
				break;
			case 'ModemType':
				setModemType(value);
				break;
			case 'Carrier':
				setCarrier(value);
				break;
			case 'CarrierNumber':
				setCarrierNumber(value);
				break;
			case 'KioskNotes':
				setNotes(value);
				break;
			case 'ActiveOrInactive':
				setActiveOrInactive(value);
				break;
			case 'NumberOfStarRolls':
				setNumberOfStarRolls(value);
				break;
			case 'Latitude':
				setLatitude(value);
				break;
			case 'Longitude':
				setLongitude(value);
				break;
			case 'StarFirmware':
				setStarFirmware(value);
				break;
			case 'OsInstall':
				setOsInstall(value);
				break;
			case 'LastCleaned':
				setLastCleaned(value);
				break;
		}
	};

	/**
	 * Calculates the drive time for each tech and sorts them in ascending order.
	 */
	const techAndDriveTime = () => {
		// Gets all Techs for this specific kiosk
		let driveTime = [];

		driveTime = props.driveTimes;

		// Grabs the techname and drivetime from the filtered array
		// Has to be in string form to go through RenderList()
		// Convert to get total number of Minutes
		function getTechDriveTime(id) {
			let hours = 0,
				mins = 0,
				total = 0;

			const totalMins = id.DriveTime.split(' ');
			if (totalMins.length > 2) {
				hours = parseInt(totalMins[0] * 60);
				mins = parseInt(totalMins[2]);
				total = hours + mins;
			} else {
				mins = parseInt(totalMins[0]);
				total = mins;
			}
			const techDriveTime = { Name: id.TechName, Time: total };

			return techDriveTime;
		}

		// maps the tech array to a new array of objects for the fields we want
		const timefilterdArray = driveTime.map(getTechDriveTime);

		// Sort Techs by Drive Time ASC
		timefilterdArray.sort(function (a, b) {
			if (a.Time < b.Time) {
				return -1;
			}
			if (b.Time > a.Time) {
				return 1;
			}
			return 0;
		});

		function getNewTimes(id) {
			const driveTime = `${id.Name}, ${id.Time} mins`;
			return driveTime;
		}

		const result = timefilterdArray.map(getNewTimes);

		setTechDriveTimeList(result);
	};

	/**
	 * Returns the technician's name from the given value.
	 *
	 * @param {string} value - The value containing the technician's name in the format "First Name, 00 mins".
	 * @returns {string} The technician's name.
	 */
	const GetTechName = (value) => {
		value = value.replace(/,/g, ''); // remove comma from format "First Name, 00 mins"
		value = value.split(' '); // seperate fields
		value = value[0] + ' ' + value[1]; // get first and last name

		return value;
	};

	/**
	 * Handles the display logic for different cases in the KioskModal.
	 *
	 * @param {Event} e - The event object.
	 */
	const DisplayList = (e) => {
		switch (e.target.className) {
			//Displays the Technician List
			case 'PrimaryTechNameInput':
				//Move
				document.getElementById('TablePrimaryTechnicianList').style.display = 'block';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				break;

			//Sets the Text in the Dispatch To Input Box to the name of the item selected in the Technician List
			case 'PrimaryTechnicianList':
				setPrimaryTechsInput(GetTechName(e.target.id));
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				break;

			//Displays the Technician List
			case 'BackupTechNameInput':
				//Move
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'block';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				setBackupTechsInput(GetTechName(e.target.id));
				break;

			//Sets the Text in the Dispatch To Input Box to the name of the item selected in the Technician List
			case 'BackupTechnicianList':
				setBackupTechsInput(GetTechName(e.target.id));
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				break;

			//Displays the Technician List
			case 'PaperTechNameInput':
				//Move
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'block';
				break;

			//Sets the Text in the Dispatch To Input Box to the name of the item selected in the Technician List
			case 'PaperTechnicianList':
				// TODO: Figure out how to set the tech list differently
				setPaperTechInput(GetTechName(e.target.id));
				document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
				document.getElementById('TableBackupTechnicianList').style.display = 'none';
				document.getElementById('TablePaperTechnicianList').style.display = 'none';
				break;

			//Makes the Background for a selected Part in the List Grey
			case 'StoreHoursList': {
				setStoreHoursValue(e.target.id);
				const hoursListArray = document.getElementsByClassName('StoreHoursList');

				for (let j = 0; j < hoursListArray.length; j++) {
					hoursListArray[j].style.backgroundColor = '#ffffff';
				}

				document.getElementById(e.target.id).style.backgroundColor = '#808080';
				break;
			}

			//Makes the Background for a selected Part in the List Grey
			case 'PrimaryTechsList': {
				setPrimaryTechValue(e.target.id);
				let primaryListArray = document.getElementsByClassName('PrimaryTechsList');
				for (let j = 0; j < primaryListArray.length; j++) {
					primaryListArray[j].style.backgroundColor = '#ffffff';
				}
				document.getElementById(e.target.id).style.backgroundColor = '#808080';
				break;
			}

			//Makes the Background for a selected Part in the List Grey
			case 'BackupTechsList': {
				setBackupTechValue(e.target.id);
				let backupListArray = document.getElementsByClassName('BackupTechsList');
				for (let j = 0; j < backupListArray.length; j++) {
					backupListArray[j].style.backgroundColor = '#ffffff';
				}
				document.getElementById(e.target.id).style.backgroundColor = '#808080';
				break;
			}

			//Makes the Background for a selected Part in the List Grey
			case 'PaperTechList': {
				setPaperTechValue(e.target.id);
				let paperListArray = document.getElementsByClassName('PaperTechList');
				for (let j = 0; j < paperListArray.length; j++) {
					paperListArray[j].style.backgroundColor = '#ffffff';
				}
				document.getElementById(e.target.id).style.backgroundColor = '#808080';
				break;
			}
		}
	};

	//Reactive Table List that will only Display a list that matches string from the input
	/**
	 * Renders a list based on the passed value and array.
	 *
	 * @param {string} passedValue - The value to filter the array.
	 * @param {Array<string>} passedArray - The array to filter.
	 * @param {string} passedListClassName - The class name for the list items.
	 * @param {string} passedTableListClassName - The class name for the unordered list.
	 * @returns {React.Element} - The rendered unordered list.
	 */
	const RenderList = (passedValue, passedArray, passedListClassName, passedTableListClassName) => {
		//Filters the passedArray so the only values saved in filteredArray are the ones that partially match the passedValue
		let value = '';

		const filteredArray = [];
		if (passedArray) {
			for (let j = 0; j < passedArray.length; ++j) {
				value = passedArray[j];
				if (value.toLowerCase().includes(passedValue.toLowerCase())) {
					filteredArray.push(value.replace(/"/g, ''));
				}
			}
		}

		//Saves the filteredArray into the array that will display as a list under the text inputs
		const returnArray = [];
		if (filteredArray) {
			for (let i = 0; i < filteredArray.length; i++) {
				returnArray.push(
					<li
						key={i}
						className={passedListClassName}
						id={filteredArray[i]}
						value={filteredArray[i]}
						onClick={DisplayList}
					>
						{filteredArray[i]}
					</li>
				);
			}
		}

		//Returns an Unordered List
		return (
			<ul className={passedTableListClassName} id={passedTableListClassName}>
				{returnArray}
			</ul>
		);
	};

	//Adds the Current Item in Select to the Parts List
	/**
	 * Function that handles the logic for adding a technician in the KioskModal.
	 *
	 * @param {Event} e - The event object.
	 * @returns {boolean} - Returns false if the selected value already exists in the corresponding array, otherwise returns undefined.
	 */
	const PlusTechButton = (e) => {
		const primaryList = document.getElementById('TablePrimaryTechnicianList');
		const backupList = document.getElementById('TableBackupTechnicianList');
		const paperList = document.getElementById('TablePaperTechnicianList');

		if (primaryList) primaryList.style.display = 'none';
		if (backupList) backupList.style.display = 'none';
		if (paperList) paperList.style.display = 'none';

		const value = document.getElementById(e.currentTarget.id.replace('PlusButton', 'Input')).value;
		if (!value) return;

		switch (e.currentTarget.id) {
			case 'StoreHoursPlusButton':
				handleArrayChange(setStoreHours, storeHours, value);
				break;
			case 'PrimPlusTechButton':
				handleArrayChange(setPrimaryTechs, primaryTechs, value);
				setPrimaryTechsInput('');
				break;
			case 'BackPlusTechButton':
				handleArrayChange(setBackupTechs, backupTechs, value);
				setBackupTechsInput('');
				break;
			case 'PapPlusTechButton':
				setPaperTech(value);
				setPaperTechInput('');
				break;
		}
	};

	//When a Parts List Item is Selected (Grey) Clicking the Minus Button Removes it From the List
	/**
	 * Function that handles the minus button click event for the technician lists.
	 * @param {Event} e - The click event object.
	 */
	const MinusTechButton = (e) => {
		document.getElementById('TablePrimaryTechnicianList').style.display = 'none';
		document.getElementById('TableBackupTechnicianList').style.display = 'none';
		document.getElementById('TablePaperTechnicianList').style.display = 'none';

		const value = document.getElementById(e.currentTarget.id.replace('MinusButton', 'Input')).value;

		switch (e.currentTarget.id) {
			case 'StoreHoursMinusButton':
				handleArrayRemove(setStoreHours, storeHours, storeHoursValue);
				break;
			case 'PrimMinusTechButton':
				handleArrayRemove(setPrimaryTechs, primaryTechs, primaryTechValue);
				break;
			case 'BackMinusTechButton':
				handleArrayRemove(setBackupTechs, backupTechs, backupTechValue);
				break;
			case 'PapMinusTechButton':
				setPaperTech('');
				break;
		}
	};

	/**
	 * Function that handles the deletion of a Kiosk.
	 * Prompts the user to confirm the deletion and emits a socket event to delete the Kiosk.
	 * If the deletion is successful, it refreshes the data and hides the modal.
	 * If the deletion fails, it displays an alert message.
	 */
	const KioskDeleteButton = () => {
		const deleteJobConfirm = prompt("Please enter 'DELETE' to confirm deletion of this Kiosk.", '');

		if (deleteJobConfirm?.toLowerCase() === 'delete') {
			try {
				const KioskID = KioskID;
				socket.emit('deleteKioskForTM', KioskID, (success) => {
					if (success) {
						props.refresh_data();
						document.getElementById('modal_kiosks').style.display = 'none';
						document.getElementById('myModal').style.display = 'none';
					} else {
						alert('Kiosk was not deleted.');
					}
				});
			} catch (error) {
				console.error('Error deleting Kiosk:', error);
				alert('An error occured while trying to delete the kiosk.');
			}
		}
	};

	/**
	 * Function to handle the closing of the Kiosk modal.
	 */
	function KioskCloseButton() {
		document.getElementById('modal_kiosks').style.display = 'none';
		document.getElementById('myModal').style.display = 'none';
		props.close_modal();
	}

	/**
	 * Updates the technicians and coordinates for a given kiosk.
	 *
	 * @param {string} kiosk_address - The address of the kiosk.
	 * @param {string} kiosk_id - The ID of the kiosk.
	 * @param {string} KioskID_Loc - The location of the kiosk ID.
	 * @param {string} type - The type of the kiosk.
	 */
	async function UpdateTechsAndCoordinates(kiosk_address, kiosk_id, KioskID_Loc, type) {
		try {
			// Await the axios.post call to get the resolved value
			const coordinatesResponse = await axios.post(`/api/geocode_address`, { address: kiosk_address });
			const kiosk_coordinates = coordinatesResponse.data;
			if (!kiosk_coordinates) {
				console.error(`ERROR: KioskModal.geocode_address: Could not geocode address: ${kiosk_address} - ${new Date()}`);

				alert('Could not geocode address. Please check the address and try again.');

				return;
			}

			const latLong = {
				ForTable: 'kiosks',
				ID: kiosk_id,
				Latitude: kiosk_coordinates.latitude,
				Longitude: kiosk_coordinates.longitude,
			};

			await new Promise((resolve) => {
				socket.emit('insertLatLong', latLong, resolve);
			});

			return await axios.post(`/api/get_drive_times`, {
				address: kiosk_address,
				coordinates: kiosk_coordinates,
				kiosk_id: kiosk_id,
				KioskID_Loc: KioskID_Loc,
				type: type,
			});
		} catch (error) {
			console.error(`ERROR: KioskModal.UpdateTechsAndCoordinates: ${error.message} - ${new Date()}`);

			alert('An error occured while updating the kiosk. Please try again.');
			throw error;
		}
	}

	// TODO: Make this function less repetative. This needs to update the values in the DB with the values being entered
	// Only update the DB if the values are different than the state values
	/**
	 * Saves the changes made to the kiosk information.
	 * If the kiosk information has changed or is being entered for the first time, it archives the original information and updates the kiosk.
	 * If the address has changed, it updates the tech drive times and kiosk coordinates.
	 *
	 * @async
	 * @function KioskSaveButton
	 * @returns {void}
	 */
	const KioskSaveButton = () => {
		let changed = false;
		const Constructed_KioskID_Loc = `${kioskID}_${serverID.trim().replace(/ /g, '').replace(/-/g, '_')}`;
		const oldKioskData = normalizeData(kioskOld);
		const newKioskData = normalizeData({
			KioskID: kioskID,
			KioskID_Loc: Constructed_KioskID_Loc,
			KioskInstallDate: kioskInstallDate,
			StoreName: storeName,
			StoreHours: storeHours,
			StorePhone: storePhone,
			StoreAddress: storeAddress,
			StoreCity: storeCity,
			StoreState: storeState,
			StoreZip: storeZip,
			StoreCounty: storeCounty,
			ServerID: serverID,
			ModemType: modemType,
			Carrier: carrier,
			CarrierNumber: carrierNumber,
			PrimaryTechs: primaryTechs,
			BackupTechs: backupTechs,
			PaperChanger: paperTech,
			Notes: notes,
			has_switch: document.getElementById('restart-switch').checked ? 1 : 0,
			paper_bridge: document.getElementById('paper-bridge').checked ? 1 : 0,
			cleaned: document.getElementById('cleaned').value
				? document.getElementById('cleaned').value
				: setLastCleaned(document.getElementById('cleaned').value),
			star_firmware: document.getElementById('star-firmware').value,
			ski_slope: document.getElementById('ski-slope').checked ? 1 : 0,
			os_installed: document.getElementById('os-installed').value
				? document.getElementById('os-installed').value
				: setOsInstall(document.getElementById('os-installed').value),
			ActiveOrInactive: activeOrInactive,
			NumberOfStarRolls: numberOfStarRolls,
			Latitude: latitude,
			Longitude: longitude,
		});
		const old_address = oldKioskData.StoreAddress;
		const old_city = oldKioskData.StoreCity;
		const old_state = oldKioskData.StoreState;

		const address = newKioskData.StoreAddress;
		const city = newKioskData.StoreCity;
		const state = newKioskData.StoreState;

		const old_kiosk_address = `${old_address} ${old_city} ${old_state}`; // old kiosk address
		const new_kiosk_address = `${address} ${city} ${state}`; // new kiosk address

		const objectsEqual = deepEqual(oldKioskData, newKioskData);
		const did_address_change = old_kiosk_address !== new_kiosk_address;

		if (!objectsEqual || did_address_change) {
			changed = true;
		}

		if (!did_address_change) {
			newKioskData.Latitude = oldKioskData.Latitude;
			newKioskData.Longitude = oldKioskData.Longitude;
		}

		const evaluateValues = !objectsEqual ? newKioskData : oldKioskData;
		if (!changed) {
			alert('No changes were made to the kiosk information.');
		} else {
			// if kiosk info has changed, or is being entered for the first time, archive the original info
			socket.emit('archiveKioskForTM', evaluateValues, async () => {
				if (kioskID_Loc) {
					socket.emit('updateKioskForTM', evaluateValues, async (updateSuccess) => {
						try {
							if (!updateSuccess) {
								alert('You did not fill the form out correctly!');
							} else if (did_address_change) {
								await UpdateTechsAndCoordinates(
									new_kiosk_address,
									evaluateValues.KioskID,
									evaluateValues.KioskID_Loc,
									'update'
								);
								KioskCloseButton(); // Close the modal after updating the kiosk
							}
							KioskCloseButton(); // Close the modal after updating the kiosk
						} catch (error) {
							console.error(`ERROR: KioskModal.updateKioskForTM: ${error.message} - ${new Date()}`);

							throw error;
						}
					});
				}
			});
		}
	};

	// Attach KioskSaveButton to the window object
	window.KioskSaveButton = KioskSaveButton;

	/**
	 * Formats a list of strings by removing double quotes.
	 *
	 * @param {string[]} _list - The list of strings to be formatted.
	 * @returns {string[]} - The formatted list of strings.
	 */
	function FormatTechs(_list) {
		if (Array.isArray(_list)) {
			return _list.map((el) => el.replace(/['"]+/g, ''));
		}
		return _list;
	}

	/**
	 * Function to handle adding a kiosk.
	 *
	 * @async
	 * @function KioskAddButton
	 * @returns {void}
	 */
	const KioskAddButton = async () => {
		// remove this check when finished
		if (!kioskID) {
			console.error('Kiosk ID is undefined! Please enter a valid Kiosk ID.');
			return;
		}

		const kiosk_address = `${storeAddress} ${storeCity} ${storeState}`.trim();
		const Constructed_KioskID_Loc = `${kioskID}_${serverID.trim().replace(/ /g, '').replace(/-/g, '_')}`;

		const newKioskData = normalizeData({
			KioskID: document.getElementById('kioskID')?.value || kioskID,
			KioskID_Loc: Constructed_KioskID_Loc,
			KioskInstallDate: document.getElementById('kioskInstallDate')?.value || kioskInstallDate,
			StoreName: document.getElementById('kioskStoreName')?.value || storeName,
			StoreHours: storeHours,
			StorePhone: document.getElementById('kioskStorePhone')?.value || storePhone,
			StoreAddress: document.getElementById('storeAddress')?.value || storeAddress,
			StoreCity: document.getElementById('storeCity')?.value || storeCity,
			StoreState: document.getElementById('storeState')?.value || storeState,
			StoreZip: document.getElementById('storeZip')?.value || storeZip,
			StoreCounty: document.getElementById('storeCounty')?.value || storeCounty,
			ServerID: document.getElementById('kioskServerID')?.value || serverID,
			ModemType: document.getElementById('kioskModem')?.value || modemType,
			Carrier: document.getElementById('kioskCarrier')?.value || carrier,
			CarrierNumber: document.getElementById('kioskCarrierPhone')?.value || carrierNumber,
			PrimaryTechs: primaryTechs,
			BackupTechs: backupTechs,
			PaperChanger: paperTech,
			Notes: document.getElementById('KioskNotesSection')?.value || notes,
			has_switch: document.getElementById('restart-switch')?.checked ? 1 : 0,
			paper_bridge: document.getElementById('paper-bridge')?.checked ? 1 : 0,
			cleaned: document.getElementById('cleaned')?.value || lastCleaned,
			star_firmware: document.getElementById('star-firmware')?.value || starFirmware,
			ski_slope: document.getElementById('ski-slope')?.checked ? 1 : 0,
			os_installed: document.getElementById('os-installed')?.value || osInstall,
			ActiveOrInactive: activeOrInactive,
			NumberOfStarRolls: document.getElementById('numberofstarrolls')?.value || numberOfStarRolls,
			Latitude: latitude,
			Longitude: longitude,
		});
		if (!kiosk_address) {
			alert('WARNING: Kiosk Address Cannot Be Blank');
		} else {
			socket.emit('archiveKioskForTM', newKioskData, async () => {
				try {
					const add_new_kiosk_data = await new Promise((resolve) => {
						socket.emit('insertKioskForTM', newKioskData, resolve);
					});

					if (add_new_kiosk_data) {
						await UpdateTechsAndCoordinates(kiosk_address, kioskID, kioskID_Loc, 'add');
						KioskCloseButton(); // Close the modal after updating the kiosk
					} else {
						alert('You did not fill the form out correctly');
					}
				} catch (error) {
					console.error(`ERROR: KioskModal.archiveKioskForTM ${error} - ${new Date()}`);
				}
			});
		}
	};

	const InputField = ({ id, label, value, onChange, type = 'text', maxLength, size = 'small' }) => (
		<TextField
			id={id}
			label={label}
			className={`${id} KioskModalField`}
			defaultValue={value}
			onChange={onChange}
			type={type}
			InputLabelProps={{ shrink: true }}
			maxLength={maxLength}
			size={size}
		/>
	);

	const CheckboxField = ({ id, label, checked }) => (
		<FormControlLabel label={label} control={<Checkbox id={id} color='primary' defaultChecked={checked} />} />
	);

	const TechList = ({
		label,
		inputId,
		inputValue,
		onChange,
		plusButtonId,
		minusButtonId,
		techs,
		listClassName,
		tableClassName,
	}) => (
		<div className={`${label.replace(' ', '')}Div`} onClick={DisplayList}>
			<label className={`${label.replace(' ', '')}Label`} onClick={DisplayList}>
				{label}
			</label>
			<div className={`${label.replace(' ', '')}Header`} onClick={DisplayList}>
				<input
					className={`${label.replace(' ', '')}NameInput`}
					id={inputId}
					type='text'
					placeholder={label}
					value={inputValue}
					onChange={onChange}
					onClick={() => {
						DisplayList;
						techAndDriveTime();
					}}
				/>
				<IconButton
					variant='outlined'
					size='small'
					className='round-button'
					id={minusButtonId}
					onClick={MinusTechButton}
				>
					<MdRemove />
				</IconButton>
				<IconButton variant='outlined' size='small' className='round-button' id={plusButtonId} onClick={PlusTechButton}>
					<MdAdd />
				</IconButton>
			</div>
			{RenderList('', techs, listClassName, tableClassName)}
		</div>
	);

	return (
		<div>
			{loading ? (
				<Spinner />
			) : (
				<div className='KioskFormContainer' onClick={DisplayList}>
					<div className='KioskForm' onClick={DisplayList}>
						{RenderList(primaryTechsInput, techDriveTimeList, 'PrimaryTechnicianList', 'TablePrimaryTechnicianList')}
						{RenderList(backupTechsInput, techDriveTimeList, 'BackupTechnicianList', 'TableBackupTechnicianList')}

						<div className='flex-align-center flex-just-between flex-wrap' onClick={DisplayList}>
							<InputField id='kioskID' label='Kiosk ID' value={kioskID} onChange={(e) => onChange(e, 'KioskID')} />
							<InputField
								id='kioskInstallDate'
								label='Install Date'
								value={kioskInstallDate}
								onChange={(e) => onChange(e, 'KioskInstallDate')}
								type='date'
							/>
						</div>

						<div className='SecondLayer vert-margin' onClick={DisplayList}>
							<div className='FormLeft' onClick={DisplayList}>
								<div className='vert-margin'>
									<InputField
										id='kioskStoreName'
										label='Store Name'
										value={storeName}
										onChange={(e) => onChange(e, 'StoreName')}
										maxLength='45'
									/>
								</div>
								<div className='vert-margin'>
									<InputField
										id='kioskStorePhone'
										label='Store Phone Number'
										value={storePhone}
										onChange={(e) => onChange(e, 'StorePhone')}
										maxLength='10'
									/>
								</div>
							</div>

							<div className='StoreHoursTableContent' onClick={DisplayList}>
								<div className='StoreHoursHeader' onClick={DisplayList}>
									<IconButton
										variant='outlined'
										size='small'
										className='round-button'
										id='StoreHoursMinusButton'
										onClick={MinusTechButton}
									>
										<MdRemove />
									</IconButton>
									<IconButton
										variant='outlined'
										size='small'
										className='round-button'
										id='StoreHoursPlusButton'
										onClick={PlusTechButton}
									>
										<MdAdd />
									</IconButton>
									<TextField
										id='StoreHoursInput'
										className='StoreHoursInput KioskModalField'
										label='Store Hours'
										InputLabelProps={{ shrink: true }}
										type='text'
										size='small'
									/>
								</div>
								{RenderList('', storeHours, 'StoreHoursList', 'StoreHoursBodyTable')}
							</div>
						</div>

						<div className='flex-align-center flex-just-between flex-wrap vert-margin' onClick={DisplayList}>
							<InputField
								id='kioskServerID'
								label='Server ID'
								value={serverID}
								onChange={(e) => onChange(e, 'ServerID')}
								maxLength='45'
							/>
							<InputField
								id='kioskModem'
								label='Modem'
								value={modemType}
								onChange={(e) => onChange(e, 'ModemType')}
								maxLength='20'
							/>
						</div>

						<div className='flex-align-center flex-just-between flex-wrap vert-margin' onClick={DisplayList}>
							<InputField
								id='kioskCarrier'
								label='Carrier'
								value={carrier}
								onChange={(e) => onChange(e, 'Carrier')}
								maxLength='20'
							/>
							<InputField
								id='kioskCarrierPhone'
								label='Carrier Number'
								value={carrierNumber}
								onChange={(e) => onChange(e, 'CarrierNumber')}
								maxLength='10'
							/>
						</div>

						<div className='FifthLayer flex-wrap' id='FifthLayer'>
							<div className='vert-margin flex-row flex-just-between'>
								<InputField
									id='cleaned'
									label='Last Cleaned'
									value={lastCleaned}
									onChange={(e) => onChange(e, 'LastCleaned')}
									type='date'
								/>
								<InputField
									id='star-firmware'
									label='Star Firmware'
									value={starFirmware}
									onChange={(e) => onChange(e, 'StarFirmware')}
									type='date'
								/>
								<InputField
									id='os-installed'
									label='OS Install'
									value={osInstall}
									onChange={(e) => onChange(e, 'OsInstall')}
									type='date'
								/>
							</div>
							<AddressField
								Address={storeAddress}
								City={storeCity}
								County={storeCounty}
								State={storeState}
								Zip={storeZip}
								onChange={onChange}
							/>

							<div className='vert-margin flex-row flex-just-between'>
								<CheckboxField id='restart-switch' label='Has Restart Switch' checked={hasSwitch} />
								<CheckboxField id='paper-bridge' label='Has Paper Bridge' checked={paperBridge} />
								<CheckboxField id='ski-slope' label='Has Ski Slope' checked={skiSlope} />
							</div>
							<textarea
								className='KioskNotesSection'
								id='KioskNotesSection'
								value={notes}
								onChange={(e) => onChange(e, 'KioskNotes')}
								onClick={DisplayList}
							></textarea>
						</div>

						<div>
							<button className='RTIButton' id='KIOSFormClose' onClick={KioskCloseButton}>
								CLOSE
							</button>

							{props.kioskKey && props.role === 'admin' && (
								<button
									className='RTIButton'
									id='KIOSFormDelete'
									onClick={KioskDeleteButton}
									style={{ position: 'absolute', top: '0', right: '0' }}
								>
									DELETE
								</button>
							)}

							{props.kioskKey && props.role !== 'user' && props.role !== 'noAcc' && (
								<button className='RTIButton' id='KIOSFormSave' onClick={KioskSaveButton} style={{ float: 'right' }}>
									SAVE
								</button>
							)}

							{(!props.kioskKey && props.role === 'admin') ||
								(props.role === 'master' && (
									<button className='RTIButton' id='KIOSFormAdd' onClick={KioskAddButton} style={{ float: 'right' }}>
										ADD
									</button>
								))}
						</div>
					</div>

					<div className='TechniciansTableContent' onClick={DisplayList}>
						<select value={activeOrInactive} onChange={(e) => onChange(e, 'ActiveOrInactive')} onClick={DisplayList}>
							<option>Active</option>
							<option>InActive</option>
						</select>
						<TechList
							label='Primary Techs'
							inputId='PrimaryTechNameInput'
							inputValue={primaryTechsInput}
							onChange={(e) => onChange(e, 'PrimaryTechsInput')}
							plusButtonId='PrimPlusTechButton'
							minusButtonId='PrimMinusTechButton'
							techs={primaryTechs}
							listClassName='PrimaryTechsList'
							tableClassName='PrimaryTechsListTable'
						/>
						<TechList
							label='Backup Techs'
							inputId='BackupTechNameInput'
							inputValue={backupTechsInput}
							onChange={(e) => onChange(e, 'BackupTechsInput')}
							plusButtonId='BackPlusTechButton'
							minusButtonId='BackMinusTechButton'
							techs={backupTechs}
							listClassName='BackupTechsList'
							tableClassName='BackupTechsListTable'
						/>
						<TechList
							label='Paper Tech'
							inputId='PaperTechNameInput'
							inputValue={paperTechInput}
							onChange={(e) => onChange(e, 'PaperTechInput')}
							plusButtonId='PapPlusTechButton'
							minusButtonId='PapMinusTechButton'
							techs={[paperTech]}
							listClassName='PaperTechList'
							tableClassName='PaperTechListTable'
						/>
						<div
							className='NumberOfStarRolls'
							onClick={DisplayList}
							style={{ textAlign: 'center', position: 'relative', top: '15px' }}
						>
							<label onClick={DisplayList}>Star Rolls</label> <br />
							<input
								className='numberOfStarRolls'
								id='numberofstarrolls'
								type='text'
								maxLength='3'
								value={numberOfStarRolls}
								onChange={(e) => onChange(e, 'NumberOfStarRolls')}
								onClick={DisplayList}
								style={{ width: '52px' }}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

KioskModal.propTypes = {
	kioskKey: PropTypes.string,
	kioskModal: PropTypes.object,
	driveTimes: PropTypes.array,
	refresh_data: PropTypes.func.isRequired,
	close_modal: PropTypes.func.isRequired,
	role: PropTypes.string,
};
