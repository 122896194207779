// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-boundary {
  padding: 20px;
}

.center-screen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }


.login-link{
    height: 1.2rem;
    width: auto;
    color: #007bff;
    font-weight: 600;
    font-size: 1.2rem;
}

.error-boundary .login-link a {
  font-size: 24px;
  font-weight: bold; 
  color: #007bff; 
  text-decoration: underline; 
}

.error-boundary .login-link a:hover {
  color: #0056b3; 
}`, "",{"version":3,"sources":["webpack://./src/components/ErrorBoundary.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;EACnB;;;AAGF;IACI,cAAc;IACd,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".error-boundary {\n  padding: 20px;\n}\n\n.center-screen {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    min-height: 100vh;\n  }\n\n\n.login-link{\n    height: 1.2rem;\n    width: auto;\n    color: #007bff;\n    font-weight: 600;\n    font-size: 1.2rem;\n}\n\n.error-boundary .login-link a {\n  font-size: 24px;\n  font-weight: bold; \n  color: #007bff; \n  text-decoration: underline; \n}\n\n.error-boundary .login-link a:hover {\n  color: #0056b3; \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
