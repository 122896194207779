// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help-container {
    position: absolute;
    top: 60px;
}

.help-solution {
    white-space: break-spaces;
}`, "",{"version":3,"sources":["webpack://./src/StyleSheets/Help.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".help-container {\n    position: absolute;\n    top: 60px;\n}\n\n.help-solution {\n    white-space: break-spaces;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
