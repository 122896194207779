import React, { useState } from 'react';
import Counter from './Counter';

import '../../StyleSheets/StickerCounter.css';

const StickerCounter = () => {
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [nextYear, setNextYear] = useState(new Date().getFullYear() + 1);
	const [futureYear, setFutureYear] = useState(new Date().getFullYear() + 2);

	return (
		<div>
			<div className='parent-counter-container'>
				<Counter className={currentYear} name={currentYear} setCurrentYear={setCurrentYear} />

				<Counter className={nextYear} name={nextYear} setNextYear={setNextYear} />

				<Counter className={futureYear} name={futureYear} setFutureYear={setFutureYear} />
			</div>
		</div>
	);
};

export default StickerCounter;
